import { faCheck, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListGroup } from "react-bootstrap";
import { GlobalVariable, Variable } from "../../../../types";
import { getIconFor } from "../../../shared/utils";

type VariablesListProps = {
  variables: Variable[];
  variable: GlobalVariable;
  setVariable: (variable: GlobalVariable) => void;
};

export function VariablesList({
  variables,
  variable,
  setVariable,
}: VariablesListProps) {
  if (variables.length === 0) {
    return (
      <p>
        There are no variables in use in this applet, try adding some nodes
        first!
      </p>
    );
  }

  return (
    <ListGroup className="context-list list-bg">
      {variables.map((item, index) => (
        <ListGroup.Item
          key={index}
          onClick={() => {
            if (variable.variables[item.value]) {
              const _variable = { ...variable };

              delete _variable.variables[item.value];

              setVariable(_variable);
            } else {
              setVariable({
                ...variable,
                variables: {
                  ...variable.variables,
                  [item.value]: item,
                },
              });
            }
          }}
          className="hover"
        >
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              <FontAwesomeIcon icon={getIconFor(item.icon)} />{" "}
              <strong>
                {"{"}
                {item.source === "global" ? "g_" + item.value : item.value}
                {"}"}
              </strong>{" "}
              <small>
                | {item.type} | {item.origin}
              </small>
              <br />
              <small>{item.description}</small>
            </div>
            <div>
              {!variable.variables[item.value] ? (
                <FontAwesomeIcon
                  icon={faTimes}
                  size="lg"
                  className="text-danger"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faCheck}
                  size="lg"
                  className="text-success"
                />
              )}
            </div>
          </div>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
}

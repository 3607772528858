import { faChevronDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Dropdown } from "react-bootstrap";

export type SelectDropdownItem = {
  name: string;
  value: string;
  imageUrl?: string;
};

type SelectDropdownProps = {
  placeholder: string;
  value?: string;
  onChange?: (value: string) => void;
  items: SelectDropdownItem[];
  isInvalid?: boolean;
  errorMessage?: string;
};

export function SelectDropdown({
  value,
  onChange,
  placeholder,
  items,
  isInvalid,
  errorMessage,
}: SelectDropdownProps) {
  const [selected, setSelected] = useState(
    items.find((x) => x.value === value)
  );

  return (
    <>
      <Dropdown
        className={`dropdown-select ${isInvalid ? "border border-danger" : ""}`}
      >
        <Dropdown.Toggle id="team-select">
          <div className="d-flex align-items-center">
            <div className="flex-grow-1">
              {selected && selected.imageUrl && (
                <img
                  src={selected.imageUrl}
                  alt="Team Icon"
                  height={24}
                  className="mr-2"
                />
              )}
              {selected ? selected.name : placeholder}
            </div>
            <FontAwesomeIcon icon={faChevronDown} size="sm" />
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {items.map((item) => (
            <Dropdown.Item
              key={item.value}
              onClick={() => {
                setSelected(item);
                if (onChange) {
                  onChange(item.value);
                }
              }}
            >
              <img
                src={item.imageUrl}
                alt="Team Icon"
                height={24}
                className="mr-2"
              />
              {item.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      {errorMessage && <small className="text-danger">{errorMessage}</small>}
    </>
  );
}

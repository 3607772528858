import { faPencil, faTrash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createElement, useState } from "react";
import { Button } from "react-bootstrap";
import { Applet, GlobalVariable } from "../../../../types";
import { ConfirmationModal } from "../../../shared/modals/ConfirmationModal";
import { elements } from "./elements";
import { ConfigElementModal } from "./Modals/ConfigElementModal";

type ElementProps = {
  data: Applet;
  setData: (data: Applet) => void;
  variable: GlobalVariable;
  onDragStart: () => void;
  onDragEnd: () => void;
  onDragOver: () => void;
};

export function Element({
  data,
  setData,
  variable,
  onDragStart,
  onDragEnd,
  onDragOver,
}: ElementProps) {
  const [dragging, setDragging] = useState(false);
  const [hovering, setHovering] = useState(false);
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);

  function dragStart(e: React.DragEvent<HTMLDivElement>) {
    e.stopPropagation();
    setDragging(true);
    setHovering(false);
    onDragStart();
  }

  function dragEnd(e: React.DragEvent<HTMLDivElement>) {
    e.stopPropagation();
    setDragging(false);
    onDragEnd();
  }

  function dragOver(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    onDragOver();
  }

  function deleteElement() {
    setData({
      ...data,
      globalVariables: [
        ...data.globalVariables.filter((x) => x.id !== variable.id),
      ],
    });
  }

  return (
    <>
      <div
        className={`config-editor-element ${dragging ? "dragging" : ""}`}
        onDragStart={dragStart}
        onDragEnd={dragEnd}
        onDragOver={dragOver}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        draggable
      >
        <div
          className="config-editor-element-controls"
          style={{
            width: hovering && !dragging ? 40 : 0,
          }}
        >
          <Button
            className="m-1"
            size="sm"
            style={{ width: 30, height: 30 }}
            block
            onClick={() => setEditing(true)}
          >
            <FontAwesomeIcon icon={faPencil} />
          </Button>
          <Button
            className="m-1"
            size="sm"
            variant="outline-primary"
            style={{ width: 30, height: 30 }}
            block
            onClick={() => setDeleting(true)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </Button>
        </div>
        {createElement(elements[variable.type].component, {
          name: variable.name,
          value: variable.defaultValue,
          disabled: true,
        })}
      </div>
      {editing && (
        <ConfigElementModal
          data={data}
          element={elements[variable.type]}
          onClose={() => setEditing(false)}
          editVariable={variable}
        />
      )}
      {deleting && (
        <ConfirmationModal
          title="Delete Element?"
          body={`Are you sure you want to delete element ${variable.name}? This will also delete the global variable associated with the element. If you are using this in your applet it may no longer work as expected!`}
          onClose={() => setDeleting(false)}
          onConfirm={() => {
            deleteElement();
            setDeleting(false);
          }}
        />
      )}
    </>
  );
}

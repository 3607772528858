import { LinkInstance, NodeInstance, NodeOption } from "../../../../../types";
import _ from "underscore";

type LinkProps = {
  nodes: NodeInstance[];
  link: LinkInstance;
  nodeWidth: (node: NodeOption) => number;
  nodeHeight: () => number;
  onDelete: () => void;
};

export function Link({
  nodes,
  link,
  nodeWidth,
  nodeHeight,
  onDelete,
}: LinkProps) {
  const outputNode = _.find(nodes, (node) => node.id === link.nodeId);
  const inputNode = _.find(nodes, (node) => node.id === link.inputId);

  if (outputNode && inputNode) {
    const x1 = outputNode.x + nodeWidth(outputNode.node) - 20;
    const y1 =
      outputNode.y +
      (nodeHeight() / (outputNode.node.outputs.length + 1)) *
        (outputNode.node.outputs.map((o) => o.id).indexOf(link.outputId) + 1);
    const x2 = inputNode.x + 20;
    const y2 = inputNode.y + nodeHeight() / 2;

    return (
      <line
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
        strokeWidth={5}
        onMouseDown={(e) => {
          if (e.button === 1) {
            onDelete();
          }
        }}
      />
    );
  }
  return null;
}

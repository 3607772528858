import axios from "axios";
import { createElement, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { APIUrl } from "../../../Constants";
import { Applet, ConfigGroup, GlobalVariable } from "../../../types";
import { Spinner } from "../../shared/Spinner";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { UnloadWarning } from "../../shared/UnloadWarning";
import { useNotifications } from "../../../context/NotificationContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPencil,
  faPencilAlt,
  faSave,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";
import { elements } from "../Editor/ConfigEditor/elements";

type EditorProps = {
  onEdit: (applet: Applet) => void;
};

type ConfigData = {
  name: string;
  variables: GlobalVariable[];
  values: { [key: string]: string };
  groups: ConfigGroup[];
};

export function Config({ onEdit }: EditorProps) {
  const [loading, setLoading] = useState(true);
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [config, setConfig] = useState<ConfigData>();
  const [hasChanges, setHasChanges] = useState(false);
  const notifications = useNotifications();
  const [renaming, setRenaming] = useState(false);
  const [renameInput, setRenameInput] = useState("");
  const [renameLoading, setRenameLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    let cancelled = false;
    async function getData() {
      if (id) {
        const response = await axios.get(`${APIUrl}/applet/${id}`);
        if (!cancelled) {
          const _data = response.data;

          setConfig({
            name: _data.name,
            variables: _data.globalVariables,
            values: _data.values,
            groups: _data.configGroups,
          });
          setRenameInput(_data.name);
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    }
    getData();

    return () => {
      cancelled = true;
    };
  }, [id]);

  async function save() {
    setIsSaving(true);
    if (config) {
      const response = await axios.post(
        `${APIUrl}/applet/${id}/config`,
        config.values
      );
      onEdit(response.data);
      setHasChanges(false);
      notifications.dispatchNotification(
        "success",
        "Applet Saved",
        `Applet "${config.name}" has been saved successfully`
      );
    }
    setIsSaving(false);
  }

  async function rename() {
    setRenameLoading(true);
    const response = await axios.post(`${APIUrl}/applet/${id}/rename`, {
      name: renameInput,
    });
    onEdit(response.data);
    setConfig({
      name: response.data.name,
      values: config!.values,
      variables: config!.variables,
      groups: config!.groups,
    });
    setRenaming(false);
    setRenameLoading(false);
  }

  if (loading || !config) {
    return <Spinner />;
  }

  return (
    <div className="page-content">
      <Container fluid>
        <div className="d-flex">
          <div className="flex-grow-1">
            {renaming ? (
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  rename();
                }}
              >
                <Form.Row className="align-items-center">
                  <Col xs="auto">
                    <Form.Control
                      placeholder="Applet Name"
                      autoComplete="off"
                      type="text"
                      value={renameInput}
                      onChange={(e) => setRenameInput(e.target.value)}
                      autoFocus
                    />
                  </Col>
                  <Col xs="auto">
                    <Button
                      type="button"
                      className="mr-2"
                      variant="outline-primary"
                      onClick={() => setRenaming(false)}
                      disabled={renameLoading}
                    >
                      Cancel
                    </Button>
                    <Button type="submit" disabled={renameLoading}>
                      {renameLoading ? "Saving..." : "Save"}
                    </Button>
                  </Col>
                </Form.Row>
              </Form>
            ) : (
              <h2
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 0,
                }}
              >
                {config.name}{" "}
                <FontAwesomeIcon
                  icon={faPencilAlt}
                  className="text-primary"
                  style={{ fontSize: 20, marginLeft: 10, cursor: "pointer" }}
                  onClick={() => setRenaming(true)}
                />
              </h2>
            )}
          </div>
          <div className="d-flex">
            <Button
              className="mx-1"
              variant="outline-primary"
              onClick={() => history.push("/applets")}
            >
              <FontAwesomeIcon icon={faTimes} /> Close
            </Button>
            <Button
              className="mx-1"
              variant="outline-primary"
              onClick={() => history.push(`/applets/editor/${id}`)}
            >
              <FontAwesomeIcon icon={faPencil} /> Open Editor
            </Button>
            <Button
              className="mx-1"
              variant="primary"
              onClick={save}
              disabled={isSaving}
            >
              <FontAwesomeIcon icon={faSave} />{" "}
              {isSaving ? "Saving..." : "Save"}
            </Button>
          </div>
        </div>
        {config.groups.length <= 0 && <p>This applet has no configuration.</p>}
        {config.groups.map((group) => (
          <div key={group.id} className="category">
            <h4 className="mb-3">{group.name}</h4>
            <Row>
              {group.panels.map((panel) => (
                <Col key={panel.id} md={3} className="mb-4">
                  <div className="panel">
                    <h5 className="mb-3">{panel.name}</h5>
                    {config.variables
                      .filter(
                        (x) =>
                          x.category === group.id && x.subcategory === panel.id
                      )
                      .map((v) => {
                        if (typeof elements[v.type] !== "undefined") {
                          return createElement(elements[v.type].component, {
                            key: v.id,
                            id: v.id,
                            value: config.values[v.id],
                            setValue: (val: string) => {
                              if (!hasChanges) setHasChanges(true);
                              setConfig({
                                ...config,
                                values: { ...config.values, [v.id]: val },
                              });
                            },
                            name: v.name,
                            data: v.data,
                            defaultValue: v.defaultValue,
                            variables: Object.values(v.variables),
                          });
                        } else {
                          return null;
                        }
                      })}
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        ))}
        <UnloadWarning showWarning={hasChanges} />
      </Container>
    </div>
  );
}

import { faDiscord } from "@fortawesome/free-brands-svg-icons";
import {
  faBars,
  faBug,
  faLifeRing,
  faUserCircle,
  faPallet,
  faSignOutAlt,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { useHistory } from "react-router";
import { ClientUrl } from "../Constants";
import { useAuth } from "../context/AuthContext";
import { useTutorial } from "../context/TutorialContext";
import { Notification } from "../types";
import { NotificationList } from "./NotificationList";

type NavigationProps = {
  sidenavEnabled: boolean;
  sidenavOpen: boolean;
  setSidenavOpen: (value: boolean) => void;
  sidenavOverride: boolean;
  notifications: Notification[];
  setNotifications: (notifications: Notification[]) => void;
  openBugReporter: () => void;
  openThemeSelector: () => void;
};

export function Navigation({
  sidenavEnabled,
  sidenavOpen,
  setSidenavOpen,
  sidenavOverride,
  notifications,
  setNotifications,
  openBugReporter,
  openThemeSelector,
}: NavigationProps) {
  const auth = useAuth();
  const history = useHistory();
  const tutorial = useTutorial();

  return (
    <Navbar expand="lg" className="dashboard-navbar">
      <Navbar.Brand href="/" style={{ width: 270 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 10,
            position: "relative",
          }}
        >
          <img src={`${ClientUrl}/logo.png`} alt="logo" height={30} />
          <h4 className="mb-0">Stream Hydra</h4>
          <Badge
            variant="primary"
            style={{
              position: "absolute",
              fontSize: 12,
              right: -5,
              top: 5,
            }}
          >
            BETA
          </Badge>
        </div>
        {sidenavEnabled && sidenavOpen && (
          <div className="nav-underline">
            <span></span>
          </div>
        )}
      </Navbar.Brand>
      <Nav className="mr-auto">
        {sidenavEnabled && !sidenavOverride && (
          <Nav.Link
            style={{ marginLeft: 20 }}
            onClick={() => setSidenavOpen(!sidenavOpen)}
          >
            <FontAwesomeIcon icon={faBars} style={{ fontSize: 25 }} />
          </Nav.Link>
        )}
      </Nav>
      <NotificationList
        notifications={notifications}
        setNotifications={setNotifications}
      />
      <NavDropdown
        title={
          <>
            {/* <Image
                src=""
                style={{
                  width: 30,
                  height: 30,
                  marginRight: 10,
                  backgroundColor: "#fff",
                }}
                roundedCircle
              /> */}
            {auth.user?.profile.name}
          </>
        }
        id="account-dropdown"
        className="nav-dropdown mr-sm-2"
        alignRight
      >
        <NavDropdown.Item
          href="https://discord.gg/pwx5HScw6M"
          target="_blank"
          rel="noreferrer"
        >
          <FontAwesomeIcon icon={faDiscord} className="mr-2" /> Join Our Discord
        </NavDropdown.Item>
        <NavDropdown.Item onClick={openBugReporter}>
          <FontAwesomeIcon icon={faBug} className="mr-2" /> Found a Bug?
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item onClick={() => tutorial.restartTutorial()}>
          <FontAwesomeIcon icon={faLifeRing} className="mr-2" /> Launch Tutorial
        </NavDropdown.Item>
        <NavDropdown.Item onClick={() => history.push("/account")}>
          <FontAwesomeIcon icon={faUserCircle} className="mr-2" /> My Account
        </NavDropdown.Item>
        <NavDropdown.Item onClick={openThemeSelector}>
          <FontAwesomeIcon icon={faPallet} className="mr-2" /> Change Theme
        </NavDropdown.Item>
        <NavDropdown.Item onClick={() => auth.userManager?.signoutRedirect()}>
          <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" /> Logout
        </NavDropdown.Item>
      </NavDropdown>
    </Navbar>
  );
}

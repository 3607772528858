import {
  faPencilAlt,
  faTrash,
  faUnlink,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListGroup } from "react-bootstrap";
import { Coords } from "../../../../../types";

type ContextMenuProps = {
  coords: Coords;
  connections: number;
  canEdit?: boolean;
  onEdit: () => void;
  onRemoveConnections: () => void;
  onDelete: () => void;
  selectedNodes: string[];
};

export function ContextMenu({
  coords,
  connections,
  canEdit,
  onEdit,
  onRemoveConnections,
  onDelete,
  selectedNodes,
}: ContextMenuProps) {
  return (
    <ListGroup
      className="context-menu"
      style={{ left: coords.x, top: coords.y }}
    >
      {canEdit && (
        <ListGroup.Item onClick={onEdit}>
          <FontAwesomeIcon icon={faPencilAlt} /> Edit
        </ListGroup.Item>
      )}
      <ListGroup.Item onClick={onRemoveConnections}>
        <FontAwesomeIcon icon={faUnlink} /> Remove {connections}{" "}
        {connections > 1 ? "Connections" : "Connection"}{" "}
        {selectedNodes.length > 1 && `from ${selectedNodes.length} Nodes`}
      </ListGroup.Item>
      <ListGroup.Item onClick={onDelete}>
        <FontAwesomeIcon icon={faTrash} />{" "}
        {selectedNodes.length > 1
          ? `Delete ${selectedNodes.length} Nodes`
          : "Delete Node"}
      </ListGroup.Item>
    </ListGroup>
  );
}

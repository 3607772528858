import { faLink, faUnlink } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { Spinner } from "../../shared/Spinner";
import qs from "qs";
import axios from "axios";
import { APIUrl } from "../../../Constants";

export function CustomBot() {
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [username, setUsername] = useState<string>();

  useEffect(() => {
    let cancelled = false;

    async function load() {
      try {
        const response = await axios.get(`${APIUrl}/twitch/custom-bot`);
        if (!cancelled) {
          setUsername(response.data.username);
        }
      } catch (e: any) {
        history.push("/");
      } finally {
        setLoading(false);
      }
    }
    load();

    return () => {
      cancelled = true;
    };
  }, [history]);

  async function unlink() {
    setLoading(true);
    await axios.delete(`${APIUrl}/twitch/custom-bot`);
    setUsername(undefined);
    setLoading(false);
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <Container fluid>
      <h1>Custom Bot Name</h1>
      <p>
        Here you can link another twitch account to act as your bot instead of
        our StreamHydraBot account.
      </p>
      {username ? (
        <>
          <p>Signed in as {username}</p>
          <Button onClick={unlink}>
            <FontAwesomeIcon icon={faUnlink} /> Unlink Account
          </Button>
        </>
      ) : (
        <Button
          onClick={() => {
            window.location.href = `https://id.twitch.tv/oauth2/authorize?client_id=${process.env.REACT_APP_TWITCH_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_TWITCH_REDIRECT_URL}&response_type=code&scope=chat:read chat:edit&force_verify=true`;
          }}
        >
          <FontAwesomeIcon icon={faLink} /> Link Account
        </Button>
      )}
    </Container>
  );
}

export function Signin() {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    async function sendCode() {
      var query = qs.parse(location.search, { ignoreQueryPrefix: true });

      if (query.code) {
        await axios.post(`${APIUrl}/twitch/custom-bot?code=${query.code}`);
        history.push("/twitch/custom-bot");
      } else {
        history.push("/twitch/custom-bot");
      }
    }
    sendCode();
  }, [location, history]);

  return <Spinner fill />;
}

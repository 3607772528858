import {
  faCheck,
  faEnvelopeOpen,
  faEye,
  faRss,
  faUserHeadset,
} from "@fortawesome/pro-light-svg-icons";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import {
  Button,
  Container,
  Form,
  Modal,
  OverlayTrigger,
  Table,
  Tooltip,
} from "react-bootstrap";
import ReactJson from "react-json-view";
import { useHistory } from "react-router-dom";
import { APIUrl } from "../../Constants";
import { Paged, UserError } from "../../types";
import { Paginator } from "../shared/Paginator";
import { Spinner } from "../shared/Spinner";

export function Errors() {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const history = useHistory();
  const [errors, setErrors] = useState<Paged<UserError>>();
  const [showDataModal, setShowDataModal] = useState<UserError>();
  const [showRequestHelp, setShowRequestHelp] = useState<UserError>();
  const [helpMessage, setHelpMessage] = useState<string>();

  useEffect(() => {
    let cancelled = false;

    async function loadErrors() {
      setLoading(true);
      const response = await axios.get(`${APIUrl}/error/user?page=${page}`);
      if (!cancelled) {
        setErrors(response.data);
        setLoading(false);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
    loadErrors();

    return () => {
      cancelled = true;
    };
  }, [page]);

  if (!errors) {
    return <Spinner />;
  }

  async function openError(userError: UserError) {
    if (!loading) {
      try {
        await axios.post(`${APIUrl}/error/user/read/${userError.id}`);
        history.push(
          `/applets/editor/${userError.appletId}?errorNode=${userError.nodeId}&message=${userError.errorMessage}`
        );
      } catch (error) {
        console.log(error);
      }
    }
  }

  async function clearError(userError: UserError) {
    try {
      await axios.post(`${APIUrl}/error/user/resolved/${userError.id}`);
      var newErrors = errors;
      if (newErrors) {
        newErrors.results = newErrors.results.filter(
          (er) => er.id !== userError.id
        );
        newErrors.totalResults--;
        setErrors({ ...newErrors });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setShowRequestHelp(undefined);
    }
  }

  async function markAllAsRead() {
    try {
      await axios.post(`${APIUrl}/error/user/read`);
      if (errors && errors.totalResults > 0) {
        var newErrors = errors;
        var results = newErrors.results;
        for (let i = 0; i < results.length; i++) {
          results[i].read = true;
        }
        newErrors.results = results;
        setErrors({ ...newErrors });
        //TODO Liam can clean this up into 1 line i'm sure, but cba to spend any more time on this when it bloody works
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function sendRequestHelp() {
    if (showRequestHelp) {
      try {
        await axios.post(`${APIUrl}/error/user/help`, {
          errorId: showRequestHelp.id,
          message: helpMessage ? helpMessage : " ",
        });
        var newErrors = errors;
        if (newErrors) {
          var index = newErrors.results.findIndex(
            (error) => error.id === showRequestHelp.id
          );
          if (index !== -1) {
            newErrors.results[index].requestHelp = true;
          }
          setErrors({ ...newErrors });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setShowRequestHelp(undefined);
        setHelpMessage("");
        window.location.reload();
      }
    }
  }

  async function cancelHelpRequest() {
    if (showRequestHelp) {
      try {
        await axios.delete(`${APIUrl}/error/user/help/${showRequestHelp.id}`);
        var newErrors = errors;
        if (newErrors) {
          var index = newErrors.results.findIndex(
            (error) => error.id === showRequestHelp.id
          );
          if (index !== -1) {
            newErrors.results[index].requestHelp = false;
          }
          setErrors({ ...newErrors });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setShowRequestHelp(undefined);
        window.location.reload();
      }
    }
  }

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <h2>Applet Errors</h2>
          <p>
            View any errors from your applets that have occured in the last
            month. You can view more details about each error to aid in fixing
            them or you can request help from the Stream Hydra team and we'll
            gladly help you out.
          </p>
          {errors.totalResults === 0 ? (
            <h5>No Errors, Good Job!</h5>
          ) : loading ? (
            <Spinner />
          ) : (
            <>
              <div className="bg-base mt-5">
                <Table striped hover>
                  <thead>
                    <tr>
                      <th>
                        <OverlayTrigger
                          placement={"top"}
                          overlay={
                            <Tooltip id="markAllAsRead">
                              Mark all errors as read
                            </Tooltip>
                          }
                        >
                          <Button
                            variant="primary"
                            size="sm"
                            onClick={() => markAllAsRead()}
                          >
                            <FontAwesomeIcon icon={faEnvelopeOpen} />
                          </Button>
                        </OverlayTrigger>
                      </th>
                      <th>Applet Name</th>
                      <th>Node</th>
                      <th>Error</th>
                      <th>Occured</th>
                      <th>Options</th>
                    </tr>
                  </thead>
                  {loading ? (
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        bottom: "0px",
                        left: "0px",
                        right: "0px",
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      {/* <Spinner /> */}
                    </div>
                  ) : null}
                  <tbody>
                    {errors.results.map((error) => (
                      <tr key={error.id} onClick={() => openError(error)}>
                        <td>
                          {!error.read ? (
                            <FontAwesomeIcon icon={faCircle} />
                          ) : (
                            <div style={{ width: 20, height: 20 }}></div>
                          )}
                        </td>
                        <td>{error.appletName}</td>
                        <td>{error.nodeName}</td>
                        <td style={{ width: "25%" }}>{error.errorMessage}</td>
                        <td>
                          {DateTime.fromISO(error.createdAt).toLocaleString(
                            DateTime.DATETIME_FULL
                          )}
                        </td>
                        <td style={{ width: "20%" }}>
                          <Button
                            size="sm"
                            variant="primary"
                            disabled={loading}
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowDataModal(error);
                            }}
                            className="mr-1"
                          >
                            <FontAwesomeIcon icon={faEye} /> Data
                          </Button>
                          <Button
                            size="sm"
                            variant="success"
                            className="mr-1"
                            disabled={loading}
                            onClick={(e) => {
                              e.stopPropagation();
                              clearError(error);
                            }}
                          >
                            <FontAwesomeIcon icon={faCheck} /> Clear
                          </Button>
                          {error.requestHelp ? (
                            <Button
                              size="sm"
                              variant="warning"
                              className="mr-1"
                              disabled={loading}
                              onClick={(e) => {
                                e.stopPropagation();
                                setShowRequestHelp(error);
                              }}
                            >
                              <FontAwesomeIcon icon={faRss} /> Help Pending
                            </Button>
                          ) : (
                            <Button
                              size="sm"
                              variant="info"
                              className="mr-1"
                              disabled={loading}
                              onClick={(e) => {
                                e.stopPropagation();
                                setShowRequestHelp(error);
                              }}
                            >
                              <FontAwesomeIcon icon={faUserHeadset} /> Request
                              Help
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <div className="py-3 d-flex justify-content-center align-items-center position-relative">
                  <Paginator
                    totalResults={errors.totalResults}
                    pageSize={errors.pageSize}
                    page={errors.page}
                    setPage={setPage}
                  />
                </div>
              </div>
            </>
          )}
        </Container>
      </div>

      {showRequestHelp !== undefined ? (
        <Modal
          show={showRequestHelp !== undefined}
          onHide={() => setShowRequestHelp(undefined)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Request Help</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              {showRequestHelp && showRequestHelp.messages.length > 0 ? (
                <>
                  <Form.Label>
                    Need some assistance with this problem? Send us a message
                    explaining what you need below and we'll get back to you as
                    quick as we can.
                  </Form.Label>
                  {showRequestHelp.messages.map((message) => (
                    <div
                      id={message.timeStamp}
                      style={{ marginRight: "10px", marginLeft: "10px" }}
                    >
                      <p
                        style={{
                          textAlign:
                            message.displayName === "Stream Hydra Support"
                              ? "left"
                              : "right",
                          marginBottom: "2px",
                          marginLeft: "5px",
                          marginRight: "5px",
                          fontWeight: "bold",
                        }}
                      >
                        {message.displayName} |{" "}
                        {DateTime.fromISO(message.timeStamp).toRelative()}
                      </p>
                      <div className={"help-requester-message"}>
                        <p
                          style={{
                            textAlign:
                              message.displayName === "Stream Hydra Support"
                                ? "left"
                                : "right",
                            whiteSpace: "pre-line",
                          }}
                        >
                          {message.message}
                        </p>
                      </div>
                    </div>
                  ))}
                  <div
                    style={{ display: "flex", float: "right" }}
                    className="mt-3"
                  >
                    <Button
                      variant="success"
                      onClick={() => clearError(showRequestHelp)}
                      className="mr-3"
                      size="sm"
                      style={{ minWidth: 100 }}
                      disabled={loading}
                    >
                      Mark as Resolved
                    </Button>
                    <Button
                      variant="danger"
                      onClick={() => cancelHelpRequest()}
                      style={{ minWidth: 100 }}
                      size="sm"
                      disabled={loading}
                    >
                      Cancel Help Request
                    </Button>
                  </div>
                  <Form.Label className="mt-4">Send a New Message</Form.Label>
                  <Form.Control
                    className="mt-3"
                    as="textarea"
                    rows={8}
                    type="text"
                    placeholder={"Message"}
                    style={{ whiteSpace: "pre-wrap" }}
                    onChange={(e) => {
                      setHelpMessage(e.target.value);
                    }}
                  />
                </>
              ) : (
                <>
                  <Form.Label>
                    Need some assistance with this problem? Send us a message
                    explaining what you need below and we'll get back to you as
                    quick as we can.
                  </Form.Label>
                  <Form.Control
                    className="mt-3"
                    as="textarea"
                    rows={8}
                    type="text"
                    placeholder={"Message"}
                    style={{ whiteSpace: "pre-wrap" }}
                    onChange={(e) => {
                      setHelpMessage(e.target.value);
                    }}
                  />
                </>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-primary"
              onClick={() => setShowRequestHelp(undefined)}
              style={{ minWidth: 100 }}
              disabled={loading}
            >
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                sendRequestHelp();
              }}
              style={{ minWidth: 100 }}
              disabled={loading}
            >
              Send
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}

      {showDataModal !== undefined ? (
        <Modal
          show={showDataModal !== undefined}
          onHide={() => setShowDataModal(undefined)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Applet data</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group>
              <Form.Label>
                View the data that was passed when the error occured:
              </Form.Label>
              {showDataModal && showDataModal.data ? (
                <ReactJson
                  theme="ashes"
                  src={showDataModal!.data}
                  enableClipboard={false}
                  displayDataTypes={false}
                  quotesOnKeys={false}
                />
              ) : (
                <p className="mt-4 ml-3">No data</p>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-primary"
              onClick={() => setShowDataModal(undefined)}
              style={{ minWidth: 100 }}
              disabled={loading}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </>
  );
}

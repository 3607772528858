import {
  faBolt,
  faCogs,
  faTag,
  faTerminal,
  faUserCog,
  IconDefinition,
} from "@fortawesome/pro-light-svg-icons";
import {
  GlobalVariable,
  LinkInstance,
  Module,
  Variable,
  NodeInstance,
  Role,
  TeamMemberRole,
} from "../../types";
import * as lodash from "lodash";

export function getIconFor(type: string): IconDefinition {
  return type === "GlobalVariable"
    ? faUserCog
    : type === "Logic"
    ? faCogs
    : type === "Trigger"
    ? faBolt
    : type === "Comment"
    ? faTag
    : faTerminal;
}

export function calculateContext(
  node: NodeInstance,
  variables: GlobalVariable[],
  nodes: NodeInstance[],
  links: LinkInstance[]
) {
  const _vars: Variable[] = variables.map((v) => ({
    value: `g_${v.id}`,
    source: "global",
    icon: "GlobalVariable",
    origin: "config",
    originId: "config",
    type: v.type,
  }));
  let _nodes = [node];
  let evaluated: string[] = [];

  while (_nodes.length > 0) {
    const _node = _nodes[0];
    const _links = links.filter((link) => link.inputId === _node.id);

    // eslint-disable-next-line no-loop-func
    _links.forEach((link) => {
      const origin = nodes.find((node) => node.id === link.nodeId);

      if (origin) {
        if (!evaluated.find((x) => x === origin.id)) {
          _nodes.push(origin);
          evaluated.push(origin.id);
        }
        const output = origin.node.outputs.find((o) => o.id === link.outputId);

        if (output) {
          Object.keys(output.data).forEach((out) => {
            if (!_vars.find((v) => v.value === out)) {
              _vars.push({
                value: out,
                source: "context",
                icon: origin.node.type,
                origin: origin.node.name,
                originId: origin.nodeId,
                type: output.data[out].type,
                description: output.data[out].description,
                definitions: output.data[out].definitions,
              });
            }
          });
        }
      }
    });

    _nodes = _nodes.filter((n) => n !== _node);
  }

  return _vars;
}

export function findNestedValue(
  data: { [key: string]: unknown },
  parents: string[],
  id: string
) {
  return lodash.get(
    data,
    parents.length > 0 ? `${parents.join(".")}.${id}` : id
  );
}

export function setNestedValue(
  data: { [key: string]: unknown },
  setData: (data: { [key: string]: any }) => void,
  parents: string[],
  id: string,
  value: unknown
) {
  const newData = { ...data };
  lodash.set(
    newData,
    parents.length > 0 ? `${parents.join(".")}.${id}` : id,
    value
  );
  setData(newData);
}

export function deleteNestedValue(
  data: { [key: string]: unknown },
  setData: (data: { [key: string]: any }) => void,
  parents: string[],
  id: string
) {
  const newData = { ...data };
  lodash.unset(newData, parents.length > 0 ? `${parents.join(".")}.${id}` : id);
  setData(newData);
}

export function setNestedErrors(
  errors: { [id: string]: unknown },
  setErrors: (errors: { [id: string]: any }) => void,
  parents: string[],
  id: string,
  error: string
) {
  const newErrors = { ...errors };
  lodash.set(
    newErrors,
    parents.length > 0 ? `${parents.join(".")}.${id}` : id,
    error
  );
  setErrors(newErrors);
}

export function getNestedError(
  errors: { [id: string]: unknown },
  parents: string[],
  id: string,
  arrayIndex?: number
) {
  if (arrayIndex) {
    return lodash.get(
      errors,
      parents.length > 0
        ? `${parents.join(".")}.${id}[${arrayIndex}]`
        : `${id}[${arrayIndex}]`
    ) as string;
  } else {
    return lodash.get(
      errors,
      parents.length > 0 ? `${parents.join(".")}.${id}` : id
    ) as string;
  }
}

export function getPath(parents: string[], id: string) {
  return parents.length > 0 ? `${parents.join(".")}.${id}` : id;
}

export function formatNumber(value: number) {
  if (value < 10000) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  if (value < 1000000) {
    return Math.floor(value / 1000) + "K";
  }

  return Math.floor(value / 1000000) + "M";
}

export function canUseModule(module: Module, role: Role | string) {
  if (role === "FREE" && module.minimumRole === "FREE") {
    return true;
  } else if (
    role === "PREMIUM" &&
    (module.minimumRole === "FREE" || module.minimumRole === "PREMIUM")
  ) {
    return true;
  } else if (
    role === "ADVANCED" &&
    (module.minimumRole === "FREE" ||
      module.minimumRole === "PREMIUM" ||
      module.minimumRole === "ADVANCED")
  ) {
    return true;
  } else if (role === "PROFESSIONAL" || role === "TESTER" || role === "ADMIN") {
    return true;
  }

  return false;
}

export function arrayMove(arr: any[], oldIndex: number, newIndex: number) {
  if (newIndex >= arr.length) {
    var k = newIndex - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
}

export function getTeamMemberBadgeVariant(state: TeamMemberRole) {
  return state === "MEMBER"
    ? "success"
    : state === "MOD"
    ? "primary"
    : state === "ADMIN"
    ? "danger"
    : "info";
}

import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import { Badge, Button, Modal, Table } from "react-bootstrap";
import { APIUrl } from "../../../Constants";
import { Team, TeamMember } from "../../../types";
import { Spinner } from "../../shared/Spinner";
import { getTeamMemberBadgeVariant } from "../../shared/utils";

type FreeUsersModalProps = {
  onClose: () => void;
  team: Team;
};

export function FreeUsersModal({ onClose, team }: FreeUsersModalProps) {
  const [loading, setLoading] = useState(true);
  const [members, setMembers] = useState<TeamMember[]>([]);

  useEffect(() => {
    async function load() {
      const response = await axios.get(`${APIUrl}/teams/${team.id}/free-users`);
      if (response && response.data) {
        setMembers(response.data);
        setLoading(false);
      }
    }
    load();
  }, [team.id]);

  return (
    <Modal show={true} onHide={onClose} backdrop="static" centered>
      <Modal.Header>
        <Modal.Title>Free Users</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <Spinner />
        ) : (
          <>
            <p>
              Here is a list of users using free team slots on your team.
              <br />
              You have used{" "}
              <strong>
                {team.currentUsedFreeSlots} / {team.freeSlotsLimit}
              </strong>{" "}
              of your free slots for this team.
            </p>
            <Table striped>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Rank</th>
                  <th>Shared Triggers</th>
                </tr>
              </thead>
              <tbody>
                {members.map((member) => (
                  <tr key={member.userId}>
                    <td>
                      <div className="d-flex">{member.name}</div>
                    </td>
                    <td>
                      <Badge
                        variant={getTeamMemberBadgeVariant(member.teamRole)}
                      >
                        {member.teamRole}
                      </Badge>
                    </td>
                    <td>{member.sharedTriggers}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

import { LinkInstance, NodeInstance } from "../../../../types";
import _ from "underscore";
import { ConfigModal } from "./Modals/EditorModal";

type NodeEditorProps = {
  nodes: NodeInstance[];
  links: LinkInstance[];
  setNodes: (nodes: NodeInstance[]) => void;
  nodeId: string;
  onFinish: () => void;
};

export function NodeEditor({
  nodes,
  links,
  setNodes,
  nodeId,
  onFinish,
}: NodeEditorProps) {
  const node = _.find(nodes, { id: nodeId });

  if (!node) {
    return null;
  }

  function onCancel() {
    onFinish();
  }

  function onSave(data: { [key: string]: unknown }) {
    let _nodes = [...nodes.filter((node) => node.id !== nodeId)];

    let newData = { ...data };
    Object.keys(data).forEach((x) => {
      if (typeof data[x] === "object" && data[x] !== null) {
        newData[x] = JSON.stringify(data[x]);
      } else {
        newData[x] = `${data[x]}`;
      }
    });

    node!.data = newData;
    node!.requiresSetup = false;
    _nodes.push(node!);
    setNodes(_nodes);
    onFinish();
  }

  function parseData() {
    let data = node ? (node.data ? { ...node.data } : {}) : {};

    let newData = { ...data };
    Object.keys(data).forEach((x) => {
      try {
        const parsed = JSON.parse(data[x] as string);
        newData[x] = parsed;
      } catch {
        newData[x] = `${data[x]}`;
      }
    });

    return newData;
  }

  return (
    <ConfigModal
      nodes={nodes}
      links={links}
      node={node}
      setNode={(node) =>
        setNodes([...nodes.filter((x) => x.id !== node.id), node])
      }
      onCancel={onCancel}
      parsedData={parseData()}
      onSave={onSave}
    />
  );
}

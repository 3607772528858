import {
  faEnvelopeSquare,
  faEye,
  faTicketAlt,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { Button, Container, Form, Modal, Table } from "react-bootstrap";
import ReactJson from "react-json-view";
import { useHistory } from "react-router-dom";
import { APIUrl } from "../../../Constants";
import { Paged, UserError } from "../../../types";
import { Paginator } from "../../shared/Paginator";
import { Spinner } from "../../shared/Spinner";

export function Support() {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const history = useHistory();
  const [errors, setErrors] = useState<Paged<UserError>>();
  const [showDataModal, setShowDataModal] = useState<UserError>();
  const [showRequestHelp, setShowRequestHelp] = useState<UserError>();
  const [helpMessage, setHelpMessage] = useState<string>();

  useEffect(() => {
    let cancelled = false;

    async function loadErrors() {
      setLoading(true);
      const response = await axios.get(
        `${APIUrl}/admin/help-requests?page=${page}`
      );
      if (!cancelled) {
        setErrors(response.data);
        setLoading(false);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
    loadErrors();

    return () => {
      cancelled = true;
    };
  }, [page]);

  if (!errors) {
    return <Spinner />;
  }

  async function openError(userError: UserError) {
    if (!loading) {
      history.push(
        `/applets/editor/${userError.appletId}?errorNode=${userError.nodeId}&message=${userError.errorMessage}`
      );
    }
  }

  async function sendMessage() {
    if (showRequestHelp) {
      try {
        await axios.post(`${APIUrl}/admin/help-request`, {
          errorId: showRequestHelp.id,
          message: helpMessage ? helpMessage : " ",
        });
        var newErrors = errors;
        if (newErrors) {
          var index = newErrors.results.findIndex(
            (error) => error.id === showRequestHelp.id
          );
          if (index !== -1) {
            newErrors.results[index].requestHelp = true;
          }
          setErrors({ ...newErrors });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setShowRequestHelp(undefined);
        setHelpMessage("");
        window.location.reload();
      }
    }
  }

  return (
    <div className="page-content">
      <div className="page-content">
        <Container fluid>
          <h1>
            <FontAwesomeIcon icon={faTicketAlt} /> Support
          </h1>
          {errors.totalResults === 0 ? (
            <h5>No Help Requests</h5>
          ) : loading ? (
            <Spinner />
          ) : (
            <>
              <div className="bg-base mt-5">
                <Table striped hover>
                  <thead>
                    <tr>
                      <th>Username</th>
                      <th>Applet Name</th>
                      <th>Node</th>
                      <th>Error</th>
                      <th>Occured</th>
                      <th>Options</th>
                    </tr>
                  </thead>
                  {loading ? (
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        bottom: "0px",
                        left: "0px",
                        right: "0px",
                        textAlign: "center",
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      {/* <Spinner /> */}
                    </div>
                  ) : null}
                  <tbody>
                    {errors.results.map((error) => (
                      <tr key={error.id} onClick={() => openError(error)}>
                        <td>{error.username}</td>
                        <td>{error.appletName}</td>
                        <td>{error.nodeName}</td>
                        <td style={{ width: "25%" }}>{error.errorMessage}</td>
                        <td>
                          {DateTime.fromISO(error.createdAt).toLocaleString(
                            DateTime.DATETIME_FULL
                          )}
                        </td>
                        <td style={{ width: "20%" }}>
                          <Button
                            size="sm"
                            variant="primary"
                            disabled={loading}
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowDataModal(error);
                            }}
                            className="mr-1"
                          >
                            <FontAwesomeIcon icon={faEye} /> Data
                          </Button>
                          <Button
                            size="sm"
                            variant="success"
                            className="mr-1"
                            disabled={loading}
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowRequestHelp(error);
                            }}
                          >
                            <FontAwesomeIcon icon={faEnvelopeSquare} />
                            Messages
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <div className="py-3 d-flex justify-content-center align-items-center position-relative">
                  <Paginator
                    totalResults={errors.totalResults}
                    pageSize={errors.pageSize}
                    page={errors.page}
                    setPage={setPage}
                  />
                </div>
              </div>
            </>
          )}
        </Container>
      </div>
      <Modal
        show={showRequestHelp !== undefined}
        onHide={() => setShowRequestHelp(undefined)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Messages</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            {showRequestHelp &&
              showRequestHelp.messages.map((message) => (
                <div
                  id={message.timeStamp}
                  style={{ marginRight: "10px", marginLeft: "10px" }}
                >
                  <p
                    style={{
                      textAlign:
                        message.displayName === "Stream Hydra Support"
                          ? "right"
                          : "left",
                      marginBottom: "2px",
                      marginLeft: "5px",
                      marginRight: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    {message.displayName} |{" "}
                    {DateTime.fromISO(message.timeStamp).toRelative()}
                  </p>
                  <div className={"help-requester-message"}>
                    <p
                      style={{
                        textAlign:
                          message.displayName === "Stream Hydra Support"
                            ? "right"
                            : "left",
                        whiteSpace: "pre-line",
                      }}
                    >
                      {message.message}
                    </p>
                  </div>
                </div>
              ))}
            <Form.Label className="mt-4">Send a New Message</Form.Label>
            <Form.Control
              className="mt-3"
              as="textarea"
              rows={8}
              type="text"
              placeholder={"Message"}
              style={{ whiteSpace: "pre-wrap" }}
              onChange={(e) => {
                setHelpMessage(e.target.value);
              }}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            onClick={() => setShowRequestHelp(undefined)}
            style={{ minWidth: 100 }}
            disabled={loading}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              sendMessage();
            }}
            style={{ minWidth: 100 }}
            disabled={loading}
          >
            Send
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showDataModal !== undefined}
        onHide={() => setShowDataModal(undefined)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Applet data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>
              View the data that was passed when the error occured:
            </Form.Label>
            {showDataModal && showDataModal.data ? (
              <ReactJson
                theme="ashes"
                src={showDataModal!.data}
                enableClipboard={false}
                displayDataTypes={false}
                quotesOnKeys={false}
              />
            ) : (
              <p className="mt-4 ml-3">No data</p>
            )}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            onClick={() => setShowDataModal(undefined)}
            style={{ minWidth: 100 }}
            disabled={loading}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

import axios from "axios";
import { useState } from "react";
import { Badge, Button, Modal } from "react-bootstrap";
import { APIUrl } from "../../../../Constants";
import { MinecraftServer } from "../../../../types";

type RegenerateServerKeyModalProps = {
  onClose: () => void;
  server: MinecraftServer;
};

export function RegenerateServerKeyModal({
  onClose,
  server,
}: RegenerateServerKeyModalProps) {
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState<string>();

  async function regen() {
    setLoading(true);

    const response = await axios.get(
      `${APIUrl}/minecraft/server/regenerate/${server.id}`
    );
    console.log(response);
    setKey(response.data);

    setLoading(false);
  }

  return (
    <Modal show={true} onHide={onClose} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>{server.name}</Modal.Title>
        <Modal.Title className="ml-3">
          <Badge
            variant={
              server.status === "Online"
                ? "success"
                : server.status === "Offline"
                ? "danger"
                : "secondary"
            }
          >
            {server.status}
          </Badge>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {key ? (
          <>
            <p>
              Your key has been regenerated. Use the following command to
              reconnect your minecraft server:
            </p>
            <p className="code">/streamhydra link {key}</p>
            <p>Once the server is linked simply type</p>
            <p className="code">/streamhydra connect</p>
            <p>
              <strong>
                Copy this key now as you will NOT be able to access it again!
              </strong>
            </p>
            <p>
              Do NOT share this key with anyone! If this key becomes
              compromised, regenerate or delete it immediately as anyone with
              this key can control your applet!
            </p>
          </>
        ) : (
          <p>
            Are you sure you want to regenerate the key for this server? This
            will replace the existing key so any servers using it will no longer
            work!
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!key && (
          <Button
            variant="outline-primary"
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </Button>
        )}
        <Button onClick={key ? onClose : regen} disabled={loading}>
          {key ? "Done" : "Regenerate"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

import { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";

type PaginatorProps = {
  totalResults: number;
  pageSize: number;
  page: number;
  setPage: (page: number) => void;
};

export function Paginator({
  totalResults,
  pageSize,
  page,
  setPage,
}: PaginatorProps) {
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    setTotalPages(
      totalResults % pageSize === 0
        ? totalResults / pageSize
        : Math.floor(totalResults / pageSize) + 1
    );
  }, [totalResults, pageSize]);

  function renderButtons() {
    const qty = 5;
    const half = Math.floor(qty / 2);
    const pages = [];
    let first = page - half;
    let last = page + half;

    if (page <= half) {
      first = 1;
      last = totalPages >= qty ? qty : totalPages;
    } else if (page >= totalPages - half) {
      last = totalPages;
      first = totalPages - qty + 1;
    }

    if (first < 1) first = 1;
    if (last > totalPages) last = totalPages;

    for (var i = first; i <= last; i++) {
      pages.push(i);
    }

    return pages.map((i) => (
      <Pagination.Item key={i} active={page === i} onClick={() => setPage(i)}>
        {i}
      </Pagination.Item>
    ));
  }

  return (
    <Pagination className="mb-0">
      <Pagination.First onClick={() => setPage(1)} disabled={page === 1} />
      <Pagination.Prev
        onClick={() => (page > 1 ? setPage(page - 1) : setPage(1))}
        disabled={page === 1}
      />

      {renderButtons()}

      <Pagination.Next
        onClick={() =>
          page < totalPages ? setPage(page + 1) : setPage(totalPages)
        }
        disabled={page >= totalPages}
      />
      <Pagination.Last
        onClick={() => setPage(totalPages)}
        disabled={page >= totalPages}
      />
    </Pagination>
  );
}

import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Keys } from "./Keys";

export function StreamDeck() {
  const { path } = useRouteMatch();

  return (
    <div className="page-content">
      <Switch>
        <Route path={`${path}/keys`}>
          <Keys />
        </Route>
      </Switch>
    </div>
  );
}

import { faSave, faTimes, faTrash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import {
  Applet,
  ConfigGroup,
  ConfigPanel,
  GlobalVariable,
} from "../../../../types";
import { ConfigElement, elements } from "./elements";
import { ConfigElementModal } from "./Modals/ConfigElementModal";
import { Element } from "./Element";
import { ConfirmationModal } from "../../../shared/modals/ConfirmationModal";

type PanelProps = {
  panel: ConfigPanel;
  group: ConfigGroup;
  setPanel: (panel: ConfigPanel) => void;
  deletePanel: () => void;
  onDragStart: () => void;
  onDragEnd: () => void;
  onDragOver: () => void;
  data: Applet;
  setData: (data: Applet) => void;
};

export function Panel({
  panel,
  group,
  setPanel,
  deletePanel,
  onDragStart,
  onDragEnd,
  onDragOver,
  data,
  setData,
}: PanelProps) {
  const [renaming, setRenaming] = useState(false);
  const [nameInput, setNameInput] = useState("");
  const [dragging, setDragging] = useState(false);
  const [configModal, setConfigModal] = useState<ConfigElement>();
  const [draggingElement, setDraggingElement] = useState<GlobalVariable>();
  const [deleting, setDeleting] = useState(false);

  function dragStart(e: React.DragEvent<HTMLDivElement>) {
    e.dataTransfer.setData("type", "panel");
    e.stopPropagation();
    setDragging(true);
    onDragStart();
  }

  function dragEnd(e: React.DragEvent<HTMLDivElement>) {
    setDragging(false);
    e.stopPropagation();
    onDragEnd();
  }

  function dragOver(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    e.stopPropagation();
    onDragOver();
  }

  function onDrop(e: React.DragEvent<HTMLDivElement>) {
    if (e.dataTransfer.getData("type") === "element") {
      const id = e.dataTransfer.getData("id");
      console.log(`dropped element ${id}`);
      setConfigModal(elements[id]);
    }
  }

  function onElementDragStart(variable: GlobalVariable) {
    setDraggingElement(variable);
  }

  function onElementDragEnd() {
    setDraggingElement(undefined);
  }

  function onElementDragOver(variable: GlobalVariable) {
    if (draggingElement && variable !== draggingElement) {
      let oldIndex = data.globalVariables.indexOf(draggingElement);
      let newIndex = data.globalVariables.indexOf(variable);

      setData({
        ...data,
        globalVariables: [
          ...data.globalVariables.map((x, index) =>
            index === oldIndex
              ? variable
              : index === newIndex
              ? draggingElement
              : x
          ),
        ],
      });
    }
  }

  return (
    <>
      <div
        className={`config-panel ${dragging ? "dragging" : ""}`}
        draggable={!renaming}
        onDragStart={dragStart}
        onDragEnd={dragEnd}
        onDragOver={dragOver}
        onDrop={onDrop}
      >
        <div className="d-flex align-items-center mb-2">
          <div className="flex-grow-1">
            {renaming ? (
              <>
                <Form
                  className="d-flex"
                  onSubmit={(e) => {
                    e.preventDefault();
                    setPanel({ ...panel, name: nameInput });
                    setRenaming(false);
                  }}
                >
                  <Form.Control
                    value={nameInput}
                    onChange={(e) => setNameInput(e.target.value)}
                  />
                  <div>
                    <Button
                      className="ml-2"
                      size="sm"
                      style={{ width: 35, height: 35 }}
                    >
                      <FontAwesomeIcon icon={faSave} />
                    </Button>
                  </div>
                  <div>
                    <Button
                      className="ml-2"
                      size="sm"
                      style={{ width: 35, height: 35 }}
                      variant="outline-primary"
                      onClick={() => setRenaming(false)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>
                  </div>
                </Form>
              </>
            ) : (
              <div>
                <h5
                  className="hover mb-0"
                  onClick={() => {
                    setRenaming(true);
                    setNameInput(panel.name);
                  }}
                >
                  {panel.name}
                </h5>
              </div>
            )}
          </div>

          {!renaming && (
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => setDeleting(true)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          )}
        </div>

        {data.globalVariables
          .filter((x) => x.category === group.id && x.subcategory === panel.id)
          .map((v) => (
            <Element
              data={data}
              setData={setData}
              key={v.id}
              variable={v}
              onDragStart={() => onElementDragStart(v)}
              onDragEnd={() => onElementDragEnd()}
              onDragOver={() => onElementDragOver(v)}
            />
          ))}
      </div>
      {configModal && (
        <ConfigElementModal
          data={data}
          category={group.id}
          subcategory={panel.id}
          element={configModal}
          onClose={() => setConfigModal(undefined)}
        />
      )}
      {deleting && (
        <ConfirmationModal
          title="Delete Panel?"
          body={`Are you sure you want to delete panel ${panel.name}? This will also delete all elements and global variables associated with the panel.`}
          onClose={() => setDeleting(false)}
          onConfirm={() => {
            deletePanel();
            setDeleting(false);
          }}
        />
      )}
    </>
  );
}

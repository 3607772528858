import { Badge, Modal } from "react-bootstrap";

type ChangelogListProps = {
  date: string;
  sections: Section[];
};

export type Section = {
  title: string;
  items: {
    title?: string;
    advanced?: boolean;
    description: string;
    suggestion?: {
      name: string;
      link: string;
    };
    premium?: boolean;
  }[];
};

export function ChangelogList({ date, sections }: ChangelogListProps) {
  return (
    <>
      <Modal.Header closeButton>
        <div>
          <Modal.Title>What's New?</Modal.Title>
          <p>
            <small>{date}</small>
          </p>
        </div>
      </Modal.Header>
      <Modal.Body className="pt-0 changelog">
        {sections.map((section, i) => (
          <div key={i}>
            <h5 className="text-primary text-uppercase">{section.title}</h5>
            <ul className="pl-4">
              {section.items.map((item, j) => (
                <li key={j}>
                  {item.premium && (
                    <>
                      <Badge variant="warning" pill>
                        Premium
                      </Badge>{" "}
                    </>
                  )}
                  {item.title && (
                    <>
                      <strong>{item.title}</strong>{" "}
                    </>
                  )}
                  {item.advanced && <>(Advanced) </>}
                  <span className="text-muted">
                    {item.description}
                    {item.suggestion && (
                      <>
                        <br />
                        (Suggested by{" "}
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={item.suggestion.link}
                        >
                          {item.suggestion.name}
                        </a>
                        )
                      </>
                    )}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </Modal.Body>
    </>
  );
}

import { useState } from "react";
import { Form } from "react-bootstrap";
import { NodeOption } from "../../../../../types";
import { EditorSidenavContextMenu } from "./EditorSidenav";
import { NodeView } from "./NodeView";

type SearchProps = {
  nodes: NodeOption[];
  setContextMenu: (contextMenu: EditorSidenavContextMenu) => void;
};

export function Search({ nodes, setContextMenu }: SearchProps) {
  const [search, setSearch] = useState("");

  return (
    <>
      <h6 className="module-title">Search Nodes</h6>
      <div className="sidenav-content p-3">
        <Form.Control
          placeholder="Search..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </div>
      {search && search !== "" && (
        <NodeView
          nodes={nodes.filter(
            (x) => x.name.toLowerCase().indexOf(search.toLowerCase()) > -1
          )}
          showModules
          setContextMenu={setContextMenu}
        />
      )}
    </>
  );
}

import { faCopy } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { APIUrl, AppUrl, AuthUrl } from "../../Constants";
import { useAuth } from "../../context/AuthContext";
import { Plan } from "../../types";
import { FeatureList } from "../shared/FeatureList";
import { DeleteAccountModal } from "./DeleteAccountModal";
import { DisableMFAModal } from "./DisableMFAModal";
import { PasswordResetModal } from "./PasswordResetModal";

export function Account() {
  const auth = useAuth();
  const history = useHistory();
  const [plan, setPlan] = useState<Plan>();
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const [canChangePassword, setCanChangePassword] = useState(false);
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);
  const [marketing, setMarketing] = useState(false);
  const [mfa, setMfa] = useState(false);
  const [disableMfaCheck, setDisableMfaCheck] = useState(false);
  let { success }: { success?: string } = useParams();
  const [referralTier, setReferralTier] = useState<string>();
  const [referralCode, setReferralCode] = useState();
  const [referredUsers, setReferredUsers] = useState(0);
  const [referralEarnt, setReferralEarnt] = useState<string>("0");
  const [userCurrency, setUserCurrency] = useState<string>("");

  useEffect(() => {
    GetPlanLimits();
    GetUserData();
  }, []);

  useEffect(() => {
    if (success && success === "true") {
      history.push("/account");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  async function GoToPortal() {
    try {
      const response = await axios.post(
        `${APIUrl}/payments/create-customer-portal?redirect=${AppUrl}/settings/true`
      );
      window.location.href = response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async function GetPlanLimits() {
    try {
      const response = await axios.get(
        `${APIUrl}/statistics/current-plan-information`
      );
      setPlan(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function GetUserData() {
    try {
      const response = await axios.get(`${APIUrl}/user/settings-data`);
      if (response) {
        console.log(response.data);
        setCanChangePassword(response.data.canChangePassword);
        setMarketing(response.data.marketing);
        setMfa(response.data.mfa);
        setReferralTier(response.data.referralTier);
        setReferralCode(response.data.referralCode);
        setReferredUsers(response.data.referredUsers);
        setReferralEarnt(response.data.referralEarnt);
        setUserCurrency(response.data.userCurrency);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function ChangeMarketing(marketing: boolean) {
    try {
      setMarketing(marketing);
      await axios.post(
        `${APIUrl}/user/change-marketing?marketing=${marketing}`
      );
    } catch (error) {
      console.log(error);
    }
  }

  async function EnableMFA() {
    window.location.replace(
      `${AuthUrl}/account/mfa/enable?returnUrl=${window.location.href}`
    );
  }

  async function AffiliateSignUp() {
    try {
      await axios.post(`${APIUrl}/payments/affiliate-sign-up`);
      await GetUserData();
    } catch (error) {
      console.log(error);
    }
  }

  function GetReferralAmount() {
    switch (userCurrency) {
      case "gbp":
        return referralTier === "PARTNER" ? "£10" : "£5";
      case "usd":
        return referralTier === "PARTNER" ? "$14" : "$7";
      case "eur":
        return referralTier === "PARTNER" ? "€14" : "€7";
      case "cad":
        return referralTier === "PARTNER" ? "CAD $20" : "CAD $10";
      case "aud":
        return referralTier === "PARTNER" ? "AUD $20" : "AUD $10";
    }
  }

  function GetEarntAmount() {
    switch (userCurrency) {
      case "gbp":
        return `£${referralEarnt ? referralEarnt : "0"}`;
      case "usd":
        return `$${referralEarnt ? referralEarnt : "0"}`;
      case "eur":
        return `€${referralEarnt ? referralEarnt : "0"}`;
      case "cad":
        return `CAD $${referralEarnt ? referralEarnt : "0"}`;
      case "aud":
        return `AUD $${referralEarnt ? referralEarnt : "0"}`;
    }
  }

  return (
    <div className="page-content">
      <Container fluid>
        <DeleteAccountModal
          mfa={mfa}
          onHide={() => {
            setShowDeleteAccount(false);
          }}
          show={showDeleteAccount}
        />

        <DisableMFAModal
          mfa={mfa}
          onHide={() => {
            setDisableMfaCheck(false);
          }}
          show={disableMfaCheck}
          onComplete={() => {
            setMfa(false);
          }}
        />

        {canChangePassword ? (
          <PasswordResetModal
            mfa={mfa}
            show={showPasswordChange}
            onHide={() => {
              setShowPasswordChange(false);
            }}
          />
        ) : null}

        <Row>
          <Col xl={4}>
            <Card>
              <Card.Header>
                <Card.Title>
                  <h5 className="float-left">
                    Plan:{" "}
                    {plan && plan.roleType[0]
                      ? plan.roleType[0].toUpperCase() +
                        plan.roleType.slice(1).toLowerCase()
                      : null}
                  </h5>
                  {plan && plan.roleType === "FREE" ? null : (
                    <Button
                      className="float-right"
                      onClick={() => {
                        GoToPortal();
                      }}
                    >
                      Manage Subscription
                    </Button>
                  )}
                </Card.Title>
              </Card.Header>
              <Card.Body>{plan && <FeatureList plan={plan} />}</Card.Body>
              <Card.Footer>
                <Button
                  variant={"success"}
                  onClick={() => {
                    history.push("/pricing");
                  }}
                  block
                >
                  See Plans
                </Button>
              </Card.Footer>
            </Card>
          </Col>
          <Col xl={8}>
            <Card>
              <Card.Header>
                <Card.Title>Account and Security</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form.Group>
                  <Row>
                    <Col sm={9}>
                      <p>Email Address: {auth.user?.profile.email}</p>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col sm={7}>
                      {!canChangePassword ? (
                        <p>
                          You can't change your password when signed in with a
                          social provider
                        </p>
                      ) : (
                        <p>Change your account password</p>
                      )}
                    </Col>
                    <Col sm={5}>
                      <Button
                        className="float-right mt-2"
                        disabled={!canChangePassword}
                        onClick={() => {
                          setShowPasswordChange(true);
                        }}
                      >
                        Reset Password
                      </Button>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col sm={7}>
                      {!canChangePassword ? (
                        <p>
                          You can't enable 2FA when signed in with a social
                          provider
                        </p>
                      ) : (
                        <p>
                          {mfa
                            ? "You've enabled 2 Factor Authentication"
                            : "Protect your account by enabling 2 Factor Authentication"}
                        </p>
                      )}
                    </Col>
                    <Col sm={5}>
                      <Button
                        className="float-right mt-2"
                        disabled={!canChangePassword}
                        onClick={() => {
                          if (mfa) {
                            setDisableMfaCheck(true);
                          } else {
                            EnableMFA();
                          }
                        }}
                      >
                        {mfa ? "Disable 2FA" : "Enable 2FA"}
                      </Button>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col sm={7}>
                      <p>Opt in to receive marketing emails</p>
                    </Col>
                    <Col sm={5}>
                      <Form.Check
                        id="marketing"
                        type="switch"
                        custom
                        checked={marketing}
                        className={"float-right mt-3"}
                        onChange={(e) => {
                          ChangeMarketing(e.target.checked);
                        }}
                      />
                    </Col>
                  </Row>
                </Form.Group>
              </Card.Body>
              <Card.Footer>
                <Button
                  className="float-right"
                  onClick={() => {
                    setShowDeleteAccount(true);
                  }}
                >
                  Delete Account
                </Button>
              </Card.Footer>
            </Card>
            <Card className="mt-3">
              <Card.Header>
                <Card.Title>
                  {referralTier === "PARTNER"
                    ? "Partner Program"
                    : "Affiliate Program"}
                </Card.Title>
              </Card.Header>
              <Card.Body>
                {plan && plan?.roleType === "FREE" ? (
                  <div>
                    <h6 className="text-center">
                      You must be on a paid plan to become an affiliate or
                      partner
                    </h6>
                  </div>
                ) : referralTier === "NORMAL" ? (
                  <div>
                    <h6 className="text-center">
                      You haven't signed up for a referral program yet, would
                      you like to become a Stream Hydra Affiliate?
                    </h6>
                    <h6 className="text-center mt-3">
                      Earn <strong>{GetReferralAmount()}</strong> credit to your
                      subscription for every user you invite that converts to a
                      paid plan. They'll also get 10% off too!
                    </h6>
                    <div className="text-center mt-3">
                      <Button
                        onClick={() => {
                          AffiliateSignUp();
                        }}
                      >
                        Become an Affiliate
                      </Button>
                    </div>
                  </div>
                ) : (
                  <Form.Group>
                    <p>
                      Share this code to others for them to receive a
                      {referralTier === "AFFILIATE" ? " 10% " : " 20% "}
                      discount. You'll receive {GetReferralAmount()} credit
                      towards your subscription for every new user who signs up
                      to a paid plan.
                    </p>
                    <Row>
                      <Col sm={7}>
                        <p>
                          <strong>Your Referral Code</strong>
                        </p>
                      </Col>
                      <Col sm={5}>
                        <h5 className="text-right mr-2">
                          {referralCode}{" "}
                          <FontAwesomeIcon
                            size={"1x"}
                            cursor={"pointer"}
                            className="ml-2"
                            color={"#d65a66"}
                            onClick={() => {
                              navigator.clipboard.writeText(referralCode!);
                            }}
                            icon={faCopy}
                          />
                        </h5>
                      </Col>
                    </Row>
                    <p>
                      This code should be entered in the "discount code" field
                      during checkout
                    </p>
                    <Row className="mt-4">
                      <Col sm={7}>
                        <p>
                          <strong>Total Referred Users</strong>
                        </p>
                      </Col>
                      <Col sm={5}>
                        <p className="text-right mr-5">
                          <strong>{referredUsers}</strong>
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={7}>
                        <p>
                          <strong>Total Earnt From Referrals</strong>
                        </p>
                      </Col>
                      <Col sm={5}>
                        <p className="text-right mr-5">
                          <strong>{GetEarntAmount()}</strong>
                        </p>
                      </Col>
                    </Row>
                    <h6 className="text-left">
                      The above amount will be deducted from your next invoice.
                    </h6>
                  </Form.Group>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

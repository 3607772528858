import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Dashboard } from "./Dashboard";
import { Config } from "./Config";
import { StressTest } from "./StressTest";
import { Users } from "./Users/Users";
import { Emails } from "./Emails";
import { Support } from "./Support/Support";
import { Applets } from "../Applets/Applets";

export function Admin() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/dashboard`}>
        <Dashboard />
      </Route>
      <Route path={`${path}/config`}>
        <Config />
      </Route>
      <Route path={`${path}/stress-test`}>
        <StressTest />
      </Route>
      <Route path={`${path}/users/:userid/applets`}>
        <Applets />
      </Route>
      <Route path={`${path}/users`}>
        <Users />
      </Route>
      <Route path={`${path}/emails`}>
        <Emails />
      </Route>
      <Route path={`${path}/support`}>
        <Support />
      </Route>
    </Switch>
  );
}

import { ReactNode, useEffect, useState } from "react";
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from "react-router-dom";
import { Applets } from "./Applets/Applets";
import { Main } from "./Main/Main";
import { Modules } from "./Modules/Modules";
import { Sidenav } from "./Sidenav";
import { Library } from "./Library/Library";
import { SidenavContext } from "../context/SidenavContext";
import { useAuth } from "../context/AuthContext";
import axios from "axios";
import { Spinner } from "./shared/Spinner";
import { Module, Notification, NotificationType } from "../types";
import { APIUrl } from "../Constants";
import { Minecraft } from "./Modules/Minecraft/Minecraft";
import { StreamDeck } from "./Modules/StreamDeck/StreamDeck";
import { NotificationContext } from "../context/NotificationContext";
import { Notifications } from "./Notifications";
import * as signalR from "@microsoft/signalr";
import { Account } from "./Account/Account";
import { EmailVerified } from "./ErrorModals/EmaiVerified";
import { Admin } from "./Admin/Admin";
import { User } from "oidc-client";
import { Community } from "./Modules/Community/Community";
import { Pricing } from "./Pricing/Pricing";
import { Twitch } from "./Modules/Twitch/Twitch";
import { BugReporter } from "./BugReporter";
import { Changelog } from "./Changelogs/Changelog";
import { Teams } from "./Teams/Teams";
import { UserProvider } from "../context/UserContext";
import { useTheme } from "../context/ThemeContext";
import { ThemeSelector } from "./ThemeSelector";
import { ModulesContext } from "../context/ModulesContext";
import { Tutorial } from "./Tutorial/Tutorial";
import { TutorialProvider } from "../context/TutorialContext";
import { Navigation } from "./Navigation";
import { Errors } from "./Errors/Errors";

function Dashboard(props: RouteComponentProps) {
  const [loading, setLoading] = useState(true);
  const [userLoading, setUserLoading] = useState(true);
  const [sidenavOpen, setSidenavOpen] = useState(true);
  const [sidenavOverride, setSidenavOverride] = useState<ReactNode>(null);
  const [sidenavEnabled, setSidenavEnabled] = useState(true);
  const [modules, setModules] = useState<Module[]>();
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [hub, setHub] = useState<signalR.HubConnection>();
  const auth = useAuth();
  const [showChangelog, setShowChangelog] = useState(false);
  const [showEmailVerifiedModal, setShowEmailVerifiedModal] = useState(false);
  const [showBugReporter, setShowBugReporter] = useState(false);
  const [showThemeSelector, setShowThemeSelector] = useState(false);
  const [errorNumber, setErrorNumber] = useState<number>(0);
  const { refresh } = useTheme();

  useEffect(() => {
    function registerRequestInterceptor(user: User | null) {
      return axios.interceptors.request.use((request) => {
        if (user && user.access_token) {
          request.headers["Authorization"] = `Bearer ${user.access_token}`;
        }

        return request;
      });
    }

    let interceptor = registerRequestInterceptor(auth.user);

    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response && error.response.data === "Email not verified") {
          setLoading(false);
          setShowEmailVerifiedModal(true);
        }
        throw error;
      }
    );

    let cancelled = false;

    async function loadData() {
      try {
        const module = await axios.get(`${APIUrl}/module`);
        if (!cancelled) {
          setModules(module.data);
        }

        const notification = await axios.get(`${APIUrl}/notification`);
        if (!cancelled) {
          setNotifications(notification.data);
          setLoading(false);
        }

        const changelog = await axios.get(`${APIUrl}/user/changelog`);
        if (!cancelled) {
          setShowChangelog(changelog.data);
        }

        // var errors = await axios.get(`${APIUrl}/error/user/count`);
        // if (errors && errors.data) {
        //   setErrorNumber(errors.data);
        // }
      } catch (error) {
        console.log(error);
      }
    }

    loadData();

    async function onUserLogout() {
      if (auth && auth.userManager) {
        await auth.userManager.removeUser();
        window.location.reload();
      }
    }

    async function onTokenExpire() {
      if (auth && auth.userManager) {
        console.log("token expired");
        const user = await auth.userManager.signinSilent();
        console.log(user);
        if (user) {
          axios.interceptors.request.eject(interceptor);
          interceptor = registerRequestInterceptor(user);
          await auth.userManager.storeUser(user);
        }
      }
    }

    function onSilentRenewError(e: any) {
      console.log(e);
    }

    if (auth && auth.userManager) {
      auth.userManager.events.addUserSignedOut(onUserLogout);
      auth.userManager.events.addAccessTokenExpired(onTokenExpire);
      auth.userManager.events.addSilentRenewError(onSilentRenewError);
      refresh();
    }

    return () => {
      cancelled = true;
      axios.interceptors.request.eject(interceptor);
      axios.interceptors.response.eject(responseInterceptor);

      if (auth && auth.userManager) {
        auth.userManager.events.removeUserSignedOut(onUserLogout);
        auth.userManager.events.removeAccessTokenExpired(onTokenExpire);
        auth.userManager.events.removeSilentRenewError(onSilentRenewError);
      }
    };
  }, [auth, refresh]);

  function dispatchNotification(
    type: NotificationType,
    title: string,
    message: string,
    url?: string
  ) {
    setNotifications([
      ...notifications,
      {
        id: Math.random().toString(),
        alert: true,
        flash: true,
        createdAt: new Date().toString(),
        read: false,
        title,
        message,
        type,
        url,
      },
    ]);
  }

  useEffect(() => {
    const hub = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(`${APIUrl}/realtime/dashboard`, {
        accessTokenFactory: () => (auth.user ? auth.user.access_token : ""),
      })
      .withAutomaticReconnect()
      .build();

    async function start() {
      try {
        await hub.start();
        setHub(hub);
      } catch (e: any) {
        console.log(e);
      }
    }

    if (auth.user && auth.user.access_token) {
      start();
    }

    return () => {
      if (hub) {
        hub.stop();
      }
    };
  }, [auth]);

  useEffect(() => {
    if (hub) {
      hub.on("SendNotification", (notification: Notification) => {
        setNotifications((notifications) => [...notifications, notification]);
      });
      hub.on("Errors", (errorNumber: number) => {
        setErrorNumber(errorNumber);
      });
    }
  }, [hub]);

  function overrideSidenav(sidenav: ReactNode) {
    setSidenavOverride(sidenav);
    setSidenavOpen(true);
  }

  if (loading) {
    return <Spinner fill />;
  }

  if (showEmailVerifiedModal) {
    return <EmailVerified />;
  }

  return (
    <>
      <UserProvider onLoad={() => setUserLoading(false)}>
        {userLoading ? (
          <Spinner fill />
        ) : (
          <TutorialProvider>
            <Tutorial />
            {modules && (
              <ModulesContext.Provider value={{ modules, setModules }}>
                <Changelog
                  show={showChangelog}
                  onHide={() => {
                    setShowChangelog(false);
                  }}
                />
                <SidenavContext.Provider
                  value={{
                    override: sidenavOverride,
                    setOverride: overrideSidenav,
                    enabled: sidenavEnabled,
                    setEnabled: setSidenavEnabled,
                  }}
                >
                  <NotificationContext.Provider
                    value={{ dispatchNotification }}
                  >
                    <Navigation
                      sidenavEnabled={sidenavEnabled}
                      sidenavOpen={sidenavOpen}
                      setSidenavOpen={setSidenavOpen}
                      sidenavOverride={!!sidenavOverride}
                      notifications={notifications}
                      setNotifications={setNotifications}
                      openBugReporter={() => setShowBugReporter(true)}
                      openThemeSelector={() => setShowThemeSelector(true)}
                    />
                    {sidenavEnabled && (
                      <>
                        {sidenavOverride ? (
                          sidenavOverride
                        ) : (
                          <Sidenav
                            open={sidenavOpen}
                            errorNumber={errorNumber}
                            {...props}
                          />
                        )}
                      </>
                    )}
                    <div
                      className="page-wrapper"
                      style={{
                        marginLeft: sidenavEnabled
                          ? sidenavOpen
                            ? 300
                            : 60
                          : 0,
                      }}
                    >
                      <Switch>
                        <Route path="/applets">
                          <Applets />
                        </Route>
                        <Route path="/library" exact>
                          <Library />
                        </Route>
                        <Route path="/modules">
                          <Modules />
                        </Route>
                        <Route path="/community">
                          <Community />
                        </Route>
                        <Route path="/teams">
                          <Teams />
                        </Route>
                        <Route path="/errors">
                          <Errors />
                        </Route>
                        <Route path="/twitch">
                          <Twitch />
                        </Route>
                        <Route path="/minecraft">
                          <Minecraft />
                        </Route>
                        <Route path="/streamdeck">
                          <StreamDeck />
                        </Route>
                        <Route path="/pricing/:success?">
                          <Pricing />
                        </Route>
                        <Route path="/account/:success?">
                          <Account />
                        </Route>
                        {auth.user?.profile.role === "ADMIN" ? (
                          <Route path="/admin">
                            <Admin />
                          </Route>
                        ) : null}
                        <Route path="/" exact>
                          <Main />
                        </Route>
                        <Redirect from="*" to="/" />
                      </Switch>
                    </div>
                    <Notifications
                      notifications={notifications}
                      setNotifications={setNotifications}
                    />
                  </NotificationContext.Provider>
                </SidenavContext.Provider>
              </ModulesContext.Provider>
            )}
          </TutorialProvider>
        )}
      </UserProvider>
      {showBugReporter && (
        <BugReporter onClose={() => setShowBugReporter(false)} />
      )}
      {showThemeSelector && (
        <ThemeSelector onClose={() => setShowThemeSelector(false)} />
      )}
    </>
  );
}

export default withRouter(Dashboard);

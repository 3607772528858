import { NodeOption } from "../../../../../types";
import { EditorSidenavContextMenu } from "./EditorSidenav";
import { NodeView } from "./NodeView";

type FavouriteProps = {
  nodes: NodeOption[];
  setContextMenu: (contextMenu: EditorSidenavContextMenu) => void;
};

export function Favourite({ nodes, setContextMenu }: FavouriteProps) {
  const favourites = nodes.filter((x) => x.favourite);

  return (
    <>
      <h6 className="module-title">Favourite Nodes</h6>
      <div className="sidenav-content">
        {favourites.length > 0 ? (
          <NodeView
            nodes={favourites}
            showModules
            setContextMenu={setContextMenu}
          />
        ) : (
          <p className="p-3">
            It looks like you don't have any favourite nodes yet! You can add
            one by right-clicking on any node and clicking "Add to Favourites"
          </p>
        )}
      </div>
    </>
  );
}

import axios from "axios";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { APIUrl } from "../../../../Constants";
import { MinecraftServer } from "../../../../types";

type AddServerModalProps = {
  onClose: () => void;
  onAdd: (server: MinecraftServer) => void;
};

export function AddServerModal({ onClose, onAdd }: AddServerModalProps) {
  const [name, setName] = useState("");
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [server, setServer] = useState<MinecraftServer>();

  async function add() {
    let cancelled = false;

    setError(undefined);

    if (!name || name.trim() === "") {
      setError("This field is required");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(`${APIUrl}/minecraft/server`, {
        name: name.trim(),
      });
      if (!cancelled) {
        setServer(response.data);
        onAdd(response.data);
        setLoading(false);
      }
    } catch (error) {}
    setLoading(false);
    return () => {
      cancelled = true;
    };
  }

  return (
    <Modal show={true} onHide={onClose} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>Add Server</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {server ? (
          <>
            <p>
              Thanks, your server has been registered. Download and install our
              plugin from{" "}
              <a
                href="https://www.spigotmc.org/resources/stream-hydra.95195/"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>{" "}
              then copy the activation command below and run it on the server.
              Do not share this key with anyone!{" "}
            </p>
            <p className="code">/streamhydra link {server.apiKey}</p>
            <p>Once the server is linked simply type</p>
            <p className="code">/streamhydra connect</p>
            <p>
              <strong>
                Copy this key now as you will NOT be able to access it again!
              </strong>
            </p>
            <p>
              Do NOT share this key with anyone! If this key becomes
              compromised, regenerate or delete it immediately as anyone with
              this key can control your applet!
            </p>
          </>
        ) : (
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              add();
            }}
          >
            <p>
              Enter a name for the server, this is so you can identify it in our
              server list if you have more than one.
            </p>
            <Form.Group>
              <Form.Label>Server Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter name"
                isInvalid={!!error}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Form.Control.Feedback type="invalid">
                {error}
              </Form.Control.Feedback>
            </Form.Group>
          </Form>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!server && (
          <Button
            variant="outline-primary"
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </Button>
        )}
        <Button onClick={server ? onClose : add} disabled={loading}>
          {server ? "Done" : "Add"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

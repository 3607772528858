import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Servers } from "./Servers";

export function Minecraft() {
  const { path } = useRouteMatch();

  return (
    <div className="page-content">
      <Switch>
        <Route path={`${path}/servers`}>
          <Servers />
        </Route>
      </Switch>
    </div>
  );
}

import {
  faCube,
  faProjectDiagram,
  faSpinnerThird,
  faToggleOff,
  faToggleOn,
  faUserChart,
} from "@fortawesome/pro-light-svg-icons";
import {
  faWifi,
  faWifiSlash,
  faExclamationTriangle,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Applet } from "../../types";
import { ContextMenu } from "./AppletList";
import { useState } from "react";

type AppletListItemProps = {
  applet: Applet;
  onEdit: () => void;
  setStatus: (id: string, status: "enable" | "disable") => void;
  setContextMenu: (menu: ContextMenu) => void;
};

export function AppletListItem({
  applet,
  onEdit,
  setStatus,
  setContextMenu,
}: AppletListItemProps) {
  const [settingStatus, setSettingStatus] = useState(false);

  async function status(id: string, status: "enable" | "disable") {
    setSettingStatus(true);
    await setStatus(id, status);
    setSettingStatus(false);
  }

  function onContextMenu(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.preventDefault();
    setContextMenu({ applet, x: e.pageX, y: e.pageY });
  }

  return (
    <>
      <Card
        onClick={onEdit}
        className="applet-list-item"
        onContextMenu={onContextMenu}
      >
        <Card.Body>
          <Card.Title className="text-center">
            <div style={{ position: "relative" }}>
              <FontAwesomeIcon
                icon={
                  applet.errors > 0
                    ? faExclamationTriangle
                    : applet.status === "ENABLED"
                    ? faWifi
                    : faWifiSlash
                }
                size="3x"
                className={`mb-4 mt-3 ${
                  applet.errors > 0 ? "duo-inverse" : ""
                }`}
              />
              {applet.errors > 0 && (
                <Badge
                  variant="danger"
                  className="applet-list-error-count"
                  pill
                >
                  {applet.errors}
                </Badge>
              )}
            </div>
            <h5 className="mb-4">{applet.name}</h5>
            <div>
              <div
                className={`ribbon ${
                  settingStatus
                    ? ""
                    : applet.status === "ENABLED"
                    ? "ribbon-success"
                    : "ribbon-danger"
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!settingStatus) {
                    status(
                      applet.id,
                      applet.status === "ENABLED" ? "disable" : "enable"
                    );
                  }
                }}
              >
                {settingStatus ? (
                  <div>
                    <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin />{" "}
                    Loading
                  </div>
                ) : applet.status === "ENABLED" ? (
                  <>
                    <FontAwesomeIcon icon={faToggleOn} size="lg" /> Enabled
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faToggleOff} size="lg" /> Disabled
                  </>
                )}
              </div>
            </div>
          </Card.Title>
          <div>
            <div className="d-flex">
              <div className="mb-0 flex-grow-1">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-${applet.id}-nodes`}>
                      Node Count
                    </Tooltip>
                  }
                >
                  <FontAwesomeIcon
                    icon={faProjectDiagram}
                    size="sm"
                    className="mr-1"
                  />
                </OverlayTrigger>{" "}
                <span className="text-primary">{applet.nodeCount}</span>
              </div>
              <div className="mb-0 flex-grow-1">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-${applet.id}-invocations`}>
                      Total Invocations
                    </Tooltip>
                  }
                >
                  <FontAwesomeIcon
                    icon={faUserChart}
                    size="sm"
                    className="mr-1"
                  />
                </OverlayTrigger>{" "}
                <span className="text-primary">{applet.totalInvocations}</span>
              </div>
            </div>
            <div className="d-flex mt-1">
              <div className="mb-0 flex-grow-1">
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={`tooltip-${applet.id}-modules`}>
                      Required Modules
                    </Tooltip>
                  }
                >
                  <FontAwesomeIcon icon={faCube} size="sm" className="mr-1" />
                </OverlayTrigger>{" "}
                <span className="text-primary">
                  {applet.requiredModules.length > 0
                    ? applet.requiredModules.join(", ")
                    : "None"}
                </span>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}

import axios from "axios";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { APIUrl, AuthUrl } from "../../Constants";

type DisableMFAModalProps = {
  show: boolean;
  onHide: () => void;
  mfa: boolean;
  onComplete: () => void;
};

export function DisableMFAModal(props: DisableMFAModalProps) {
  const [mfaCode, setMfaCode] = useState<string>();
  const [mfaError, setMfaError] = useState(false);

  async function DisableMFA() {
    setMfaError(false);
    let code = mfaCode ? mfaCode.replace(" ", "") : null;
    if (props.mfa && (!code || code.length < 6 || code.length > 6)) {
      setMfaError(true);
      return;
    }
    try {
      const response = await axios.post(`${APIUrl}/user/mfa/disable`, {
        code: code,
      });
      if (response && response.data) {
        props.onHide();
        props.onComplete();
      }
    } catch (error) {
      setMfaError(true);
      console.log(error);
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.onHide();
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Disable 2 Factor Authentication</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>
            Are you sure you want to disable 2 Factor Authentication?
          </Form.Label>
          <br />
          <Form.Label>
            This will make your account less secure. You can re-enable at any
            time
          </Form.Label>
          <br />
          {props.mfa ? (
            <>
              <Form.Label>
                Please enter the code from your authenticator app below
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={"6 Digit Code"}
                onFocus={() => {
                  setMfaError(false);
                }}
                isInvalid={mfaError}
                onChange={(e) => {
                  setMfaCode(e.target.value);
                }}
              />
              {mfaError ? (
                <Form.Label className="form-error-text">
                  Code Invalid
                </Form.Label>
              ) : null}
              <br />
              <a
                className="mt-2"
                href={`${AuthUrl}/account/mfa/forgot?returnUrl=${window.location}`}
              >
                Can't access your code? Recover 2 factor here
              </a>
            </>
          ) : null}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-primary"
          onClick={() => {
            setMfaError(false);
            props.onHide();
          }}
          style={{ minWidth: 100 }}
        >
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            DisableMFA();
          }}
          style={{ minWidth: 100 }}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

import {
  faChartLine,
  faCubes,
  faTachometerAlt,
  faUser,
  faUsers,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Pie } from "react-chartjs-2";
import { APIUrl } from "../../Constants";
import { AdminStatistics } from "../../types";
import { Spinner } from "../shared/Spinner";
import { StatCard } from "../shared/StatCard";

export function Dashboard() {
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState<AdminStatistics>();

  useEffect(() => {
    let cancelled = false;

    async function load() {
      try {
        const response = await axios.get(`${APIUrl}/admin/statistics`);
        if (!cancelled) {
          setStats(response.data);
        }
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    }
    load();

    return () => {
      cancelled = true;
    };
  }, []);

  if (loading || !stats) {
    return <Spinner />;
  }

  return (
    <div className="page-content">
      <h1>
        <FontAwesomeIcon icon={faTachometerAlt} /> Admin Dashboard
      </h1>
      <Row className="mt-4">
        <Col xl={3} lg={6}>
          <StatCard icon={faUsers} name="Total Users" value={stats.users} />
        </Col>
        <Col xl={3} lg={6}>
          <StatCard icon={faCubes} name="Total Applets" value={stats.applets} />
        </Col>
        <Col xl={3} lg={6}>
          <StatCard
            icon={faCubes}
            name="Enabled Applets"
            value={stats.enabledApplets}
          />
        </Col>
        <Col xl={3} lg={6}>
          <StatCard
            icon={faChartLine}
            name="Invocations Today"
            value={stats.invocationsToday}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xl={6}>
          <Row>
            <Col xl={6}>
              <StatCard
                icon={faUser}
                name="Free Users"
                value={stats.freeUsers}
              />
            </Col>
            <Col xl={6}>
              <StatCard
                icon={faUser}
                name="Premium Users"
                value={stats.premiumUsers}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xl={6}>
              <StatCard
                icon={faUser}
                name="Advanced Users"
                value={stats.advancedUsers}
              />
            </Col>
            <Col xl={6}>
              <StatCard
                icon={faUser}
                name="Professional Users"
                value={stats.professionalUsers}
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Pie
            height={125}
            data={{
              labels: [
                "Free Users",
                "Premium Users",
                "Advanced Users",
                "Professional Users",
              ],
              datasets: [
                {
                  label: "Users by Plan",
                  data: [
                    stats.freeUsers,
                    stats.premiumUsers,
                    stats.advancedUsers,
                    stats.professionalUsers,
                  ],
                  borderWidth: 0,
                  backgroundColor: [
                    "#d65a66",
                    "#5e5cd6",
                    "#5cccd6",
                    "#5cd65c",
                    "#d6d45c",
                  ],
                },
              ],
            }}
            options={{}}
          />
        </Col>
      </Row>
    </div>
  );
}

import { Button, Modal } from "react-bootstrap";

type WelcomeModalProps = {
  onClose: () => void;
  startTutorial: () => void;
};

export function WelcomeModal({ onClose, startTutorial }: WelcomeModalProps) {
  return (
    <Modal show={true} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Welcome to Stream Hydra!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Would you like to take a short tutorial on the platform to better
        understand how it works?
      </Modal.Body>
      <Modal.Footer>
        <div className="flex-grow-1">
          <Button variant="outline-primary" onClick={onClose} block>
            Skip
          </Button>
        </div>
        <div className="flex-grow-1">
          <Button variant="primary" onClick={startTutorial} block>
            Yes
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

import {
  faChevronDown,
  faChevronLeft,
  faUserEdit,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useModules } from "../../../../context/ModulesContext";
import { NodeModule } from "../../../../types";
import { elements } from "./elements";

export function ConfigSidenav() {
  return (
    <div className="sidenav">
      <div className="sidenav-content">
        <SidenavGroup module="Core" />
        <SidenavGroup module="Minecraft" />
        <SidenavGroup module="PhilipsHue" />
      </div>
    </div>
  );
}

type SidenavGroupProps = {
  module: NodeModule;
};

export function SidenavGroup({ module }: SidenavGroupProps) {
  const { modules } = useModules();
  const [expanded, setExpanded] = useState(true);

  if (
    module !== "Core" &&
    (!modules.find((x) => x.id === module) ||
      !modules.find((x) => x.id === module)?.enabled)
  ) {
    return null;
  }

  return (
    <div className="sidenav-group">
      <div
        className="sidenav-group-header"
        onClick={() => setExpanded(!expanded)}
      >
        <p>{module} Module</p>
        <FontAwesomeIcon icon={expanded ? faChevronDown : faChevronLeft} />
      </div>
      {expanded && (
        <ul className="sidenav-group-items">
          {Object.keys(elements)
            .filter((x) => elements[x].module === module)
            .map((el) => (
              <SidenavItem
                key={elements[el].id}
                id={elements[el].id}
                name={elements[el].name}
                description={elements[el].description}
              />
            ))}
        </ul>
      )}
    </div>
  );
}

type SidenavItemProps = {
  id: string;
  name: string;
  description?: string;
};

export function SidenavItem({ id, name, description }: SidenavItemProps) {
  function onDragStart(e: React.DragEvent<HTMLLIElement>) {
    e.dataTransfer.setData("type", "element");
    e.dataTransfer.setData("id", id);
  }

  return (
    <>
      <li draggable onDragStart={onDragStart}>
        <div className="sidenav-group-item-icon">
          <FontAwesomeIcon icon={faUserEdit} />
        </div>
        <span className="sidenav-group-item-divider" />
        <div className="sidenav-group-item-label">
          <div className="d-flex align-items-center">
            <p className="flex-grow-1" style={{ lineHeight: "12px" }}>
              {name}
              <br />
              <small>{description}</small>
            </p>
          </div>
        </div>
      </li>
    </>
  );
}

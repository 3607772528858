import { faUndo } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createRef, RefObject, useEffect, useMemo, useState } from "react";
import { Button, Form, ListGroup } from "react-bootstrap";
import { NodeOptionData, Variable } from "../../../../../types";
import { getIconFor } from "../../../../shared/utils";

type ContextModalProps = {
  onUse: (variable: string) => void;
  variables?: Variable[];
  selected?: number;
};

type Selecting = {
  origin: Variable;
  data: NodeOptionData;
  depth: NodeOptionDataSelection[];
  value: string;
};

type NodeOptionDataSelection = NodeOptionData & {
  value: string;
};

export function ContextModal({
  onUse,
  variables,
  selected,
}: ContextModalProps) {
  const [selecting, setSelecting] = useState<Selecting>();
  const [arrayIndex, setArrayIndex] = useState<string>("0");

  const refs = useMemo(
    () =>
      variables
        ? variables.reduce((acc, value, index) => {
            acc[index] = createRef<HTMLAnchorElement>();
            return acc;
          }, {} as { [key: string]: RefObject<HTMLAnchorElement> })
        : {},
    [variables]
  );

  function selectVariable() {
    if (selecting && selecting.value && selecting.origin.value) {
      onUse(selecting.value);
      setSelecting(undefined);
      setArrayIndex("0");
    }
  }

  function onClick(ctx: Variable) {
    if (
      !selecting ||
      !selecting.value ||
      selecting.origin.value !== ctx.value
    ) {
      if (ctx.type === "Array" || ctx.type === "Object") {
        setSelecting({
          origin: ctx,
          data: {
            type: ctx.type,
            description: ctx.description || "",
            definitions: ctx.definitions,
          },
          depth: [],
          value: ctx.value,
        });
      } else {
        onUse(ctx.value);
      }
    }
  }

  function getSelectorContext(): NodeOptionData | null {
    if (selecting && selecting.data) {
      let value: NodeOptionData = selecting.data;

      selecting.depth.forEach((key, index) => {
        if (
          key.type !== "Array" &&
          value.definitions &&
          value.definitions[key.value]
        ) {
          value = value.definitions[key.value];
        } else if (
          key.type === "Array" &&
          index === selecting.depth.length - 1
        ) {
          value.type = "Object";
        }
      });

      return value;
    }

    return null;
  }

  function renderDepthEditor() {
    const current = getSelectorContext();
    if (current) {
      if (current.type === "Array") {
        return (
          <Form
            onSubmit={(e) => {
              e.stopPropagation();
            }}
          >
            <p className="mt-4">
              <small>
                Click Use Array Variable to get the whole array as a string.
                <br />
                OR
                <br />
                Enter a value and click Use Index to exact an element form the
                array. (You can manually change this to a variable later)
              </small>
            </p>
            <div className="d-flex" style={{ gap: 5 }}>
              <div>
                <Form.Control
                  type="text"
                  placeholder="Array Index"
                  size="sm"
                  value={arrayIndex}
                  onChange={(e) =>
                    e.target.value === ""
                      ? setArrayIndex("")
                      : !isNaN(parseInt(e.target.value))
                      ? setArrayIndex(parseInt(e.target.value).toString())
                      : setArrayIndex("0")
                  }
                  style={{ maxWidth: 60 }}
                />
              </div>
              <div className="flex-grow-1">
                <Button
                  variant="outline-primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    setArrayIndex("0");
                    next(
                      current,
                      !isNaN(parseInt(arrayIndex)) ? arrayIndex : "0"
                    );
                  }}
                  size="sm"
                  block
                >
                  Use Index
                </Button>
              </div>
              <div className="flex-grow-1">
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    selectVariable();
                  }}
                  size="sm"
                  block
                >
                  Use Array Variable
                </Button>
              </div>
            </div>
          </Form>
        );
      } else if (current.type === "Object" && current.definitions) {
        return (
          <>
            <p className="mt-4">
              <small>
                Click Use Object Variable to get the whole object as a string.
                <br />
                OR
                <br />
                Click on an item in the list to get its value.
              </small>
            </p>
            <ul className="list-unstyled list-bg">
              {Object.keys(current.definitions).map((x) => (
                <li
                  key={x}
                  onClick={(e) => {
                    e.stopPropagation();
                    next(current, x);
                  }}
                  className="pl-2 py-2 list-group-item"
                >
                  {"{"}
                  {x}
                  {"}"} | <small>{current.definitions![x].type}</small>
                  <br />
                  <small>{current.definitions![x].description}</small>
                </li>
              ))}
            </ul>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                selectVariable();
              }}
              size="sm"
            >
              Use Object Variable
            </Button>
          </>
        );
      } else {
        return (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              selectVariable();
            }}
            size="sm"
          >
            Use Variable
          </Button>
        );
      }
    }
  }

  function next(current: NodeOptionData, value: string) {
    if (current.type === "Array") {
      setSelecting({
        ...selecting!,
        value: `${selecting!.value}[${value}]`,
        depth: [...selecting!.depth, { ...current, value }],
      });
    } else if (current.type === "Object") {
      setSelecting({
        ...selecting!,
        value: `${selecting!.value}[${value}]`,
        depth: [...selecting!.depth, { ...current, value }],
        data: selecting!.data.definitions![value],
      });
    }
  }

  useEffect(() => {
    if (
      selected !== undefined &&
      !!refs[selected] &&
      !!refs[selected].current
    ) {
      refs[selected].current!.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [selected, refs]);

  return (
    <div className="context-modal">
      {variables && variables.length > 0 ? (
        <ListGroup className="context-list list-bg">
          {variables.map((item, index) => (
            <ListGroup.Item
              key={index}
              onClick={() => onClick(item)}
              className={`${
                selecting &&
                selecting.value &&
                selecting.origin.value === item.value
                  ? ""
                  : "hover"
              } ${selected === index ? "selected" : ""}`}
              ref={refs[index]}
            >
              <FontAwesomeIcon icon={getIconFor(item.icon)} />{" "}
              {selecting &&
              selecting.value &&
              selecting.origin.value === item.value ? (
                <>
                  <strong>
                    {"{"}
                    {selecting.value}
                    {"}"}
                  </strong>{" "}
                  <small> | {selecting.data.type}</small>
                  <Button
                    size="sm"
                    style={{ float: "right" }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelecting(undefined);
                    }}
                  >
                    <FontAwesomeIcon icon={faUndo} /> Reset
                  </Button>
                  <br />
                  <small>{selecting.data.description}</small>
                </>
              ) : (
                <>
                  <strong>
                    {"{"}
                    {item.value}
                    {"}"}
                  </strong>{" "}
                  <small>| {item.type} | {item.origin}</small>
                  <br />
                  <small>{item.description}</small>
                </>
              )}
              <div>
                {selecting &&
                  selecting.origin.value === item.value &&
                  renderDepthEditor()}
              </div>
            </ListGroup.Item>
          ))}
        </ListGroup>
      ) : (
        <p className="m-2">There are no variables available.</p>
      )}
    </div>
  );
}

import {
  faExclamationTriangle,
  faMinusCircle,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { APIUrl, ClientUrl } from "../../Constants";
import { Team } from "../../types";
import { ConfirmationModal } from "../shared/modals/ConfirmationModal";

type PendingProps = {
  team: Team;
  onCancel: () => void;
};

export function Pending({ team, onCancel }: PendingProps) {
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);

  async function cancelRequest() {
    setLoading(true);
    await axios.delete(`${APIUrl}/teams/${team.id}/member/request`);
    setLoading(false);
    onCancel();
  }

  async function closeRequest() {
    setLoading(true);
    await axios.patch(`${APIUrl}/teams/${team.id}/member/request`);
    setLoading(false);
    onCancel();
  }

  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 70,
          right: 0,
          bottom: 0,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="text-center">
          <img
            src={team.iconUrl || `${ClientUrl}/logo.png`}
            alt="Team Icon"
            width={250}
          />
          <h1 className="mt-2">{team.name}</h1>

          {team.activeUser && team.activeUser.state === "PENDING" && (
            <p>
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                size="lg"
                className="mr-2 text-info"
              />
              Join Request Pending...
            </p>
          )}

          {team.activeUser && team.activeUser.state === "REJECTED" && (
            <p>
              <FontAwesomeIcon
                icon={faMinusCircle}
                size="lg"
                className="mr-2 text-danger"
              />
              Join Request Rejected
            </p>
          )}

          <Button
            variant="primary"
            onClick={() =>
              team.activeUser && team.activeUser.state === "PENDING"
                ? setConfirm(true)
                : closeRequest()
            }
            disabled={loading}
          >
            {loading
              ? team.activeUser && team.activeUser.state === "PENDING"
                ? "Cancelling Request..."
                : "Closing Request..."
              : team.activeUser && team.activeUser.state === "PENDING"
              ? "Cancel Request"
              : "Close Request"}
          </Button>
        </div>
      </div>
      {confirm && (
        <ConfirmationModal
          title="Cancel Request"
          body="Are you sure you want to cancel this request?"
          onConfirm={cancelRequest}
          onClose={() => setConfirm(false)}
        />
      )}
    </>
  );
}

import { Route, Switch, useRouteMatch } from "react-router-dom";
import { useModules } from "../../context/ModulesContext";
import { ModuleList } from "./ModuleList";

export function Modules() {
  const { path } = useRouteMatch();
  const { modules, setModules } = useModules();

  return (
    <div className="page-content">
      <Switch>
        <Route path={`${path}`}>
          <ModuleList modules={modules} setModules={setModules} />
        </Route>
      </Switch>
    </div>
  );
}

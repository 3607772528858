import { useEffect, useRef, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { SketchPicker } from "react-color";

type ColorPickerInputProps = {
  id: string;
  value: string;
  onChange: (value: string) => void;
  label?: string;
  placeholder?: string;
  isInvalid?: boolean;
  error?: string;
  disabled?: boolean;
};

export function ColorPickerInput({
  id,
  value,
  onChange,
  label,
  placeholder,
  isInvalid,
  error,
  disabled,
}: ColorPickerInputProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [showPicker, setShowPicker] = useState(false);
  const [color, setColor] = useState<string>(value);

  useEffect(() => {
    function onFocus(e: MouseEvent) {
      if (ref.current && ref.current.contains(e.target as Node)) {
        setShowPicker(true);
      } else {
        setShowPicker(false);
      }
    }

    window.addEventListener("click", onFocus);

    return () => {
      window.removeEventListener("click", onFocus);
    };
  }, []);

  return (
    <div ref={ref}>
      <Form.Group className="color-picker-input">
        {label && <Form.Label>{label}</Form.Label>}

        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text
              className="color"
              style={{ backgroundColor: color }}
            ></InputGroup.Text>
          </InputGroup.Prepend>
          <Form.Control
            id={id}
            type="text"
            placeholder={placeholder ? placeholder : ""}
            value={value}
            isInvalid={isInvalid}
            readOnly
            className="bg-base-bg"
            disabled={disabled}
          />
        </InputGroup>

        {showPicker && !disabled && (
          <div>
            <SketchPicker
              className="picker"
              color={color}
              onChange={(e) => setColor(e.hex)}
              onChangeComplete={(e) => onChange(e.hex)}
            />
          </div>
        )}

        <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      </Form.Group>
    </div>
  );
}

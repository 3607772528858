type SpinnerProps = {
  fill?: boolean;
  size?: "sm" | "xs";
};

export function Spinner({ fill, size }: SpinnerProps) {
  if (fill) {
    return (
      <div className="loader-wrapper">
        <div className="loader">Loading...</div>
      </div>
    );
  }

  return (
    <div
      className={`loader ${
        size === "sm" ? "small" : size === "xs" ? "tiny" : ""
      }`}
    >
      Loading...
    </div>
  );
}

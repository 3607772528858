import {
  faBoxFull,
  faBoxOpen,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Badge, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useModules } from "../../context/ModulesContext";
import { Template } from "../../types";

type TemplateSelectorProps = {
  show: boolean;
  openEditor: (id?: string, state?: unknown) => void;
  templates: Template[];
  onClose: () => void;
};

export function TemplateSelector({
  show,
  openEditor,
  templates,
  onClose,
}: TemplateSelectorProps) {
  const [selected, setSelected] = useState<string>();
  const { modules } = useModules();

  useEffect(() => {
    setSelected(undefined);
  }, [show]);

  return (
    <div
      className="template-selector-wrapper"
      style={{ width: show ? 300 : 0 }}
    >
      <div className="template-selector">
        <div className="template-selector-column">
          <div className="d-flex justify-content-center align-items-center">
            <div className="flex-grow-1">
              <h5 className="mb-0">Select a Template</h5>
            </div>
            <FontAwesomeIcon
              icon={faTimes}
              className="hover-primary"
              size="lg"
              onClick={onClose}
            />
          </div>
          <div className="template-list">
            <div
              className={`template-list-item ${
                selected === undefined ? "active" : ""
              }`}
              onClick={() => setSelected(undefined)}
            >
              <div>
                <FontAwesomeIcon icon={faBoxOpen} size="5x" />
              </div>
              <p className="mt-3 mb-0">Blank Applet</p>
              <p className="mt-2 mb-0">
                <small>
                  A completely empty applet for you to create something awesome.
                </small>
              </p>
            </div>
            {templates.map((template) =>
              template.requiredModules.filter((mod) =>
                modules.find((x) => x.id === mod && x.enabled)
              ).length < template.requiredModules.length ? (
                <div
                  className={`template-list-item ${
                    selected === template.id ? "active" : ""
                  }`}
                  key={template.id}
                >
                  <OverlayTrigger
                    placement="bottom"
                    overlay={
                      <Tooltip id={`tooltip-${template.id}`}>
                        Please enable all the required modules before using this
                        template!
                      </Tooltip>
                    }
                  >
                    <div>
                      <div>
                        <FontAwesomeIcon icon={faBoxFull} size="5x" />
                      </div>
                      <p className="mt-3 mb-0">{template.name}</p>
                      <p className="mt-2 mb-0">
                        <small>{template.description}</small>
                      </p>
                      <div className="d-flex mt-2 justify-content-center">
                        {template.requiredModules.map((module) => (
                          <Badge
                            key={module}
                            variant="primary"
                            pill
                            className="m-1"
                          >
                            {module}
                          </Badge>
                        ))}
                      </div>
                    </div>
                  </OverlayTrigger>
                </div>
              ) : (
                <div
                  className={`template-list-item ${
                    selected === template.id ? "active" : ""
                  }`}
                  onClick={() => setSelected(template.id)}
                  key={template.id}
                >
                  <div>
                    <FontAwesomeIcon icon={faBoxFull} size="5x" />
                  </div>
                  <p className="mt-3 mb-0">{template.name}</p>
                  <p className="mt-2 mb-0">
                    <small>{template.description}</small>
                  </p>
                  <div className="d-flex mt-2 justify-content-center">
                    {template.requiredModules.map((module) => (
                      <Badge
                        key={module}
                        variant="primary"
                        pill
                        className="m-1"
                      >
                        {module}
                      </Badge>
                    ))}
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
      <div className="template-controls">
        <Button
          onClick={() => openEditor(undefined, { template: selected })}
          block
        >
          Create Applet
        </Button>
      </div>
    </div>
  );
}

import { faCogs, faSignOut } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { DateTime } from "luxon";
import { useEffect, useRef, useState } from "react";
import { Badge, Button, Col, Overlay, Row, Tooltip } from "react-bootstrap";
import { APIUrl } from "../../Constants";
import { Team } from "../../types";
import { CustomAlert } from "../shared/CustomAlert";
import { ConfirmationModal } from "../shared/modals/ConfirmationModal";
import { getTeamMemberBadgeVariant } from "../shared/utils";
import { AppletSharing } from "./AppletSharing";
import { TeamSettings } from "./Modals/TeamSettings";
import { TriggerSharing } from "./TriggerSharing";
import { Users } from "./Users";

type TeamViewProps = {
  team: Team;
  setTeam: (team: Team) => void;
  onLeaveTeam: () => void;
};

export function TeamView({ team, setTeam, onLeaveTeam }: TeamViewProps) {
  const [showLink, setShowLink] = useState(false);
  const [showCopied, setShowCopied] = useState(false);
  const [showTeam, setShowTeam] = useState(false);
  const [confirmLeave, setConfirmLeave] = useState(false);
  const target = useRef(null);

  useEffect(() => {
    if (showCopied) {
      setTimeout(() => {
        setShowCopied(false);
      }, 2000);
    }
  }, [showCopied]);

  async function leaveTeam() {
    await axios.post(`${APIUrl}/teams/${team.id}/leave-team`);
    onLeaveTeam();
  }

  function getExpirationDate() {
    return DateTime.fromISO(team.lockedAt)
      .plus({ days: 14 })
      .diffNow()
      .toFormat("dd");
  }

  return (
    <>
      <div className="d-flex align-items-center">
        <div className="flex-grow-1">
          <div className="d-flex align-items-center">
            <h2 className="mb-0 mr-3">{team.name}</h2>
            <div>
              <Badge
                variant={getTeamMemberBadgeVariant(team.activeUser!.teamRole)}
              >
                {team.activeUser!.teamRole}
              </Badge>
            </div>
          </div>
          <p>
            Team Invite Code:{" "}
            {showLink ? (
              <>
                <span
                  className="hover text-primary"
                  onClick={() => {
                    navigator.clipboard.writeText(team.inviteCode);
                    setShowCopied(true);
                  }}
                  ref={target}
                >
                  {team.inviteCode}
                </span>
                <Overlay
                  target={target.current}
                  show={showCopied}
                  placement="right"
                >
                  {(props) => (
                    <Tooltip id="copied" {...props}>
                      Copied to Clipboard!
                    </Tooltip>
                  )}
                </Overlay>
              </>
            ) : (
              <span
                className="text-primary hover"
                onClick={() => setShowLink(true)}
              >
                Click to Show
              </span>
            )}
          </p>
        </div>
        {team.activeUser && team.activeUser.teamRole === "ADMIN" ? (
          <div>
            <Button variant="outline-primary" onClick={() => setShowTeam(true)}>
              <FontAwesomeIcon icon={faCogs} /> Team Settings
            </Button>
          </div>
        ) : (
          <div>
            <Button
              variant="outline-primary"
              onClick={() => setConfirmLeave(true)}
            >
              <FontAwesomeIcon icon={faSignOut} /> Leave Team
            </Button>
          </div>
        )}
      </div>
      {team.locked ? (
        <CustomAlert type="danger" title="Team Locked" border>
          The owner of this team is no longer on a plan that supports teams.
          This team will continue to function with any data sharing already
          setup.
          <br />
          <strong>
            The team will be deleted{" "}
            {getExpirationDate() === "00"
              ? "Today at Midnight UTC"
              : `${getExpirationDate()} Days`}
          </strong>
        </CustomAlert>
      ) : team.freeSlotsLimit !== -1 &&
        team.currentUsedFreeSlots > team.freeSlotsLimit ? (
        <CustomAlert type="danger" title="Free Slots Limit Exceeded" border>
          You have exceeded your free slots limit and this team has been locked
          to free users. {team.currentUsedFreeSlots - team.freeSlotsLimit} Free
          User(s) must upgrade their account or be removed from the team!
        </CustomAlert>
      ) : null}

      {!team.locked &&
        !(
          team.activeUser?.isFree &&
          team.currentUsedFreeSlots > team.freeSlotsLimit
        ) && (
          <>
            <Row className="mt-4">
              <Col xl={5}>
                <Users
                  team={team}
                  setActiveUser={(user) =>
                    setTeam({ ...team, activeUser: user })
                  }
                  setTeam={setTeam}
                />
              </Col>
              <Col xl={7}>
                <TriggerSharing team={team} />
              </Col>
            </Row>
            <div className="mt-4">
              <AppletSharing team={team} />
            </div>
          </>
        )}

      {showTeam && (
        <TeamSettings
          team={team}
          setTeam={setTeam}
          onClose={() => setShowTeam(false)}
        />
      )}
      {confirmLeave && (
        <ConfirmationModal
          title="Leave Team"
          body="Are you sure you want to leave this team?"
          onClose={() => setConfirmLeave(false)}
          onConfirm={() => {
            leaveTeam();
            setConfirmLeave(false);
          }}
        />
      )}
    </>
  );
}

import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { APIUrl } from "../../Constants";
import { ChangelogList } from "./ChangelogList";

type ChangelogProps = {
  show: boolean;
  onHide: () => void;
};

export function Changelog(props: ChangelogProps) {
  const lists = [
    <ChangelogList
      date="7th of June, 2021"
      sections={[
        {
          title: "NEW MODULES!",
          items: [
            {
              title: "Patreon",
              description:
                "We have added patreon as a module so now you can trigger an applet when a patreon pledge is created, updated or deleted!",
            },
            {
              title: "Twitter",
              description:
                "We have added twitter as a module so you can send automatic tweets from applets!",
            },
          ],
        },
        {
          title: "CORE MODULE",
          items: [
            {
              title: "Added Label Node",
              description:
                "This unique node lets you write a note on a label and place it anywhere in the applet editor!",
              suggestion: {
                name: "Katieelise17",
                link: "https://www.twitch.tv/katieelise17",
              },
            },
            {
              title: "Added Timer Node",
              description:
                "This node will continuously run your applet every so many minutes, as specified by you.",
            },
            {
              title: "Added For-Loop Node",
              advanced: true,
              description:
                "This node allows you to loop through an array variable and execute other nodes for each element in the array.",
            },
          ],
        },
        {
          title: "TWITCH MODULE",
          items: [
            {
              title: "PREDICTIONS!!!",
              description:
                "You can now automatically create, update, lock and delete channel predictions, as well as triggering an applet based on any of these things happening.",
            },
            {
              title: "POLLS!!!",
              description:
                "Just like predictions, you can now automatically create, update and delete polls and run applets based on these triggers.",
            },
            {
              title: "Bot Command Arguments",
              advanced: true,
              description:
                "Bot commands now pass a list of all the other words sent after the command.",
            },
            {
              title: "Simple Commands Improved",
              advanced: true,
              description:
                "You can now use variables to reply with the username of the user who executed the command or access other words in their message.",
            },
          ],
        },
        {
          title: "OTHER IMPROVEMENTS",
          items: [
            {
              title: "Double-Click to Edit Node",
              description:
                "You can now double click on a node in the advanced editor to edit the node, instead of having to right click on it first.",
              suggestion: {
                name: "AutomaticFlash",
                link: "https://www.twitch.tv/AutomaticFlash",
              },
            },
            {
              title: "Array & Object Variables",
              advanced: true,
              description:
                "We now support arrays and objects in the applet editor, allowing far more options when it comes to manipulating data.",
            },
          ],
        },
        {
          title: "BUG FIXES",
          items: [
            {
              description:
                "Fixed an issue where the chat bot server could become out of sync with the master server. (Liam did a stupid)",
            },
            {
              description:
                "Had a harsh talk with the number ranged input, it now shouldnt keep replacing your numbers with a 1 while typing.",
            },
            {
              description:
                "Fixed a rare issue that could cause applets using the twitch API to become out of sync. (This one is elliots fault)",
            },
          ],
        },
      ]}
    />,
    <ChangelogList
      date="16th of June, 2021"
      sections={[
        {
          title: "Paid Plans Now Available!",
          items: [
            {
              description:
                "If you would like to support the development of Stream Hydra, you can now subscribe to a paid plan and get even more features!",
            },
          ],
        },
        {
          title: "AFFILIATE & PARTNER PROGRAMS",
          items: [
            {
              title: "Affiliate Program",
              premium: true,
              description:
                "Affiliates get a code they can give out. Users of your code at checkout get 10% off their first month, and you get £5 off your next bill... per user! The affiliate program is open to anyone as long as you are on a paid plan.",
            },
            {
              title: "Partner Program",
              premium: true,
              description:
                "Similar to the affiliate program but with greater discounts and you get a custom promo code and Stream Hydra banners for your stream. Currently this is invite only but please let us know if you are interested via discord.",
            },
          ],
        },
        {
          title: "TWITCH MODULE",
          items: [
            {
              title: "On Resubscribe",
              description:
                "We have added a new trigger to the twitch module allowing you to detect when someone resubscribes to your channel.",
            },
            {
              title: "On Subscription Gift",
              description:
                "We have added a new trigger to the twitch module allowing you to detect when someone gifts subs in your channel.",
            },
            {
              title: "Custom Bot Names",
              premium: true,
              description:
                "You can now link a 2nd twitch account to Stream Hydra to act as your bot in chat instead of using StreamHydraBot",
            },
          ],
        },
        {
          title: "OTHER IMPROVEMENTS",
          items: [
            {
              title: "Settings Page",
              description:
                "The Settings page has been updated to better reflect your current subscription plan.",
            },
          ],
        },
        {
          title: "BUG FIXES",
          items: [
            {
              description:
                "Fixed incorrect descriptions showing on Get User Info node (oops)",
            },
            {
              description:
                "Fixed a case where Toggle Inputs could crash the applet editor (double oops)",
            },
            {
              description:
                "11 other minor bug fixes & internal optimizations (im too lazy to type)",
            },
          ],
        },
      ]}
    />,
    <ChangelogList
      date="30th of July, 2021"
      sections={[
        {
          title: "TWITCH MODULE",
          items: [
            {
              title: "Command List",
              description:
                "You can now have the bot list all commands available in your channel by typing !commands. Enable this from the Simple Bot Commands menu.",
            },
            {
              title: "Command List Editing for Mods",
              description:
                "You can now allow mods to add, edit and remove your simple commands via the twitch chat. Enable this from the Simple Bot Commands menu.",
            },
            {
              title: "Force Create Reward",
              description:
                "The Create Channel Point Reward Node now has an option for Force Create, this will make sure the new reward is always created by removing any duplicates.",
            },
            {
              title: "Stream Tags Node",
              description:
                "The stream tags node has been fixed and now includes a searchable dropdown list of tags.",
            },
          ],
        },
        {
          title: "COMMUNITY MODULE",
          items: [
            {
              title: "Improved Explanation",
              description:
                "Community now shows a couple of tutorial cards that help explain how to get started with community.",
            },
            {
              title: "Improved Performance",
              description:
                "We have reworked a lot of internal systems in community to make the experience better.",
            },
            {
              title: "Deleting Users",
              description:
                "You can now delete users from your community, they will be able to join back again.",
            },
            {
              title: "Banning Users",
              description:
                "You can now permanently ban users from your community.",
            },
          ],
        },
        {
          title: "CORE MODULE",
          items: [
            {
              title: "Timer Revamp",
              description:
                "Timers have been completely redesigned to give you much more control.",
            },
            {
              title: "Unique Filter",
              description:
                "You can now reset the unique filter data from an applet via a button on the node.",
            },
            {
              title: "Delete Data Node",
              description:
                "You can now delete data from the applets internal storage via this node.",
            },
          ],
        },
        {
          title: "OTHER IMPROVEMENTS",
          items: [
            {
              title: "Invocation Counting Updated",
              description:
                "Applets that fail will no longer count towards your invocation count. Invocations are now only counted once, even if you have multiple applets using the same trigger.",
            },
            {
              title: "Error Handling Improvements",
              description:
                "Applet crashes will now only kill the current thread, allowing for the rest of the applet to continue. We have also improved our internal error handling system to be more transparent giving you better data on why something is failing, we will continue to improve this over time.",
            },
            {
              title: "Applet Editor Grid",
              description:
                "Snap to Grid and Show Grid Lines are both now enabled by default.",
            },
            {
              title: "Applet Expiry Timer",
              description:
                "For Free Users, the applet expiry time is now shown on the applets list page.",
            },
            {
              title: "Modules Page Revwork",
              description: "The Modules page has been completely redesigned.",
            },
          ],
        },
        {
          title: "BUG FIXES",
          items: [
            {
              description:
                "Fixed an issue where the twitch module could not be enabled after signing in.",
            },
            {
              description:
                "Fixed Twitch On Unsubscribe not working as intended",
            },
            {
              description:
                "Fixed the Applet Testing System sometimes returning a false positive",
            },
            {
              description: "Fixed random number generator not being inclusive",
            },
            {
              description:
                "9 other minor bug fixes & internal optimizations (im too lazy to type)",
            },
          ],
        },
      ]}
    />,
    <ChangelogList
      date="20th of August, 2021"
      sections={[
        {
          title: "TEAMS",
          items: [
            {
              title: "Added Teams System",
              description:
                "You can now create / join teams and invite your friends to share triggers you can use in your applets!",
            },
            {
              title: "Added Team Templates",
              description:
                "You can now share your applets to your teams own private template library for other team members to use!",
            },
          ],
        },
        {
          title: "WE HAVE THEMES",
          items: [
            {
              title: "Added 9 New Themes",
              description:
                "We have added 9 new themes with a variety of colour combinations, check them out by clicking the dropdown in the top right of the dashboard!",
            },
            {
              title: "New Default Theme",
              description:
                "Our default theme has been given a lick of paint and is now a bit easier on the eyes. Don't worry though, if you reeeaaaaaly like the old theme then the Classic Dark Theme is available.",
            },
            {
              title: "Light Themes",
              description:
                "If you like eye pain then we now have a variety of light themes available for you!",
            },
          ],
        },
        {
          title: "COMMUNITY MODULE",
          items: [
            {
              title: "Banned Members",
              description:
                "You can now view banned members of your community and unban them.",
            },
          ],
        },
        {
          title: "MINECRAFT MODULE",
          items: [
            {
              title: "Added Get Player Info Node",
              description:
                "This node will get the UUID and if the player is a server operator.",
            },
            {
              title: "Added Has Permission Node",
              description:
                "This node will check if a specific player has a certain permission node ingame.",
            },
          ],
        },
        {
          title: "TWITCH MODULE",
          items: [
            {
              title: "Temporarily Removed Set Stream Tags",
              description:
                "There is a bug in the Twitch API that doesn't let us set this, they are looking into it.",
            },
          ],
        },
        {
          title: "OTHER IMPROVEMENTS",
          items: [
            {
              title: "Improved Invalid Node Visibility",
              description:
                "If you drag a node into the editor that requires more information it will be highlighted and you will be unable to save the applet.",
            },
          ],
        },
        {
          title: "BUG FIXES",
          items: [
            {
              description:
                "Fixed an issue where sometimes double clicking a node wouldn't open the editor.",
            },
            {
              description:
                "Fixed several issues causing tasks to sometimes fail.",
            },
            {
              description:
                "23 other minor bug fixes & internal optimizations (im too lazy to type)",
            },
          ],
        },
      ]}
    />,
  ];
  const [index, setIndex] = useState(lists.length - 1);

  async function UpdateUserChangelog() {
    try {
      await axios.post(`${APIUrl}/user/changelog`);
      props.onHide();
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={() => {
        UpdateUserChangelog();
        props.onHide();
      }}
      centered
      backdrop="static"
    >
      {lists[index]}
      <Modal.Footer>
        <div className="d-flex w-100">
          <div className="flex-grow-1">
            <Button
              variant="primary mr-2"
              disabled={index === 0}
              onClick={() => setIndex(index - 1)}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </Button>
            <Button
              variant="primary"
              disabled={index >= lists.length - 1}
              onClick={() => setIndex(index + 1)}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </Button>
          </div>
          <div className="flex-grow-1 text-right">
            <Button
              variant="primary"
              onClick={() => {
                UpdateUserChangelog();
                props.onHide();
              }}
              style={{ minWidth: 100 }}
            >
              OK
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

import axios from "axios";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { APIUrl, AuthUrl } from "../../Constants";
import { useAuth } from "../../context/AuthContext";

type DeleteAccountModalProps = {
  show: boolean;
  onHide: () => void;
  mfa: boolean;
};

export function DeleteAccountModal(props: DeleteAccountModalProps) {
  const [mfaCode, setMfaCode] = useState<string>();
  const [mfaError, setMfaError] = useState(false);
  const auth = useAuth();

  async function DeleteAccount() {
    setMfaError(false);
    let code = mfaCode ? mfaCode.replace(" ", "") : null;
    if (props.mfa && (!code || code.length < 6 || code.length > 6)) {
      setMfaError(true);
      return;
    }
    try {
      const response = await axios.post(`${APIUrl}/user/delete-account`, {
        code: code,
      });
      if (response && response.data) {
        props.onHide();
        auth.userManager?.signoutRedirect();
      } else {
        setMfaError(true);
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={() => {
        setMfaError(false);
        props.onHide();
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete Account</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Are you sure you want to delete your account?</Form.Label>
          <br />
          <Form.Label>
            This will sign you out and your account will be deleted within 14
            days. You will be sent an email with a link to cancel if you wish.
          </Form.Label>
          <br />
          {props.mfa ? (
            <>
              <Form.Label>
                Please enter the code from your authenticator app below
              </Form.Label>
              <br />
              <Form.Control
                type="text"
                placeholder={"6 Digit Code"}
                onFocus={() => {
                  setMfaError(false);
                }}
                isInvalid={mfaError}
                onChange={(e) => {
                  setMfaCode(e.target.value);
                }}
              />
              {mfaError ? (
                <Form.Label className="form-error-text">
                  Code Invalid
                </Form.Label>
              ) : null}
              <br />
              <a
                className="mt-2"
                href={`${AuthUrl}/account/mfa/forgot?returnUrl=${window.location}`}
              >
                Can't access your code? Recover 2 factor here
              </a>
            </>
          ) : null}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-primary"
          onClick={() => {
            setMfaError(false);
            props.onHide();
          }}
          style={{ minWidth: 100 }}
        >
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            DeleteAccount();
          }}
          style={{ minWidth: 100 }}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

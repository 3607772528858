import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import { APIUrl } from "../../Constants";
import { useAuth } from "../../context/AuthContext";

export function EmailVerified() {
  const [showSent, setShowSent] = React.useState(false);
  const auth = useAuth();
  async function resend() {
    try {
      setShowSent(true);
      await axios.get(APIUrl + "/Verify/Resend");
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <Modal show={true} centered backdrop="static">
      <Modal.Header>
        <Modal.Title>Please Verify Your Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Thanks for signing up! Please click the button in the email we just sent
        you to verify your email address. If you didn't receive the email, click
        the button below.
        {showSent ? (
          <Alert className={"notification mt-4 mb-0 info"}>
            <FontAwesomeIcon
              icon={faInfoCircle}
              className={"alert-icon text-info"}
            />
            Email Sent!
          </Alert>
        ) : null}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="primary" onClick={resend} disabled={showSent}>
          {showSent ? "Sent" : "Resend Email"}
        </Button>
        <Button
          variant="primary"
          onClick={() => auth.userManager?.signoutRedirect()}
        >
          Log Out
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

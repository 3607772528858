import {
  faCheckCircle,
  faSearch,
  faUsers,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { Badge, Button, Form, InputGroup, Table } from "react-bootstrap";
import { APIUrl } from "../../../Constants";
import { Paged, User } from "../../../types";
import { Paginator } from "../../shared/Paginator";
import { Spinner } from "../../shared/Spinner";
import * as _ from "underscore";
import { DateTime } from "luxon";
import { PartnerProgramModal } from "../Modal/PartnerProgramModal";
import { useHistory } from "react-router-dom";

export function Users() {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState<Paged<User>>();
  const [page, setPage] = useState(1);
  const [searchField, setSearchField] = useState("");
  const [search, setSearch] = useState("");
  const [showPartnerProgramModal, setShowPartnerProgramModal] =
    useState<User>();
  const history = useHistory();

  const debounceSearch = useMemo(
    () => _.debounce((value: string) => setSearch(value), 1000),
    []
  );

  useEffect(() => {
    let cancelled = false;

    async function load() {
      const response = await axios.get(
        `${APIUrl}/admin/users?page=${page}&search=${search}`
      );

      if (!cancelled) {
        setUsers(response.data);
        setLoading(false);
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    }
    load();

    return () => {
      cancelled = true;
    };
  }, [page, search]);

  if (loading || !users) {
    return <Spinner />;
  }

  return (
    <div className="page-content">
      <div className="d-flex">
        <h1 className="flex-grow-1">
          <FontAwesomeIcon icon={faUsers} /> Users
        </h1>
        <Form style={{ width: 300 }}>
          <Form.Group>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control
                type="text"
                placeholder="Search Users"
                value={searchField}
                onChange={(e) => {
                  setSearchField(e.target.value);
                  debounceSearch(e.target.value);
                }}
              />
            </InputGroup>
          </Form.Group>
        </Form>
      </div>

      <div className="bg-base mt-5">
        <Table striped hover>
          <thead>
            <tr>
              <th>Display Name</th>
              <th>Role</th>
              <th>Email Address</th>
              <th>Providers</th>
              <th>Status</th>
              <th>Last Login</th>
              <th>Referral</th>
              <th></th>
            </tr>
          </thead>
          {loading ? (
            <Spinner size="sm" />
          ) : (
            <tbody>
              {users.results.map((user) => (
                <tr
                  key={user.id}
                  onClick={() => {
                    history.push(`/admin/users/${user.id}/applets`);
                  }}
                >
                  <td>{user.displayName}</td>
                  <td>
                    {user.role === "FREE" ? (
                      <Badge variant="success">FREE</Badge>
                    ) : user.role === "PREMIUM" ||
                      user.role === "ADVANCED" ||
                      user.role === "PROFESSIONAL" ? (
                      <Badge variant="warning">{user.role}</Badge>
                    ) : (
                      <Badge variant="danger">{user.role}</Badge>
                    )}
                  </td>
                  <td>
                    {user.email}{" "}
                    {user.emailVerified && (
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="text-success"
                      />
                    )}
                  </td>
                  <td>{user.providers.join(", ")}</td>
                  <td>
                    {user.deleted ? (
                      <Badge variant="danger">Deleting</Badge>
                    ) : (
                      <Badge variant="success">Active</Badge>
                    )}
                  </td>
                  <td>
                    {DateTime.fromISO(user.lastSignIn).toLocaleString(
                      DateTime.DATETIME_FULL
                    )}
                  </td>
                  <td>
                    {user.referralTier !== "NORMAL" ? (
                      <>
                        {user.referralTier}{" "}
                        <Badge variant="dark">{user.referralCode}</Badge>
                      </>
                    ) : (
                      "n/a"
                    )}
                  </td>
                  <td>
                    {user.referralTier !== "PARTNER" && (
                      <Button
                        size="sm"
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowPartnerProgramModal(user);
                        }}
                      >
                        Add to Partner Program
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </Table>

        <div className="py-3 d-flex justify-content-center align-items-center position-relative">
          <Paginator
            totalResults={users.totalResults}
            pageSize={users.pageSize}
            page={users.page}
            setPage={setPage}
          />
        </div>
      </div>

      {showPartnerProgramModal && (
        <PartnerProgramModal
          user={showPartnerProgramModal}
          onClose={() => setShowPartnerProgramModal(undefined)}
        />
      )}
    </div>
  );
}

import { faEngineWarning } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NodeInstance, NodeOption } from "../../../../../types";

type DeadNodeProps = {
  node: NodeInstance;
  selected: boolean;
  onContextMenu: (e: React.MouseEvent<SVGGElement, MouseEvent>) => void;
  errorMessage?: string;
  nodeWidth: (node: NodeOption) => number;
  nodeHeight: () => number;
};

export function DeadNode({
  node,
  selected,
  onContextMenu,
  errorMessage,
  nodeWidth,
  nodeHeight,
}: DeadNodeProps) {
  const infoLine = `Missing node ${node.nodeId} from ${node.module}!`;

  return (
    <g onContextMenu={onContextMenu}>
      <rect
        x={node.x}
        y={node.y}
        width={nodeWidth(node.node)}
        height={nodeHeight()}
        className="node"
        stroke={selected ? "white" : "red"}
      />
      <FontAwesomeIcon
        icon={faEngineWarning}
        className="text-danger"
        x={node.x + 20}
        y={node.y + nodeHeight() / 2 - 25}
        width={50}
        height={50}
      />
      <text x={node.x + 90} y={node.y + (infoLine ? 25 : 35)} fontSize={14}>
        Error
      </text>
      <text x={node.x + 88} y={node.y + (infoLine ? 45 : 55)} fontSize={20}>
        This node has been removed!
      </text>
      {infoLine && (
        <text x={node.x + 90} y={node.y + 60} fontSize={10}>
          {infoLine}
        </text>
      )}
      {errorMessage && (
        <g>
          <text x={node.x} y={node.y - 10}>
            {errorMessage}
          </text>
        </g>
      )}
    </g>
  );
}

import {
  faBolt,
  faCog,
  faPencilAlt,
  faSave,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useState } from "react";
import {
  Badge,
  Button,
  Col,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { APIUrl } from "../../../Constants";
import { useNotifications } from "../../../context/NotificationContext";
import { useTutorial } from "../../../context/TutorialContext";
import { Applet } from "../../../types";
import { TestModal } from "../../shared/modals/TestModal";

type HeaderProps = {
  id: string;
  hasChanges: boolean;
  setHasChanges: (changes: boolean) => void;
  data: Applet;
  setData: (data: Applet) => void;
  renameInput: string;
  setRenameInput: (renameInput: string) => void;
  onEdit: (applet: Applet) => void;
  setEditingConfig: (editingConfig: boolean) => void;
};

export function Header({
  id,
  hasChanges,
  setHasChanges,
  data,
  setData,
  renameInput,
  setRenameInput,
  onEdit,
  setEditingConfig,
}: HeaderProps) {
  const history = useHistory();
  const notifications = useNotifications();
  const [renaming, setRenaming] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showTestModal, setShowTestModal] = useState(false);
  const tutorial = useTutorial();

  async function save() {
    console.log(data);
    setIsSaving(true);

    const parsedData = {
      ...data,
      nodes: data.nodes.map((n) => {
        return { ...n, node: n.nodeId };
      }),
    };

    const response = await axios.post(`${APIUrl}/applet`, parsedData);
    onEdit(response.data);
    setHasChanges(false);
    notifications.dispatchNotification(
      "success",
      "Applet Saved",
      `Applet "${data.name}" has been saved successfully`
    );
    setIsSaving(false);

    if (id === "new") {
      history.push(`/applets/editor/${response.data.id}`);
    }
  }

  async function enable() {
    const response = await axios.post(`${APIUrl}/applet/${id}/enable`);
    setData({ ...data, status: "ENABLED" });
    onEdit(response.data);
  }

  return (
    <>
      <div className="d-flex">
        <div className="flex-grow-1">
          <div className="d-flex align-items-center">
            {renaming ? (
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (!hasChanges) setHasChanges(true);
                  setData({ ...data, name: renameInput });
                  setRenaming(false);
                }}
              >
                <Form.Row className="align-items-center">
                  <Col xs="auto">
                    <Form.Control
                      placeholder="Applet Name"
                      autoComplete="off"
                      type="text"
                      value={renameInput}
                      onChange={(e) => setRenameInput(e.target.value)}
                      autoFocus
                    />
                  </Col>
                  <Col xs="auto">
                    <Button
                      type="button"
                      className="mr-2"
                      variant="outline-primary"
                      onClick={() => setRenaming(false)}
                    >
                      Cancel
                    </Button>
                    <Button type="submit">Save</Button>
                  </Col>
                </Form.Row>
              </Form>
            ) : (
              <h2
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 0,
                }}
              >
                {data.name}{" "}
                <FontAwesomeIcon
                  icon={faPencilAlt}
                  className="text-primary"
                  style={{
                    fontSize: 20,
                    marginLeft: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => setRenaming(true)}
                />
              </h2>
            )}
            <div className="ml-4">
              <Badge
                variant={data.status === "ENABLED" ? "success" : "primary"}
                style={{ fontSize: 16 }}
              >
                {data.status === "ENABLED" ? "Enabled" : "Disabled"}
              </Badge>
            </div>
          </div>
          <p className="mb-0">
            Drag nodes from the panel on the left onto the editor below.
            <br />
            Connect nodes together and pass data to create applets.
          </p>
        </div>
        <div>
          <div className="d-flex my-2" style={{ gap: 10 }}>
            <div className="flex-grow-1">
              <Button
                variant="outline-primary"
                onClick={() => {
                  if (
                    tutorial.inTutorial &&
                    (tutorial.stage === "APPLET EDITOR 8" ||
                      tutorial.stage === "DASHBOARD" ||
                      tutorial.stage === "FINAL")
                  ) {
                    tutorial.setStage("DASHBOARD");
                    history.push(`/`);
                  } else if (
                    id === "new" ||
                    data.globalVariables.length === 0
                  ) {
                    history.push(`/applets`);
                  } else {
                    history.push(`/applets/config/${id}`);
                  }
                }}
                block
              >
                <FontAwesomeIcon icon={faTimes} /> Close
              </Button>
            </div>
            <div className="flex-grow-1">
              {data.nodes.filter((x) => x.requiresSetup).length > 0 ? (
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id="save-tooltip">
                      Some nodes have not been setup, please open all nodes with
                      a border and enter the required information.
                    </Tooltip>
                  }
                >
                  <Button variant="primary" className="disabled" block>
                    <FontAwesomeIcon icon={faSave} /> Save
                  </Button>
                </OverlayTrigger>
              ) : (
                <Button
                  variant="primary"
                  onClick={save}
                  disabled={isSaving}
                  block
                >
                  <FontAwesomeIcon icon={faSave} />{" "}
                  {isSaving ? "Saving..." : "Save"}
                </Button>
              )}
            </div>
          </div>
          <div className="d-flex my-2" style={{ gap: 10 }}>
            {hasChanges ? (
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="test-tooltip">
                    Please save your changes before testing!
                  </Tooltip>
                }
              >
                <Button variant="outline-success disabled">
                  <FontAwesomeIcon icon={faBolt} />{" "}
                  {data.status === "DISABLED" ? "Enable & Test" : "Test"}
                </Button>
              </OverlayTrigger>
            ) : (
              <Button
                variant="outline-success"
                onClick={() => {
                  setShowTestModal(true);

                  if (data.status !== "ENABLED") {
                    enable();
                  }
                }}
              >
                <FontAwesomeIcon icon={faBolt} />{" "}
                {data.status === "DISABLED" ? "Enable & Test" : "Test"}
              </Button>
            )}

            <Button variant="primary" onClick={() => setEditingConfig(true)}>
              <FontAwesomeIcon icon={faCog} /> Open Config Editor
            </Button>
          </div>
        </div>
      </div>

      {showTestModal && (
        <TestModal
          appletId={id}
          nodes={data.nodes}
          onClose={() => setShowTestModal(false)}
        />
      )}
    </>
  );
}

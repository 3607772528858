import { faPlusCircle, faPortalEnter } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Badge, Modal, Nav } from "react-bootstrap";
import { useUser } from "../../../context/UserContext";
import { Team } from "../../../types";
import { CreateTeam } from "./CreateTeam";
import { JoinTeam } from "./JoinTeam";

type NewTeamProps = {
  onClose: () => void;
  onCreateTeam: (team: Team) => void;
};

export function AddTeam({ onClose, onCreateTeam }: NewTeamProps) {
  const [tab, setTab] = useState("join");
  const user = useUser();

  return (
    <Modal show={true} onHide={onClose} backdrop="static" centered>
      <Nav
        fill
        variant="tabs"
        activeKey={tab}
        onSelect={(e) => setTab(e || "join")}
      >
        <Nav.Item>
          <Nav.Link eventKey="join">
            <FontAwesomeIcon icon={faPortalEnter} className="mr-2" />
            Join a Team
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            eventKey="create"
            disabled={!user.plan || !user.plan.canCreateTeams}
          >
            <div className="d-flex justify-content-center align-items-center">
              <FontAwesomeIcon icon={faPlusCircle} className="mr-2" />
              Create a Team
              <div className="ml-2">
                <Badge variant="warning" style={{ marginTop: -5 }}>
                  Premium
                </Badge>
              </div>
            </div>
          </Nav.Link>
        </Nav.Item>
      </Nav>
      {tab === "join" && (
        <JoinTeam
          onCancel={onClose}
          onJoinTeam={(team) => onCreateTeam(team)}
        />
      )}
      {tab === "create" && (
        <CreateTeam
          onCancel={onClose}
          onCreateTeam={(team) => onCreateTeam(team)}
        />
      )}
    </Modal>
  );
}

import {
  faBox,
  faQuestionCircle,
  faSearch,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useState } from "react";
import {
  Badge,
  Button,
  Card,
  Container,
  Form,
  FormControl,
  InputGroup,
  OverlayTrigger,
  Popover,
  Tooltip,
} from "react-bootstrap";
import { APIUrl, AuthUrl } from "../../Constants";
import { useUser } from "../../context/UserContext";
import { Module, ModuleCategory } from "../../types";
import { HelpIcon } from "../shared/HelpIcon";
import { ConfirmationModal } from "../shared/modals/ConfirmationModal";
import { Spinner } from "../shared/Spinner";
import { canUseModule } from "../shared/utils";

type ModuleListProps = {
  modules: Module[];
  setModules: (modules: Module[]) => void;
};

export function ModuleList({ modules, setModules }: ModuleListProps) {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [disabling, setDisabling] = useState<Module>();
  const user = useUser();

  async function enable(module: Module) {
    setLoading(true);

    window.location.replace(
      `${AuthUrl}/module/${module.id}/enable?returnUrl=${window.location.href}`
    );
  }

  async function disable(module: Module) {
    setDisabling(undefined);
    setLoading(true);

    const response = await axios.post(`${APIUrl}/module/${module.id}/disable`);

    if (response && response.status === 200 && response.data) {
      setModules([
        ...modules.map((x) => {
          if (x.id === module.id) {
            x.enabled = false;
          }
          return x;
        }),
      ]);
    }

    setLoading(false);
  }

  function renderList(category: ModuleCategory, title: string) {
    let filteredModules = modules.filter((x) => x.category === category);

    if (!!search && search !== "") {
      filteredModules = filteredModules.filter(
        (x) =>
          x.name.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
          x.description.toLowerCase().indexOf(search.toLowerCase()) > -1
      );
    }

    if (filteredModules.length > 0) {
      return (
        <div className="mt-4">
          <h4>
            {title} <small>({filteredModules.length})</small>
          </h4>
          <div className="module-list-wrapper">
            {filteredModules.map((module, index) => (
              <div key={index} className="module-list-item">
                <Card style={{ height: "100%", paddingBottom: 40 }}>
                  <Card.Body>
                    <div
                      style={{
                        position: "absolute",
                        top: 15,
                        left: 15,
                        zIndex: 100,
                      }}
                    >
                      <HelpIcon
                        id={`module-help-${module.id}`}
                        tooltip="View Documentation"
                        onClick={() => window.open(module.docURL, "_blank")}
                      />
                    </div>
                    <Card.Title className="text-center">
                      <div
                        className="mt-2 mb-4"
                        style={{ position: "relative" }}
                      >
                        {module.imageUrl ? (
                          <img
                            src={module.imageUrl}
                            alt={module.name}
                            width="80"
                            height="80"
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faBox}
                            size="3x"
                            className="mr-4"
                          />
                        )}
                      </div>
                      <h5>{module.name} Module</h5>
                      <div>
                        <div
                          className={`ribbon ${
                            module.minimumRole === "FREE"
                              ? "ribbon-success"
                              : "ribbon-warning"
                          }`}
                        >
                          {module.minimumRole}
                        </div>
                      </div>
                    </Card.Title>
                    <Card.Text style={{ marginTop: 25, fontSize: 14 }}>
                      {module.enabled &&
                        !canUseModule(module, user.role ? user.role : "") && (
                          <div className="mb-4 d-flex align-items-center">
                            <Badge variant="danger">
                              <h5 className="mb-0">Module Locked</h5>
                            </Badge>
                            <OverlayTrigger
                              trigger="hover"
                              placement="right"
                              overlay={
                                <Popover id="popover-basic">
                                  <Popover.Title as="h3">
                                    Locked Module
                                  </Popover.Title>
                                  <Popover.Content>
                                    This module is locked because you are no
                                    longer on a plan that supports this feature,
                                    any data relating to this module has been
                                    saved should you wish to upgrade your
                                    account in the future.
                                    <br />
                                    To delete your data relating to this module,
                                    click the button below.
                                  </Popover.Content>
                                </Popover>
                              }
                            >
                              <Button
                                style={{
                                  background: "transparent",
                                  border: "none",
                                  outline: "none",
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faQuestionCircle}
                                  size="lg"
                                />
                              </Button>
                            </OverlayTrigger>
                          </div>
                        )}
                      {module.description}
                    </Card.Text>
                    <div
                      style={{
                        position: "absolute",
                        bottom: 20,
                        left: 20,
                        right: 20,
                      }}
                    >
                      {canUseModule(module, user.role ? user.role : "") ? (
                        <>
                          {module.enabled ? (
                            <div className="d-flex" style={{ gap: 20 }}>
                              <div style={{ flex: 1 }}>
                                <Button
                                  variant="outline-primary"
                                  onClick={() => setDisabling(module)}
                                  block
                                >
                                  Disable
                                </Button>
                              </div>
                              {module.hasConfig && (
                                <div style={{ flex: 1 }}>
                                  <Button block>Edit</Button>
                                </div>
                              )}
                            </div>
                          ) : (
                            <Button block onClick={() => enable(module)}>
                              Enable
                            </Button>
                          )}
                        </>
                      ) : (
                        <>
                          {module.enabled &&
                          !canUseModule(module, user.role ? user.role : "") ? (
                            <>
                              <Button
                                variant="outline-primary"
                                onClick={() => setDisabling(module)}
                                block
                              >
                                Delete Data
                              </Button>
                            </>
                          ) : (
                            <>
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip id="test-tooltip">
                                    Sorry, this feature requires the{" "}
                                    {module.minimumRole.toLowerCase()} plan or
                                    higher to use.
                                  </Tooltip>
                                }
                              >
                                <Button block className="disabled">
                                  Enable
                                </Button>
                              </OverlayTrigger>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </div>
            ))}
          </div>
        </div>
      );
    }
  }

  if (loading) {
    return <Spinner fill />;
  }

  return (
    <>
      <Container fluid>
        <h2>Modules</h2>
        <p>
          Modules are what you use to enable functionality in Stream Hydra.
          Without any modules enabled, the app won't do anything!
        </p>
        <div className="float-right" style={{ width: 350 }}>
          <Form>
            <InputGroup className="mb-2">
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
              <FormControl
                id="inlineFormInputGroup"
                placeholder="Search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </InputGroup>
          </Form>
        </div>

        {renderList("Official", "Official Modules")}
        {renderList("StreamingPlatforms", "Streaming Platforms")}
        {renderList("Services", "Supported Services")}
        {renderList("Games", "Game Integrations")}
      </Container>

      {disabling && (
        <ConfirmationModal
          title="Disable Module"
          body="Are you sure you want to disable this module? Doing so will delete any associated data as well as disabling any applets that use this module!"
          onConfirm={() => disable(disabling)}
          onClose={() => setDisabling(undefined)}
          negativeText="Cancel"
          positiveText="Disable"
        />
      )}
    </>
  );
}

import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { APIUrl } from "../../../../Constants";
import { Applet, StreamDeckKey } from "../../../../types";
import { Spinner } from "../../../shared/Spinner";

type GenerateKeyModalProps = {
  onClose: () => void;
  onAdd: (key: StreamDeckKey) => void;
};

export function GenerateKeyModal({ onClose, onAdd }: GenerateKeyModalProps) {
  const [loadingApplets, setLoadingApplets] = useState(false);
  const [applets, setApplets] = useState<Applet[]>([]);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState<StreamDeckKey>();
  const [applet, setApplet] = useState<string>();

  useEffect(() => {
    let cancelled = false;

    async function loadApplets() {
      setLoadingApplets(true);

      const response = await axios.get(`${APIUrl}/streamdeck/list-applets`);

      if (!cancelled) {
        setLoadingApplets(false);
        setApplets(response.data);
      }
    }
    loadApplets();

    return () => {
      cancelled = true;
    };
  }, []);

  async function add() {
    setLoading(true);

    const response = await axios.get(`${APIUrl}/streamdeck/generate/${applet}`);
    setKey(response.data);
    onAdd(response.data);
    setLoading(false);
  }

  return (
    <Modal show={true} onHide={onClose} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>Generate Key</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {key ? (
          <>
            <p>
              Thanks, your key has been generated. If you havent already,
              download and install our plugin from{" "}
              <a
                href="https://apps.elgato.com/plugins/io.streamhydra.execut"
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              . Copy and paste the key below into a stream hydra action on your
              stream deck.
            </p>
            <p className="code">{key.key}</p>
            <p>
              <strong>
                Copy this key now as you will NOT be able to access it again!
              </strong>
            </p>
            <p>
              Do NOT share this key with anyone! If this key becomes
              compromised, regenerate or delete it immediately as anyone with
              this key can control your applet!
            </p>
          </>
        ) : (
          <>
            {loadingApplets ? (
              <Spinner size="sm" />
            ) : (
              <>
                {applets.length > 0 ? (
                  <Form.Group>
                    <Form.Label>
                      Select an applet to generate a key for, only applets that
                      use the stream deck module are listed.
                    </Form.Label>
                    <Form.Control
                      as="select"
                      value={applet}
                      onChange={(e) => setApplet(e.target.value)}
                    >
                      <option>(Select Applet)</option>
                      {applets.map((a) => (
                        <option key={a.id} value={a.id}>
                          {a.name}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                ) : (
                  <p>
                    You don't have any applets that use the stream deck "On
                    Button Press" trigger node, please make a new applet with
                    the stream deck trigger or add it to an existing one!
                  </p>
                )}
              </>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!key && (
          <Button
            variant="outline-primary"
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </Button>
        )}
        <Button
          onClick={key ? onClose : add}
          disabled={loading || (!key && !applet)}
        >
          {key ? "Done" : "Generate"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

import { faInfinity, faUsers } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Card, Nav } from "react-bootstrap";
import { useUser } from "../../context/UserContext";
import { Team, TeamMember } from "../../types";
import { HelpIcon } from "../shared/HelpIcon";
import { Spinner } from "../shared/Spinner";
import { FreeUsersModal } from "./Modals/FreeUsersModal";
import { ActiveMembers } from "./Users/ActiveMembers";
import { PendingRequests } from "./Users/PendingRequests";
import { RejectedMembers } from "./Users/RejectedMembers";

type UsersProps = {
  team: Team;
  setTeam: (team: Team) => void;
  setActiveUser: (user: TeamMember) => void;
};

export function Users({ team, setActiveUser, setTeam }: UsersProps) {
  const [tab, setTab] = useState("active");
  const [showFreeUsers, setShowFreeUsers] = useState(false);
  const user = useUser();

  if (!user) {
    return <Spinner />;
  }

  return (
    <>
      <Card>
        <Card.Header className="border-bottom-0">
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center flex-grow-1">
              <div className="mr-3">
                <FontAwesomeIcon icon={faUsers} size="2x" />
              </div>
              <div>
                <h4 className="mb-0">Users</h4>
                <p className="mb-0">A list of the users in your team</p>
              </div>
            </div>
            <div
              className="d-flex align-items-center hover-primary"
              onClick={() =>
                team.currentUsedFreeSlots > 0 &&
                team.activeUser?.teamRole === "ADMIN"
                  ? setShowFreeUsers(true)
                  : {}
              }
            >
              Free Slots Used:
              <span
                className={`ml-2
                  ${
                    team.freeSlotsLimit !== -1 &&
                    team.currentUsedFreeSlots > team.freeSlotsLimit
                      ? "text-danger"
                      : ""
                  }
                `}
              >
                {team.currentUsedFreeSlots} /{" "}
                {team.freeSlotsLimit === -1 ? (
                  <FontAwesomeIcon icon={faInfinity} />
                ) : (
                  team.freeSlotsLimit
                )}
              </span>{" "}
              <HelpIcon
                id="tooltip-help-slots"
                tooltip={`Stream Hydra Teams is a premium feature however some plans come with "free team slots" that allow users to join your team, even if they are on the free plan.`}
              />
            </div>
          </div>
        </Card.Header>
        <Card.Body className="p-0">
          <Nav
            fill
            variant="tabs"
            activeKey={tab}
            onSelect={(e) => setTab(e || "active")}
          >
            <Nav.Item>
              <Nav.Link eventKey="active">Active Members</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="pending">Pending Requests</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="rejected">Rejected</Nav.Link>
            </Nav.Item>
          </Nav>

          {tab === "active" && (
            <ActiveMembers
              team={team}
              setActiveUser={setActiveUser}
              setTeam={setTeam}
            />
          )}
          {tab === "pending" && (
            <PendingRequests team={team} setTeam={setTeam} />
          )}
          {tab === "rejected" && <RejectedMembers team={team} />}
        </Card.Body>
      </Card>
      {showFreeUsers && (
        <FreeUsersModal onClose={() => setShowFreeUsers(false)} team={team} />
      )}
    </>
  );
}

import { useRef } from "react";
import { Form } from "react-bootstrap";
import { EditorElement } from "../../../../../../types";
import {
  findNestedValue,
  getNestedError,
  setNestedErrors,
  setNestedValue,
} from "../../../../../shared/utils";

type VariableStoreProps = {
  data: { [key: string]: unknown };
  setData: (data: { [key: string]: unknown }) => void;
  errors: { [id: string]: string | null };
  setErrors: (data: { [id: string]: string | null }) => void;
  showErrors: boolean;
  element: EditorElement;
  parents: string[];
};

export function VariableStore({
  data,
  setData,
  errors,
  setErrors,
  showErrors,
  element,
  parents,
}: VariableStoreProps) {
  const ref = useRef<HTMLInputElement>(null);

  function validate(value: string) {
    if (element.required) {
      setNestedErrors(
        errors,
        setErrors,
        parents,
        element.id,
        !value || value === "" ? "This field is required" : ""
      );
    }
  }

  function setValue(value: string) {
    setNestedValue(data, setData, parents, element.id, value);
    validate(value);
  }

  return (
    <>
      <Form.Group style={{ flex: 2 }}>
        <Form.Label>{element.label}</Form.Label>
        <Form.Control
          type="text"
          placeholder={element.placeholder ? element.placeholder : ""}
          onChange={(e) =>
            setValue(
              e.target.value
                .toLowerCase()
                .replaceAll(" ", "_")
                .replaceAll(/[^a-z0-9_]+/g, "")
            )
          }
          value={
            findNestedValue(data, parents, element.id)
              ? (findNestedValue(data, parents, element.id) as string)
              : ""
          }
          ref={ref}
          isInvalid={
            showErrors && !!getNestedError(errors, parents, element.id)
          }
        />
        <Form.Control.Feedback type="invalid">
          {getNestedError(errors, parents, element.id)}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
}

import { Button, Form, Modal } from "react-bootstrap";
import { useTheme } from "../context/ThemeContext";

type ThemeSelectorProps = {
  onClose: () => void;
};

export function ThemeSelector({ onClose }: ThemeSelectorProps) {
  const { theme, changeTheme } = useTheme();
  const themes: { [key: string]: string } = {
    Dark: "Basic Dark Theme",
    Light: "Basic Light Theme",
    Purple: "Purple Theme",
    PurpleLight: "Light Theme (Purple)",
    PurpleDark: "Dark Theme (Purple)",
    Blue: "Blue Theme",
    BlueLight: "Light Theme (Blue)",
    BlueDark: "Dark Theme (Blue)",
    NeonPink: "Pink (don't use this, its awful)",
    LegacyDark: "Classic Dark Theme",
  };

  return (
    <Modal show={true} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Select Theme</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Themes change the appearance of the entire Application, select one
          that appeals to you!
        </p>
        <Form.Control
          as="select"
          value={theme}
          onChange={(e) => changeTheme(e.target.value)}
        >
          {Object.keys(themes).map((x) => (
            <option value={x} key={x}>
              {themes[x]}
            </option>
          ))}
        </Form.Control>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onClose} style={{ minWidth: 100 }}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

import { ActiveLinkData, NodeInstance, NodeOption } from "../../../../../types";
import _ from "underscore";

type ActiveLinkProps = {
  nodes: NodeInstance[];
  link: ActiveLinkData;
  nodeWidth: (node: NodeOption) => number;
  nodeHeight: () => number;
};

export function ActiveLink({
  nodes,
  link,
  nodeWidth,
  nodeHeight,
}: ActiveLinkProps) {
  const node = _.find(nodes, (node) => node.id === link.nodeId);

  return node ? (
    <>
      {node.node.outputs.map((output, index) => {
        return (
          link.outputId === output.id && (
            <line
              key={index}
              x1={node.x + nodeWidth(node.node) - 20}
              y1={
                node.y +
                (nodeHeight() / (node.node.outputs.length + 1)) * (index + 1)
              }
              x2={link.mouseX}
              y2={link.mouseY}
              strokeWidth={5}
            />
          )
        );
      })}
    </>
  ) : null;
}

import axios from "axios";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { APIUrl } from "../../../Constants";
import { User } from "../../../types";

type PartnerProgramModalProps = {
  user: User;
  onClose: () => void;
};

export function PartnerProgramModal({
  user,
  onClose,
}: PartnerProgramModalProps) {
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");

  async function addToProgram() {
    if (code) {
      setLoading(true);
      await axios.post(`${APIUrl}/payments/partner-sign-up`, {
        userId: user.id,
        code,
      });
      setLoading(false);
    }
  }

  return (
    <Modal show={true} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add to Partner Program</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Add {user.displayName} to the partner program?</p>
        <Form.Control
          type="text"
          placeholder="Enter Promo Code"
          value={code}
          onChange={(e) => setCode(e.target.value.toUpperCase())}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-primary"
          onClick={onClose}
          style={{ minWidth: 100 }}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={addToProgram}
          style={{ minWidth: 100 }}
          disabled={loading}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

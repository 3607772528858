import { Form } from "react-bootstrap";
import { EditorElement } from "../../../../../../types";
import { findNestedValue, setNestedValue } from "../../../../../shared/utils";

type ConditionDropdownProps = {
  data: { [key: string]: unknown };
  setData: (data: { [key: string]: unknown }) => void;
  element: EditorElement;
  parents: string[];
};

export function ConditionDropdown({
  data,
  setData,
  element,
  parents,
}: ConditionDropdownProps) {
  return (
    <Form.Group>
      <Form.Label>{element.label}</Form.Label>
      <Form.Control
        as="select"
        value={findNestedValue(data, parents, element.id) as string}
        onChange={(e) =>
          setNestedValue(data, setData, parents, element.id, e.target.value)
        }
      >
        <option value="equal">is equal to</option>
        <option value="notequal">is not equal to</option>
        <option value="contains">contains</option>
        <option value="greater">is greater than</option>
        <option value="greaterequal">is greater than or equal to</option>
        <option value="less">is less than</option>
        <option value="lessequal">is less than or equal to</option>
        <option value="isnull">is null or empty</option>
        <option value="notnull">is not null or empty</option>
      </Form.Control>
    </Form.Group>
  );
}

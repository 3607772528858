import { Plan } from "../../types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faTimesCircle } from "@fortawesome/pro-light-svg-icons";
import { formatNumber } from "./utils";

type FeatureListProps = {
  plan: Plan;
};

export function FeatureList({ plan }: FeatureListProps) {
  return (
    <ul className="pricing-list">
      <li>
        <FontAwesomeIcon icon={faCheckCircle} className="text-success" /> Up to{" "}
        {formatNumber(plan.invocationLimit)} Invocations per day
      </li>
      <li>
        <FontAwesomeIcon icon={faCheckCircle} className="text-success" />{" "}
        {plan.appletLimit > -1
          ? `Up to ${plan.appletLimit} Enabled Applets`
          : "Unlimited Enabled Applets"}
      </li>
      <li>
        <FontAwesomeIcon icon={faCheckCircle} className="text-success" />{" "}
        {plan.activeMinecraftServers === -1
          ? "Unlimited"
          : plan.activeMinecraftServers}{" "}
        Active Minecraft Server Integration
        {plan.activeMinecraftServers > 1 || -1 ? "s" : ""}
      </li>
      <li>
        <FontAwesomeIcon icon={faCheckCircle} className="text-success" /> Access
        to a full library of free templates
      </li>
      <li>
        <FontAwesomeIcon
          icon={plan.customTwitchBotName ? faCheckCircle : faTimesCircle}
          className={plan.customTwitchBotName ? "text-success" : "text-danger"}
        />{" "}
        Custom Twitch Chatbot Name
      </li>
      <li>
        <FontAwesomeIcon
          icon={plan.canCreateTeams === true ? faCheckCircle : faTimesCircle}
          className={
            plan.canCreateTeams === true ? "text-success" : "text-danger"
          }
        />{" "}
        Ability To Create Teams
      </li>
      {plan.teamsFreeSlots >= 1 ? (
        <li>
          <FontAwesomeIcon icon={faCheckCircle} className={"text-success"} />{" "}
          {plan.teamsFreeSlots} Team slot
          {plan.teamsFreeSlots === 1 ? " " : "s "} for{" "}
          {plan.teamsFreeSlots === 1 ? "a free user." : "free users."} Unlimited
          paid user slots
        </li>
      ) : null}
      <li>
        <FontAwesomeIcon
          icon={plan.prioritySupport ? faCheckCircle : faTimesCircle}
          className={plan.prioritySupport ? "text-success" : "text-danger"}
        />{" "}
        Priority Support
      </li>
    </ul>
  );
}

import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Card, CardDeck, Dropdown } from "react-bootstrap";
import { APIUrl, StripeKey } from "../../Constants";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { useHistory, useParams } from "react-router";
import { Plan } from "../../types";
import { FeatureList } from "../shared/FeatureList";
import { useUser } from "../../context/UserContext";
import { Spinner } from "../shared/Spinner";

const stripePromise = loadStripe(StripeKey!);
let stripe: Stripe | null;
async function LoadStripe() {
  stripe = await stripePromise;
}

export function Pricing() {
  const [loading, setLoading] = useState(true);
  const [planInfo, setPlanInfo] = useState<Plan[]>();
  let { success }: { success?: string } = useParams();
  const history = useHistory();
  const [currency, setCurrency] = useState<
    "USD" | "EUR" | "GBP" | "CAD" | "AUD"
  >("GBP");
  const [prices, setPrices] = useState<{
    premium: string;
    advanced: string;
    professional: string;
  }>();
  const user = useUser();

  useEffect(() => {
    if (success && success === "true") {
      user.refresh();
      history.push("/pricing");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  useEffect(() => {
    GetPlanInformation();
    LoadStripe();
    setLoading(false);
  }, []);

  useEffect(() => {
    GetPrices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency]);

  async function GetPlanInformation() {
    try {
      const response = await axios.get(`${APIUrl}/statistics/plan-information`);
      setPlanInfo(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  function ConvertRoleToTier(role: string) {
    role = role.toLowerCase();
    role = role.charAt(0).toUpperCase() + role.slice(1);
    return role + " Tier";
  }

  async function RedirectToCheckout(role: string) {
    if (stripe) {
      var checkoutSession: string = await CreateCheckoutSession(
        ConvertRoleToTier(role)
      );
      await stripe?.redirectToCheckout({
        sessionId: checkoutSession,
      });
    }
  }

  async function CreateCheckoutSession(tier: string) {
    try {
      const response = await axios.post(
        `${APIUrl}/payments/create-checkout-session`,
        {
          Tier: tier,
          Currency: currency,
        }
      );
      return response.data.sessionId;
    } catch (error) {
      console.log(error);
    }
  }

  async function GoToPortal() {
    try {
      const response = await axios.post(
        `${APIUrl}/payments/create-customer-portal`
      );
      window.location.href = response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async function GetPrices() {
    setLoading(true);
    try {
      const response = await axios.get(
        `${APIUrl}/payments/prices?currency=${currency}`
      );
      setPrices(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  function GetFormattedPrice(role: string) {
    if (!prices) {
      return;
    }
    let prefix: string;
    switch (currency) {
      case "GBP":
        prefix = "£";
        break;
      case "EUR":
        prefix = "€";
        break;
      case "USD":
        prefix = "$";
        break;
      case "CAD":
        prefix = "CAD $";
        break;
      case "AUD":
        prefix = "AUD $";
        break;
    }
    let suffix: string = "";
    switch (role) {
      case "FREE":
        suffix = "0.00";
        break;
      case "PREMIUM":
        suffix = prices?.premium;
        break;
      case "ADVANCED":
        suffix = prices?.advanced;
        break;
      case "PROFESSIONAL":
        suffix = prices?.professional;
        break;
    }
    return prefix + suffix;
  }

  function getDescription(role: string) {
    switch (role) {
      case "FREE":
        return "Free forever! Get access to most features to try the platform out.";
      case "PREMIUM":
        return "Suitable for small to medium streamers with added features.";
      case "ADVANCED":
        return "Suitable for medium to large streamers with extra features.";
      case "PROFESSIONAL":
        return "Suitable for the largest streamers who need all the features.";
    }
  }

  return (
    <div className="page-content">
      <Dropdown className="mb-5">
        <Dropdown.Toggle id="dropdown-basic">{currency}</Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => setCurrency("GBP")}>GBP</Dropdown.Item>
          <Dropdown.Item onClick={() => setCurrency("USD")}>USD</Dropdown.Item>
          <Dropdown.Item onClick={() => setCurrency("EUR")}>EUR</Dropdown.Item>
          <Dropdown.Item onClick={() => setCurrency("CAD")}>CAD</Dropdown.Item>
          <Dropdown.Item onClick={() => setCurrency("AUD")}>AUD</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {!loading && planInfo ? (
        <CardDeck>
          {planInfo.map((plan) => (
            <Card
              key={plan.roleType}
              style={{ minWidth: 300, maxWidth: 300, marginTop: 25 }}
            >
              <Card.Header className="text-center">
                <h3>{ConvertRoleToTier(plan.roleType)}</h3>
                <p>{getDescription(plan.roleType)}</p>
                <h1>{GetFormattedPrice(plan.roleType)}</h1> Monthly
              </Card.Header>
              <Card.Body>
                <FeatureList plan={plan} />
              </Card.Body>
              <Card.Footer>
                <Button
                  block
                  disabled={user.role === "FREE" && plan.roleType === "FREE"}
                  className="btn-primary"
                  onClick={() => {
                    if (user.role !== "FREE" && user.role !== "ADMIN") {
                      GoToPortal();
                    } else {
                      RedirectToCheckout(plan.roleType);
                    }
                  }}
                >
                  {user.role === "FREE" ? "Choose Plan" : "Manage Subscription"}
                </Button>
              </Card.Footer>
            </Card>
          ))}
        </CardDeck>
      ) : (
        <Spinner />
      )}
    </div>
  );
}

import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { APIUrl } from "../Constants";
import { TutorialPanel } from "../dashboard/Tutorial/TutorialPanel";

export type TutorialStage =
  | "WELCOME"
  | "MODULES"
  | "MODULES 2"
  | "APPLETS"
  | "APPLETS 2"
  | "APPLETS 3"
  | "APPLETS 4"
  | "APPLET EDITOR"
  | "APPLET EDITOR 2"
  | "APPLET EDITOR 3"
  | "APPLET EDITOR 4"
  | "APPLET EDITOR 5"
  | "APPLET EDITOR 6"
  | "APPLET EDITOR 7"
  | "APPLET EDITOR 8"
  | "DASHBOARD"
  | "FINAL";

type TutorialContextProps = {
  inTutorial: boolean;
  skipTutorial: () => void;
  stage: TutorialStage;
  setStage: (stage: TutorialStage) => void;
  restartTutorial: () => void;
};

const TutorialContext = createContext<TutorialContextProps>({
  inTutorial: true,
  skipTutorial: () => {},
  stage: "WELCOME",
  setStage: () => {},
  restartTutorial: () => {},
});

export function useTutorial() {
  return useContext(TutorialContext);
}

export function TutorialProvider({ children }: { children: React.ReactNode }) {
  const [inTutorial, setInTutorial] = useState(false);
  const [stage, setStage] = useState<TutorialStage>("WELCOME");
  const [restartModal, setRestartModal] = useState(false);
  const history = useHistory();

  useEffect(() => {
    async function load() {
      const response = await axios.get(`${APIUrl}/user/tutorial`);
      setInTutorial(response && !response.data);
    }
    load();
  }, []);

  async function skipTutorial() {
    setInTutorial(false);
    setRestartModal(true);

    await axios.post(`${APIUrl}/user/tutorial`);
  }

  function restartTutorial() {
    setInTutorial(true);
    setStage("WELCOME");
    history.push("/");
  }

  return (
    <TutorialContext.Provider
      value={{ inTutorial, skipTutorial, stage, setStage, restartTutorial }}
    >
      {restartModal && (
        <TutorialPanel
          title="Restart Tutorial"
          right={70}
          top={60}
          arrowPosition="top"
          btnText="Ok"
          onBtnClick={() => setRestartModal(false)}
          renderButton={true}
        >
          <p>
            Should you wish to do the tutorial in the future, you can start it
            again from the user menu!
          </p>
        </TutorialPanel>
      )}
      {children}
    </TutorialContext.Provider>
  );
}

import { RectCoords } from "../../../../../types";

type SelectBoxProps = {
  rect: RectCoords;
};

export function SelectBox({ rect }: SelectBoxProps) {
  return (
    <rect
      x={rect.x1 > rect.x2 ? rect.x2 : rect.x1}
      y={rect.y1 > rect.y2 ? rect.y2 : rect.y1}
      width={rect.x1 > rect.x2 ? rect.x1 - rect.x2 : rect.x2 - rect.x1}
      height={rect.y2 > rect.y1 ? rect.y2 - rect.y1 : rect.y1 - rect.y2}
      stroke="grey"
      strokeWidth={2}
      fill="grey"
      fillOpacity={0.2}
    ></rect>
  );
}

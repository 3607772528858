import axios from "axios";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { APIUrl, ClientUrl } from "../../../Constants";
import { Team } from "../../../types";
import { CustomAlert } from "../../shared/CustomAlert";

type JoinTeamProps = {
  onCancel: () => void;
  onJoinTeam: (team: Team) => void;
};

export function JoinTeam({ onCancel, onJoinTeam }: JoinTeamProps) {
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [error, setError] = useState<string>();
  const [validation, setValidation] = useState<{ [name: string]: string }>({});
  const [team, setTeam] = useState<Team>();
  const [autoReject, setAutoReject] = useState(false);

  async function verify() {
    setLoading(true);
    const _errors: { [name: string]: string } = {};

    if (!code || code === "") {
      _errors["code"] = "A code is required";
    }

    setValidation(_errors);

    if (Object.keys(_errors).length > 0) {
      return;
    }

    try {
      const response = await axios.get(`${APIUrl}/teams/verify?code=${code}`);
      console.log(response.data);
      setTeam(response.data);
      setLoading(false);
    } catch (e: any) {
      setError("A team with that invite code was not found");
      setLoading(false);
    }
  }

  async function sendRequest() {
    setLoading(true);

    if (team) {
      const response = await axios.post(
        `${APIUrl}/teams/${team.id}/member/request`
      );
      if (response && response.data) {
        onJoinTeam(response.data);
      } else {
        setLoading(false);
        setAutoReject(true);
      }
    }
  }

  return team ? (
    <div className="d-flex justify-content-center align-items-center py-5">
      <div className="text-center">
        <img
          width={250}
          src={team.iconUrl || `${ClientUrl}/logo.png`}
          alt="Team Icon"
        />
        <h3 className="mt-3">{team.name}</h3>
        <p>Are you sure you want to request to join this team?</p>
        {autoReject && (
          <div className="text-left">
            <CustomAlert type="danger" title="Request Rejected">
              This team is not accepting join requests at this time.
            </CustomAlert>
          </div>
        )}
        <div className="d-flex w-100">
          <div className="flex-grow-1 pr-2">
            <Button
              variant="outline-primary"
              onClick={onCancel}
              block
              disabled={loading}
            >
              Cancel
            </Button>
          </div>
          <div className="flex-grow-1 pl-2">
            <Button
              variant="primary"
              onClick={sendRequest}
              block
              disabled={loading || autoReject}
            >
              Send Request
            </Button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <>
      <Modal.Body>
        <Form.Group className="mt-3">
          <Form.Label>Team Invite Code</Form.Label>
          <Form.Control
            type="text"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="Enter a team invite code"
            isInvalid={!!validation["code"]}
          />
          <Form.Control.Feedback type="invalid">
            {validation["code"]}
          </Form.Control.Feedback>
        </Form.Group>
        {error && (
          <CustomAlert type="danger" title="Error">
            {error}
          </CustomAlert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex w-100">
          <div className="flex-grow-1 pr-2">
            <Button
              variant="outline-primary"
              onClick={onCancel}
              block
              disabled={loading}
            >
              Cancel
            </Button>
          </div>
          <div className="flex-grow-1 pl-2">
            <Button variant="primary" onClick={verify} block disabled={loading}>
              Next
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </>
  );
}

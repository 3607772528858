import { EditorElement, NodeInstance } from "../../../../../../types";

type VariableListProps = {
  node: NodeInstance;
  element: EditorElement;
};

export function VariableList({ node, element }: VariableListProps) {
  return (
    <>
      <p>{element.label}</p>
      <ul className="pl-4">
        {Object.keys(node.node.outputs[0].data).map((variable) => (
          <li key={variable} className="list-unstyled">
            <strong>
              {"{"}
              {variable}
              {"}"}
            </strong>{" "}
            | {node.node.outputs[0].data[variable].type}
            <p>{node.node.outputs[0].data[variable].description}</p>
          </li>
        ))}
      </ul>
    </>
  );
}

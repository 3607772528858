import { IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type CategoryProps = {
  name: string;
  selected: boolean;
  onSelect: () => void;
  icon?: IconDefinition;
  image?: string;
};

export function Category({
  name,
  selected,
  onSelect,
  icon,
  image,
}: CategoryProps) {
  return (
    <OverlayTrigger
      placement="right"
      overlay={<Tooltip id={`tooltip-${name}`}>{name}</Tooltip>}
    >
      <div
        className={`category-icon ${selected ? "active" : ""}`}
        onClick={onSelect}
      >
        {icon && <FontAwesomeIcon icon={icon} size="lg" />}
        {image && <img src={image} alt="Category Icon" />}
      </div>
    </OverlayTrigger>
  );
}

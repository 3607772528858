import { faUpload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import axios from "axios";
import { APIUrl } from "../../Constants";
import { Applet } from "../../types";

type PublishLibraryModalProps = {
  applet: Applet;
  onClose: () => void;
};

export function PublishLibraryModal({
  applet,
  onClose,
}: PublishLibraryModalProps) {
  const [name, setName] = useState(applet.name);
  const [mainModule, setMainModule] = useState<string>();
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState<{ [name: string]: string }>({});

  async function publish() {
    const _errors: { [name: string]: string } = {};

    if (!name) _errors["name"] = "This field is required";
    if (!description) _errors["description"] = "This field is required";

    if (Object.keys(_errors).length > 0) {
      setErrors(_errors);
    } else {
      setLoading(true);
      try {
        await axios.post(`${APIUrl}/library`, {
          id: applet.id,
          name,
          description,
          mainModule,
        });
        onClose();
      } catch (e: any) {
        console.log(e);
        setLoading(false);
      }
    }
  }

  return (
    <Modal show={true} onHide={onClose} backdrop="static" centered>
      <Modal.Header>
        <Modal.Title>Publish Applet</Modal.Title>
        <Modal.Title>
          <FontAwesomeIcon icon={faUpload} size="lg" className="m-2" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={publish}>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              placeholder="Enter a short name for this applet"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              isInvalid={!!errors["name"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["name"]}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Form.Label>Main Module</Form.Label>
            <Form.Control
              type="text"
              value={mainModule}
              onChange={(e) => setMainModule(e.target.value)}
              placeholder="What module is this primarily for?"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Description</Form.Label>
            <Form.Control
              placeholder="What does this applet do?"
              as="textarea"
              rows={5}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              isInvalid={!!errors["description"]}
            />
            <Form.Control.Feedback type="invalid">
              {errors["description"]}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer style={{ display: "flex", gap: 15 }}>
        <Button variant="outline-primary" onClick={onClose} style={{ flex: 1 }}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={publish}
          style={{ flex: 1 }}
          disabled={loading}
        >
          {loading ? "Loading..." : "Publish"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

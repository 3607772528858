import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert } from "react-bootstrap";

type CustomAlertProps = {
  type: "success" | "danger" | "info" | "warning";
  title: string;
  children: React.ReactNode;
  border?: boolean;
  onClose?: () => void;
};

export function CustomAlert({
  type,
  title,
  children,
  border,
  onClose,
}: CustomAlertProps) {
  return (
    <Alert className={`notification ${type} ${border ? `border-${type}` : ""}`}>
      <Alert.Heading>
        <FontAwesomeIcon
          icon={
            type === "danger"
              ? faExclamationCircle
              : type === "warning"
              ? faExclamationTriangle
              : type === "info"
              ? faInfoCircle
              : faCheckCircle
          }
          className={`alert-icon text-${type}`}
        />
        {title}
        {onClose && (
          <FontAwesomeIcon className="close" icon={faTimes} onClick={onClose} />
        )}
      </Alert.Heading>
      {children}
    </Alert>
  );
}

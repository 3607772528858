import { faCog } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { APIUrl, ClientUrl, CommunityUrl } from "../../../Constants";
import { Community } from "../../../types";
import { Spinner } from "../../shared/Spinner";
import { Members } from "./Members";
import { SettingsModal } from "./Modals/SettingsModal";

export function Home() {
  const [loading, setLoading] = useState(true);
  const [community, setCommunity] = useState<Community>();
  const [showSettingsModal, setShowSettingsModal] = useState(false);

  useEffect(() => {
    let cancelled = false;

    async function load() {
      const response = await axios.get(`${APIUrl}/community`);

      if (!cancelled) {
        setLoading(false);
        setCommunity(response.data);
      }
    }
    load();

    return () => {
      cancelled = true;
    };
  }, []);

  if (loading || !community) {
    return <Spinner />;
  }

  return (
    <>
      <div className="d-flex align-items-center">
        <div className="d-flex flex-grow-1 align-items-center">
          <div>
            <img
              src={
                community.iconUrl ? community.iconUrl : `${ClientUrl}/logo.png`
              }
              alt="Community Icon"
              width={80}
            />
          </div>
          <div className="ml-3">
            <h2 className="mb-0">{community.name}</h2>
            <a
              href={`${CommunityUrl}/c/${community.slug}`}
              target="_blank"
              rel="noreferrer"
            >
              {CommunityUrl}/c/{community.slug}
            </a>
          </div>
        </div>
        <Button onClick={() => setShowSettingsModal(true)}>
          <FontAwesomeIcon icon={faCog} /> Settings
        </Button>
      </div>
      {showSettingsModal && (
        <SettingsModal
          community={community}
          setCommunity={setCommunity}
          onClose={() => setShowSettingsModal(false)}
        />
      )}
      <div className="mt-5">
        <Members />
      </div>
    </>
  );
}

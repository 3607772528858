import { createContext, useContext } from "react";
import { NotificationType } from "../types";

export type NotificationContextProps = {
  dispatchNotification: (
    type: NotificationType,
    title: string,
    message: string
  ) => void;
};

export const NotificationContext = createContext<NotificationContextProps>({
  dispatchNotification: () => {},
});

export function useNotifications() {
  return useContext(NotificationContext);
}

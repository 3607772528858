import { Route, Switch, useRouteMatch } from "react-router-dom";
import { Home } from "./Home";

export function Community() {
  const { path } = useRouteMatch();

  return (
    <div className="page-content">
      <Switch>
        <Route path={`${path}`}>
          <Home />
        </Route>
      </Switch>
    </div>
  );
}

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import {
  EditorElement,
  LinkInstance,
  NodeInstance,
} from "../../../../../types";
import { findNestedValue, getIconFor } from "../../../../shared/utils";
import { elements } from "../../ConfigEditor/elements";
import { ArrayEditor } from "./InternalElements/ArrayEditor";
import { ButtonInput } from "./InternalElements/ButtonInput";
import { ConditionDropdown } from "./InternalElements/ConditionDropdown";
import { CustomSelect } from "./InternalElements/CustomSelect";
import { HardStringInput } from "./InternalElements/HardStringInput";
import { ObjectEditor } from "./InternalElements/ObjectEditor";
import { SearchableDropdown } from "./InternalElements/SearchableDropdown";
import { VariableList } from "./InternalElements/VariableList";
import { VariableStore } from "./InternalElements/VariableStore";
import { InputElementWrapper } from "./InputElementWrapper";
import { TeamsPanel } from "./TeamsPanel";
import { HelpIcon } from "../../../../shared/HelpIcon";

type ConfigModalProps = {
  nodes: NodeInstance[];
  links: LinkInstance[];
  node: NodeInstance;
  setNode: (node: NodeInstance) => void;
  onCancel: () => void;
  parsedData: { [key: string]: unknown };
  onSave: (data: { [key: string]: unknown }) => void;
};

export function ConfigModal({
  nodes,
  links,
  node,
  setNode,
  onCancel,
  parsedData,
  onSave,
}: ConfigModalProps) {
  const [data, setData] = useState(parsedData ? parsedData : {});
  const [errors, setErrors] = useState<{ [name: string]: string | null }>(
    getDefaultErrors(true)
  );
  const [showErrors, setShowErrors] = useState(node.requiresSetup || false);
  const [teamsMenuOpen, setTeamsMenuOpen] = useState(false);

  function getDefaultErrors(includeArrays: boolean) {
    let _errors: { [name: string]: string } = {};

    node.node.editorElements.forEach((el) => {
      _errors = { ..._errors, ...getErrorsForElement(includeArrays, el, []) };
    });

    return _errors;
  }

  function getErrorsForElement(
    includeArrays: boolean,
    el: EditorElement,
    parents: string[]
  ): any {
    let _errors: { [name: string]: any } = {};

    let shouldValidate = true;
    if (el.requirements) {
      el.requirements.forEach((req) => {
        const value = findNestedValue(data, parents, req);
        if (!value || value === "false") {
          shouldValidate = false;
        }
      });
    }

    if (!shouldValidate) {
      return {};
    }

    if (el.required && !findNestedValue(data, parents, el.id)) {
      _errors[el.id] = "This field is required";
    }

    if (
      el.options &&
      el.options.length > 0 &&
      !!(el.options[0] as EditorElement).id &&
      (includeArrays ? true : el.type !== "Array")
    ) {
      var _childErrors: { [name: string]: string | null } = {};
      el.options.forEach((x) => {
        _childErrors = {
          ..._childErrors,
          ...getErrorsForElement(includeArrays, x as EditorElement, [
            ...parents,
            el.id,
          ]),
        };
      });

      if (el.type === "Array" && el.min !== -1) {
        _childErrors["__error"] =
          el.min === el.max
            ? `This array must contain ${el.min} entries`
            : `This array must contain between ${el.min} and ${el.max} entries`;
      }

      _errors[el.id] = _childErrors;
    }

    return _errors;
  }

  function refreshErrors() {
    setErrors(getDefaultErrors(true));
  }

  useEffect(() => {
    setErrors(getDefaultErrors(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  function save() {
    const totalErrors = Object.keys(getDefaultErrors(false)).filter(
      (e) => errors[e] !== ""
    ).length;

    if (totalErrors > 0) {
      setShowErrors(true);
    } else {
      onSave(data);
    }
  }

  return (
    <Modal
      show={true}
      onHide={onCancel}
      backdrop="static"
      className={teamsMenuOpen ? "modal-shift" : ""}
      centered
    >
      {node.node.shareable && (
        <TeamsPanel
          open={teamsMenuOpen}
          setOpen={setTeamsMenuOpen}
          node={node}
          setNode={setNode}
        />
      )}

      <Modal.Header>
        <Modal.Title style={{ lineHeight: 1 }}>
          <div className="d-flex">
            <div className="mr-2">
              <FontAwesomeIcon
                icon={getIconFor(node.node.type)}
                size="lg"
                className="m-2"
              />
            </div>
            <div>
              {node.node.name}
              <br />
              <span style={{ fontSize: 18 }}>{node.node.type}</span>
            </div>
          </div>
        </Modal.Title>
        {node.node.docURL && (
          <Modal.Title>
            <HelpIcon
              id={`node-help-${node.id}`}
              tooltip="View Documentation"
              onClick={() => window.open(node.node.docURL, "_blank")}
            />
          </Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body>
        <p className="mb-4">{node.node.description}</p>
        <Form onSubmit={save}>
          {node.node.editorElements.map((el) => {
            if (el.type === "Button") {
              return <ButtonInput key={el.id} node={node} element={el} />;
            } else if (el.type === "CustomSelect") {
              return (
                <CustomSelect
                  key={el.id}
                  element={el}
                  data={data}
                  setData={(_data: { [key: string]: unknown }) => {
                    setShowErrors(false);
                    setData(_data);
                  }}
                  errors={errors}
                  setErrors={setErrors}
                  showErrors={showErrors}
                  parents={[] as string[]}
                />
              );
            } else if (el.type === "SearchableDropdown") {
              return (
                <SearchableDropdown
                  key={el.id}
                  node={node}
                  element={el}
                  nodes={nodes}
                  links={links}
                  data={data}
                  setData={(_data: { [key: string]: unknown }) => {
                    setShowErrors(false);
                    setData(_data);
                  }}
                  errors={errors}
                  setErrors={setErrors}
                  showErrors={showErrors}
                  parents={[] as string[]}
                />
              );
            } else if (el.type === "Array") {
              return (
                <ArrayEditor
                  key={el.id}
                  node={node}
                  element={el}
                  nodes={nodes}
                  links={links}
                  data={data}
                  setData={(_data: { [key: string]: unknown }) => {
                    setShowErrors(false);
                    setData(_data);
                  }}
                  errors={errors}
                  setErrors={setErrors}
                  showErrors={showErrors}
                  parents={[] as string[]}
                  refreshErrors={refreshErrors}
                />
              );
            } else if (el.type === "Object") {
              return (
                <ObjectEditor
                  key={el.id}
                  node={node}
                  element={el}
                  nodes={nodes}
                  links={links}
                  data={data}
                  setData={(_data: { [key: string]: unknown }) => {
                    setShowErrors(false);
                    setData(_data);
                  }}
                  errors={errors}
                  setErrors={setErrors}
                  showErrors={showErrors}
                  parents={[] as string[]}
                />
              );
            } else if (el.type === "HardString") {
              return (
                <HardStringInput
                  key={el.id}
                  data={data}
                  setData={(_data: { [key: string]: unknown }) => {
                    setShowErrors(false);
                    setData(_data);
                  }}
                  errors={errors}
                  setErrors={setErrors}
                  showErrors={showErrors}
                  element={el}
                  parents={[] as string[]}
                />
              );
            } else if (el.type === "VariableList") {
              return <VariableList key={el.id} node={node} element={el} />;
            } else if (el.type === "VariableStore") {
              return (
                <VariableStore
                  key={el.id}
                  data={data}
                  setData={(_data: { [key: string]: unknown }) => {
                    setShowErrors(false);
                    setData(_data);
                  }}
                  errors={errors}
                  setErrors={setErrors}
                  showErrors={showErrors}
                  element={el}
                  parents={[] as string[]}
                />
              );
            } else if (el.type === "ConditionDropdown") {
              return (
                <ConditionDropdown
                  key={el.id}
                  data={data}
                  setData={(_data: { [key: string]: unknown }) => {
                    setShowErrors(false);
                    setData(_data);
                  }}
                  element={el}
                  parents={[]}
                />
              );
            } else if (typeof elements[el.type] !== "undefined") {
              return (
                <InputElementWrapper
                  key={el.id}
                  node={node}
                  nodes={nodes}
                  links={links}
                  data={data}
                  setData={(_data: { [key: string]: unknown }) => {
                    setShowErrors(false);
                    setData(_data);
                  }}
                  errors={errors}
                  setErrors={setErrors}
                  showErrors={showErrors}
                  element={el}
                  parents={[] as string[]}
                />
              );
            } else {
              return (
                <p className="text-danger">Element {el.type} does not exist!</p>
              );
            }
          })}
        </Form>
      </Modal.Body>
      <Modal.Footer style={{ display: "flex", gap: 15 }}>
        {node.node.editable && (
          <Button
            variant="outline-primary"
            onClick={onCancel}
            style={{ flex: 1 }}
          >
            Cancel
          </Button>
        )}
        <Button
          variant="primary"
          onClick={node.node.editable ? save : onCancel}
          style={{ flex: 1 }}
        >
          {node.node.editable ? "Save" : "Ok"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

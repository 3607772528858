import axios from "axios";
import { useEffect, useState } from "react";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { APIUrl } from "../../Constants";
import { Applet, Paged } from "../../types";
import { Spinner } from "../shared/Spinner";
import { AppletList } from "./AppletList";
import { Config } from "./Config/Config";
import { Editor } from "./Editor/Editor";

export function Applets() {
  const { path } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [applets, setApplets] = useState<Paged<Applet>>();
  const { userid } = useParams<{ userid?: string }>();
  const [page, setPage] = useState(1);

  useEffect(() => {
    let cancelled = false;

    async function getApplets() {
      let response;
      if (userid) {
        response = await axios.get(
          `${APIUrl}/applet/admin/${userid}/?page=${page}`
        );
      } else {
        response = await axios.get(`${APIUrl}/applet?page=${page}`);
      }
      if (!cancelled) {
        setLoading(false);
        setApplets(response.data);
      }
    }
    getApplets();

    return () => {
      cancelled = true;
    };
  }, [page, userid]);

  useEffect(() => {
    console.log(path);
    console.log(userid);
  }, [path, location, userid]);

  async function setStatus(id: string, status: "enable" | "disable") {
    if (applets) {
      try {
        const response = await axios.post(`${APIUrl}/applet/${id}/${status}`);
        const _applets = { ...applets };
        _applets.results = [
          ...applets.results.map((applet) => {
            if (applet.id === id) {
              return response.data;
            }
            return applet;
          }),
        ];
        setApplets(_applets);
      } catch (error) {}
    }
  }

  if (loading || !applets) {
    return <Spinner />;
  }

  return (
    <Switch>
      <Route exact path={`${path}/editor/:id`}>
        <Editor
          onEdit={(applet) => {
            const _applets = { ...applets };

            if (applets.results.find((x) => x.id === applet.id)) {
              _applets.results = applets.results.map((a) =>
                a.id === applet.id ? applet : a
              );
            } else {
              _applets.results = [...applets.results, applet];
            }

            setApplets(_applets);
          }}
        />
      </Route>
      <Route exact path={`${path}/config/:id`}>
        <Config
          onEdit={(applet) => {
            const _applets = { ...applets };
            _applets.results = applets.results.map((a) =>
              a.id === applet.id ? applet : a
            );
            setApplets(_applets);
          }}
        />
      </Route>
      <Route path={path}>
        <AppletList
          applets={applets}
          openEditor={(id, state) => {
            history.push(
              id
                ? `${location.pathname}/editor/${id}`
                : `${location.pathname}/editor/new`,
              state
            );
          }}
          openConfig={(id) => {
            history.push(`${location.pathname}/config/${id}`);
          }}
          setStatus={setStatus}
          onDelete={(id) => {
            const _applets = { ...applets };
            _applets.results = applets.results.filter((a) => a.id !== id);
            setApplets(_applets);
          }}
          page={page}
          setPage={setPage}
        />
      </Route>
    </Switch>
  );
}

import {
  faChartLine,
  faCube,
  faCubes,
  faExclamation,
  faPuzzlePiece,
  faUserChart,
} from "@fortawesome/pro-light-svg-icons";
import axios from "axios";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Line, Pie } from "react-chartjs-2";
import { APIUrl } from "../../Constants";
import { StatCard, StatCardProps } from "../shared/StatCard";
import * as ChartAnnotation from "chartjs-plugin-annotation";
import { ChartOptions } from "chart.js";
import { useTutorial } from "../../context/TutorialContext";
import { TutorialPanel } from "../Tutorial/TutorialPanel";
import { Spinner } from "../shared/Spinner";

export function Main() {
  const [planUsage, setPlanUsage] = useState<StatCardProps[]>();
  const [maxInvocations, setMaxInvocations] = useState(0);
  const [invocationHistory, setInvocationHistory] = useState<
    { date: string; invocations: number }[]
  >([]);
  const [topApplets, setTopApplets] = useState<
    {
      name: string;
      invocations: number;
    }[]
  >([]);
  const tutorial = useTutorial();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let cancelled = false;

    async function loadData() {
      setLoading(true);
      if (!cancelled) {
        await GetTopApplets();
        await GetPlanData();
        await GetInvocationHistory();
        setLoading(false);
      }
    }
    loadData();

    return () => {
      cancelled = true;
    };
  }, []);

  async function GetPlanData() {
    try {
      const response = await axios.get(APIUrl + "/Statistics/Plan");
      if (response && response.data) {
        let array: StatCardProps[] = [];
        if (response.data.hasOwnProperty("Modules")) {
          array.push({
            icon: faPuzzlePiece,
            name: "Modules Enabled",
            value: response.data.Modules.current,
            maxValue: response.data.Modules.limit,
            infinite: response.data.Modules.limit === -1,
          });
        }

        if (response.data.hasOwnProperty("Errors")) {
          array.push({
            icon: faExclamation,
            name: "Errors in the last 7 days",
            value: response.data.Errors.current,
            infinite: response.data.Errors.limit === -1,
          });
        }

        if (response.data.hasOwnProperty("Applets")) {
          array.push({
            icon: faCube,
            name: "Applets Enabled",
            value: response.data.Applets.current,
            maxValue: response.data.Applets.limit,
            infinite: response.data.Applets.limit === -1,
          });
        }

        if (response.data.hasOwnProperty("TotalApplets")) {
          array.push({
            icon: faCubes,
            name: "Total Applets",
            value: response.data.TotalApplets.current,
            maxValue: response.data.TotalApplets.limit,
            infinite: response.data.TotalApplets.limit === -1,
          });
        }

        if (response.data.hasOwnProperty("Invocations")) {
          array.push({
            icon: faChartLine,
            name: "Invocations Today",
            value: response.data.Invocations.current,
            maxValue: response.data.Invocations.limit,
            infinite: response.data.Invocations.limit === -1,
          });
          setMaxInvocations(response.data.Invocations.limit);
        }

        if (response.data.hasOwnProperty("TotalInvocations")) {
          array.push({
            icon: faUserChart,
            name: "Total Invocations",
            value: response.data.TotalInvocations.current,
            infinite: response.data.TotalInvocations.limit === -1,
          });
        }

        setPlanUsage(array);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function GetInvocationHistory() {
    try {
      const response = await axios.get(APIUrl + "/Statistics/Invocations");
      if (response && response.data) {
        setInvocationHistory(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function GetTopApplets() {
    try {
      const response = await axios.get(APIUrl + "/Statistics/TopApplets");
      if (response && response.data) {
        setTopApplets(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  function GetSuggestedMax() {
    if (maxInvocations === -1) {
      let highestValue = invocationHistory
        .map((x) => x.invocations)
        .sort((a, b) => b - a)[0];
      if (highestValue > 0) {
        return highestValue * 1.1;
      } else {
        return 50;
      }
    } else {
      return maxInvocations * 1.1;
    }
  }

  const lineOptions: ChartOptions = {
    annotation: {
      annotations: [
        {
          type: "line",
          mode: "horizontal",
          scaleID: "y-axis-0",
          value: maxInvocations === -1 ? 0 : maxInvocations,
          borderColor: "#d65a66",
          borderWidth: maxInvocations === -1 ? 0 : 3,
          label: {
            backgroundColor: "black",
            content: "Daily Limit",
            enabled: maxInvocations === -1 ? false : true,
          },
        },
      ],
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            suggestedMax: GetSuggestedMax(),
            fontColor: "white",
          },
        },
      ],
      xAxes: [
        {
          ticks: {
            fontColor: "white",
          },
        },
      ],
    },
  };

  const LineData = {
    labels: invocationHistory.map((x) => x.date),
    datasets: [
      {
        label: "# of Invocations",
        data: invocationHistory.map((x) => x.invocations),
        backgroundColor: "#d65a66",
      },
    ],
  };

  const GetPieLabels = () => {
    let newArray: string[] = [];
    topApplets.forEach((x) => {
      newArray.push(x.name);
    });
    return newArray;
  };

  const GetPieData = () => {
    let newArray: number[] = [];
    topApplets.forEach((x) => {
      newArray.push(x.invocations);
    });
    return newArray;
  };

  const PieData = {
    labels: GetPieLabels(),
    datasets: [
      {
        label: "# of Invocations",
        data: GetPieData(),
        borderWidth: 0,
        backgroundColor: [
          "#d65a66",
          "#5e5cd6",
          "#5cccd6",
          "#5cd65c",
          "#d6d45c",
        ],
      },
    ],
  };

  return loading ? (
    <Spinner />
  ) : (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              {planUsage ? (
                <Section name="Plan Usage" cards={planUsage} />
              ) : null}
            </Col>
            <Col>
              <h3 className="title">Top 5 Applets by Invocations</h3>
              <div className="bg-base pt-2 pb-4">
                {GetPieData().length === 0 ? (
                  <p className="ml-2">No data to show</p>
                ) : (
                  <Pie data={PieData} />
                )}
              </div>
            </Col>
          </Row>
          <h3 className="title">Invocation History</h3>
          <div className="bg-base">
            <Line
              data={LineData}
              options={lineOptions}
              plugins={[ChartAnnotation]}
              height={60}
            />
          </div>
        </Container>
      </div>
      {tutorial.inTutorial && tutorial.stage === "DASHBOARD" && (
        <TutorialPanel
          title="Dashboard & Invocations"
          right={20}
          bottom={20}
          renderButton={true}
          onBtnClick={() => {
            tutorial.setStage("FINAL");
          }}
        >
          The dashboard is where you can view information about your applets and
          plan usage as well as an in-depth overview of what is using your
          invocations.
          <br />
          <br />
          Invocations are counted every time a trigger runs an applet and this
          is the value we use to limit plan tiers. An invocation is only counted
          if the applet executes successfully and will only count once even if
          multiple applets use the same trigger.
        </TutorialPanel>
      )}

      {tutorial.inTutorial && tutorial.stage === "FINAL" && (
        <TutorialPanel
          title="Tutorial Complete"
          right={20}
          bottom={20}
          renderButton={true}
          onBtnClick={() => {
            tutorial.skipTutorial();
          }}
          btnText="Finish"
        >
          Thanks for running through the tutorial! More tutorials will be coming
          soon for the more advanced features of Stream Hydra.
          <br />
          <br />
          If you have any further questions please contact us{" "}
          <a
            href="https://discord.gg/pwx5HScw6M"
            target="_blank"
            rel="noreferrer"
          >
            via Discord
          </a>{" "}
          or{" "}
          <a
            href="https://twitter.com/StreamHydra"
            target="_blank"
            rel="noreferrer"
          >
            via Twitter
          </a>
        </TutorialPanel>
      )}
    </>
  );
}

type SectionProps = {
  name: string;
  cards: StatCardProps[];
};

function Section({ name, cards }: SectionProps) {
  return (
    <>
      <h4 className="mb-3">{name}</h4>
      <div className="stat-card-wrapper">
        {cards.map((card, index) => (
          <StatCard
            key={index}
            icon={card.icon}
            name={card.name}
            value={card.value}
            maxValue={card.maxValue}
            infinite={card.infinite}
          />
        ))}
      </div>
    </>
  );
}

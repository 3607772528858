import { faBracketsCurly, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { InputProps } from "../../../types";
import { ContextModal } from "../../Applets/Editor/NodeEditor/Modals/ContextModal";

export function ToggleInput({
  id,
  name,
  value,
  defaultValue,
  setValue,
  disabled,
  error,
  placeholder,
  variables,
  required,
  onValidate,
}: InputProps) {
  const [dropdown, setDropdown] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const [override, setOverride] = useState<string | undefined>(
    value && (value + "").startsWith("{") ? (value as string) : undefined
  );

  useEffect(() => {
    function onFocus(e: MouseEvent) {
      if (divRef.current && !divRef.current.contains(e.target as Node)) {
        setDropdown(false);
      }
    }

    window.addEventListener("click", onFocus);

    return () => {
      window.removeEventListener("click", onFocus);
    };
  }, []);

  function onChange(value: string | boolean) {
    let error = "";

    if (required && (!value || value === "")) {
      error = "This field is required";
    }

    if (!!onValidate) {
      onValidate(error);
    }

    setValue(value.toString());
  }

  function addVariable(variable: string) {
    setDropdown(false);
    const override = `{${variable}}`;
    setOverride(override);
    onChange(override);
  }

  return (
    <div ref={divRef}>
      <Form.Group>
        <Form.Label>{name}</Form.Label>
        <div className="d-flex position-relative">
          <div className="flex-grow-1">
            {override ? (
              <>
                <Form.Control
                  id={id}
                  type="text"
                  disabled={true}
                  value={`Using variable: ${override}`}
                  className=".bg-base-bg"
                />
              </>
            ) : (
              <>
                <Form.Check
                  id={id}
                  custom
                  type="switch"
                  label={placeholder}
                  onChange={(e) => onChange(e.target.checked)}
                  checked={
                    value
                      ? value === "true" || value === true
                      : defaultValue === "true" || defaultValue === true
                  }
                  disabled={disabled}
                  isInvalid={!!error}
                />
                <Form.Control.Feedback type="invalid">
                  {error}
                </Form.Control.Feedback>
              </>
            )}
          </div>
          {!!variables && variables.length > 0 && (
            <div
              style={{ width: 35, height: 35, minWidth: 35, minHeight: 35 }}
              className={`d-flex justify-content-center align-items-center hover-primary bg-base-bg border-left-0 ${
                dropdown ? "text-primary" : ""
              }`}
              onClick={() => {
                if (override) {
                  setOverride(undefined);
                  setValue(false);
                } else {
                  setDropdown(!dropdown);
                }
              }}
            >
              <FontAwesomeIcon icon={override ? faTimes : faBracketsCurly} />
            </div>
          )}
          {!!variables && variables.length > 0 && dropdown && (
            <ContextModal variables={variables} onUse={addVariable} />
          )}
        </div>
      </Form.Group>
    </div>
  );
}

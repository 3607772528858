import { Button, Modal } from "react-bootstrap";

type InfoModalProps = {
  title: string;
  body: string;
  btnText?: string;
  onClose: () => void;
};

export function InfoModal({ title, body, btnText, onClose }: InfoModalProps) {
  return (
    <Modal show={true} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onClose} block>
          {btnText ? btnText : "OK"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

import { faPencilAlt, faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Badge, Button } from "react-bootstrap";
import {
  EditorElement,
  LinkInstance,
  NodeInstance,
} from "../../../../../../types";
import { getNestedError, setNestedErrors } from "../../../../../shared/utils";
import { elements } from "../../../ConfigEditor/elements";
import { InputElementWrapper } from "../InputElementWrapper";
import { SearchableDropdown } from "./SearchableDropdown";

type ArrayEditorProps = {
  node: NodeInstance;
  element: EditorElement;
  nodes: NodeInstance[];
  links: LinkInstance[];
  data: {
    [key: string]: unknown;
  };
  setData: (_data: { [key: string]: unknown }) => void;
  errors: {
    [name: string]: string | null;
  };
  setErrors: (errors: { [name: string]: string | null }) => void;
  showErrors: boolean;
  parents: string[];
  refreshErrors: () => void;
};

export function ArrayEditor({
  node,
  element,
  nodes,
  links,
  data,
  setData,
  errors,
  setErrors,
  showErrors,
  parents,
  refreshErrors,
}: ArrayEditorProps) {
  const [adding, setAdding] = useState(-1);
  const [array, setArray] = useState<unknown[]>(
    data[element.id] ? (data[element.id] as unknown[]) : []
  );
  const [showErrorsOverride, setShowErrorsOverride] = useState(false);

  function getRelevantData() {
    const newData = { ...data };

    if (adding === array.length) {
      setArray([...array, {}]);
    }

    newData[element.id] = array[adding];
    return newData;
  }

  function onDataUpdate(update: { [data: string]: unknown }) {
    const newArray = [...array];
    newArray[adding] = update[element.id];
    setArray(newArray);
    console.log(newArray);
    const newData = { ...data };
    newData[element.id] = newArray;
    console.log(newData);
    setData(newData);
  }

  function save() {
    if (!hasErrors()) {
      setAdding(-1);
      refreshErrors();
      setShowErrorsOverride(false);
      validate();
    } else {
      setShowErrorsOverride(true);
    }
  }

  function remove() {
    setAdding(-1);
    let _array = array.filter((x, i) => i !== adding);
    setArray(_array);
    setShowErrorsOverride(false);
    validate(_array);
  }

  function validate(_array?: unknown[]) {
    let error = "";

    const __array = _array || array;

    if (
      (element.min !== -1 && __array.length < element.min) ||
      (element.max !== -1 && __array.length > element.max)
    ) {
      if (element.min === element.max) {
        error = `This array must contain ${element.min} entries`;
      } else {
        error = `This array must contain between ${element.min} and ${element.max} entries`;
      }
    }

    setNestedErrors(
      errors,
      setErrors,
      [...parents, element.id],
      "__error",
      error
    );
  }

  function hasErrors() {
    let error = false;

    (element.options as EditorElement[]).forEach((el) => {
      if (!error) {
        let _error = getNestedError(errors, [...parents, element.id], el.id);
        error = !!_error && typeof _error === "string" && _error !== "";
      }
    });

    return error;
  }

  function flushErrors() {
    setNestedErrors(errors, setErrors, parents, element.id, "");
    (element.options as EditorElement[]).forEach((el) => {
      setNestedErrors(errors, setErrors, [...parents, element.id], el.id, "");
    });
  }

  function getStreamTagLabel(item: any) {
    let toReturn = "";
    if (item) {
      Object.keys(item).forEach((x) => {
        if (x === "tag_id") {
          Object.keys(item[x]).forEach((y) => {
            if (y === "label") {
              toReturn = item[x][y].toString();
            }
          });
        }
      });
    }
    return toReturn;
  }

  return (
    <div style={{ marginLeft: parents.length * 20 }} className="mb-4">
      <p>
        {element.label}
        {(!!element.min || !!element.max) && " |"}
        {!!element.min && ` Min: ${element.min}`}
        {!!element.max && ` Max: ${element.max}`}
        {array.length < element.max && adding === -1 && (
          <Button
            size="sm"
            className="ml-2"
            onClick={() => {
              setShowErrorsOverride(false);
              setAdding(array.length);
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        )}
      </p>
      {array.map((x, index) => (
        <ul className="list-unstyled ml-2">
          <li key={index}>
            <div className="d-flex">
              <div className="d-flex align-items-center flex-grow-1">
                <p
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "block",
                    margin: "0 20px 0 0",
                    maxWidth: 375,
                  }}
                >
                  #{index + 1}{" "}
                  {node.node.id === "twitch_set_stream_tags"
                    ? getStreamTagLabel(x)
                    : JSON.stringify(x)
                        .replaceAll("{", "")
                        .replaceAll("}", "")
                        .replaceAll('"', " ")
                        .replaceAll(":", "=")}
                </p>
                {adding === index && (
                  <Badge variant="primary" className="ml-2">
                    Editing
                  </Badge>
                )}
              </div>
              {adding === -1 && (
                <div>
                  <Button
                    size="sm"
                    onClick={() => {
                      flushErrors();
                      setAdding(index);
                    }}
                  >
                    <FontAwesomeIcon icon={faPencilAlt} />
                  </Button>
                </div>
              )}
            </div>
          </li>
        </ul>
      ))}
      {adding !== -1 && (
        <>
          {(element.options as EditorElement[]).map((el) => {
            if (typeof elements[el.type] !== "undefined") {
              return (
                <InputElementWrapper
                  key={el.id}
                  node={node}
                  nodes={nodes}
                  links={links}
                  data={getRelevantData()}
                  setData={onDataUpdate}
                  errors={errors}
                  setErrors={setErrors}
                  showErrors={showErrors || showErrorsOverride}
                  element={el}
                  parents={[...parents, element.id]}
                />
              );
            } else if (el.type === "SearchableDropdown") {
              return (
                <SearchableDropdown
                  key={el.id}
                  node={node}
                  element={el}
                  nodes={nodes}
                  links={links}
                  data={getRelevantData()}
                  setData={onDataUpdate}
                  errors={errors}
                  setErrors={setErrors}
                  showErrors={showErrors || showErrorsOverride}
                  parents={[...parents, element.id]}
                />
              );
            } else {
              return null;
            }
          })}
          <div className="mt-3">
            <Button size="sm" onClick={remove}>
              Remove
            </Button>{" "}
            <Button size="sm" onClick={save}>
              Save
            </Button>
          </div>
        </>
      )}
      {showErrors && (
        <p className="text-danger mb-0" style={{ fontSize: "80%" }}>
          {getNestedError(errors, [...parents, element.id], "__error")}
        </p>
      )}
    </div>
  );
}

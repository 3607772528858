import { createContext, useContext } from "react";
import { GlobalVariable } from "../../../../../../types";

export type GlobalVariableContextProps = {
  variables: GlobalVariable[];
  setVariables: (variables: GlobalVariable[]) => void;
};

export const GlobalVariableContext = createContext<GlobalVariableContextProps>({
  variables: [],
  setVariables: () => {},
});

export function useGlobalVariables() {
  return useContext(GlobalVariableContext);
}

import axios from "axios";
import { APIUrl } from "../../../../../../Constants";
import AsyncSelect from "react-select/async";
import {
  EditorElement,
  LinkInstance,
  NodeInstance,
} from "../../../../../../types";
import {
  findNestedValue,
  getNestedError,
  setNestedErrors,
  setNestedValue,
} from "../../../../../shared/utils";
import lodash from "lodash";

type SearchableDropdownProps = {
  node: NodeInstance;
  nodes: NodeInstance[];
  links: LinkInstance[];
  data: { [key: string]: unknown };
  setData: (data: { [key: string]: unknown }) => void;
  errors: { [id: string]: string | null };
  setErrors: (data: { [id: string]: string | null }) => void;
  showErrors: boolean;
  element: EditorElement;
  parents: string[];
};

type Option = {
  label: string;
  value: string;
};

export function SearchableDropdown({
  data,
  setData,
  errors,
  setErrors,
  showErrors,
  element,
  parents,
}: SearchableDropdownProps) {
  const debouncedLoad = lodash.debounce(load, 500);

  function load(search: string, callback: any) {
    if (element.options.length === 1) {
      axios
        .get(`${APIUrl}/${element.options[0]}?searchQuery=${search}`)
        .then((response) => {
          console.log(response.data);
          callback(
            response && response.data ? (response.data as Option[]) : []
          );
        });
    }
  }

  function validate(value: string) {
    if (element.required) {
      setNestedErrors(
        errors,
        setErrors,
        parents,
        element.id,
        !value || value === "" ? "This field is required" : ""
      );
    }
  }

  return (
    <>
      <AsyncSelect
        cacheOptions
        loadOptions={debouncedLoad}
        className="search-select"
        defaultOptions
        placeholder={element.placeholder}
        value={findNestedValue(data, parents, element.id) as Option}
        styles={{
          control: (styles) => ({
            ...styles,
            backgroundColor: "var(--base-bg)",
            borderRadius: 0,
            border:
              showErrors && !!getNestedError(errors, parents, element.id)
                ? "1px solid var(--danger) !important"
                : "1px solid var(--base-alt) !important",
            boxShadow: "none",
          }),
          input: (styles) => ({
            ...styles,
            color: "var(--color)",
            outline: "none",
          }),
          menu: (styles) => ({
            ...styles,
            backgroundColor: "var(--base-bg)",
            color: "var(--color)",
            borderRadius: 0,
          }),
          option: (styles) => ({
            ...styles,
            backgroundColor: "var(--base-bg)",
            color: "var(--color)",
            "&:hover": {
              cursor: "pointer",
              backgroundColor: "var(--base)",
            },
          }),
          placeholder: (styles) => ({
            ...styles,
            color: "var(--color-darken-25)",
          }),
          singleValue: (styles) => ({
            ...styles,
            color: "var(--color)",
          }),
        }}
        onChange={(value) => {
          setNestedValue(data, setData, parents, element.id, value);
          validate((value as Option).value);
        }}
      />
      {showErrors && !!getNestedError(errors, parents, element.id) && (
        <p>
          <small className="text-danger">
            {getNestedError(errors, parents, element.id)}
          </small>
        </p>
      )}
    </>
  );
}

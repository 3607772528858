import { faCheck } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import { Badge, Button, Table } from "react-bootstrap";
import { APIUrl } from "../../../Constants";
import { Paged, Team, TeamMember } from "../../../types";
import { Paginator } from "../../shared/Paginator";
import { Spinner } from "../../shared/Spinner";

type RejectedMembersProps = {
  team: Team;
};

export function RejectedMembers({ team }: RejectedMembersProps) {
  const [loading, setLoading] = useState(true);
  const [invites, setInvites] = useState<Paged<TeamMember>>({
    page: 1,
    pageSize: 1,
    results: [],
    totalResults: 0,
  });
  const [page, setPage] = useState(1);
  const [loadingAction, setLoadingAction] = useState(false);

  useEffect(() => {
    let cancelled = false;

    async function getMembers() {
      setLoading(true);

      const response = await axios.get(
        `${APIUrl}/teams/${team.id}/member/rejected?page=${page}`
      );
      console.log(response.data);
      if (!cancelled) {
        setInvites(response.data);
        setLoading(false);
      }
    }
    getMembers();

    return () => {
      cancelled = true;
    };
  }, [page, team.id]);

  async function unban(userId: string) {
    setLoadingAction(true);
    const response = await axios.post(
      `${APIUrl}/teams/${team.id}/member/unban?userId=${userId}`
    );
    if (response.data === true) {
      setInvites({
        ...invites,
        results: [...invites.results.filter((x) => x.userId !== userId)],
      });
    }
    setLoadingAction(false);
  }

  if (loading || !invites) {
    return <Spinner />;
  }

  if (invites && invites.results.length === 0) {
    return <p className="m-4">There are no rejected members.</p>;
  }

  return (
    <>
      <Table striped className="users-list">
        <thead>
          <tr>
            <th>Name</th>
            <th>Rank</th>
            {(team.activeUser!.teamRole === "ADMIN" ||
              team.activeUser!.teamRole === "MOD") && <th>Moderation</th>}
          </tr>
        </thead>
        {loading ? (
          <Spinner size="sm" />
        ) : (
          <tbody>
            {invites &&
              invites.results &&
              invites.results.map((member) => (
                <tr key={member.userId}>
                  <td>{member.name}</td>
                  <td>
                    <Badge variant="danger">REJECTED</Badge>
                  </td>
                  {(team.activeUser!.teamRole === "ADMIN" ||
                    team.activeUser!.teamRole === "MOD") && (
                    <td>
                      <div className="user-moderation-controls">
                        <Button
                          size="sm"
                          variant="success"
                          onClick={() => unban(member.userId)}
                          disabled={loadingAction}
                        >
                          <FontAwesomeIcon icon={faCheck} />
                        </Button>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        )}
      </Table>
      <div className="my-3 d-flex justify-content-center">
        <Paginator
          totalResults={invites.totalResults}
          pageSize={invites.pageSize}
          page={page}
          setPage={setPage}
        />
      </div>
    </>
  );
}

import {
  faChevronDown,
  faChevronLeft,
  IconDefinition,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment } from "react";
import { useState } from "react";
import { Badge, OverlayTrigger, Popover, Tooltip } from "react-bootstrap";

type SidenavGroupProps = {
  name: string;
  items: SidenavGroupItem[];
  onNavigate: (link: string) => void;
  pathname: string;
  open?: boolean;
};

export type SidenavGroupItem = {
  icon: IconDefinition;
  name: string;
  link: string;
  disabled?: boolean;
  requiredPlan?: string;
  beta?: boolean;
  notificationAmount?: number;
};

export function SidenavGroup({
  name,
  items,
  onNavigate,
  pathname,
  open,
}: SidenavGroupProps) {
  const [expanded, setExpanded] = useState(true);

  function isActive(item: SidenavGroupItem) {
    if (item.link === "/") {
      return pathname === item.link;
    } else {
      return pathname.startsWith(item.link);
    }
  }

  function listItem(item: SidenavGroupItem) {
    return (
      <li
        className={`${isActive(item) ? "active" : ""} ${
          item.disabled ? "disabled" : ""
        }`}
        onClick={() => {
          if (!item.disabled) {
            onNavigate(item.link);
          }
        }}
      >
        {!open ? (
          <OverlayTrigger
            placement="right"
            overlay={
              item.disabled ? (
                <Popover id={`tooltip-${item.name}`}>
                  <Popover.Title>{item.name}</Popover.Title>
                  <Popover.Content>
                    Sorry this feature requires the{" "}
                    {item.requiredPlan && item.requiredPlan?.toLowerCase()} plan
                    or higher to use.
                  </Popover.Content>
                </Popover>
              ) : (
                <Tooltip id={`tooltip-${item.name}`}>{item.name}</Tooltip>
              )
            }
          >
            <div className="sidenav-group-item-icon">
              <FontAwesomeIcon icon={item.icon} />
            </div>
          </OverlayTrigger>
        ) : (
          <div className="sidenav-group-item-icon">
            <FontAwesomeIcon icon={item.icon} />
          </div>
        )}
        <span className="sidenav-group-item-divider" />
        <div className="sidenav-group-item-label">
          <div className="d-flex align-items-center">
            <p className="flex-grow-1">{item.name}</p>{" "}
            {item.beta && (
              <div>
                <Badge variant="primary">BETA</Badge>
              </div>
            )}
            {item.notificationAmount && item.notificationAmount > 0 ? (
              <div>
                <h5>
                  <Badge pill variant="primary">
                    {item.notificationAmount}
                  </Badge>
                </h5>
              </div>
            ) : null}
          </div>
        </div>
      </li>
    );
  }

  return (
    <div className="sidenav-group">
      <div
        className="sidenav-group-header"
        onClick={() => setExpanded(!expanded)}
      >
        <p>{name}</p>
        <FontAwesomeIcon icon={expanded ? faChevronDown : faChevronLeft} />
      </div>
      {expanded && (
        <ul className="sidenav-group-items">
          {items.map((item, index) => (
            <Fragment key={index}>
              {open && item.disabled ? (
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Popover id={`tooltip-${index}`}>
                      <Popover.Title>Upgrade Required!</Popover.Title>
                      <Popover.Content>
                        Sorry this feature requires the{" "}
                        {item.requiredPlan && item.requiredPlan?.toLowerCase()}{" "}
                        plan or higher to use.
                      </Popover.Content>
                    </Popover>
                  }
                >
                  {listItem(item)}
                </OverlayTrigger>
              ) : (
                <>{listItem(item)}</>
              )}
            </Fragment>
          ))}
        </ul>
      )}
    </div>
  );
}

import {
  faBell,
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { Alert, NavDropdown } from "react-bootstrap";
import { useHistory } from "react-router";
import { APIUrl } from "../Constants";
import { Notification } from "../types";

type NotificationListProps = {
  notifications: Notification[];
  setNotifications: (notifications: Notification[]) => void;
};

export function NotificationList({
  notifications,
  setNotifications,
}: NotificationListProps) {
  const history = useHistory();

  return (
    <NavDropdown
      title={
        <>
          <FontAwesomeIcon icon={faBell} />
          {notifications.filter((n) => !n.read && !n.flash).length > 0 && (
            <div className="notification-alert"></div>
          )}
        </>
      }
      id="notification-dropdown"
      className="nav-dropdown notification-dropdown"
      alignRight
      onClick={async () => {
        await axios.get(`${APIUrl}/notification/read-all`);
        setNotifications([
          ...notifications.map((n) => {
            n.read = true;
            return n;
          }),
        ]);
      }}
    >
      {notifications.filter((n) => !n.flash).length === 0 ? (
        <span>No notifications found.</span>
      ) : (
        <>
          <p>Notifications</p>
          {notifications
            .filter((n) => !n.flash)
            .sort(
              (a, b) =>
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
            )
            .map((n) => (
              <Alert
                key={n.id}
                className={`notification ${n.type.toLowerCase()}`}
                onClick={() => {
                  if (n.url) {
                    history.push(n.url);
                  }
                }}
                style={{ cursor: n.url ? "pointer" : "inherit" }}
              >
                <Alert.Heading>
                  <FontAwesomeIcon
                    icon={
                      n.type.toLowerCase() === "danger"
                        ? faExclamationCircle
                        : n.type.toLowerCase() === "warning"
                        ? faExclamationTriangle
                        : n.type.toLowerCase() === "info"
                        ? faInfoCircle
                        : faCheckCircle
                    }
                    className={`alert-icon text-${n.type.toLowerCase()}`}
                  />
                  {n.title}
                  {!n.read && <div className="unread"></div>}
                </Alert.Heading>
                {n.message}
              </Alert>
            ))}
        </>
      )}
    </NavDropdown>
  );
}

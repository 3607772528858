import axios from "axios";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { APIUrl } from "../../../Constants";
import { useSidenav } from "../../../context/SidenavContext";
import { Applet, NodeOption } from "../../../types";
import { Spinner } from "../../shared/Spinner";
import { UnloadWarning } from "../../shared/UnloadWarning";
import { GlobalVariableContext } from "./NodeEditor/Modals/InternalElements/GlobalVariableContext";
import { NodeViewer } from "./NodeEditor/NodeViewer";
import { EditorSidenav } from "./NodeEditor/Navigation/EditorSidenav";
import { Header } from "./Header";
import { ConfigEditor } from "./ConfigEditor/ConfigEditor";
import { TutorialPanel } from "../../Tutorial/TutorialPanel";
import { useTutorial } from "../../../context/TutorialContext";
import { getIconFor } from "../../shared/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons";
import { faBracketsCurly } from "@fortawesome/pro-light-svg-icons";

const defaultApplet: Applet = {
  id: "",
  name: "New Applet",
  nodes: [],
  links: [],
  globalVariables: [],
  values: {},
  status: "DISABLED",
  requiredModules: [],
  nodeCount: 0,
  snap: true,
  showGrid: true,
  totalInvocations: 0,
  errors: 0,
  configGroups: [],
};

type EditorProps = {
  onEdit: (applet: Applet) => void;
};

type EditorState = {
  template?: string;
};

export function Editor({ onEdit }: EditorProps) {
  const state = useLocation().state as EditorState;
  const sidenav = useSidenav();
  const [loading, setLoading] = useState(true);
  const [nodes, setNodes] = useState<NodeOption[]>([]);
  const [data, setData] = useState<Applet>(defaultApplet);
  const [renameInput, setRenameInput] = useState(data.name);
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [hasChanges, setHasChanges] = useState(false);
  const [scale, setScale] = useState<number>(1);
  const [editingConfig, setEditingConfig] = useState(false);
  const tutorial = useTutorial();

  useEffect(() => {
    sidenav.setEnabled(false);

    return () => {
      sidenav.setEnabled(true);
    };
  }, [sidenav]);

  useEffect(() => {
    let cancelled = false;
    async function getData() {
      const nodeResponse = await axios.get(`${APIUrl}/node`);
      const _nodes: NodeOption[] = nodeResponse.data;
      setNodes(_nodes);

      if (id && id !== "new") {
        try {
          const response = await axios.get(`${APIUrl}/applet/${id}`);
          if (!cancelled) {
            const _data = response.data;
            _data.id = id;

            _data.nodes = _data.nodes.map((n: any) => {
              return {
                ...n,
                node: _nodes.find((x) => x.id === n.node),
                nodeId: n.node,
              };
            });

            setScale(_data.scale ? _data.scale : 1);
            setData(_data);
            setRenameInput(_data.name);
          }
        } catch (e: any) {
          history.push("/applets");
        }
      }

      if (state && state.template) {
        const response = await axios.get(
          `${APIUrl}/template/${state.template}`
        );
        console.log(response.data);
        setData((data) => ({
          ...data,
          nodes: response.data.nodes.map((n: any) => {
            return {
              ...n,
              node: _nodes.find((x) => x.id === n.node),
              nodeId: n.node,
            };
          }),
          links: response.data.links,
          globalVariables: response.data.globalVariables,
          requiredModules: response.data.requiredModules,
          nodeCount: response.data.nodeCount,
        }));
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
    getData();

    return () => {
      cancelled = true;
    };
  }, [id, history, state]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      {tutorial.inTutorial && tutorial.stage === "APPLET EDITOR" && (
        <TutorialPanel
          position="absolute"
          title="The Applet Editor"
          bottom={20}
          right={20}
          renderButton={true}
          onBtnClick={() => {
            tutorial.setStage("APPLET EDITOR 2");
          }}
        >
          Welcome to the Applet Editor! Here you use our drag & drop node system
          to connect services together in any way you can imagine!
        </TutorialPanel>
      )}
      <EditorSidenav nodes={nodes} setNodes={setNodes} />
      {tutorial.inTutorial && tutorial.stage === "APPLET EDITOR 2" && (
        <TutorialPanel
          position="absolute"
          title="Introduction to Nodes"
          bottom={20}
          right={20}
          renderButton={true}
          onBtnClick={() => {
            tutorial.setStage("APPLET EDITOR 3");
          }}
        >
          A node represents a single thing that a module can do. Nodes are split
          into a few different categories but the main ones are: <br />
          <br />
          <strong>Triggers</strong>{" "}
          <FontAwesomeIcon icon={getIconFor("Trigger")} /> : These nodes will
          run your applet when something happens, when you get a follow on
          Twitch for example.
          <br />
          <br />
          <strong>Actions</strong>{" "}
          <FontAwesomeIcon icon={getIconFor("Action")} /> : These nodes will
          instruct the applet to do something, send a chat message for exmaple.
          <br />
          <br />
          <strong>Logic</strong> <FontAwesomeIcon icon={getIconFor("Logic")} />{" "}
          : These nodes will perform some sort of logic such as comparing two
          values.
        </TutorialPanel>
      )}
      {tutorial.inTutorial && tutorial.stage === "APPLET EDITOR 3" && (
        <TutorialPanel
          position="absolute"
          title="Finding Nodes"
          left={80}
          top={110}
          renderButton={true}
          onBtnClick={() => {
            tutorial.setStage("APPLET EDITOR 4");
          }}
          arrowPosition="left"
        >
          Nodes are grouped by module and type for ease of use. Click on a
          module to view all its available nodes. Or use the search bar to
          search through all nodes you have access to.
          <br />
          Once you have chosen a node, simply drag it onto the Node Editor grid.
        </TutorialPanel>
      )}
      {tutorial.inTutorial && tutorial.stage === "APPLET EDITOR 4" && (
        <TutorialPanel
          position="absolute"
          title="Connecting Nodes"
          bottom={20}
          right={20}
          renderButton={true}
          onBtnClick={() => {
            tutorial.setStage("APPLET EDITOR 5");
          }}
        >
          Nodes can have 2 types of connectors.
          <br />
          <br />
          <strong>Output</strong>{" "}
          <FontAwesomeIcon icon={faCircle} className="text-primary" /> : This
          connector will send its data and the data of all nodes before it to
          the selected input.
          <br />
          <br />
          <strong>Input</strong>{" "}
          <FontAwesomeIcon icon={faCircle} className="text-success" /> : This
          connector will receive data from nodes it is connected to.
          <br />
          <br />
          To connect nodes together, simply drag an output{" "}
          <FontAwesomeIcon icon={faCircle} className="text-primary" /> to an
          input <FontAwesomeIcon icon={faCircle} className="text-success" />
        </TutorialPanel>
      )}
      {tutorial.inTutorial && tutorial.stage === "APPLET EDITOR 5" && (
        <TutorialPanel
          position="absolute"
          title="Configuring Nodes"
          bottom={20}
          right={20}
          renderButton={true}
          onBtnClick={() => {
            tutorial.setStage("APPLET EDITOR 6");
          }}
        >
          Some nodes will have a red border, this means they have additional
          configuration required before they can be used. Simply double click on
          it to open the node configuration panel.
        </TutorialPanel>
      )}
      {tutorial.inTutorial && tutorial.stage === "APPLET EDITOR 6" && (
        <TutorialPanel
          position="absolute"
          title="Variables"
          bottom={20}
          right={20}
          renderButton={true}
          onBtnClick={() => {
            tutorial.setStage("APPLET EDITOR 7");
          }}
        >
          Some nodes have variables that they send to other connected nodes. If
          a variable is available to use on a field inside the node
          configuration panel you will see a{" "}
          <FontAwesomeIcon icon={faBracketsCurly} /> icon. Click this (or
          pressing {"{"} while typing) will show a list of all possible
          variables.
          <br />
          <br />
          Using a variable, which is just some text that starts with {"{"} and
          ends with {"}"} ({"{user_id}"} for example), will instruct the node to
          replace that variable with whatever data it receives at runtime.
        </TutorialPanel>
      )}
      {tutorial.inTutorial && tutorial.stage === "APPLET EDITOR 7" && (
        <TutorialPanel
          position="absolute"
          title="Testing"
          top={55}
          right={400}
          arrowPosition="right"
          renderButton={true}
          onBtnClick={() => {
            tutorial.setStage("APPLET EDITOR 8");
          }}
        >
          If you wish to test your applet, you can click here to manually run
          your applet from a specific trigger and enter "dummy" data to test
          with.
        </TutorialPanel>
      )}
      {tutorial.inTutorial && tutorial.stage === "APPLET EDITOR 8" && (
        <TutorialPanel
          position="absolute"
          title="Save your Applet"
          top={5}
          right={390}
          arrowPosition="right"
        >
          Once you are happy with your applet, click save and then close.
        </TutorialPanel>
      )}
      <div className="page-content editor-wrapper">
        <Container fluid>
          <GlobalVariableContext.Provider
            value={{
              variables: data.globalVariables,
              setVariables: (globalVariables) => {
                const values: { [key: string]: string } = {};
                globalVariables.forEach((v) => (values[v.id] = v.defaultValue));
                setData({ ...data, globalVariables, values });
              },
            }}
          >
            <ConfigEditor
              open={editingConfig}
              onClose={() => setEditingConfig(false)}
              data={data}
              setData={setData}
            />
            <Header
              id={id}
              hasChanges={hasChanges}
              setHasChanges={setHasChanges}
              data={data}
              setData={setData}
              renameInput={renameInput}
              setRenameInput={setRenameInput}
              onEdit={onEdit}
              setEditingConfig={setEditingConfig}
            />
            <NodeViewer
              nodeOptions={nodes}
              nodes={data.nodes}
              links={data.links}
              setNodes={(nodes) => {
                setData({ ...data, nodes });
                if (!hasChanges) setHasChanges(true);
              }}
              setLinks={(links) => {
                setData({ ...data, links });
                if (!hasChanges) setHasChanges(true);
              }}
              setNodesAndLinks={(nodes, links) => {
                setData({ ...data, nodes, links });
                if (!hasChanges) setHasChanges(true);
              }}
              scale={scale}
              setScale={setScale}
              snap={data.snap}
              setSnap={(snap) => setData({ ...data, snap })}
              showGrid={data.showGrid}
              setShowGrid={(showGrid) => setData({ ...data, showGrid })}
            />
            <UnloadWarning showWarning={hasChanges} />
          </GlobalVariableContext.Provider>
        </Container>
      </div>
    </>
  );
}

import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Button, Overlay, Tooltip } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { APIUrl } from "../../../../../../Constants";
import { EditorElement, NodeInstance } from "../../../../../../types";

type ButtonInputProps = {
  node: NodeInstance;
  element: EditorElement;
};

export function ButtonInput({ node, element }: ButtonInputProps) {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const [showDone, setShowDone] = useState(false);
  const target = useRef(null);

  useEffect(() => {
    if (showDone) {
      setTimeout(() => {
        setShowDone(false);
      }, 2000);
    }
  }, [showDone]);

  async function request() {
    setLoading(true);

    await axios.post(`${APIUrl}/${element.options[0]}`, {
      appletId: id,
      nodeId: node.id,
    });

    setShowDone(true);
    setLoading(false);
  }

  if (!element.options || element.options.length <= 0) {
    return null;
  }

  return (
    <>
      <Button ref={target} onClick={request} disabled={loading}>
        {loading ? "Loading..." : element.label}
      </Button>
      <Overlay target={target.current} show={showDone} placement="bottom">
        {(props) => (
          <Tooltip id="copied" {...props}>
            Done!
          </Tooltip>
        )}
      </Overlay>
    </>
  );
}

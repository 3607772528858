import {
  faDownload,
  faPlus,
  faRedo,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import { Badge, Button, Card, Container, Table } from "react-bootstrap";
import { APIUrl } from "../../../Constants";
import { StreamDeckKey } from "../../../types";
import { ConfirmationModal } from "../../shared/modals/ConfirmationModal";
import { Spinner } from "../../shared/Spinner";
import { GenerateKeyModal } from "./Modal/GenerateKeyModal";
import { RegenerateKeyModal } from "./Modal/RegenerateKeyModal";

export function Keys() {
  const [loading, setLoading] = useState(false);
  const [keys, setKeys] = useState<StreamDeckKey[]>([]);
  const [showGenerateKeyModal, setShowGenerateKeyModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState<StreamDeckKey>();
  const [showRegenConfirmModal, setShowRegenConfirmModal] =
    useState<StreamDeckKey>();

  useEffect(() => {
    let cancelled = false;
    setLoading(true);

    async function load() {
      const response = await axios.get(`${APIUrl}/streamdeck`);

      if (!cancelled) {
        setLoading(false);
        setKeys(response.data);
      }
    }
    load();

    return () => {
      cancelled = true;
    };
  }, []);

  async function deleteKey() {
    if (showDeleteModal) {
      await axios.delete(`${APIUrl}/streamdeck/${showDeleteModal.id}`);
      setKeys((keys) => keys.filter((x) => x.id !== showDeleteModal.id));
    }

    setShowDeleteModal(undefined);
  }

  return (
    <Container fluid>
      <h1>Stream Deck Applet Keys</h1>
      <p>
        For security, to allow a stream deck to execute an applet you must
        generate an API key for each applet you wish to use. This way if a key
        becomes compromised, only that applet becomes compromised and its key
        can easily be regenerated.
      </p>
      <Button className="mt-5" onClick={() => setShowGenerateKeyModal(true)}>
        <FontAwesomeIcon icon={faPlus} /> Generate New Key
      </Button>
      <a
        className="btn btn-primary mt-5 ml-4"
        href="https://apps.elgato.com/plugins/io.streamhydra.execut"
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon icon={faDownload} /> Download the Stream Deck Plugin
      </a>

      {loading ? (
        <Spinner />
      ) : keys.length <= 0 ? (
        <p className="mt-5">
          No keys found, click the button above to create a new one!
        </p>
      ) : (
        <Card className="mt-5">
          <Table striped>
            <thead>
              <tr>
                <td>Name</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {keys.map((key) => (
                <tr key={key.id}>
                  <td>
                    {key.applet}{" "}
                    <Badge variant="success" className="ml-3">
                      Active
                    </Badge>
                  </td>
                  <td className="text-right">
                    <Button
                      size="sm"
                      variant="success"
                      onClick={() => setShowRegenConfirmModal(key)}
                    >
                      <FontAwesomeIcon icon={faRedo} /> Regenerate
                    </Button>
                    <Button
                      className="ml-3"
                      size="sm"
                      onClick={() => setShowDeleteModal(key)}
                    >
                      <FontAwesomeIcon icon={faTrash} /> Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      )}

      {showGenerateKeyModal && (
        <GenerateKeyModal
          onClose={() => setShowGenerateKeyModal(false)}
          onAdd={(key) => setKeys((keys) => [...keys, key])}
        />
      )}
      {showDeleteModal && (
        <ConfirmationModal
          title="Delete Key"
          body="Are you sure you want to permanently delete this key? This cannot be undone and any stream deck actions using this key will no longer work!"
          onClose={() => setShowDeleteModal(undefined)}
          onConfirm={deleteKey}
        />
      )}
      {showRegenConfirmModal && (
        <RegenerateKeyModal
          keyId={showRegenConfirmModal.id}
          onClose={() => setShowRegenConfirmModal(undefined)}
        />
      )}
    </Container>
  );
}

import axios from "axios";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { APIUrl, AuthUrl } from "../../Constants";

type PasswordResetModalProps = {
  show: boolean;
  onHide: () => void;
  mfa: boolean;
};

type ChangePassword = {
  oldPassword: string;
  newPassword: string;
  newPassword2: string;
  passwordIncorrect: boolean;
};

export function PasswordResetModal(props: PasswordResetModalProps) {
  const [passwordData, setPasswordData] = useState<ChangePassword>({
    oldPassword: "",
    newPassword: "",
    newPassword2: "",
    passwordIncorrect: false,
  });
  const [showPasswordErrors, setShowPasswordErrors] = useState(false);
  const [mfaCode, setMfaCode] = useState<string>();
  const [mfaError, setMfaError] = useState(false);

  async function ChangePassword() {
    try {
      setShowPasswordErrors(false);
      setMfaError(false);
      let code = mfaCode ? mfaCode.replace(" ", "") : null;
      if (props.mfa && (!code || code.length < 6 || code.length > 6)) {
        setMfaError(true);
        return;
      }
      if (
        passwordData &&
        passwordData.oldPassword &&
        passwordData.newPassword &&
        passwordData.newPassword2 &&
        passwordData.newPassword === passwordData.newPassword2
      ) {
        const response = await axios.post(`${APIUrl}/user/change-password`, {
          oldPassword: passwordData.oldPassword,
          newPassword: passwordData.newPassword,
          code: code,
        });
        if (response && response.data === false) {
          let newData = passwordData;
          newData.passwordIncorrect = true;
          setPasswordData(newData);
          setShowPasswordErrors(true);
        } else {
          props.onHide();
        }
      } else {
        setShowPasswordErrors(true);
      }
    } catch (error) {
      setMfaError(true);
      setShowPasswordErrors(true);
      console.log(error);
    }
  }

  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.onHide();
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Change Password</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Please enter your old password</Form.Label>
          <Form.Control
            type="password"
            placeholder={"Old Password"}
            onFocus={() => {
              let newData = { ...passwordData };
              newData.passwordIncorrect = false;
              setPasswordData(newData);
            }}
            isInvalid={
              showPasswordErrors &&
              (passwordData.oldPassword === "" ||
                passwordData.passwordIncorrect)
            }
            onChange={(e) => {
              let newData = { ...passwordData };
              newData.oldPassword = e.target.value;
              setPasswordData(newData);
            }}
          />
          {showPasswordErrors && passwordData.passwordIncorrect ? (
            <Form.Label className="form-error-text">
              Password incorrect
            </Form.Label>
          ) : null}
          <br />
          <Form.Label>Please enter your new password</Form.Label>
          <Form.Control
            type="password"
            placeholder={"New Password"}
            isInvalid={
              showPasswordErrors &&
              (passwordData.newPassword === "" ||
                passwordData.newPassword !== passwordData.newPassword2)
            }
            onChange={(e) => {
              let newData = { ...passwordData };
              newData.newPassword = e.target.value;
              setPasswordData(newData);
            }}
          />
          {showPasswordErrors &&
          passwordData.newPassword &&
          passwordData.newPassword !== passwordData.newPassword2 ? (
            <Form.Label className="form-error-text">
              New passwords don't match
            </Form.Label>
          ) : null}
          <br />
          <Form.Label>Please re-enter your new password</Form.Label>
          <Form.Control
            type="password"
            placeholder={"Retype New Password"}
            isInvalid={
              showPasswordErrors &&
              (passwordData.newPassword2 === "" ||
                passwordData.newPassword !== passwordData.newPassword2)
            }
            onChange={(e) => {
              let newData = { ...passwordData };
              newData.newPassword2 = e.target.value;
              setPasswordData(newData);
            }}
          />
          {showPasswordErrors &&
          passwordData.newPassword2 &&
          passwordData.newPassword !== passwordData.newPassword2 ? (
            <Form.Label className="form-error-text">
              New passwords don't match
            </Form.Label>
          ) : null}
          <br />
          {props.mfa ? (
            <>
              <Form.Label>
                Please enter the code from your authenticator app
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={"6 Digit Code"}
                isInvalid={mfaError}
                onFocus={() => {
                  setMfaError(false);
                }}
                onChange={(e) => {
                  setMfaCode(e.target.value);
                }}
              />
              {mfaError ? (
                <Form.Label className="form-error-text">
                  Code Invalid
                </Form.Label>
              ) : null}
              <br />
              <a
                className="mt-2"
                href={`${AuthUrl}/account/mfa/forgot?returnUrl=${window.location}`}
              >
                Can't access your code? Recover 2 factor here
              </a>
            </>
          ) : null}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-primary"
          onClick={() => {
            setShowPasswordErrors(false);
            setMfaError(false);
            props.onHide();
          }}
          style={{ minWidth: 100 }}
        >
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            ChangePassword();
          }}
          style={{ minWidth: 100 }}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

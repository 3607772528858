import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NodeInstance, NodeOption } from "../../../../../types";
import { getIconFor } from "../../../../shared/utils";

type NodeProps = {
  node: NodeInstance;
  selected: boolean;
  onContextMenu: (e: React.MouseEvent<SVGGElement, MouseEvent>) => void;
  errorMessage?: string;
  nodeWidth: (node: NodeOption) => number;
  nodeHeight: () => number;
  calculateTextWidth: (text: string) => number;
  gridSize: number;
};

export function Node({
  node,
  selected,
  onContextMenu,
  errorMessage,
  nodeWidth,
  nodeHeight,
  calculateTextWidth,
  gridSize,
}: NodeProps) {
  const infoLine = null;

  if (node.node.id === "core_label") {
    const label = node.data["label"] as string;

    return (
      <g onContextMenu={onContextMenu}>
        <rect
          x={node.x}
          y={node.y}
          width={calculateTextWidth(label)}
          height={gridSize}
          className={`node ${
            errorMessage ? "stroke-primary" : selected ? "stroke-color" : ""
          }`}
        />
        <FontAwesomeIcon
          icon={getIconFor(node.node.type)}
          x={node.x + 5}
          y={node.y + 5}
          width={15}
          height={15}
        />
        <text x={node.x + 25} y={node.y + 18} fontSize={16}>
          {label}
        </text>
      </g>
    );
  }

  return (
    <g onContextMenu={onContextMenu}>
      <rect
        x={node.x}
        y={node.y}
        width={nodeWidth(node.node)}
        height={nodeHeight()}
        strokeWidth={2}
        className={`node ${
          errorMessage
            ? "stroke-danger"
            : selected
            ? "stroke-color"
            : node.requiresSetup
            ? "stroke-primary"
            : ""
        }`}
      />
      <FontAwesomeIcon
        icon={getIconFor(node.node.type)}
        x={node.x + (node.node.input ? 60 : 20)}
        y={node.y + nodeHeight() / 2 - (infoLine ? 32 : 25)}
        width={50}
        height={50}
      />
      <text
        x={node.x + (node.node.input ? 130 : 90)}
        y={node.y + (infoLine ? 30 : 35)}
        fontSize={14}
      >
        {node.node.module}
      </text>
      <text
        x={node.x + (node.node.input ? 128 : 88)}
        y={node.y + (infoLine ? 50 : 55)}
        fontSize={20}
      >
        {node.node.name}
      </text>
      {infoLine && (
        <text x={node.x + 20} y={node.y + 70} fontSize={10}>
          {infoLine}
        </text>
      )}
      {errorMessage && (
        <g>
          <text x={node.x} y={node.y - 10}>
            {errorMessage}
          </text>
        </g>
      )}
    </g>
  );
}

import { faBolt, faChevronRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import {
  Module,
  ModuleCategory,
  NodeOption,
  SharingData,
  Team,
} from "../../types";
import * as _ from "underscore";
import axios from "axios";
import { APIUrl } from "../../Constants";
import { Spinner } from "../shared/Spinner";
import { useModules } from "../../context/ModulesContext";
import { TriggerModal } from "./Modals/TriggerModal";
import { ConfirmationModal } from "../shared/modals/ConfirmationModal";

type TriggerSharingProps = {
  team: Team;
};

export function TriggerSharing({ team }: TriggerSharingProps) {
  const [category, setCategory] = useState<ModuleCategory>();
  const [module, setModule] = useState<Module>();
  const [loading, setLoading] = useState(true);
  const [loadingNodes, setLoadingNodes] = useState(false);
  const [saving, setSaving] = useState(false);
  const [nodes, setNodes] = useState<NodeOption[]>([]);
  const [node, setNode] = useState<NodeOption>();
  const [lastNode, setLastNode] = useState<NodeOption>();
  const [sharing, setSharing] = useState<SharingData>({});
  const [shareAll, setShareAll] = useState(false);
  const [loadedShareAll, setLoadedShareAll] = useState(false);
  const [triggerModal, setTriggerModal] = useState(false);
  const [unshareConfirm, setUnshareConfirm] = useState(false);
  const { modules } = useModules();

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    async function load() {
      let response = await axios.get(`${APIUrl}/teams/${team.id}/triggers`);
      if (response && response.data) {
        setSharing(response.data);
      }

      response = await axios.get(`${APIUrl}/teams/${team.id}/triggers/all`);
      if (response) {
        setShareAll(response.data);
        setLoadedShareAll(response.data);
      }
      setLoading(false);
    }
    load();
  }, [team.id]);

  useEffect(() => {
    async function loadNodes() {
      if (module) {
        setLoadingNodes(true);
        const response = await axios.get(
          `${APIUrl}/node/shareable/module/${module.id}`
        );
        setNodes(response.data);
        setLoadingNodes(false);
      }
    }
    loadNodes();
    setLastNode(undefined);
  }, [module]);

  useEffect(() => {
    if (ref && ref.current) {
      if (!lastNode) {
        ref.current.scrollIntoView({ behavior: "smooth" });
      }

      setLastNode(node);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, category, module, node]);

  useEffect(() => {
    if (shareAll) {
      setCategory(undefined);
      setModule(undefined);
      setNodes([]);
      setNode(undefined);
    }
  }, [shareAll]);

  function getValue(module: string, trigger: string, variable: string) {
    let sharedModule = sharing[module];

    if (!sharedModule) {
      return false;
    }

    let sharedTriggers = sharedModule.sharedTriggers[trigger];

    if (!sharedTriggers) {
      return false;
    }

    return sharedTriggers.indexOf(variable) > -1;
  }

  function setValue(
    module: string,
    trigger: string,
    variable: string,
    value: boolean
  ) {
    let _sharing = { ...sharing };

    if (value) {
      if (!_sharing[module]) {
        _sharing[module] = {
          sharedTriggers: { [trigger]: [variable] },
        };
      } else if (!_sharing[module].sharedTriggers) {
        _sharing[module] = {
          ..._sharing[module],
          sharedTriggers: { [trigger]: [variable] },
        };
      } else if (!_sharing[module].sharedTriggers[trigger]) {
        _sharing[module].sharedTriggers = {
          ..._sharing[module].sharedTriggers,
          [trigger]: [variable],
        };
      } else if (
        _sharing[module].sharedTriggers[trigger].indexOf(variable) === -1
      ) {
        _sharing[module].sharedTriggers[trigger].push(variable);
      }
    } else {
      if (
        _sharing[module] &&
        _sharing[module].sharedTriggers &&
        _sharing[module].sharedTriggers[trigger] &&
        _sharing[module].sharedTriggers[trigger].indexOf(variable) > -1
      ) {
        _sharing[module].sharedTriggers[trigger].splice(
          _sharing[module].sharedTriggers[trigger].indexOf(variable),
          1
        );
      }
    }

    setSharing(_sharing);
  }

  async function save() {
    setSaving(true);
    if (shareAll !== loadedShareAll) {
      await axios.post(
        `${APIUrl}/teams/${team.id}/triggers/all?toggle=${shareAll}`
      );
    }

    await axios.post(`${APIUrl}/teams/${team.id}/triggers`, {
      teamId: team.id,
      sharedModules: sharing,
    });
    setSaving(false);
  }

  async function setAll(node: NodeOption, value: boolean) {
    let _sharing = { ...sharing };
    let module = node.module;
    let trigger = node.id;

    if (value) {
      let list: string[] = [];
      node.outputs.map((out) =>
        Object.keys(out.data).map((data) => list.push(data))
      );

      if (!_sharing[module]) {
        _sharing[module] = {
          sharedTriggers: { [trigger]: list },
        };
      } else if (!_sharing[module].sharedTriggers) {
        _sharing[module] = {
          ..._sharing[module],
          sharedTriggers: { [trigger]: list },
        };
      } else if (!_sharing[module].sharedTriggers[trigger]) {
        _sharing[module].sharedTriggers = {
          ..._sharing[module].sharedTriggers,
          [trigger]: list,
        };
      } else {
        _sharing[module].sharedTriggers[trigger] = list;
      }
    } else {
      if (
        _sharing[module] &&
        _sharing[module].sharedTriggers &&
        _sharing[module].sharedTriggers[trigger]
      ) {
        delete _sharing[module].sharedTriggers[trigger];
      }
    }

    setSharing(_sharing);
  }

  async function unshareAllTriggers() {
    setCategory(undefined);
    setModule(undefined);
    setNodes([]);
    setNode(undefined);

    await axios.delete(`${APIUrl}/teams/${team.id}/triggers/all`);

    setSharing({});
    setShareAll(false);
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <Card>
        <Card.Header>
          <div className="d-flex align-items-center">
            <div className="d-flex align-items-center flex-grow-1">
              <div className="mr-3">
                <FontAwesomeIcon icon={faBolt} size="2x" />
              </div>
              <div>
                <h4 className="mb-0">Trigger Sharing</h4>
                <p className="mb-0">
                  Select which triggers (and data) you share with your team
                </p>
              </div>
            </div>
            <Button size="sm" onClick={() => setUnshareConfirm(true)}>
              Unshare All Triggers
            </Button>
          </div>
        </Card.Header>
        <Card.Body className="trigger-sharing">
          <div className="trigger-flex-container">
            <div className="trigger-sharing-column">
              <p className="trigger-sharing-column-title">Module Category</p>
              <ul>
                {_.unique(
                  modules.filter((x) => x.enabled).map((x) => x.category)
                ).map((c) => (
                  <li
                    key={c}
                    onClick={() => {
                      setCategory(c);
                      setModule(undefined);
                      setNode(undefined);
                    }}
                    className={category && category === c ? "active" : ""}
                  >
                    {c.replace(/([A-Z])/g, " $1")}
                    {category && category === c && (
                      <FontAwesomeIcon
                        className="chevron"
                        icon={faChevronRight}
                      />
                    )}
                  </li>
                ))}
              </ul>
            </div>

            <div className="trigger-sharing-column">
              <p className="trigger-sharing-column-title">Module</p>
              {category && (
                <ul>
                  {modules
                    .filter((x) => x.enabled && x.category === category)
                    .map((m) => (
                      <li
                        key={m.id}
                        onClick={() => {
                          setModule(m);
                          setNode(undefined);
                        }}
                        className={module && module.id === m.id ? "active" : ""}
                      >
                        {m.name}
                        {module && module.id === m.id && (
                          <FontAwesomeIcon
                            className="chevron"
                            icon={faChevronRight}
                          />
                        )}
                      </li>
                    ))}
                </ul>
              )}
            </div>

            <div className="trigger-sharing-column">
              <p className="trigger-sharing-column-title">Trigger</p>
              {category && module && (
                <ul>
                  {loadingNodes ? (
                    <li>
                      <Spinner size="sm" />
                    </li>
                  ) : nodes.length > 0 ? (
                    nodes.map((n) => (
                      <li
                        key={n.id}
                        onClick={() => setNode(n)}
                        className={node && node.id === n.id ? "active" : ""}
                      >
                        {n.name}
                        {node && node.id === n.id && (
                          <FontAwesomeIcon
                            className="chevron"
                            icon={faChevronRight}
                          />
                        )}
                      </li>
                    ))
                  ) : (
                    <p className="p-1">
                      <small>This node has no triggers.</small>
                    </p>
                  )}
                </ul>
              )}
            </div>

            <div className="trigger-sharing-column">
              <p className="trigger-sharing-column-title">Settings</p>
              {category && module && node && (
                <div className="trigger-sharing-settings">
                  <h5>{node.name}</h5>
                  <p>{node.description}</p>
                  <p className="mt-3">
                    Select which data to share with your team:
                  </p>
                  <div className="mb-3">
                    <Button
                      variant="primary"
                      size="sm"
                      className="mr-2"
                      onClick={() => setAll(node, true)}
                    >
                      Select All
                    </Button>
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={() => setAll(node, false)}
                    >
                      Deselect All
                    </Button>
                  </div>
                  {node.outputs.map((out) =>
                    Object.keys(out.data).map((data) => (
                      <div className="d-flex align-items-center" key={data}>
                        <Form.Check
                          type="switch"
                          id={data}
                          checked={getValue(module.id, node.id, data)}
                          onChange={(e) =>
                            setValue(module.id, node.id, data, e.target.checked)
                          }
                        />
                        <div className="my-2">
                          <p className="mb-0">
                            {"{"}
                            {data}
                            {"}"} | {out.data[data].type}
                          </p>
                          <p className="mb-0">{out.data[data].description}</p>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              )}
            </div>

            <div ref={ref}></div>

            {shareAll && (
              <div className="trigger-all-notice">
                <p>All triggers are shared!</p>
              </div>
            )}
          </div>

          <div className="trigger-sharing-footer">
            <div className="d-flex">
              <div className="flex-grow-1">
                <Form.Check
                  type="switch"
                  id="all-triggers"
                  label="Share all triggers with team (not recommended)"
                  checked={shareAll}
                  onChange={(e) => setShareAll(e.target.checked)}
                />
              </div>

              <Button
                size="sm"
                className="mr-2"
                variant="outline-primary"
                onClick={() => setTriggerModal(true)}
              >
                Who is using your triggers?
              </Button>
              <Button size="sm" onClick={save} disabled={saving}>
                {saving ? "Saving..." : "Save Changes"}
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
      {triggerModal && <TriggerModal onClose={() => setTriggerModal(false)} />}
      {unshareConfirm && (
        <ConfirmationModal
          title="Unshare all Triggers"
          body="Are you sure you want to unshare all your triggers with this team?"
          onClose={() => setUnshareConfirm(false)}
          onConfirm={() => {
            unshareAllTriggers();
            setUnshareConfirm(false);
          }}
        />
      )}
    </>
  );
}

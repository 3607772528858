import {
  EditorElement,
  LinkInstance,
  NodeInstance,
} from "../../../../../../types";
import { elements } from "../../../ConfigEditor/elements";
import { InputElementWrapper } from "../InputElementWrapper";

type ObjectEditorProps = {
  node: NodeInstance;
  element: EditorElement;
  nodes: NodeInstance[];
  links: LinkInstance[];
  data: {
    [key: string]: unknown;
  };
  setData: (_data: { [key: string]: unknown }) => void;
  errors: {
    [name: string]: string | null;
  };
  setErrors: (errors: { [name: string]: string | null }) => void;
  showErrors: boolean;
  parents: string[];
};

export function ObjectEditor({
  node,
  element,
  nodes,
  links,
  data,
  setData,
  errors,
  setErrors,
  showErrors,
  parents,
}: ObjectEditorProps) {
  return (
    <div style={{ marginLeft: parents.length * 20 }}>
      <p>{element.label}</p>
      {(element.options as EditorElement[]).map((el) => {
        if (typeof elements[el.type] !== "undefined") {
          return (
            <InputElementWrapper
              key={el.id}
              node={node}
              nodes={nodes}
              links={links}
              data={data}
              setData={setData}
              errors={errors}
              setErrors={setErrors}
              showErrors={showErrors}
              element={el}
              parents={[] as string[]}
            />
          );
        } else {
          return null;
        }
      })}
    </div>
  );
}

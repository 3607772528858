import {
  faCheck,
  faCogs,
  faEnvelope,
  faPlus,
  faVials,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Card, Col, Form, ListGroup, Row } from "react-bootstrap";
import { APIUrl } from "../../Constants";
import { ConfirmationModal } from "../shared/modals/ConfirmationModal";
import { Spinner } from "../shared/Spinner";

export function Config() {
  const [loading, setLoading] = useState(true);
  const [twitchListenersNumber, setTwitchListenersNumber] = useState(0);
  const [patreonListenersNumber, setPatreonListenersNumber] = useState(0);
  const [twitchListenersRavenNumber, setTwitchListenersRavenNumber] =
    useState(0);
  const [patreonListenersRavenNumber, setPatreonListenersRavenNumber] =
    useState(0);
  const [testers, setTesters] = useState<{ email: string; active: boolean }[]>(
    []
  );
  const [testerEmail, setTesterEmail] = useState<string>("");
  const [testMode, setTestMode] = useState<boolean>();
  const [showTwitchResetModal, setShowTwitchResetModal] = useState(false);
  const [showPatreonResetModal, setShowPatreonResetModal] = useState(false);
  const [changeLogVersion, setChangeLogVersion] = useState(0);
  const [minecraftPluginVersion, setMinecraftPluginVersion] = useState(0);

  useEffect(() => {
    async function load() {
      await GetTwitchListeners();
      await GetTesters();
      await GetTestMode();
      await GetTwitchRavenListeners();
      await GetPatreonListeners();
      await GetPatreonRavenListeners();
      await GetChangeLogVersion();
      await getMinecraftPluginVersion();
      setLoading(false);
    }
    load();
  }, []);

  async function GetTestMode() {
    try {
      const response = await axios.get(`${APIUrl}/admin/testmode`);
      if (response && response.data) {
        setTestMode(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function GetTwitchListeners() {
    try {
      const response = await axios.get(`${APIUrl}/twitch/subscribe`);
      if (response && response.data) {
        setTwitchListenersNumber(response.data.total);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function GetTwitchRavenListeners() {
    try {
      const response = await axios.get(`${APIUrl}/admin/twitchlisteners/raven`);
      if (response && response.data) {
        setTwitchListenersRavenNumber(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function GetPatreonListeners() {
    try {
      const response = await axios.get(`${APIUrl}/patreon/subscribe`);
      if (response && response.data) {
        setPatreonListenersNumber(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function GetPatreonRavenListeners() {
    try {
      const response = await axios.get(
        `${APIUrl}/admin/patreonlisteners/raven`
      );
      if (response && response.data) {
        setPatreonListenersRavenNumber(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function ResetTwitchListeners() {
    try {
      await axios.post(`${APIUrl}/admin/twitch-reset`);
    } catch (error) {
      console.log(error);
    }
  }

  async function ResetPatreonListeners() {
    try {
      await axios.post(`${APIUrl}/admin/patreon-reset`);
    } catch (error) {
      console.log(error);
    }
  }

  async function GetTesters() {
    try {
      const response = await axios.get(`${APIUrl}/admin/testers`);
      if (response && response.data) {
        setTesters(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function AddTester() {
    try {
      if (
        testerEmail &&
        testerEmail !== "" &&
        testers.findIndex((item) => (item.email === testerEmail) === null)
      ) {
        await axios.post(`${APIUrl}/admin/testers?email=${testerEmail}`);

        var array = testers;
        array.push({ email: testerEmail, active: false });
        setTesters([...array]);
        setTesterEmail("");
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function DeleteTester(email: string) {
    try {
      if (email && email !== "") {
        await axios.delete(`${APIUrl}/admin/testers?email=${email}`);
        var array = testers.filter((x) => x.email !== email);
        setTesters([...array]);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function DeleteAllTesters() {
    try {
      await axios.delete(`${APIUrl}/admin/testers/all`);
      setTesters([]);
    } catch (error) {
      console.log(error);
    }
  }
  async function ToggleTestMode(toggle: boolean) {
    try {
      await axios.post(`${APIUrl}/admin/testmode?mode=${toggle}`);
      setTestMode(toggle);
    } catch (error) {
      console.log(error);
    }
  }

  async function TestButton() {
    try {
      const response = await axios.get(`${APIUrl}/admin/testbutton1`);
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function GetChangeLogVersion() {
    try {
      const response = await axios.get(`${APIUrl}/admin/changelog-version`);
      console.log(response.data);
      setChangeLogVersion(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function UpdateChangeLogVersion() {
    try {
      await axios.post(`${APIUrl}/admin/update-changelog-version`);
      setChangeLogVersion(changeLogVersion + 1);
    } catch (error) {
      console.log(error);
    }
  }

  async function reloadTags() {
    try {
      await axios.get(`${APIUrl}/admin/twitch-reset-tags`);
    } catch (error) {
      console.log(error);
    }
  }

  async function getMinecraftPluginVersion() {
    try {
      const response = await axios.get(
        `${APIUrl}/minecraft/latest-plugin-version`
      );
      setMinecraftPluginVersion(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function incrementMinecraftPluginVersion() {
    try {
      await axios.post(`${APIUrl}/admin/minecraft/increment-plugin-version`);
      setMinecraftPluginVersion(minecraftPluginVersion + 1);
    } catch (error) {
      console.log(error);
    }
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="page-content">
      <div className="d-flex mb-4">
        <div className="d-flex flex-grow-1">
          <h1>
            <FontAwesomeIcon icon={faCogs} /> App Config |{" "}
            <small>v{changeLogVersion}</small>
          </h1>
          <div className="d-flex align-items-center">
            <Button
              className="ml-3"
              size="sm"
              onClick={() => {
                UpdateChangeLogVersion();
              }}
            >
              <FontAwesomeIcon icon={faPlus} /> Increment Version Number
            </Button>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <Button
            onClick={() => {
              TestButton();
            }}
          >
            <FontAwesomeIcon icon={faVials} /> Test Button
          </Button>
        </div>
      </div>
      {showTwitchResetModal ? (
        <ConfirmationModal
          title={"Confirm Twitch Listener Reset"}
          body={
            "Are you sure you want to do this? This will cause applets to break if they are currently being used."
          }
          onConfirm={() => {
            ResetTwitchListeners();
            setShowTwitchResetModal(false);
          }}
          onClose={() => {
            setShowTwitchResetModal(false);
          }}
        />
      ) : null}
      {showPatreonResetModal ? (
        <ConfirmationModal
          title={"Confirm Patreon Listener Reset"}
          body={
            "Are you sure you want to do this? This will cause applets to break if they are currently being used."
          }
          onConfirm={() => {
            ResetPatreonListeners();
            setShowPatreonResetModal(false);
          }}
          onClose={() => {
            setShowPatreonResetModal(false);
          }}
        />
      ) : null}
      <Row>
        <Col lg={4}>
          <Card>
            <Card.Header>
              <Card.Title className="mb-0">
                Maintenance Mode{" "}
                <Form.Check
                  id="testmode"
                  type="switch"
                  custom
                  checked={testMode}
                  className={"float-right"}
                  onChange={(e) => {
                    ToggleTestMode(e.target.checked);
                  }}
                />
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Form.Group>
                <Form.Label>Invite Admins</Form.Label>
                <Form.Control
                  placeholder="Email"
                  type="email"
                  onChange={(e) => {
                    setTesterEmail(e.target.value);
                  }}
                  value={testerEmail}
                />
                <Button className="mt-3 ml-1" onClick={AddTester}>
                  Add
                </Button>
                <Button className="mt-3 ml-2" onClick={DeleteAllTesters}>
                  Remove All Admins
                </Button>
              </Form.Group>
              <Form.Group>
                <Form.Label>Approved Admins</Form.Label>
                <ListGroup className="mt-2">
                  {testers.length === 0 ? (
                    <ListGroup.Item className="tester-item">
                      No Testers
                    </ListGroup.Item>
                  ) : (
                    testers.map((item) => {
                      return (
                        <ListGroup.Item className="d-flex" key={item.email}>
                          <h6 style={{ flex: 1 }}>{item.email}</h6>
                          <FontAwesomeIcon
                            icon={item.active ? faCheck : faEnvelope}
                            color={item.active ? "green" : "orange"}
                            size={"lg"}
                            style={{ cursor: "pointer" }}
                            onClick={() => DeleteTester(item.email)}
                          />
                        </ListGroup.Item>
                      );
                    })
                  )}
                </ListGroup>
              </Form.Group>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={4}>
          <Card>
            <Card.Header>
              <Card.Title>Twitch</Card.Title>
            </Card.Header>
            <Card.Body>
              <Form.Group>
                <p>
                  Number of active twitch listeners: {twitchListenersNumber}
                </p>
                <p>
                  Number of active twitch listeners stored in database:{" "}
                  {twitchListenersRavenNumber}
                </p>
              </Form.Group>
              <Button onClick={reloadTags}>Reload Tags</Button>
            </Card.Body>
            <Card.Footer>
              <Button
                onClick={() => {
                  setShowTwitchResetModal(true);
                }}
              >
                Reset Twitch Listeners
              </Button>
            </Card.Footer>
          </Card>
          <Card className="mt-4">
            <Card.Header>
              <Card.Title>Patreon</Card.Title>
            </Card.Header>
            <Card.Body>
              <Form.Group>
                <p>
                  Number of active patreon listeners: {patreonListenersNumber}
                </p>
                <p>
                  Number of active patreon listeners stored in database:{" "}
                  {patreonListenersRavenNumber}
                </p>
              </Form.Group>
            </Card.Body>
            <Card.Footer>
              <Button
                onClick={() => {
                  setShowPatreonResetModal(true);
                }}
              >
                Reset Patreon Listeners
              </Button>
            </Card.Footer>
          </Card>
        </Col>
        <Col lg={4}>
          <Card>
            <Card.Header>
              <Card.Title>Minecraft</Card.Title>
            </Card.Header>
            <Card.Body>
              <Form.Group>
                <p>Current Plugin Version Number: {minecraftPluginVersion}</p>
              </Form.Group>
            </Card.Body>
            <Card.Footer>
              <Button onClick={incrementMinecraftPluginVersion}>
                Increment Plugin Version
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

import {
  faCog,
  faPeopleArrows,
  faUserShield,
  faUserSlash,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import { Badge, Dropdown, Table } from "react-bootstrap";
import { APIUrl } from "../../../Constants";
import { Paged, Team, TeamMember, TeamMemberRole } from "../../../types";
import { ConfirmationModal } from "../../shared/modals/ConfirmationModal";
import { Paginator } from "../../shared/Paginator";
import { Spinner } from "../../shared/Spinner";
import { getTeamMemberBadgeVariant } from "../../shared/utils";

type ActiveMembersProps = {
  team: Team;
  setTeam: (team: Team) => void;
  setActiveUser: (user: TeamMember) => void;
};

export function ActiveMembers({
  team,
  setActiveUser,
  setTeam,
}: ActiveMembersProps) {
  const [loading, setLoading] = useState(true);
  const [members, setMembers] = useState<Paged<TeamMember>>({
    page: 1,
    pageSize: 1,
    results: [],
    totalResults: 0,
  });
  const [page, setPage] = useState(1);
  const [banConfirm, setBanConfirm] = useState<TeamMember>();
  const [promoteConfirm, setPromoteConfirm] = useState<TeamMember>();
  const [transferConfirm, setTransferConfirm] = useState<TeamMember>();

  useEffect(() => {
    let cancelled = false;

    async function getMembers() {
      setLoading(true);

      const response = await axios.get(
        `${APIUrl}/teams/${team.id}/member?page=${page}`
      );
      console.log(response.data);
      if (!cancelled) {
        setMembers(response.data);
        setLoading(false);
      }
    }
    getMembers();

    return () => {
      cancelled = true;
    };
  }, [page, team.id]);

  if (loading || !members) {
    return <Spinner />;
  }

  async function rejectUser(member: TeamMember) {
    setLoading(true);
    const response = await axios.delete(
      `${APIUrl}/teams/${team.id}/member?userId=${member.userId}`
    );
    if (response.data === true) {
      setMembers({
        ...members,
        results: [...members.results.filter((x) => x.userId !== member.userId)],
      });
      if (member.isFree) {
        setTeam({
          ...team,
          currentUsedFreeSlots: team.currentUsedFreeSlots - 1,
        });
      }
    }
    setLoading(false);
  }

  async function promoteUser(user: TeamMember) {
    setLoading(true);

    await axios.patch(
      `${APIUrl}/teams/${team.id}/member?memberId=${user.id}&role=${
        user.teamRole === "MOD" ? "MEMBER" : "MOD"
      }`
    );
    setMembers({
      ...members,
      results: [
        ...members.results.map((x) =>
          x.id === user.id
            ? {
                ...x,
                teamRole:
                  user.teamRole === "MOD"
                    ? "MEMBER"
                    : ("MOD" as TeamMemberRole),
              }
            : x
        ),
      ],
    });

    setLoading(false);
  }

  async function transferOwnership(user: TeamMember) {
    setLoading(true);

    if (team.activeUser) {
      await axios.post(
        `${APIUrl}/teams/${team.id}/transfer-ownership?userId=${user.userId}`
      );

      setMembers({
        ...members,
        results: [
          ...members.results.map((x) =>
            x.id === user.id
              ? { ...x, role: "ADMIN" as TeamMemberRole }
              : x.userId === team.activeUser!.id
              ? { ...x, role: "MOD" as TeamMemberRole }
              : x
          ),
        ],
      });

      setActiveUser({ ...team.activeUser, teamRole: "MOD" });
    }

    setLoading(false);
  }

  return (
    <>
      <Table striped>
        <thead>
          <tr>
            <th>Name</th>
            <th>Rank</th>
            <th>Shared Triggers</th>
            {(team.activeUser!.teamRole === "ADMIN" ||
              team.activeUser!.teamRole === "MOD") && <th>Actions</th>}
          </tr>
        </thead>
        {loading ? (
          <Spinner size="sm" />
        ) : (
          <tbody>
            {members.results.map((member) => (
              <tr key={member.userId}>
                <td>
                  <div className="d-flex">{member.name}</div>
                </td>
                <td>
                  <Badge variant={getTeamMemberBadgeVariant(member.teamRole)}>
                    {member.teamRole}
                  </Badge>
                </td>
                <td>{member.sharedTriggers}</td>
                {(team.activeUser!.teamRole === "ADMIN" ||
                  team.activeUser!.teamRole === "MOD") && (
                  <td>
                    {member.teamRole !== "ADMIN" &&
                      !(
                        team.activeUser!.teamRole === "MOD" &&
                        member.teamRole === "MOD"
                      ) && (
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="primary"
                            id="dropdown-basic"
                            size="sm"
                            className="dropdown-no-chevron mr-2 float-right"
                          >
                            <FontAwesomeIcon icon={faCog} />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {team.activeUser!.teamRole === "ADMIN" && (
                              <Dropdown.Item
                                onClick={() => setPromoteConfirm(member)}
                              >
                                <FontAwesomeIcon icon={faUserShield} />{" "}
                                {member.teamRole === "MOD"
                                  ? "Demote"
                                  : "Promote"}
                              </Dropdown.Item>
                            )}
                            <Dropdown.Item
                              onClick={() => setBanConfirm(member)}
                            >
                              <FontAwesomeIcon icon={faUserSlash} /> Ban
                            </Dropdown.Item>
                            {team.activeUser!.teamRole === "ADMIN" &&
                              member.teamRole === "MOD" && (
                                <>
                                  <Dropdown.Divider />
                                  <Dropdown.Item
                                    onClick={() => setTransferConfirm(member)}
                                  >
                                    <FontAwesomeIcon icon={faPeopleArrows} />{" "}
                                    Transfer Ownership
                                  </Dropdown.Item>
                                </>
                              )}
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        )}
      </Table>
      <div className="my-3 d-flex justify-content-center">
        <Paginator
          totalResults={members.totalResults}
          pageSize={members.pageSize}
          page={page}
          setPage={setPage}
        />
      </div>
      {banConfirm && (
        <ConfirmationModal
          title="Ban User"
          body={`Are you sure you want to ban ${banConfirm.name}?`}
          onClose={() => setBanConfirm(undefined)}
          onConfirm={() => {
            rejectUser(banConfirm);
            setBanConfirm(undefined);
          }}
        />
      )}
      {promoteConfirm && (
        <ConfirmationModal
          title={
            promoteConfirm.teamRole === "MOD" ? "Demote User" : "Promote User"
          }
          body={`Are you sure you want to ${
            promoteConfirm.teamRole === "MOD" ? "demote" : "promote"
          } ${promoteConfirm.name} to ${
            promoteConfirm.teamRole === "MOD" ? "member" : "moderator"
          }?`}
          onClose={() => setPromoteConfirm(undefined)}
          onConfirm={() => {
            promoteUser(promoteConfirm);
            setPromoteConfirm(undefined);
          }}
        />
      )}
      {transferConfirm && (
        <ConfirmationModal
          title="Transfer Ownership"
          body={`Are you sure you want to transfer ownership of this team to ${transferConfirm.name}? They will replace you as the ADMIN of this team and you will be demoted to MOD.`}
          onClose={() => setTransferConfirm(undefined)}
          onConfirm={() => {
            transferOwnership(transferConfirm);
            setTransferConfirm(undefined);
          }}
        />
      )}
    </>
  );
}

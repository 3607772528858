import axios from "axios";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { APIUrl } from "../Constants";
import { Plan } from "../types";

type UserContextProps = {
  refresh: () => void;
  role: string | null;
  plan: Plan | null;
};
const UserContext = createContext<UserContextProps>({
  refresh: () => {},
  role: null,
  plan: null,
});

export function useUser() {
  return useContext(UserContext);
}

type UserProviderProps = {
  onLoad: () => void;
  children: React.ReactNode;
};

export function UserProvider({ onLoad, children }: UserProviderProps) {
  const [role, setRole] = useState(null);
  const [plan, setPlan] = useState(null);

  const refresh = useCallback(async () => {
    try {
      const response = await axios.get(`${APIUrl}/user/role`);
      if (response && response.data) {
        setRole(response.data);
      }
    } catch (error) {
      console.log(error);
    }

    try {
      const response = await axios.get(
        `${APIUrl}/statistics/current-plan-information`
      );
      if (response && response.data) {
        setPlan(response.data);
      }
    } catch (error) {
      console.log(error);
    }

    onLoad();
  }, [onLoad]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return (
    <UserContext.Provider value={{ refresh, role, plan }}>
      {children}
    </UserContext.Provider>
  );
}

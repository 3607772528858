import {
  faDownload,
  faPlus,
  faRedo,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import { Badge, Button, Card, Container, Table } from "react-bootstrap";
import { APIUrl } from "../../../Constants";
import { MinecraftServer } from "../../../types";
import { ConfirmationModal } from "../../shared/modals/ConfirmationModal";
import { Spinner } from "../../shared/Spinner";
import { AddServerModal } from "./Modal/AddServerModal";
import { RegenerateServerKeyModal } from "./Modal/RegenerateServerKeyModal";

export function Servers() {
  const [loading, setLoading] = useState(true);
  const [servers, setServers] = useState<MinecraftServer[]>([]);
  const [showAddServerModal, setShowAddServerModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState<MinecraftServer>();
  const [showRegenConfirmModal, setShowRegenConfirmModal] =
    useState<MinecraftServer>();

  useEffect(() => {
    let cancelled = false;

    async function getServers() {
      const response = await axios.get(`${APIUrl}/minecraft/server`);

      if (!cancelled) {
        setServers(response.data);
        setLoading(false);
      }
    }
    getServers();

    return () => {
      cancelled = true;
    };
  }, []);

  async function deleteServer() {
    if (showDeleteModal) {
      await axios.delete(`${APIUrl}/minecraft/server/${showDeleteModal.id}`);
      setServers((servers) =>
        servers.filter((x) => x.id !== showDeleteModal.id)
      );
    }

    setShowDeleteModal(undefined);
  }

  return (
    <Container fluid>
      <h1>Minecraft Servers</h1>
      <p>
        In order for us to connect to your minecraft server you must register it
        here, install our plugin and connect using the generated API key. Each
        server has its own key.
      </p>
      <Button onClick={() => setShowAddServerModal(true)} className="mt-5">
        <FontAwesomeIcon icon={faPlus} className="mr-2" /> Register Server
      </Button>
      <a
        href="https://www.spigotmc.org/resources/stream-hydra.95195/"
        target="_blank"
        rel="noreferrer"
        className="btn btn-primary mt-5 ml-4"
      >
        <FontAwesomeIcon icon={faDownload} className="mr-2" /> Download Spigot
        Plugin
      </a>

      {loading ? (
        <Spinner />
      ) : servers.length <= 0 ? (
        <p className="mt-5">
          No servers found, click the button above to begin registering a new
          one!
        </p>
      ) : (
        <Card className="mt-5">
          <Table striped>
            <thead>
              <tr>
                <td>Name</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {servers.map((server) => (
                <tr key={server.id}>
                  <td>
                    {server.name}
                    <Badge
                      className="ml-3"
                      variant={
                        server.status === "Online"
                          ? "success"
                          : server.status === "Offline"
                          ? "danger"
                          : "secondary"
                      }
                    >
                      {server.status}
                    </Badge>
                  </td>
                  <td className="text-right">
                    <Button
                      size="sm"
                      variant="success"
                      onClick={() => setShowRegenConfirmModal(server)}
                    >
                      <FontAwesomeIcon icon={faRedo} /> Generate New Key
                    </Button>
                    <Button
                      className="ml-3"
                      size="sm"
                      onClick={() => setShowDeleteModal(server)}
                    >
                      <FontAwesomeIcon icon={faTrash} /> Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      )}

      {showAddServerModal && (
        <AddServerModal
          onClose={() => setShowAddServerModal(false)}
          onAdd={(server) => setServers([...servers, server])}
        />
      )}
      {showDeleteModal && (
        <ConfirmationModal
          title="Delete Server"
          body="Are you sure you want to permanently delete this server? This cannot be undone and any associated keys will no longer work!"
          onClose={() => setShowDeleteModal(undefined)}
          onConfirm={deleteServer}
        />
      )}
      {showRegenConfirmModal && (
        <RegenerateServerKeyModal
          server={showRegenConfirmModal}
          onClose={() => setShowRegenConfirmModal(undefined)}
        />
      )}
    </Container>
  );
}

import {
  faBolt,
  faCheckCircle,
  faExclamationCircle,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useState } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { APIUrl } from "../../../Constants";
import { NodeInstance } from "../../../types";

type TestModalProps = {
  appletId: string;
  nodes: NodeInstance[];
  onClose: () => void;
};

type TestResult = {
  result: "passed" | "failed";
  error?: {
    nodeId: string;
    message: string;
  }[];
};

type Data = { [key: string]: string };

export function TestModal({ appletId, nodes, onClose }: TestModalProps) {
  const [trigger, setTrigger] = useState<NodeInstance>();
  const [data, setData] = useState<Data>({});
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState<TestResult>();

  async function runTest() {
    setLoading(true);
    setResult(undefined);
    try {
      const response = await axios.post(
        `${APIUrl}/applet/test/${appletId}/${trigger!.node.id}`,
        data
      );

      setResult(response.data);
    } catch (e: any) {
      console.log(e);
    }
    setLoading(false);
  }

  return (
    <Modal show={true} onHide={onClose} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>Test an Applet</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group>
            <Form.Label>Select a trigger to run</Form.Label>
            <Form.Control
              as="select"
              value={trigger?.id}
              onChange={(e) =>
                setTrigger(
                  e.target.value
                    ? nodes.find((x) => x.id === e.target.value)
                    : undefined
                )
              }
            >
              <option>(Select Trigger)</option>
              {nodes
                .filter((x) => x.node && x.node.type === "Trigger")
                .map((x) => (
                  <option key={x.id} value={x.id}>
                    {x.node.name}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>
          {trigger && (
            <>
              <p className="mt-4">
                Enter Test Data (You only need to enter data this applet uses)
              </p>
              {Object.keys(
                trigger.node.outputs.find((x) => x.id === "out")!.data
              ).map((x) => (
                <Form.Group key={x}>
                  <Form.Label>{x}</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter value"
                    value={data[x] ? data[x] : ""}
                    onChange={(e) => setData({ ...data, [x]: e.target.value })}
                  />
                </Form.Group>
              ))}
            </>
          )}
        </Form>
        {result && (
          <>
            <Alert
              className={`notification ${
                result.result === "passed" ? "success" : "danger"
              }`}
            >
              <Alert.Heading>
                <FontAwesomeIcon
                  icon={
                    result.result === "passed"
                      ? faCheckCircle
                      : faExclamationCircle
                  }
                  className={`alert-icon text-${
                    result.result === "passed" ? "success" : "danger"
                  }`}
                />
                {result.result === "passed" ? "Test Passed" : "Test Failed"}
              </Alert.Heading>
              {result.result === "passed" ? (
                "The applet completed successfully"
              ) : (
                <>
                  The applet failed for the following reasons:
                  {result.error && (
                    <ul>
                      {result.error.map((error, index) => (
                        <li key={index}>{error.message}</li>
                      ))}
                    </ul>
                  )}
                </>
              )}
            </Alert>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="success"
          disabled={!trigger || loading}
          onClick={runTest}
        >
          <FontAwesomeIcon icon={faBolt} /> Run
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

import axios from "axios";
import { useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { APIUrl, ClientUrl } from "../../../Constants";
import { Team } from "../../../types";
import { CustomAlert } from "../../shared/CustomAlert";

type CreateTeamProps = {
  onCancel: () => void;
  onCreateTeam: (team: Team) => void;
};

export function CreateTeam({ onCancel, onCreateTeam }: CreateTeamProps) {
  const fileRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File>();
  const [name, setName] = useState("");
  const [error, setError] = useState<string>();
  const [validation, setValidation] = useState<{ [name: string]: string }>({});
  const [loading, setLoading] = useState(false);

  async function save() {
    setLoading(true);

    const _errors: { [name: string]: string } = {};

    if (!name || name === "") {
      _errors["name"] = "Name is required";
    }

    if (Object.keys(_errors).length > 0) {
      setLoading(false);
      setValidation(_errors);
      return;
    }

    var formData = new FormData();

    if (file) {
      formData.append("icon", file as Blob);
    }

    formData.append("name", name);

    try {
      const response = await axios.post(`${APIUrl}/teams`, formData);
      onCreateTeam(response.data);
    } catch (e: any) {
      setError(e.response.data);
      setLoading(false);
    }
  }

  return (
    <>
      <Modal.Body>
        <div
          className="d-flex align-items-center"
          style={{ overflow: "hidden" }}
        >
          <div
            style={{ width: 80, height: 80, minWidth: 80 }}
            className="d-flex align-items-center justify-content-center border border-primary"
          >
            <img
              src={file ? URL.createObjectURL(file) : `${ClientUrl}/logo.png`}
              alt="Team Icon"
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          </div>
          <div>
            <input
              ref={fileRef}
              type="file"
              style={{ display: "none" }}
              onChange={(e) =>
                setFile(
                  e.target.files && e.target.files.length > 0
                    ? e.target.files[0]
                    : undefined
                )
              }
              accept="image/png, image/jpeg, image/jpg, image/pjpeg, image/gif, image/x-png"
            />
            <Button
              className="ml-3"
              size="sm"
              onClick={() => fileRef.current?.click()}
            >
              Upload Icon
            </Button>
            <span className="ml-2">{file && file.name}</span>
          </div>
        </div>
        <Form.Group className="mt-3">
          <Form.Label>Team Name</Form.Label>
          <Form.Control
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter a team name"
            isInvalid={!!validation["name"]}
          />
          <Form.Control.Feedback type="invalid">
            {validation["name"]}
          </Form.Control.Feedback>
        </Form.Group>
        {error && (
          <CustomAlert type="danger" title="Error">
            {error}
          </CustomAlert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex w-100">
          <div className="flex-grow-1 pr-2">
            <Button
              variant="outline-primary"
              onClick={onCancel}
              block
              disabled={loading}
            >
              Cancel
            </Button>
          </div>
          <div className="flex-grow-1 pl-2">
            <Button variant="primary" onClick={save} block disabled={loading}>
              Save
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </>
  );
}

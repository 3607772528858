import { faCheck, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import { Badge, Button, Table } from "react-bootstrap";
import { APIUrl } from "../../../Constants";
import { useNotifications } from "../../../context/NotificationContext";
import { Paged, Team, TeamMember } from "../../../types";
import { Paginator } from "../../shared/Paginator";
import { Spinner } from "../../shared/Spinner";

type PendingRequestsProps = {
  team: Team;
  setTeam: (team: Team) => void;
};

export function PendingRequests({ team, setTeam }: PendingRequestsProps) {
  const [loading, setLoading] = useState(true);
  const [invites, setInvites] = useState<Paged<TeamMember>>({
    page: 1,
    pageSize: 1,
    results: [],
    totalResults: 0,
  });
  const [page, setPage] = useState(1);
  const [loadingAction, setLoadingAction] = useState(false);
  const notifications = useNotifications();

  useEffect(() => {
    let cancelled = false;

    async function getMembers() {
      setLoading(true);

      const response = await axios.get(
        `${APIUrl}/teams/${team.id}/member/request?page=${page}`
      );
      console.log(response.data);
      if (!cancelled) {
        setInvites(response.data);
        setLoading(false);
      }
    }
    getMembers();

    return () => {
      cancelled = true;
    };
  }, [page, team.id]);

  async function accept(member: TeamMember) {
    setLoadingAction(true);

    const response = await axios.post(
      `${APIUrl}/teams/${team.id}/member?userId=${member.userId}`
    );
    if (response.data) {
      setInvites({
        ...invites,
        results: [...invites.results.filter((x) => x.userId !== member.userId)],
      });
      if (member.isFree) {
        setTeam({
          ...team,
          currentUsedFreeSlots: team.currentUsedFreeSlots + 1,
        });
      }
    } else {
      notifications.dispatchNotification(
        "warning",
        "Failed to Accept",
        "This user could not be added as there are no free slots left on your team!"
      );
    }

    setLoadingAction(false);
  }

  async function reject(userId: string) {
    setLoadingAction(true);

    const response = await axios.delete(
      `${APIUrl}/teams/${team.id}/member?userId=${userId}`
    );
    console.log(response.data);
    setInvites({
      ...invites,
      results: [...invites.results.filter((x) => x.userId !== userId)],
    });
    setLoadingAction(false);
  }

  if (loading || !invites) {
    return <Spinner />;
  }

  if (invites && invites.results.length === 0) {
    return <p className="m-4">There are no pending requests.</p>;
  }

  return (
    <>
      <Table striped className="users-list">
        <thead>
          <tr>
            <th>Name</th>
            <th>Rank</th>
            {(team.activeUser!.teamRole === "ADMIN" ||
              team.activeUser!.teamRole === "MOD") && <th>Actions</th>}
          </tr>
        </thead>
        {loading ? (
          <Spinner size="sm" />
        ) : (
          <tbody>
            {invites &&
              invites.results &&
              invites.results.map((member) => (
                <tr key={member.userId}>
                  <td>
                    {member.name}{" "}
                    {member.isFree && <Badge variant="success">FREE</Badge>}
                  </td>
                  <td>
                    <Badge variant="info">PENDING</Badge>
                  </td>
                  {(team.activeUser!.teamRole === "ADMIN" ||
                    team.activeUser!.teamRole === "MOD") && (
                    <td>
                      <div className="user-moderation-controls">
                        <Button
                          size="sm"
                          variant="success"
                          onClick={() => accept(member)}
                          disabled={loadingAction}
                        >
                          <FontAwesomeIcon icon={faCheck} /> Accept
                        </Button>
                        <Button
                          size="sm"
                          variant="danger"
                          onClick={() => reject(member.userId)}
                          disabled={loadingAction}
                        >
                          <FontAwesomeIcon icon={faTimes} /> Reject
                        </Button>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        )}
      </Table>
      <div className="my-3 d-flex justify-content-center">
        <Paginator
          totalResults={invites.totalResults}
          pageSize={invites.pageSize}
          page={page}
          setPage={setPage}
        />
      </div>
    </>
  );
}

import axios from "axios";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { APIUrl } from "../../Constants";
import { Team } from "../../types";
import { Spinner } from "../shared/Spinner";
import { AddTeam } from "./Modals/AddTeam";
import { Pending } from "./Pending";
import { TeamsList } from "./TeamsList";
import { TeamView } from "./TeamView";

export function Teams() {
  const [loading, setLoading] = useState(true);
  const [teamLoading, setTeamLoading] = useState(false);
  const [teams, setTeams] = useState<Team[]>([]);
  const [selected, setSelected] = useState<string>();
  const [selectedTeam, setSelectedTeam] = useState<Team>();
  const [showAddTeamModal, setShowAddTeamModal] = useState(false);

  useEffect(() => {
    async function load() {
      setLoading(true);
      try {
        const response = await axios.get(`${APIUrl}/teams`);
        const data: Team[] = response.data;
        setSelected(data.length > 0 ? data[0].id : undefined);
        setTeams(data);
      } catch (e: any) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
    load();
  }, []);

  async function updateTeamOrder(teams: Team[]) {
    setTeams(teams);

    await axios.post(
      `${APIUrl}/teams/order`,
      teams.map((t) => t.id)
    );
  }

  useEffect(() => {
    async function load() {
      setTeamLoading(true);

      try {
        const response = await axios.get(`${APIUrl}/teams/${selected}`);
        if (response && response.data) {
          console.log(response.data);
          setSelectedTeam(response.data);
        }
      } catch (e: any) {
        console.log(e);
      }

      setTeamLoading(false);
    }

    if (selected) {
      load();
    } else {
      setSelectedTeam(undefined);
    }
  }, [selected]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <Container fluid>
      <TeamsList
        teams={teams}
        updateTeamOrder={updateTeamOrder}
        showAddTeamModal={() => setShowAddTeamModal(true)}
        selected={selected}
        onTeamSelect={(team) => setSelected(team.id)}
      />
      <div className="team-page-content">
        {selectedTeam ? (
          <>
            {selectedTeam.activeUser &&
            selectedTeam.activeUser.state === "ACCEPTED" ? (
              <TeamView
                team={selectedTeam}
                setTeam={(team) => {
                  setTeams([
                    ...teams.map((x) => (x.id === selected ? team : x)),
                  ]);
                  setSelectedTeam(team);
                  setSelected(team.id);
                }}
                onLeaveTeam={() => {
                  const _teams = [...teams.filter((x) => x.id !== selected)];
                  setSelected(_teams.length > 0 ? _teams[0].id : undefined);
                  setTeams(_teams);
                }}
              />
            ) : (
              <Pending
                team={selectedTeam}
                onCancel={() => {
                  const _teams = [...teams.filter((x) => x.id !== selected)];
                  setSelected(_teams.length > 0 ? _teams[0].id : undefined);
                  setTeams(_teams);
                }}
              />
            )}
          </>
        ) : teamLoading ? (
          <Spinner />
        ) : null}

        {showAddTeamModal && (
          <AddTeam
            onClose={() => setShowAddTeamModal(false)}
            onCreateTeam={(team) => {
              setTeams((teams) => [
                ...teams.filter((x) => x.id !== team.id),
                team,
              ]);
              setSelected(team.id);
              setShowAddTeamModal(false);
            }}
          />
        )}
      </div>
    </Container>
  );
}

import { faVial } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { APIUrl } from "../../Constants";

type TestType = "Trigger Applets" | "Twitch Callbacks";
type UsageLevel = "Low" | "Medium" | "High" | "Spam";
type State = "Idle" | "Running";

export function StressTest() {
  const [testType, setTestType] = useState<TestType>("Trigger Applets");
  const [users, setUsers] = useState(10);
  const [usage, setUsage] = useState<UsageLevel>("Low");
  const [intervals, setIntervals] = useState<NodeJS.Timeout[]>([]);
  const [state, setState] = useState<State>("Idle");
  const [results, setResults] = useState<number[]>([]);
  const [resSum, setResSum] = useState(0);
  const [failures, setFailures] = useState(0);

  async function sendRequest() {
    try {
      const reqTime = new Date().getTime();
      await axios.post(`${APIUrl}/admin/stresstest`);

      const resTime = new Date().getTime();

      const time = resTime - reqTime;
      setResults((results) => [...results, time]);
      setResSum((sum) => sum + time);
    } catch (e: any) {
      setFailures((failures) => failures + 1);
    }
  }

  async function sendTwitchRequest() {
    try {
      const reqTime = new Date().getTime();
      await axios.post(`${APIUrl}/admin/stresstest/twitch`);

      const resTime = new Date().getTime();

      const time = resTime - reqTime;
      setResults((results) => [...results, time]);
      setResSum((sum) => sum + time);
    } catch (e: any) {
      setFailures((failures) => failures + 1);
    }
  }

  function startTest() {
    const _intervals = [];

    console.log(
      `Setting up test for ${users} users with ${usage} activity on test ${testType}`
    );

    for (let i = 0; i < users; i++) {
      _intervals.push(
        setInterval(() => {
          const max =
            usage === "Low"
              ? 60
              : usage === "Medium"
              ? 30
              : usage === "High"
              ? 10
              : 1;

          if (Math.floor(Math.random() * Math.floor(max * 10)) !== 1) {
            return;
          }

          if (testType === "Trigger Applets") {
            sendRequest();
          } else {
            sendTwitchRequest();
          }
        }, 100)
      );
    }

    setIntervals(_intervals);
    setResults([]);
    setState("Running");
  }

  function stopTest() {
    console.log(`Clearing ${intervals.length} tests`);

    intervals.forEach((interval) => {
      clearInterval(interval);
    });

    setIntervals([]);
    setState("Idle");
  }

  return (
    <div className="page-content">
      <h1>
        <FontAwesomeIcon icon={faVial} /> Stress Test
      </h1>
      <Row className="mt-4">
        <Col sm={4}>
          <Card>
            <Card.Header>
              <Card.Title>Test Settings</Card.Title>
            </Card.Header>
            <Card.Body>
              <Form.Group>
                <Form.Label>Test Type</Form.Label>
                <Form.Control
                  as="select"
                  value={testType}
                  onChange={(e) => setTestType(e.target.value as TestType)}
                >
                  <option>Trigger Applets</option>
                  <option>Twitch Callbacks</option>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Amount of Users</Form.Label>
                <Form.Control
                  type="text"
                  value={users}
                  onChange={(e) =>
                    setUsers(
                      parseInt(e.target.value.replaceAll(/[^0-9]+/g, "")) || 0
                    )
                  }
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Usage Level</Form.Label>
                <Form.Control
                  as="select"
                  value={usage}
                  onChange={(e) => setUsage(e.target.value as UsageLevel)}
                >
                  <option value="Low">
                    Low (1 Request / user / ~60 Seconds)
                  </option>
                  <option value="Medium">
                    Medium (1 Request / user / ~30 Seconds)
                  </option>
                  <option value="High">
                    High (1 Request / user / ~10 Seconds)
                  </option>
                  <option value="Spam">
                    Spam (1 Request / user / ~1 Second)
                  </option>
                </Form.Control>
              </Form.Group>
            </Card.Body>
            <Card.Footer>
              <Button
                variant={state === "Idle" ? "success" : "danger"}
                onClick={state === "Idle" ? startTest : stopTest}
                block
              >
                {state === "Idle" ? "Start" : "Stop"}
              </Button>
            </Card.Footer>
          </Card>
        </Col>
        <Col sm={4}>
          <Card>
            <Card.Header>
              <Card.Title>Test Results</Card.Title>
            </Card.Header>
            <Card.Body>
              <p>Completed Requests: {results.length}</p>
              <p>Failed Requests: {failures}</p>
              <p>
                Average response time:{" "}
                {Math.floor(resSum / results.length || 0)}
                ms
              </p>
              <Button
                onClick={() => {
                  setResSum(0);
                  setResults([]);
                  setFailures(0);
                }}
              >
                Clear
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

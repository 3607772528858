import axios from "axios";
import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { APIUrl } from "../../../../Constants";
import { StreamDeckKey } from "../../../../types";

type RegenerateKeyModalProps = {
  keyId: string;
  onClose: () => void;
};

export function RegenerateKeyModal({
  keyId,
  onClose,
}: RegenerateKeyModalProps) {
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState<StreamDeckKey>();

  async function regen() {
    setLoading(true);

    const response = await axios.get(
      `${APIUrl}/streamdeck/regenerate/${keyId}`
    );
    console.log(response);
    setKey(response.data);

    setLoading(false);
  }

  return (
    <Modal show={true} onHide={onClose} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>Regenerate Key</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {key ? (
          <>
            <p>Your key has been regenerated.</p>
            <p className="code">{key}</p>
            <p>
              <strong>
                Copy this key now as you will NOT be able to access it again!
              </strong>
            </p>
            <p>
              Do NOT share this key with anyone! If this key becomes
              compromised, regenerate or delete it immediately as anyone with
              this key can control your applet!
            </p>
          </>
        ) : (
          <p>
            Are you sure you want to regenerate this key? This will replace the
            existing key so any stream deck actions using it will no longer
            work!
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        {!key && (
          <Button
            variant="outline-primary"
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </Button>
        )}
        <Button onClick={key ? onClose : regen} disabled={loading}>
          {key ? "Done" : "Regenerate"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

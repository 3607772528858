import _ from "underscore";
import { NodeOption } from "../../../../../types";
import {
  faSearch,
  faStar,
  faStarHalfAlt,
} from "@fortawesome/pro-light-svg-icons";
import { useEffect, useState } from "react";
import { useModules } from "../../../../../context/ModulesContext";
import { faCogs } from "@fortawesome/pro-light-svg-icons";
import { Category } from "./Category";
import { NodeView } from "./NodeView";
import { Search } from "./Search";
import { Favourite } from "./Favourite";
import { ListGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { APIUrl } from "../../../../../Constants";
import axios from "axios";

type EditorSidenavProps = {
  nodes: NodeOption[];
  setNodes: (nodes: NodeOption[]) => void;
};

export type EditorSidenavContextMenu = {
  option: NodeOption;
  x: number;
  y: number;
};

export function EditorSidenav({ nodes, setNodes }: EditorSidenavProps) {
  const { modules } = useModules();
  const [selected, setSelected] = useState<string>();
  const [contextMenu, setContextMenu] = useState<EditorSidenavContextMenu>();

  useEffect(() => {
    document.addEventListener("click", handleClick);

    function handleClick() {
      setContextMenu(undefined);
    }

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  async function toggleFavourite() {
    await axios.post(`${APIUrl}/node/favourite/${contextMenu?.option.id}`);
    setNodes(
      nodes.map((x) =>
        x.id === contextMenu?.option.id ? { ...x, favourite: !x.favourite } : x
      )
    );
  }

  return (
    <>
      <div className="editor-sidenav">
        <div className="category-select">
          <Category
            name="Search Nodes"
            selected={selected === "search"}
            onSelect={() => setSelected("search")}
            icon={faSearch}
          />
          <Category
            name="Favourite Nodes"
            selected={selected === undefined}
            onSelect={() => setSelected(undefined)}
            icon={faStar}
          />
          {_.uniq(nodes.map((x) => x.module)).map((module, index) => (
            <Category
              key={index}
              name={`${module} Module`}
              selected={selected === module}
              onSelect={() => setSelected(module)}
              icon={module === "Core" ? faCogs : undefined}
              image={
                module !== "Core"
                  ? modules.find((x) => x.id === module)?.imageUrl
                  : undefined
              }
            />
          ))}
        </div>
        <div className="category-view">
          {selected === "search" ? (
            <Search nodes={nodes} setContextMenu={setContextMenu} />
          ) : selected === undefined ? (
            <Favourite nodes={nodes} setContextMenu={setContextMenu} />
          ) : (
            <>
              <h6 className="module-title">
                {modules.find((x) => x.id === selected)
                  ? modules.find((x) => x.id === selected)!.name
                  : "Core"}{" "}
                Module
              </h6>
              <div className="sidenav-content">
                <NodeView
                  nodes={nodes.filter((x) => x.module === selected)}
                  setContextMenu={setContextMenu}
                />
              </div>
            </>
          )}
        </div>
      </div>

      {contextMenu && (
        <ListGroup
          className="context-menu"
          style={{ top: contextMenu.y - 40, left: contextMenu.x }}
        >
          <ListGroup.Item onClick={toggleFavourite}>
            <FontAwesomeIcon
              icon={contextMenu.option.favourite ? faStarHalfAlt : faStar}
              size="lg"
            />
            {contextMenu.option.favourite
              ? "Remove from Favourites"
              : "Add to Favourites"}
          </ListGroup.Item>
        </ListGroup>
      )}
    </>
  );
}

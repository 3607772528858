import { createContext, ReactNode, useContext } from "react";

export type SidenavContextProps = {
  override: ReactNode | null;
  setOverride: (sidenav: ReactNode | null) => void;
  enabled: boolean;
  setEnabled: (enabled: boolean) => void;
};

export const SidenavContext = createContext<SidenavContextProps>({
  override: null,
  setOverride: () => {},
  enabled: true,
  setEnabled: () => {},
});

export function useSidenav() {
  return useContext(SidenavContext);
}

import axios from "axios";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { APIUrl } from "../Constants";

type BugReporterProps = {
  onClose: () => void;
};

export function BugReporter({ onClose }: BugReporterProps) {
  const [message, setMessage] = useState<string>();
  const [loading, setLoading] = useState(false);

  async function SendBugReport() {
    setLoading(true);
    try {
      if (message) {
        await axios.post(`${APIUrl}/user/error/report`, {
          message: message,
          url: window.location.href,
        });
        onClose();
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }
  return (
    <Modal show={true} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Bug Report</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>
            Thanks for letting us know! We may contact you in the future to ask
            you more questions about your report.
          </Form.Label>
          <Form.Control
            className="mt-3"
            as="textarea"
            rows={8}
            type="text"
            placeholder={"Message"}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="outline-primary"
          onClick={onClose}
          style={{ minWidth: 100 }}
          disabled={loading}
        >
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            SendBugReport();
          }}
          style={{ minWidth: 100 }}
          disabled={loading}
        >
          Send
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

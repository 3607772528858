import {
  faBooks,
  faBox,
  faCogs,
  faCubes,
  faEdit,
  faEnvelopeOpenText,
  faExclamationTriangle,
  faHome,
  faKey,
  faScroll,
  faServer,
  faTachometerAlt,
  faTerminal,
  faTicketAlt,
  faUserFriends,
  faUsers,
  faVial,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import { Badge, Button, OverlayTrigger, Popover } from "react-bootstrap";
import { RouteComponentProps } from "react-router-dom";
import { APIUrl } from "../Constants";
import { useAuth } from "../context/AuthContext";
import { useModules } from "../context/ModulesContext";
import { useTutorial } from "../context/TutorialContext";
import { useUser } from "../context/UserContext";
import { canUseModule } from "./shared/utils";
import { SidenavGroup, SidenavGroupItem } from "./SidenavGroup";

type SidenavProps = {
  open: boolean;
  errorNumber: number;
};

export function Sidenav({
  open,
  history,
  location,
  errorNumber,
}: SidenavProps & RouteComponentProps) {
  const [enabledModules, setEnabledModules] = useState<{
    [module: string]: boolean;
  }>({});

  const auth = useAuth();
  const user = useUser();
  const { modules } = useModules();
  const tutorial = useTutorial();

  useEffect(() => {
    user.refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user && user.role) {
      const _enabledModules: { [module: string]: boolean } = {};
      modules.forEach(
        (x) =>
          (_enabledModules[x.id] = x.enabled && canUseModule(x, user.role!))
      );
      setEnabledModules(_enabledModules);
    }
  }, [user, modules]);

  function onNavigate(link: string) {
    history.push(link);
  }

  function GetTier() {
    var _role: string = user.role ? user.role : "";
    _role = _role.toLowerCase();
    _role = _role.charAt(0).toUpperCase() + _role.slice(1);
    return _role + " Tier";
  }

  function twitchItems() {
    const items: SidenavGroupItem[] = [
      {
        icon: faTerminal,
        name: "Simple Bot Commands",
        link: "/twitch/commands",
      },
    ];

    items.push({
      icon: faEdit,
      name: "Custom Bot Name",
      link: "/twitch/custom-bot",
      disabled: !user.plan || !user.plan.customTwitchBotName,
      requiredPlan: "Premium",
    });

    return (
      <SidenavGroup
        name="Twitch Module"
        items={items}
        onNavigate={onNavigate}
        pathname={location.pathname}
        open={open && !tutorial.inTutorial}
      />
    );
  }

  return (
    <div
      className={`sidenav ${
        !open && !tutorial.inTutorial ? "sidenav-closed" : ""
      }`}
    >
      <div className="sidenav-content">
        <SidenavGroup
          name="Main"
          items={[
            { icon: faHome, name: "Home", link: "/" },
            { icon: faCubes, name: "My Applets", link: "/applets" },
            { icon: faBooks, name: "Applet Library", link: "/library" },
            { icon: faBox, name: "Modules", link: "/modules" },
            {
              icon: faUserFriends,
              name: "Teams",
              link: "/teams",
              beta: true,
            },
            {
              icon: faExclamationTriangle,
              name: "Errors",
              link: "/errors",
              notificationAmount: errorNumber,
            },
          ]}
          onNavigate={onNavigate}
          pathname={location.pathname}
          open={open && !tutorial.inTutorial}
        />
        {enabledModules["Community"] && (
          <SidenavGroup
            name="Community Module"
            items={[
              {
                icon: faUsers,
                name: "My Community",
                link: "/community",
              },
            ]}
            onNavigate={onNavigate}
            pathname={location.pathname}
            open={open && !tutorial.inTutorial}
          />
        )}
        {enabledModules["Twitch"] && twitchItems()}
        {enabledModules["Minecraft"] && (
          <SidenavGroup
            name="Minecraft Module"
            items={[
              {
                icon: faServer,
                name: "Minecraft Servers",
                link: "/minecraft/servers",
              },
            ]}
            onNavigate={onNavigate}
            pathname={location.pathname}
            open={open && !tutorial.inTutorial}
          />
        )}
        {enabledModules["StreamDeck"] && (
          <SidenavGroup
            name="Stream Deck Module"
            items={[{ icon: faKey, name: "Keys", link: "/streamdeck/keys" }]}
            onNavigate={onNavigate}
            pathname={location.pathname}
            open={open && !tutorial.inTutorial}
          />
        )}
        {auth.user?.profile.role === "ADMIN" ? (
          <SidenavGroup
            name="Admin"
            items={[
              {
                icon: faTachometerAlt,
                name: "Admin Dashboard",
                link: "/admin/dashboard",
              },
              { icon: faVial, name: "Stress Test", link: "/admin/stress-test" },
              { icon: faCogs, name: "App Config", link: "/admin/config" },
              { icon: faUsers, name: "Users", link: "/admin/users" },
              {
                icon: faEnvelopeOpenText,
                name: "Emails",
                link: "/admin/emails",
              },
              {
                icon: faTicketAlt,
                name: "Support",
                link: "/admin/support",
              },
            ]}
            onNavigate={onNavigate}
            pathname={location.pathname}
            open={open && !tutorial.inTutorial}
          />
        ) : null}

        {open && !tutorial.inTutorial ? (
          <div className="upgrade d-flex align-items-center bg-base-bg">
            <h5 className="flex-grow-1 mb-0">
              <Badge variant={user.role === "FREE" ? "success" : "warning"}>
                {GetTier()}
              </Badge>
            </h5>
            <div>
              <Button
                className="float-right"
                size="sm"
                onClick={() => {
                  history.push("/pricing");
                }}
              >
                Upgrade
              </Button>
            </div>
          </div>
        ) : (
          <OverlayTrigger
            placement="right"
            overlay={
              <Popover id="upgrade-popover">
                <Popover.Title>
                  <Badge variant={user.role === "FREE" ? "success" : "warning"}>
                    {GetTier()}
                  </Badge>
                </Popover.Title>
                <Popover.Content>
                  Click the scroll to upgrade your plan!
                </Popover.Content>
              </Popover>
            }
          >
            <Button
              className="upgrade border-none"
              variant={
                user.role === "FREE" ? "outline-success" : "outline-warning"
              }
              onClick={() => {
                history.push("/pricing");
              }}
            >
              <FontAwesomeIcon icon={faScroll} />
            </Button>
          </OverlayTrigger>
        )}
      </div>
    </div>
  );
}

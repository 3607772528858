import { createContext, useContext } from "react";
import { Module } from "../types";

type ModulesContextProps = {
  modules: Module[];
  setModules: (modules: Module[]) => void;
};

export const ModulesContext = createContext<ModulesContextProps>({
  modules: [],
  setModules: () => {},
});

export function useModules() {
  return useContext(ModulesContext);
}

import {
  faClone,
  faPlus,
  faShareSquare,
  faTrash,
  faUpload,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Container, ListGroup, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { APIUrl } from "../../Constants";
import { useAuth } from "../../context/AuthContext";
import { useNotifications } from "../../context/NotificationContext";
import { useTutorial } from "../../context/TutorialContext";
import { Applet, Paged, Template } from "../../types";
import { ConfirmationModal } from "../shared/modals/ConfirmationModal";
import { Paginator } from "../shared/Paginator";
import { TutorialPanel } from "../Tutorial/TutorialPanel";
import { AppletListItem } from "./AppletListItem";
import { PublishLibraryModal } from "./PublishLibraryModal";
import { PublishTemplateModal } from "./PublishTemplateModal";
import { ShareToTeam } from "./ShareToTeam";
import { TemplateSelector } from "./TemplateSelector";

type AppletListProps = {
  applets: Paged<Applet>;
  openEditor: (id?: string, state?: unknown) => void;
  openConfig: (id: string) => void;
  setStatus: (id: string, status: "enable" | "disable") => void;
  onDelete: (id: string) => void;
  page: number;
  setPage: (page: number) => void;
};

export type ContextMenu = {
  applet: Applet;
  x: number;
  y: number;
};

export function AppletList({
  applets,
  openEditor,
  openConfig,
  setStatus,
  onDelete,
  page,
  setPage,
}: AppletListProps) {
  const notifications = useNotifications();
  const auth = useAuth();
  const [showDeleteModal, setShowDeleteModal] = useState<Applet>();
  const [contextMenu, setContextMenu] = useState<ContextMenu>();
  const [showShareModal, setShowShareModal] = useState<Applet>();
  const [showPublishLibraryModal, setShowPublishLibraryModal] =
    useState<Applet>();
  const [showPublishTemplateModal, setShowPublishTemplateModal] =
    useState<Applet>();
  const [showTemplateSelector, setShowTemplateSelector] = useState(false);
  const [templates, setTemplates] = useState<Template[]>([]);
  const addBtnRef = useRef<HTMLButtonElement>(null);
  const selectorRef = useRef<HTMLDivElement>(null);
  const tutorial = useTutorial();

  useEffect(() => {
    async function loadTemplates() {
      const response = await axios.get(`${APIUrl}/template`);
      setTemplates(response.data);
    }
    loadTemplates();
  }, []);

  useEffect(() => {
    document.addEventListener("click", handleClick);

    function handleClick() {
      setContextMenu(undefined);
    }

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  async function deleteApplet() {
    if (showDeleteModal) {
      await axios.delete(`${APIUrl}/applet/${showDeleteModal.id}`);
      onDelete(showDeleteModal.id);
      notifications.dispatchNotification(
        "danger",
        "Applet Deleted",
        `Applet "${showDeleteModal.name}" has been deleted successfully`
      );
      setShowDeleteModal(undefined);
      setShowShareModal(undefined);
    }
  }

  return (
    <div className="page-content">
      <Container fluid>
        <div ref={selectorRef}>
          <TemplateSelector
            show={showTemplateSelector}
            openEditor={(id?: string | undefined, state?: unknown) => {
              if (
                tutorial.inTutorial &&
                (tutorial.stage === "APPLETS 3" ||
                  tutorial.stage === "APPLETS 4")
              ) {
                tutorial.setStage("APPLET EDITOR");
              }
              openEditor(id, state);
            }}
            templates={templates}
            onClose={() => setShowTemplateSelector(false)}
          />
          {tutorial.inTutorial && tutorial.stage === "APPLETS 3" && (
            <TutorialPanel
              position="absolute"
              title="Select a Template"
              top={100}
              left={330}
              arrowPosition="left"
              onBtnClick={() => {
                tutorial.setStage("APPLETS 4");
              }}
              renderButton={true}
            >
              Templates are starting points for your applet that do different
              things. They are great to learn how to do some basic things but
              for this tutorial, lets start with a blank one.
            </TutorialPanel>
          )}
          {tutorial.inTutorial && tutorial.stage === "APPLETS 4" && (
            <TutorialPanel
              position="absolute"
              title="Use the Template"
              bottom={20}
              left={330}
              arrowPosition="left"
            >
              Click "Create Applet" to open the editor with the selected
              template.
            </TutorialPanel>
          )}
        </div>
        <h2>My Applets</h2>
        <p>
          Applets are the core of Stream Hydra, these are fully customizable
          programs that run events based upon certain triggers.
          <br />
          If this sounds confusing, don't worry! You can create an applet from a
          template or <Link to="/library">click here</Link> to view our library
          of pre-built applets.
        </p>

        <Row className="mt-5">
          <Col style={{ position: "relative" }}>
            <Button
              onClick={() => {
                if (tutorial.inTutorial && tutorial.stage === "APPLETS 2") {
                  tutorial.setStage("APPLETS 3");
                }
                setShowTemplateSelector(true);
              }}
              ref={addBtnRef}
            >
              <FontAwesomeIcon icon={faPlus} /> New Applet
            </Button>
            {tutorial.inTutorial && tutorial.stage === "APPLETS 2" && (
              <TutorialPanel
                position="absolute"
                title="Create a new Applet"
                top={-25}
                left={170}
                arrowPosition="left"
                onBtnClick={() => {
                  setShowTemplateSelector(true);
                  tutorial.setStage("APPLETS 3");
                }}
              >
                Click "New Applet" to begin creating a new Applet.
              </TutorialPanel>
            )}
          </Col>
          {/* <Col>
          <InputGroup style={{ maxWidth: 400, float: "right" }}>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control type="text" placeholder="Search Applets" />
          </InputGroup>
        </Col> */}
        </Row>
        {applets.results.length > 0 ? (
          <>
            <div className="applet-list-wrapper">
              {applets.results.map((applet) => (
                <AppletListItem
                  key={applet.id}
                  applet={applet}
                  setStatus={setStatus}
                  onEdit={() =>
                    applet.globalVariables.length > 0
                      ? openConfig(applet.id)
                      : openEditor(applet.id)
                  }
                  setContextMenu={setContextMenu}
                />
              ))}
            </div>
            <div className="mt-4 d-flex justify-content-center">
              <Paginator
                page={page}
                setPage={setPage}
                pageSize={applets.pageSize}
                totalResults={applets.totalResults}
              />
            </div>
          </>
        ) : (
          <p className="mt-5">
            No applets found. Create a new one or{" "}
            <Link to="/library">browse our library</Link> to view pre-made ones.
          </p>
        )}
        {contextMenu && (
          <ListGroup
            className="context-menu"
            style={{ top: contextMenu.y - 45, left: contextMenu.x - 300 }}
          >
            <ListGroup.Item
              onClick={() => setShowShareModal(contextMenu.applet)}
            >
              <FontAwesomeIcon icon={faShareSquare} /> Share to Team
            </ListGroup.Item>

            {contextMenu.applet.status === "DISABLED" && (
              <ListGroup.Item
                onClick={() => setShowDeleteModal(contextMenu.applet)}
              >
                <FontAwesomeIcon icon={faTrash} /> Delete Applet
              </ListGroup.Item>
            )}

            {auth.user?.profile.role === "ADMIN" && (
              <>
                <ListGroup.Item
                  onClick={() => setShowPublishLibraryModal(contextMenu.applet)}
                >
                  <FontAwesomeIcon icon={faUpload} /> Publish to Library
                </ListGroup.Item>
                <ListGroup.Item
                  onClick={() =>
                    setShowPublishTemplateModal(contextMenu.applet)
                  }
                >
                  <FontAwesomeIcon icon={faClone} /> Publish as Template
                </ListGroup.Item>
              </>
            )}
          </ListGroup>
        )}
        {showDeleteModal && (
          <ConfirmationModal
            title="Delete Applet"
            body={`Are you sure you want to permanently delete applet "${showDeleteModal.name}"? This cannot be undone!`}
            onClose={() => setShowDeleteModal(undefined)}
            onConfirm={deleteApplet}
            positiveText="Delete"
            negativeText="Cancel"
          />
        )}
        {showShareModal && (
          <ShareToTeam
            applet={showShareModal}
            onClose={() => setShowShareModal(undefined)}
          />
        )}
        {showPublishLibraryModal && (
          <PublishLibraryModal
            applet={showPublishLibraryModal}
            onClose={() => setShowPublishLibraryModal(undefined)}
          />
        )}
        {showPublishTemplateModal && (
          <PublishTemplateModal
            applet={showPublishTemplateModal}
            onClose={() => setShowPublishTemplateModal(undefined)}
          />
        )}
      </Container>
    </div>
  );
}

import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";

type HelpIconProps = {
  id: string;
  tooltip?: string;
  onClick?: () => void;
};

export function HelpIcon({ id, tooltip, onClick }: HelpIconProps) {
  return tooltip ? (
    <OverlayTrigger
      placement="right"
      overlay={<Tooltip id={id}>{tooltip}</Tooltip>}
    >
      <Button className="help-icon" onClick={onClick}>
        <FontAwesomeIcon icon={faQuestionCircle} />
      </Button>
    </OverlayTrigger>
  ) : (
    <Button className="help-icon">
      <FontAwesomeIcon icon={faQuestionCircle} />
    </Button>
  );
}

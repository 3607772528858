import { Fragment } from "react";
import { NodeInstance, NodeOption } from "../../../../../types";

type ConnectorsProps = {
  node: NodeInstance;
  nodeWidth: (node: NodeOption) => number;
  nodeHeight: () => number;
};

export function Connectors({ node, nodeWidth, nodeHeight }: ConnectorsProps) {
  if (!node.node) {
    return null;
  }

  return (
    <Fragment>
      {node.node.input && (
        <circle
          cx={node.x + 20}
          cy={node.y + nodeHeight() / 2}
          r={8}
          className="connector input"
          id={`${node.id}`}
        />
      )}
      {node.node.outputs.map((output, index) => {
        return (
          <Fragment key={index}>
            {node.node.outputs.length > 1 && (
              <text
                x={node.x + nodeWidth(node.node) - 65}
                y={
                  node.y +
                  (nodeHeight() / (node.node.outputs.length + 1)) *
                    (index + 1) +
                  4
                }
                fontSize={14}
              >
                {output.id}
              </text>
            )}
            <circle
              cx={node.x + nodeWidth(node.node) - 20}
              cy={
                node.y +
                (nodeHeight() / (node.node.outputs.length + 1)) * (index + 1)
              }
              r={8}
              className="connector output"
              id={`${node.id}#${output.id}`}
            />
          </Fragment>
        );
      })}
    </Fragment>
  );
}

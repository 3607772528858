import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { useHistory } from "react-router";
import { Notification } from "../types";

type NotificationsProps = {
  notifications: Notification[];
  setNotifications: (notifications: Notification[]) => void;
};

export function Notifications({
  notifications,
  setNotifications,
}: NotificationsProps) {
  const [time, setTime] = useState(new Date().getTime());
  const history = useHistory();

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date().getTime());
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  function close(notification: Notification) {
    notification.alert = false;
    notification.read = true;

    setNotifications([
      ...notifications.filter((n) => n.id !== notification.id),
      notification,
    ]);
  }

  return (
    <div className="notification-wrapper">
      <div className="notifications">
        {notifications
          .sort(
            (a, b) =>
              new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
          )
          .filter(
            (n) => n.alert && time - new Date(n.createdAt).getTime() <= 10000
          )
          .map((n) => {
            return (
              <Alert
                key={n.id}
                className={`notification ${n.type.toLowerCase()}`}
                onClick={() => {
                  if (n.url) {
                    history.push(n.url);
                    close(n);
                  }
                }}
                style={{ cursor: n.url ? "pointer" : "inherit" }}
              >
                <Alert.Heading>
                  <FontAwesomeIcon
                    icon={
                      n.type.toLowerCase() === "danger"
                        ? faExclamationCircle
                        : n.type.toLowerCase() === "warning"
                        ? faExclamationTriangle
                        : n.type.toLowerCase() === "info"
                        ? faInfoCircle
                        : faCheckCircle
                    }
                    className={`alert-icon text-${n.type.toLowerCase()}`}
                  />
                  {n.title}
                  <FontAwesomeIcon
                    className="close"
                    icon={faTimes}
                    onClick={() => close(n)}
                  />
                </Alert.Heading>
                {n.message}
              </Alert>
            );
          })}
      </div>
    </div>
  );
}

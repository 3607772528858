import "./App.scss";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Dashboard from "./dashboard/Dashboard";
import { Callback, makeAuthenticator, makeUserManager } from "react-oidc";
import { Spinner } from "./dashboard/shared/Spinner";
import { AuthUrl, ClientUrl } from "./Constants";
import { ThemeProvider } from "./context/ThemeContext";

if (process.env.NODE_ENV !== "development") {
  console.log = () => {};
}

const userManager = makeUserManager({
  authority: AuthUrl,
  client_id: "webapp",
  redirect_uri: `${ClientUrl}/login/callback`,
  post_logout_redirect_uri: `${ClientUrl}/logout/callback`,
  silent_redirect_uri: `${ClientUrl}/silentrenew/callback`,
  response_type: "code",
  scope: "openid profile role api email",
  loadUserInfo: true,
  revokeAccessTokenOnSignout: true,
  automaticSilentRenew: false,
});

const Authenticator = makeAuthenticator({
  userManager,
  placeholderComponent: <Spinner fill />,
})(Dashboard);

function App() {
  return (
    <ThemeProvider>
      <BrowserRouter>
        <Switch>
          <Route
            path="/login/callback"
            render={(routeProps) => (
              <Callback
                onSuccess={() => {
                  routeProps.history.push("/");
                }}
                userManager={userManager}
              />
            )}
            exact
          />
          <Route
            path="/logout/callback"
            render={(routeProps) => {
              async function callback() {
                await userManager.signoutCallback();
                routeProps.history.push("/");
              }
              callback();

              return <Spinner fill />;
            }}
            exact
          />
          <Route
            path="/silentrenew/callback"
            render={() => {
              async function callback() {
                await userManager.signinSilentCallback();
              }
              callback();

              return <Spinner fill />;
            }}
            exact
          />
          <Authenticator />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;

import axios from "axios";
import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { APIUrl } from "../../../../Constants";
import { SimpleCommand } from "../../../../types";

type CommandModalProps = {
  onClose: () => void;
  onAdd: (cmd: SimpleCommand) => void;
  cmd?: SimpleCommand;
};

export function CommandModal({ onClose, onAdd, cmd }: CommandModalProps) {
  const [name, setName] = useState(cmd ? cmd.name : "");
  const [response, setResponse] = useState(cmd ? cmd.response : "");
  const [error, setError] = useState<{ [error: string]: string }>({});
  const [loading, setLoading] = useState(false);

  async function add() {
    let cancelled = false;

    setError({});

    if (!name || name.trim() === "") {
      setError((error) => ({ ...error, name: "This field is requied" }));
      return;
    }

    if (!response || response.trim() === "") {
      setError((error) => ({ ...error, response: "This field is requied" }));
      return;
    }

    setLoading(true);

    if (cmd) {
      const res = await axios.patch(`${APIUrl}/twitch/command`, {
        name: name.trim(),
        response: response.trim(),
      });

      if (!cancelled) {
        setLoading(false);
        onAdd(res.data);
      }
    } else {
      try {
        const res = await axios.post(`${APIUrl}/twitch/command`, {
          name: name.trim(),
          response: response.trim(),
        });

        if (!cancelled) {
          console.log(res.data);

          setLoading(false);
          onAdd(res.data);
        }
      } catch (e: any) {
        setError((error) => ({
          ...error,
          name: "This command already exists!",
        }));
        setLoading(false);
      }
    }

    return () => {
      cancelled = true;
    };
  }

  return (
    <Modal show={true} onHide={onClose} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>{cmd ? "Edit" : "New"} Command</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            add();
          }}
        >
          <Form.Group>
            <Form.Label>Command Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Example: !discord"
              isInvalid={!!error["name"]}
              value={name ? (name.startsWith("!") ? name : "!" + name) : ""}
              onChange={(e) => setName(e.target.value)}
              readOnly={!!cmd}
            />
            <Form.Control.Feedback type="invalid">
              {error.name}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group>
            <Form.Label>Bot Response</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              placeholder="Example: Join my discord"
              isInvalid={!!error["response"]}
              value={response}
              onChange={(e) => setResponse(e.target.value)}
            />
            <Form.Control.Feedback type="invalid">
              {error.response}
            </Form.Control.Feedback>
          </Form.Group>
          <p>
            Use{" "}
            <span
              className="code"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setResponse(response + "{user_name}");
              }}
            >
              {"{"}user_name{"}"}
            </span>{" "}
            to get the name of the user who executed the command.
          </p>
          <p>
            Use{" "}
            <span
              className="code"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setResponse(response + "{arg_1}");
              }}
            >
              {"{"}arg_1{"}"}
            </span>
            ,{" "}
            <span
              className="code"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setResponse(response + "{arg_2}");
              }}
            >
              {"{"}arg_2{"}"}
            </span>
            ,{" "}
            <span
              className="code"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setResponse(response + "{arg_3}");
              }}
            >
              {"{"}arg_3{"}"}
            </span>{" "}
            etc. to get up to 10 arguments used in the command (For advanced
            use, use an applet).
          </p>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button onClick={add} disabled={loading}>
          {cmd ? "Save" : "Add"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

import { useEffect } from "react";
import { Prompt } from "react-router-dom";

type UnloadWarningProps = {
  showWarning: boolean;
};

export function UnloadWarning({ showWarning }: UnloadWarningProps) {
  useEffect(() => {
    function onUnload(e: BeforeUnloadEvent) {
      if (showWarning) {
        e.preventDefault();
        e.returnValue = "";
      }
    }

    window.addEventListener("beforeunload", onUnload);

    return () => {
      window.removeEventListener("beforeunload", onUnload);
    };
  }, [showWarning]);

  return (
    <Prompt
      when={showWarning}
      message="Changes that you made may not be saved."
    />
  );
}

import {
  faCogs,
  faRedo,
  faSkull,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Button, Form, Modal, Overlay, Tooltip } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { APIUrl } from "../../../Constants";
import { useNotifications } from "../../../context/NotificationContext";
import { Team } from "../../../types";
import { ConfirmationModal } from "../../shared/modals/ConfirmationModal";

type TeamSettingsProps = {
  team: Team;
  setTeam: (team: Team) => void;
  onClose: () => void;
};

export function TeamSettings({ team, setTeam, onClose }: TeamSettingsProps) {
  const [loading, setLoading] = useState(true);
  const [showCode, setShowCode] = useState(false);
  const [showCopied, setShowCopied] = useState(false);
  const [autoReject, setAutoReject] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const target = useRef(null);
  const history = useHistory();
  const notifications = useNotifications();

  useEffect(() => {
    if (showCopied) {
      setTimeout(() => {
        setShowCopied(false);
      }, 2000);
    }
  }, [showCopied]);

  useEffect(() => {
    let cancelled = false;
    async function load() {
      const response = await axios.get(
        `${APIUrl}/teams/${team.id}/auto-reject`
      );

      if (!cancelled) {
        setAutoReject(response.data);
        setLoading(false);
      }
    }
    load();

    return () => {
      cancelled = true;
    };
  }, [team.id]);

  async function toggleAutoReject() {
    setLoading(true);

    const response = await axios.post(`${APIUrl}/teams/${team.id}/auto-reject`);
    setAutoReject(response.data);

    setLoading(false);
  }

  async function regenerateInviteCode() {
    setLoading(true);
    const response = await axios.post(`${APIUrl}/teams/${team.id}/invite-code`);
    setTeam({ ...team, inviteCode: response.data });
    setShowCode(true);
    setLoading(false);
  }

  async function deleteTeam() {
    setLoading(true);
    await axios.delete(`${APIUrl}/teams/${team.id}`);
    history.push("/");
    notifications.dispatchNotification(
      "danger",
      "Team Deleted",
      "Your team has been successfully deleted."
    );
  }

  return (
    <>
      <Modal show={true} onHide={onClose} backdrop="static" centered>
        <Modal.Header>
          <h4>
            <FontAwesomeIcon icon={faCogs} /> Team Settings
          </h4>
        </Modal.Header>
        <Modal.Body>
          <div>
            <strong>Join Requests</strong>
            <Form.Check
              id="auto-reject"
              type="switch"
              label="Auto reject join requests"
              checked={autoReject}
              onChange={toggleAutoReject}
              disabled={loading}
            />
            <small>
              Enabling this will automatically reject any existing pending join
              requests!
            </small>
          </div>
          <div className="mt-4">
            <strong>Invite Code</strong>
            <p className="mb-1">
              Team Invite Code:{" "}
              {showCode ? (
                <>
                  <span
                    className="hover text-primary"
                    onClick={() => {
                      navigator.clipboard.writeText(team.inviteCode);
                      setShowCopied(true);
                    }}
                    ref={target}
                  >
                    {team.inviteCode}
                  </span>
                  <Overlay
                    target={target.current}
                    show={showCopied}
                    placement="right"
                  >
                    {(props) => (
                      <Tooltip id="copied" {...props}>
                        Copied to Clipboard!
                      </Tooltip>
                    )}
                  </Overlay>
                </>
              ) : (
                <span
                  className="text-primary hover"
                  onClick={() => setShowCode(true)}
                >
                  Click to Show
                </span>
              )}
            </p>
            <Button size="sm" disabled={loading} onClick={regenerateInviteCode}>
              <FontAwesomeIcon icon={faRedo} /> Regenerate Code
            </Button>
          </div>
          <div className="mt-4">
            <p className="mb-1">
              <strong className="text-danger">
                <FontAwesomeIcon icon={faSkull} /> Danger Zone{" "}
                <FontAwesomeIcon icon={faSkull} />
              </strong>
            </p>
            <Button
              variant="danger"
              size="sm"
              onClick={() => setDeleting(true)}
            >
              <FontAwesomeIcon icon={faTrash} /> Delete Team
            </Button>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={onClose}>
            Save & Close
          </Button>
        </Modal.Footer>
      </Modal>
      {deleting && (
        <ConfirmationModal
          title="Delete Team"
          body="Are you sure you want to delete this team? This action cannot be undone!"
          onClose={() => setDeleting(false)}
          onConfirm={deleteTeam}
          dark
        />
      )}
    </>
  );
}

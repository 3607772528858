import { faCaretUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";

type TutorialPanelProps = {
  title?: string;
  children?: React.ReactNode;
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
  arrowPosition?: "top" | "bottom" | "left" | "right";
  arrowTop?: number;
  arrowBottom?: number;
  arrowLeft?: number;
  arrowRight?: number;
  renderButton?: boolean;
  btnText?: string;
  onBtnClick?: () => void;
  position?: "fixed" | "absolute";
};

export function TutorialPanel({
  title,
  children,
  top,
  bottom,
  left,
  right,
  arrowPosition,
  arrowTop,
  arrowBottom,
  arrowLeft,
  arrowRight,
  renderButton,
  btnText,
  onBtnClick,
  position,
}: TutorialPanelProps) {
  return (
    <div
      className="tutorial-panel"
      style={{
        position: position || "fixed",
        width: 400,
        top: top ? top : undefined,
        bottom: bottom ? bottom : undefined,
        left: left ? left : undefined,
        right: right ? right : undefined,
      }}
    >
      {arrowPosition && (
        <FontAwesomeIcon
          className={`arrow arrow-${arrowPosition}`}
          icon={faCaretUp}
          style={{
            top: arrowTop ? arrowTop : undefined,
            bottom: arrowBottom ? arrowBottom : undefined,
            left: arrowLeft ? arrowLeft : undefined,
            right: arrowRight ? arrowRight : undefined,
          }}
        />
      )}
      {title && <h5>{title}</h5>}
      {children}
      {renderButton && (
        <Button
          variant="primary"
          size="sm"
          style={{ width: 80, float: "right" }}
          onClick={onBtnClick ? onBtnClick : () => {}}
          className="mt-2 ml-2"
        >
          {btnText || "Next"}
        </Button>
      )}
    </div>
  );
}

import { faClone, faTrash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { APIUrl } from "../../Constants";
import { useAuth } from "../../context/AuthContext";
import { useModules } from "../../context/ModulesContext";
import { LibraryItem } from "../../types";
import { ConfirmationModal } from "../shared/modals/ConfirmationModal";
import { Spinner } from "../shared/Spinner";

export function Library() {
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState<LibraryItem[]>([]);
  const history = useHistory();
  const [deleteApplet, setDeleteApplet] = useState<string>();
  const auth = useAuth();
  const { modules } = useModules();

  useEffect(() => {
    let cancelled = false;

    async function loadTemplates() {
      const response = await axios.get(`${APIUrl}/library`);
      if (!cancelled) {
        setItems(response.data);
        setLoading(false);
      }
    }
    loadTemplates();

    return () => {
      cancelled = true;
    };
  }, []);

  async function clone(id: string) {
    setLoading(true);
    const response = await axios.get(`${APIUrl}/library/${id}`);
    history.push(`/applets/config/${response.data.id}`);
  }

  async function del() {
    await axios.delete(`${APIUrl}/library/${deleteApplet}`);
    setItems([...items.filter((x) => x.id !== deleteApplet)]);
    setDeleteApplet(undefined);
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="page-content">
      <Container fluid>
        <h2>Applet Library</h2>
        <p>
          Here you can find pre-built applets that just work out of the box,
          each applet has a simple config for basic customization as well as
          access to the advanced applet editor for full customization.
        </p>
        {items.length > 0 ? (
          <Row className="mt-5">
            {items.map((item, index) => (
              <Col key={index} xl={4} lg={6} className="mb-4">
                <Card style={{ height: "100%", paddingBottom: 40 }}>
                  <Card.Body>
                    <Card.Title>
                      <div className="d-flex">
                        <div>
                          <FontAwesomeIcon icon={faClone} size="3x" />
                        </div>
                        <div style={{ flex: 1 }} className="pl-4">
                          <p style={{ marginBottom: 0, fontSize: 16 }}>
                            {item.mainModule}
                          </p>
                          <h3>{item.name}</h3>
                        </div>
                        {auth.user?.profile.role === "ADMIN" && (
                          <div>
                            <Button
                              variant="primary"
                              onClick={() => setDeleteApplet(item.id)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </Button>
                          </div>
                        )}
                      </div>
                    </Card.Title>
                    <Card.Text
                      style={{ marginTop: 30, marginBottom: 30, fontSize: 14 }}
                    >
                      {item.description}
                    </Card.Text>
                    <Card.Text>
                      Required Modules:
                      <br />
                      <span className="text-primary">
                        {item.requiredModules.join(", ")}
                      </span>
                    </Card.Text>
                    <div
                      style={{
                        position: "absolute",
                        bottom: 20,
                        left: 20,
                        right: 20,
                      }}
                    >
                      {item.requiredModules.filter((mod) =>
                        modules.find((x) => x.id === mod && x.enabled)
                      ).length < item.requiredModules.length ? (
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id="test-tooltip">
                              Please enable all the required modules before
                              cloning this applet!
                            </Tooltip>
                          }
                        >
                          <Button className="disabled" block>
                            Clone Applet
                          </Button>
                        </OverlayTrigger>
                      ) : (
                        <Button
                          block
                          onClick={() => clone(item.id)}
                          disabled={loading}
                        >
                          Clone Applet
                        </Button>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          <p>No Applets Found</p>
        )}
        {deleteApplet && (
          <ConfirmationModal
            title="Delete Applet"
            body="Are you sure you want to permanently delete this applet from the library? This will not affect anyone already using this applet."
            onClose={() => setDeleteApplet(undefined)}
            onConfirm={del}
          />
        )}
      </Container>
    </div>
  );
}

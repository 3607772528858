import { faInfinity, IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, ProgressBar } from "react-bootstrap";
import { formatNumber } from "./utils";

export type StatCardProps = {
  icon: IconDefinition;
  name: string;
  value: number;
  maxValue?: number;
  infinite?: boolean;
};

export function StatCard({
  icon,
  name,
  value,
  maxValue,
  infinite,
}: StatCardProps) {
  return (
    <Card className="stat-card">
      <Card.Body className="pb-3">
        <div className="d-flex align-items-center">
          <div className="flex-grow-1">
            <Card.Text className="count">
              <span className="value">{formatNumber(value)}</span>
              {maxValue && !infinite && `/${formatNumber(maxValue)}`}
              {infinite && maxValue && (
                <>
                  /<FontAwesomeIcon icon={faInfinity} />
                </>
              )}
            </Card.Text>
            <Card.Text className="name">{name}</Card.Text>
          </div>
          <div className="icon">
            <FontAwesomeIcon icon={icon} size="3x" />
          </div>
        </div>

        {!infinite && maxValue ? (
          <ProgressBar now={(value / maxValue) * 100} />
        ) : (
          <div style={{ height: 5 }}></div>
        )}
      </Card.Body>
    </Card>
  );
}

import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { APIUrl, ClientUrl } from "../../Constants";
import { useNotifications } from "../../context/NotificationContext";
import { Applet, Team } from "../../types";
import { SelectDropdown } from "../shared/SelectDropdown";
import { Spinner } from "../shared/Spinner";

type ShareToTeamProps = {
  applet: Applet;
  onClose: () => void;
};

export function ShareToTeam({ applet, onClose }: ShareToTeamProps) {
  const [teamsLoading, setTeamsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [teams, setTeams] = useState<Team[]>([]);
  const [name, setName] = useState(applet.name);
  const [description, setDescription] = useState("");
  const [errors, setErrors] = useState<{ [name: string]: string }>({});
  const [team, setTeam] = useState<string>();
  const notifications = useNotifications();

  useEffect(() => {
    async function load() {
      try {
        const response = await axios.get(`${APIUrl}/teams`);
        const data: Team[] = response.data;
        setTeams(data);
      } catch (e: any) {
        console.log(e);
      } finally {
        setTeamsLoading(false);
      }
    }
    load();
  }, []);

  async function share() {
    setLoading(true);

    const _errors: { [name: string]: string } = {};

    if (!team) {
      _errors["team"] = "This field is required";
    }

    if (!name || name.trim() === "") {
      _errors["name"] = "This field is required";
    }

    setErrors(_errors);

    if (Object.keys(_errors).length === 0) {
      await axios.post(`${APIUrl}/teams/${team}/template`, {
        id: applet.id,
        name,
        description,
      });

      notifications.dispatchNotification(
        "success",
        "Applet Shared",
        `Your applet ${applet.name} has been shared.`
      );
      onClose();
    }

    setLoading(false);
  }

  return (
    <Modal show={true} onHide={onClose} backdrop="static" centered>
      <Modal.Header>Share to Team</Modal.Header>
      <Modal.Body>
        <p>
          Sharing an applet will create a template that anyone in your team can
          use, their applets will not be linked to yours in any way and any
          changes made to your applet must be shared again.
          <br />
          <br />
          In order to share an applet to a team you must have the MOD rank.
        </p>
        {teamsLoading ? (
          <Spinner size="sm" />
        ) : (
          <>
            <p className="mb-0 ml-1">Team</p>
            <div className="mb-3">
              <SelectDropdown
                placeholder="Select a Team"
                items={teams
                  .filter(
                    (t) =>
                      t.activeUser &&
                      t.activeUser.state === "ACCEPTED" &&
                      (t.activeUser.teamRole === "MOD" ||
                        t.activeUser.teamRole === "ADMIN")
                  )
                  .map((t) => ({
                    name: t.name,
                    value: t.id,
                    imageUrl: t.iconUrl || `${ClientUrl}/logo.png`,
                  }))}
                value={team}
                onChange={(value) => setTeam(value)}
                isInvalid={!!errors["team"]}
                errorMessage={errors["team"]}
              />
            </div>
            <Form.Group>
              <Form.Label>Name</Form.Label>
              <Form.Control
                placeholder="Enter Template Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                isInvalid={!!errors["name"]}
              />
              <Form.Control.Feedback type="invalid">
                {errors["name"]}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group>
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Enter Template Description"
                rows={5}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Group>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-primary" onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button onClick={share} disabled={loading}>
          Share
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

import { Button, Modal } from "react-bootstrap";

type ConfirmationModalProps = {
  title: string;
  body: string;
  negativeText?: string;
  positiveText?: string;
  onClose: () => void;
  onConfirm: () => void;
  dark?: boolean;
};

export function ConfirmationModal({
  title,
  body,
  negativeText,
  positiveText,
  onClose,
  onConfirm,
  dark,
}: ConfirmationModalProps) {
  return (
    <Modal show={true} onHide={onClose} centered>
      <Modal.Header closeButton className={`${dark ? "bg-base-bg" : ""}`}>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={`${dark ? "bg-base-bg" : ""}`}>{body}</Modal.Body>
      <Modal.Footer className={`${dark ? "bg-base-bg" : ""}`}>
        <Button
          variant="outline-primary"
          onClick={onClose}
          style={{ minWidth: 100 }}
        >
          {negativeText ? negativeText : "No"}
        </Button>
        <Button variant="primary" onClick={onConfirm} style={{ minWidth: 100 }}>
          {positiveText ? positiveText : "Yes"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

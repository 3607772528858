import { FunctionComponent } from "react";
import { NodeModule } from "../../../../types";
import { ColorPicker } from "../../../shared/inputs/ColorPicker";
import { HueGroupsSelect } from "../../../shared/inputs/HueGroupsSelect";
import { HueLightsSelect } from "../../../shared/inputs/HueLightsSelect";
import { MinecraftServerSelect } from "../../../shared/inputs/MinecraftServerSelect";
import { StringInput } from "../../../shared/inputs/StringInput";
import { ToggleInput } from "../../../shared/inputs/ToggleInput";

export type ConfigElement = {
  id: string;
  name: string;
  description: string;
  module: NodeModule;
  configEditor: boolean;
  component: FunctionComponent<any>;
};

export const elements: { [id: string]: ConfigElement } = {
  //Core Module
  String: {
    id: "String",
    name: "Text Field",
    description: "Allows any input",
    module: "Core",
    configEditor: true,
    component: StringInput,
  },
  Toggle: {
    id: "Toggle",
    name: "Toggle Switch",
    description: "Can only be true or false",
    module: "Core",
    configEditor: true,
    component: ToggleInput,
  },
  HexColorPicker: {
    id: "HexColorPicker",
    name: "Color Picker",
    description: "A HEX color picker",
    module: "Core",
    configEditor: true,
    component: ColorPicker,
  },

  //Minecraft Module
  MinecraftServerSelect: {
    id: "MinecraftServerSelect",
    name: "Minecraft Server Select",
    description: "Select a registered minecraft server",
    module: "Minecraft",
    configEditor: true,
    component: MinecraftServerSelect,
  },

  //PhilipsHue Module
  HueLightsSelect: {
    id: "HueLightsSelect",
    name: "Hue Lights Select",
    description: "Select a light from philips hue",
    module: "PhilipsHue",
    configEditor: true,
    component: HueLightsSelect,
  },
  HueGroupsSelect: {
    id: "HueGroupsSelect",
    name: "Hue Groups Select",
    description: "Select a group from philips hue",
    module: "PhilipsHue",
    configEditor: true,
    component: HueGroupsSelect,
  },
};

import { createElement, useEffect, useState } from "react";
import {
  EditorElement,
  LinkInstance,
  NodeInstance,
} from "../../../../../types";
import {
  calculateContext,
  findNestedValue,
  getNestedError,
  getPath,
  setNestedErrors,
  setNestedValue,
} from "../../../../shared/utils";
import { elements } from "../../ConfigEditor/elements";
import { useGlobalVariables } from "./InternalElements/GlobalVariableContext";

type InputElementWrapperProps = {
  node: NodeInstance;
  nodes: NodeInstance[];
  links: LinkInstance[];
  data: { [key: string]: unknown };
  setData: (data: { [key: string]: unknown }) => void;
  errors: { [id: string]: string | null };
  setErrors: (data: { [id: string]: string }) => void;
  showErrors: boolean;
  element: EditorElement;
  parents: string[];
};

export function InputElementWrapper({
  node,
  nodes,
  links,
  data,
  setData,
  errors,
  setErrors,
  showErrors,
  element,
  parents,
}: InputElementWrapperProps) {
  const global = useGlobalVariables();
  const [shouldRender, setShouldRender] = useState(true);
  const path = getPath(parents, element.id);

  useEffect(() => {
    if (element.requirements) {
      let _shouldRender = true;

      element.requirements.forEach((req) => {
        const value = findNestedValue(data, parents, req);
        if (!value || value === "false") {
          _shouldRender = false;
        }
      });
      setShouldRender(_shouldRender);
    }
  }, [data, parents, element.requirements]);

  if (!shouldRender) {
    return null;
  }

  return (
    <>
      {createElement(elements[element.type].component, {
        id: path,
        name: element.label,
        placeholder: element.placeholder,
        value: findNestedValue(data, parents, element.id) as string,
        setValue: (value: string) =>
          setNestedValue(data, setData, parents, element.id, value),
        error: showErrors
          ? getNestedError(errors, parents, element.id)
          : undefined,
        variables: calculateContext(node, global.variables, nodes, links),
        required: element.required,
        min: element.min,
        max: element.max,
        multiline: element.multiline,
        onValidate: (error: string) =>
          setNestedErrors(errors, setErrors, parents, element.id, error),
      })}
    </>
  );
}

import { faPencilAlt, faPlus, faTrash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
  Table,
} from "react-bootstrap";
import { APIUrl } from "../../../Constants";
import { SimpleCommand } from "../../../types";
import { ConfirmationModal } from "../../shared/modals/ConfirmationModal";
import { Spinner } from "../../shared/Spinner";
import { CommandModal } from "./Modal/CommandModal";

export function SimpleCommands() {
  const [loading, setLoading] = useState(true);
  const [showNewModal, setShowNewModal] = useState(false);
  const [commands, setCommands] = useState<SimpleCommand[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState<SimpleCommand>();
  const [showEditModal, setShowEditModal] = useState<SimpleCommand>();
  const [helperCommand, setHelperCommand] = useState(false);
  const [modEdit, setModEdit] = useState(false);

  useEffect(() => {
    let cancelled = false;

    async function get() {
      const response = await axios.get(`${APIUrl}/twitch/command`);
      const helperResponse = await axios.get(`${APIUrl}/twitch/command/helper`);
      const modResponse = await axios.get(`${APIUrl}/twitch/command/mods`);
      if (!cancelled) {
        setCommands(response.data);
        setHelperCommand(helperResponse.data);
        setModEdit(modResponse.data);
        setLoading(false);
      }
    }
    get();

    return () => {
      cancelled = true;
    };
  }, []);

  async function toggleHelper() {
    try {
      setHelperCommand(!helperCommand);
      const response = await axios.patch(`${APIUrl}/twitch/command/helper`);
      setHelperCommand(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function toggleModEdit() {
    try {
      setModEdit(!helperCommand);
      const response = await axios.patch(`${APIUrl}/twitch/command/mods`);
      setModEdit(response.data);
    } catch (error) {
      console.log(error);
    }
  }

  async function deleteCmd() {
    if (showDeleteModal) {
      await axios.delete(`${APIUrl}/twitch/command/${showDeleteModal.name}`);
      setCommands((cmds) =>
        cmds.filter((x) => x.name !== showDeleteModal.name)
      );
      setModEdit(false);
      setHelperCommand(false);
    }

    setShowDeleteModal(undefined);
  }

  return (
    <Container fluid>
      <h1>Simple Bot Commands</h1>
      <p>
        These commands make the Twitch bot respond with a predefined message
        that requires no logic. As these commands are very simple they are
        stored on the bot itself and thus are much faster than applet commands
        and do not count towards you invocation limit! Because of the way these
        commands work you are limited to 25 per user on all plans.
      </p>

      <div className="d-flex mt-5 align-items-center">
        <div className="flex-grow-1">
          <Button
            onClick={() => setShowNewModal(true)}
            disabled={commands.length >= 25}
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" /> New Command
          </Button>
        </div>
        <p className="mb-0">Usage: {commands.length}/25</p>
      </div>

      {commands.length >= 1 ? (
        <Row className="mt-3">
          <Col lg={6}>
            <Card>
              <Card.Header>
                <Card.Title>
                  Toggle the helper command
                  <Form.Check
                    id="helpercommand"
                    type="switch"
                    custom
                    checked={helperCommand}
                    className="float-right"
                    disabled={commands.length <= 0}
                    onChange={(e) => {
                      toggleHelper();
                    }}
                  />
                </Card.Title>
              </Card.Header>
              <Card.Body>
                Enabling this will add <strong>!commands</strong> to your bot
                commands that when called, will list all of your commands (both
                here and any you made in your applets) in a comma separated
                list. This allows your users to see what commands are available
                to call and will be updated automatically as you add/remove
                commands.
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6}>
            <Card>
              <Card.Header>
                <Card.Title className="mb-0">
                  Allow Moderators Control {"  "}
                  <Badge variant="primary">BETA</Badge>
                  <Form.Check
                    id="modEdit"
                    type="switch"
                    custom
                    checked={modEdit}
                    className="float-right"
                    disabled={commands.length <= 0}
                    onChange={(e) => {
                      toggleModEdit();
                    }}
                  />
                </Card.Title>
              </Card.Header>
              <Card.Body>
                Enabling this will add 3 commands that your moderators can call:
                <br />
                <strong>!addcommand, !editcommand and !deletecommand</strong>
                <br />
                These allow your moderators to make changes to your simple
                commands for you. The commands take 2 arguments, first for the
                command name and second for the response. Moderators can use
                {` {user_name} and {arg_1}, {arg_2} etc. in the response and these parameters will get passed through`}
                . <strong>!deletecommand</strong> just takes 1 argument for the
                command name
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : null}

      {loading ? (
        <Spinner />
      ) : commands.length <= 0 ? (
        <p className="mt-3">
          No commands found! Click the button above to create one.
        </p>
      ) : (
        <Card className="mt-3">
          <Table striped>
            <thead>
              <tr>
                <td>Name</td>
                <td>Response</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {commands.map((cmd) => (
                <tr key={cmd.name}>
                  <td>{cmd.name}</td>
                  <td>{cmd.response}</td>
                  <td className="text-right">
                    <Button
                      size="sm"
                      variant="success"
                      onClick={() => setShowEditModal(cmd)}
                    >
                      <FontAwesomeIcon icon={faPencilAlt} /> Edit
                    </Button>
                    <Button
                      className="ml-3"
                      size="sm"
                      onClick={() => setShowDeleteModal(cmd)}
                    >
                      <FontAwesomeIcon icon={faTrash} /> Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card>
      )}

      {showNewModal && (
        <CommandModal
          onAdd={(cmd) => {
            setCommands([...commands, cmd]);
            setShowNewModal(false);
          }}
          onClose={() => setShowNewModal(false)}
        />
      )}

      {showEditModal && (
        <CommandModal
          onAdd={(cmd) => {
            setCommands([...commands.filter((x) => x.name !== cmd.name), cmd]);
            setShowEditModal(undefined);
          }}
          onClose={() => setShowEditModal(undefined)}
          cmd={showEditModal}
        />
      )}

      {showDeleteModal && (
        <ConfirmationModal
          title="Delete Command"
          body="Are you sure you want to permanently delete this command? This cannot be undone!"
          onClose={() => setShowDeleteModal(undefined)}
          onConfirm={deleteCmd}
        />
      )}
    </Container>
  );
}

import { faCubes, faTrash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Card, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { APIUrl } from "../../Constants";
import { useModules } from "../../context/ModulesContext";
import { Team, TeamTemplate } from "../../types";
import { ConfirmationModal } from "../shared/modals/ConfirmationModal";
import { Spinner } from "../shared/Spinner";

type AppletSharingProps = {
  team: Team;
};

export function AppletSharing({ team }: AppletSharingProps) {
  const [loading, setLoading] = useState(true);
  const [templates, setTemplates] = useState<TeamTemplate[]>([]);
  const [deleting, setDeleting] = useState<TeamTemplate>();
  const [cloning, setCloning] = useState<TeamTemplate>();
  const { modules } = useModules();
  const history = useHistory();

  useEffect(() => {
    let cancelled = false;

    async function load() {
      const response = await axios.get(`${APIUrl}/teams/${team.id}/template`);

      if (!cancelled) {
        setTemplates(response.data);
        setLoading(false);
      }
    }
    load();

    return () => {
      cancelled = true;
    };
  }, [team.id]);

  async function deleteTemplate() {
    if (deleting) {
      await axios.delete(`${APIUrl}/teams/${team.id}/template/${deleting.id}`);
      setTemplates(templates.filter((t) => t.id !== deleting.id));
      setDeleting(undefined);
    }
  }

  async function cloneTemplate() {
    if (cloning) {
      const response = await axios.get(
        `${APIUrl}/teams/${team.id}/template/${cloning.id}`
      );
      history.push(`/applets/config/${response.data.id}`);
    }
  }

  return (
    <>
      <Card>
        <Card.Header>
          <div className="d-flex align-items-center flex-grow-1">
            <div className="mr-3">
              <FontAwesomeIcon icon={faCubes} size="2x" />
            </div>
            <div>
              <h4 className="mb-0">Shared Applets</h4>
              <p className="mb-0">A list of applets shared to your team</p>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          {loading ? (
            <Spinner size="sm" />
          ) : (
            <>
              {templates.length === 0 && (
                <p className="mb-0">This team has no templates.</p>
              )}
              {templates.map((template, index) => (
                <Col key={index} xl={4} lg={6} className="mb-4">
                  <Card
                    style={{ height: "100%", paddingBottom: 40 }}
                    className="bg-base-bg"
                  >
                    <Card.Body>
                      <Card.Title>
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <h3 className="mb-0">{template.name}</h3>
                            <small>by {template.creator}</small>
                          </div>
                          {team.activeUser &&
                            (team.activeUser.teamRole === "MOD" ||
                              team.activeUser.teamRole === "ADMIN") && (
                              <div>
                                <Button
                                  variant="primary"
                                  onClick={() => setDeleting(template)}
                                >
                                  <FontAwesomeIcon icon={faTrash} />
                                </Button>
                              </div>
                            )}
                        </div>
                      </Card.Title>
                      <Card.Text
                        style={{
                          marginTop: 30,
                          marginBottom: 30,
                          fontSize: 14,
                        }}
                      >
                        {template.description || "No Description"}
                      </Card.Text>
                      <Card.Text>
                        Required Modules:
                        <br />
                        <span className="text-primary">
                          {template.requiredModules.join(", ")}
                        </span>
                      </Card.Text>
                      <div
                        style={{
                          position: "absolute",
                          bottom: 20,
                          left: 20,
                          right: 20,
                        }}
                      >
                        {template.requiredModules.filter((mod) =>
                          modules.find((x) => x.id === mod && x.enabled)
                        ).length < template.requiredModules.length ? (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={
                              <Tooltip id="test-tooltip">
                                Please enable all the required modules before
                                using this template!
                              </Tooltip>
                            }
                          >
                            <Button className="disabled" block>
                              Clone Template
                            </Button>
                          </OverlayTrigger>
                        ) : (
                          <Button
                            block
                            onClick={() => setCloning(template)}
                            disabled={loading}
                          >
                            Clone Template
                          </Button>
                        )}
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </>
          )}
        </Card.Body>
      </Card>
      {deleting && (
        <ConfirmationModal
          title="Delete Template"
          body="Are you sure you want to delete this template?"
          onConfirm={deleteTemplate}
          onClose={() => setDeleting(undefined)}
        />
      )}
      {cloning && (
        <ConfirmationModal
          title="Clone Template"
          body="WARNING: This template has not been verified by the StreamHydra team, use at your own risk! Are you sure you want to clone this template? "
          onConfirm={cloneTemplate}
          onClose={() => setCloning(undefined)}
        />
      )}
    </>
  );
}

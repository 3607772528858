import axios from "axios";
import React, { useEffect, useState } from "react";
import { Badge, Button, ListGroup, Modal } from "react-bootstrap";
import { APIUrl } from "../../../Constants";
import { AppletStatus, Paged } from "../../../types";
import { Paginator } from "../../shared/Paginator";
import { Spinner } from "../../shared/Spinner";

type TriggerModalProps = {
  onClose: () => void;
};

type UsedApplet = {
  id: string;
  name: string;
  owner: string;
  status: AppletStatus;
  displayName: string;
  triggersInUse: string[];
};

export function TriggerModal({ onClose }: TriggerModalProps) {
  const [loading, setLoading] = useState(true);
  const [applets, setApplets] = useState<Paged<UsedApplet>>();
  const [page, setPage] = useState(1);

  useEffect(() => {
    async function load() {
      const response = await axios.get(
        `${APIUrl}/teams/triggers-used?page=${page}`
      );
      console.log(response.data);
      setApplets(response.data);
      setLoading(false);
    }

    load();
  }, [page]);

  return (
    <Modal show={true} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Who is using your triggers?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!applets || loading ? (
          <Spinner />
        ) : (
          <>
            <p>
              Here you can see all the applets by users in your team that use
              any of your shared triggers.
            </p>
            {applets.results.length > 0 ? (
              <>
                <ListGroup className="mb-3">
                  {applets.results.map((applet) => (
                    <ListGroup.Item>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          {applet.name} <br />
                          by {applet.displayName}
                          <br />
                          <small>
                            Using: {applet.triggersInUse.join(", ")}
                          </small>
                        </div>
                        <div>
                          <Badge
                            variant={
                              applet.status === "DISABLED"
                                ? "danger"
                                : "success"
                            }
                          >
                            {applet.status}
                          </Badge>
                        </div>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
                <div className="d-flex justify-content-center">
                  <Paginator
                    page={page}
                    setPage={setPage}
                    totalResults={applets.totalResults}
                    pageSize={applets.pageSize}
                  />
                </div>
              </>
            ) : (
              <p>There are currently no users using any of your triggers.</p>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

import { faPlus, faTimes } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Applet, ConfigGroup } from "../../../../types";
import { ConfigSidenav } from "./ConfigSidenav";
import { Group } from "./Group";
import { v4 as uuid } from "uuid";

type ConfigEditorProps = {
  open: boolean;
  onClose: () => void;
  data: Applet;
  setData: (data: Applet) => void;
};

export function ConfigEditor({
  open,
  onClose,
  data,
  setData,
}: ConfigEditorProps) {
  const [zIndex, setZIndex] = useState(-100);
  const [dragging, setDragging] = useState<ConfigGroup>();

  function onGroupDragStart(group: ConfigGroup) {
    setDragging(group);
  }

  function onGroupDragEnd() {
    setDragging(undefined);
  }

  function onGroupDragOver(group: ConfigGroup) {
    if (dragging && group !== dragging) {
      let oldIndex = data.configGroups.indexOf(dragging);
      let newIndex = data.configGroups.indexOf(group);

      setData({
        ...data,
        configGroups: [
          ...data.configGroups.map((x, index) =>
            index === oldIndex ? group : index === newIndex ? dragging : x
          ),
        ],
      });
    }
  }

  useEffect(() => {
    if (open) {
      setZIndex(200);
    } else {
      setTimeout(() => setZIndex(-100), 200);
    }
  }, [open]);

  return (
    <div
      className={`config-editor ${open ? "open" : ""}`}
      style={{ zIndex: zIndex }}
    >
      <ConfigSidenav />
      <div className="close m-3" onClick={onClose}>
        <FontAwesomeIcon icon={faTimes} size="lg" />
      </div>
      <div className="config-editor-wrapper">
        <h2>Config Editor</h2>
        <p>
          Welcome to the Config Editor, here you can create a config for your
          applet for easier editing for yourself and others.
          <br />
          Create groups and panels to place config elements in the desired order
          that create global variables for use in your applet.
          <br />
          Drag & Drop Panels and Elements to re-order them if necessary.
          <br />
          Drag Elements from the list on the left onto the panels to create
          variables.
        </p>
        <Button
          onClick={() =>
            setData({
              ...data,
              configGroups: [
                ...data.configGroups,
                { id: uuid(), name: "New Group", panels: [] },
              ],
            })
          }
          className="mr-2"
        >
          <FontAwesomeIcon icon={faPlus} /> New Group
        </Button>
        {data.configGroups.map((group) => (
          <Group
            key={group.id}
            group={group}
            setGroup={(g) =>
              setData({
                ...data,
                configGroups: [
                  ...data.configGroups.map((x) => (x.id === g.id ? g : x)),
                ],
              })
            }
            deleteGroup={() =>
              setData({
                ...data,
                configGroups: [
                  ...data.configGroups.filter((x) => group.id !== x.id),
                ],
                globalVariables: [
                  ...data.globalVariables.filter(
                    (x) => x.category !== group.id
                  ),
                ],
              })
            }
            data={data}
            setData={setData}
            onDragStart={() => onGroupDragStart(group)}
            onDragEnd={() => onGroupDragEnd()}
            onDragOver={() => onGroupDragOver(group)}
          />
        ))}
      </div>
    </div>
  );
}

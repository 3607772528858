import { faUserEdit } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createElement, useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { Applet, GlobalVariable, Variable } from "../../../../../types";
import { HelpIcon } from "../../../../shared/HelpIcon";
import { useGlobalVariables } from "../../NodeEditor/Modals/InternalElements/GlobalVariableContext";
import { ConfigElement } from "../elements";
import { VariablesList } from "../VariablesList";

type ConfigElementProps = {
  data: Applet;
  category?: string;
  subcategory?: string;
  element: ConfigElement;
  onClose: () => void;
  editVariable?: GlobalVariable;
};

export function ConfigElementModal({
  data,
  category,
  subcategory,
  element,
  onClose,
  editVariable,
}: ConfigElementProps) {
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [variable, setVariable] = useState<GlobalVariable>(
    editVariable
      ? editVariable
      : {
          id: "",
          category,
          subcategory,
          defaultValue: "",
          name: "",
          type: element.id,
          data: {},
          variables: {},
        }
  );
  const [variables, setVariables] = useState<Variable[]>([]);

  const global = useGlobalVariables();

  function save() {
    const _errors: { [key: string]: string } = {};

    if (!variable.id) _errors["id"] = "This field is required";
    if (!editVariable && global.variables.find((v) => v.id === variable.id))
      _errors["id"] = "A variable with this id already exists";
    if (!variable.name) _errors["name"] = "This field is required";

    console.log(_errors);

    if (Object.keys(_errors).length > 0) {
      setErrors(_errors);
    } else {
      if (editVariable) {
        global.setVariables([
          ...global.variables.map((v) =>
            v.id === editVariable.id ? variable : v
          ),
        ]);
      } else {
        global.setVariables([
          ...global.variables.filter((v) => v.id !== variable.id),
          variable,
        ]);
      }

      onClose();
    }
  }

  useEffect(() => {
    const _variables: Variable[] = [];

    data.nodes.map((node) =>
      node.node.outputs.map((out) =>
        Object.keys(out.data).forEach((x) => {
          _variables.push({
            value: x,
            source: "context",
            icon: node.node.type,
            origin: node.node.name,
            originId: node.nodeId,
            type: out.data[x].type,
            description: out.data[x].description,
            definitions: out.data[x].definitions,
          });
        })
      )
    );

    setVariables(_variables);
  }, [data.nodes]);

  return (
    <Modal show={true} onHide={onClose} backdrop="static" size="lg" centered>
      <Modal.Header className="pb-0">
        <Modal.Title>Config Element</Modal.Title>
        <Modal.Title>
          <FontAwesomeIcon icon={faUserEdit} size="lg" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex" style={{ gap: 20 }}>
          <div className="flex-grow-1 w-50">
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                save();
              }}
            >
              <h5>
                <strong>{element.name}</strong>
              </h5>
              <p>{element.description}</p>
              <Form.Group>
                <Form.Label>
                  Variable ID (unique, be as specific as possible)
                </Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text className="bg-base-bg">g_</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    type="text"
                    placeholder="my_awesome_variable"
                    value={variable.id}
                    onChange={(e) =>
                      setVariable({
                        ...variable,
                        id: e.target.value
                          .toLowerCase()
                          .replaceAll(" ", "_")
                          .replaceAll(/[^a-z0-9_]+/g, ""),
                      })
                    }
                    isInvalid={!!errors["id"]}
                    disabled={!!editVariable}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors["id"]}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group>
                <Form.Label>Display Name (to show in the config)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="My Variable"
                  value={variable.name}
                  onChange={(e) =>
                    setVariable({ ...variable, name: e.target.value })
                  }
                  isInvalid={!!errors["name"]}
                />
                <Form.Control.Feedback type="invalid">
                  {errors["name"]}
                </Form.Control.Feedback>
              </Form.Group>
              {createElement(element.component, {
                id: element.id,
                name: "Default Value (optional)",
                value: variable.defaultValue,
                setValue: (val: string) =>
                  setVariable({
                    ...variable,
                    defaultValue: val,
                  }),
                variables,
              })}
              <div style={{ display: "flex", gap: 10 }}>
                <div style={{ flex: 1 }}>
                  <Button variant="outline-primary" onClick={onClose} block>
                    Cancel
                  </Button>
                </div>
                <div style={{ flex: 1 }}>
                  <Button variant="primary" type="submit" block>
                    Save
                  </Button>
                </div>
              </div>
            </Form>
          </div>
          <div className="flex-grow-1 w-50">
            <h5>
              <strong>Variables available to Config</strong>
              <HelpIcon
                id="variable-list"
                tooltip="Here you can select which variables are available in the config
                for users to select. This list is compiled from all the nodes in
                the applet, it is up to you to make sure all selected variables
                are available when needed."
              />
            </h5>
            <VariablesList
              variables={variables}
              variable={variable}
              setVariable={setVariable}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

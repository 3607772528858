import { Form } from "react-bootstrap";
import { EditorElement } from "../../../../../../types";
import {
  findNestedValue,
  getNestedError,
  setNestedErrors,
  setNestedValue,
} from "../../../../../shared/utils";

type CustomSelectProps = {
  data: { [key: string]: unknown };
  setData: (data: { [key: string]: unknown }) => void;
  errors: { [id: string]: string | null };
  setErrors: (data: { [id: string]: string | null }) => void;
  showErrors: boolean;
  element: EditorElement;
  parents: string[];
};

export function CustomSelect({
  data,
  setData,
  errors,
  setErrors,
  showErrors,
  element,
  parents,
}: CustomSelectProps) {
  function validate(value: string) {
    if (element.required) {
      setNestedErrors(
        errors,
        setErrors,
        parents,
        element.id,
        !value || value === "" ? "This field is required" : ""
      );
    }
  }

  return (
    <>
      <Form.Group>
        <Form.Label>{element.label}</Form.Label>
        <div style={{ display: "flex", gap: 10 }}>
          <div style={{ flex: 1 }}>
            {/* {variable ? (
              <Form.Control
                type="text"
                disabled={true}
                value={`Using variable: ${variable}`}
                className=".bg-base-bg"
              />
            ) : ( */}
            <Form.Control
              as="select"
              value={findNestedValue(data, parents, element.id) as string}
              onChange={(e) => {
                setNestedValue(
                  data,
                  setData,
                  parents,
                  element.id,
                  e.target.value
                );
                validate(e.target.value);
              }}
              isInvalid={
                showErrors && !!getNestedError(errors, parents, element.id)
              }
            >
              <option>{element.placeholder}</option>
              {element.options &&
                (element.options as string[]).map((opt) => (
                  <option key={opt} value={opt}>
                    {opt}
                  </option>
                ))}
            </Form.Control>
            {/* )} */}
          </div>
          {/* <Button
            variant={varMenu.menu === path ? "primary" : "outline-primary"}
            onClick={() => {
              if (variable) {
                deleteNestedValue(data, setData, parents, element.id);
              } else {
                varMenu.setMenu(varMenu.menu === path ? undefined : path);
              }
            }}
            style={{ height: 38 }}
          >
            <FontAwesomeIcon icon={variable ? faTrash : faCog} />
          </Button> */}
        </div>
        <Form.Control.Feedback type="invalid">
          {getNestedError(errors, parents, element.id)}
        </Form.Control.Feedback>
      </Form.Group>
      {/* {varMenu.menu === path && (
        <ContextModal
          nodes={nodes}
          links={links}
          node={node}
          onBack={() =>
            varMenu.setMenu(varMenu.menu === path ? undefined : path)
          }
          onUse={(variable) => {
            setNestedValue(data, setData, parents, element.id, variable);

            const _var = global.variables.find(
              (v) => `{g_${v.id}}` === variable
            );
            if (_var) {
              _var.data = {
                ..._var.data,
                options: [element.placeholder, ...element.options].join(", "),
              };
              global.setVariables([
                ...global.variables.filter((v) => v.id !== _var.id),
                _var,
              ]);
            }

            setVariable(variable);
            varMenu.setMenu(undefined);
            validate(variable);
          }}
        />
      )} */}
    </>
  );
}

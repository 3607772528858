import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { createContext } from "react";
import { APIUrl } from "../Constants";

type ThemeContextProps = {
  theme: string;
  changeTheme: (theme: string) => void;
  refresh: () => void;
};

const ThemeContext = createContext<ThemeContextProps>({
  theme: "Dark",
  changeTheme: (theme: string) => {},
  refresh: () => {},
});

export function useTheme() {
  return useContext(ThemeContext);
}

type ThemeProviderProps = {
  children: React.ReactNode;
};

export function ThemeProvider({ children }: ThemeProviderProps) {
  const [theme, setTheme] = useState<string>("Dark");

  const refresh = useCallback(async () => {
    try {
      const response = await axios.get(`${APIUrl}/user/theme`);
      if (response && response.data) {
        setTheme(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  async function changeTheme(theme: string) {
    setTheme(theme);

    try {
      await axios.post(`${APIUrl}/user/theme?theme=${theme}`);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    document.body.className = `theme theme-${theme.toLowerCase()}`;
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ refresh, theme, changeTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}

import { DragEvent, useCallback, useEffect, useRef, useState } from "react";
import {
  Coords,
  NodeInstance,
  ActiveLinkData,
  LinkInstance,
  NodeOption,
  NodeError,
  RectCoords,
} from "../../../../types";
import { useWindowDimensions } from "../../../shared/useWindowDimensions";
import pixelWidth from "string-pixel-width";
import _ from "underscore";
import { Node } from "./Elements/Node";
import { v4 as uuid } from "uuid";
import { ActiveLink } from "./Elements/ActiveLink";
import { Link } from "./Elements/Link";
import { Connectors } from "./Elements/Connectors";
import { ContextMenu } from "./Elements/ContextMenu";
import { ConfirmationModal } from "../../../shared/modals/ConfirmationModal";
import { NodeEditor } from "./NodeEditor";
import { InfoModal } from "../../../shared/modals/InfoModal";
import { useLocation } from "react-router";
import qs from "qs";
import { Button, ButtonToolbar, Form } from "react-bootstrap";
import { SelectBox } from "./Elements/SelectBox";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faPaste } from "@fortawesome/pro-light-svg-icons";
import { DeadNode } from "./Elements/DeadNode";

type DragData = {
  nodeId: string;
  offsetX: number;
  offsetY: number;
};

type ContextMenuData = {
  nodeId: string;
  coords: Coords;
  connections: number;
};

type NodeViewerProps = {
  nodes: NodeInstance[];
  links: LinkInstance[];
  setNodes: (nodes: NodeInstance[]) => void;
  setLinks: (links: LinkInstance[]) => void;
  setNodesAndLinks: (nodes: NodeInstance[], links: LinkInstance[]) => void;
  nodeOptions: NodeOption[];
  scale: number;
  setScale: (scale: number) => void;
  snap: boolean;
  setSnap: (snap: boolean) => void;
  showGrid: boolean;
  setShowGrid: (show: boolean) => void;
};

type DoubleClickData = {
  nodeId: string;
};

export function NodeViewer({
  nodes,
  links,
  setNodes,
  setLinks,
  setNodesAndLinks,
  nodeOptions,
  scale,
  setScale,
  snap,
  setSnap,
  showGrid,
  setShowGrid,
}: NodeViewerProps) {
  const { width, height } = useWindowDimensions();
  const svgRef = useRef<SVGSVGElement>(null);
  const svgWidth = width - 430;
  const svgHeight = height - 250;
  const [coords, setCoords] = useState<Coords>({ x: 0, y: 0 });

  function toSvgCoords(x: number, y: number) {
    const p = svgRef.current!.createSVGPoint();
    p.x = x;
    p.y = y;
    return p.matrixTransform(svgRef.current!.getScreenCTM()!.inverse());
  }

  function onDrop(e: DragEvent<SVGSVGElement>) {
    const sidenav = e.dataTransfer.getData("drag_origin") === "sidenav";
    const node = _.where(nodeOptions, {
      id: e.dataTransfer.getData("node"),
    })[0];

    if (sidenav && node) {
      if (node.unique) {
        if (nodes.filter((n) => n.node.id === node.id).length > 0) {
          setShowUniqueWarning(true);
          return;
        }
      }

      const pos = toSvgCoords(e.pageX, e.pageY);
      const id = uuid();

      let x =
        pos.x -
        (node.id === "core_label"
          ? calculateTextWidth("") / 2
          : nodeWidth(node) / 2);
      let y = pos.y - (node.id === "core_label" ? gridSize : nodeHeight() / 2);

      if (snap) {
        x = Math.round(x / gridSize) * gridSize;
        y = Math.round(y / gridSize) * gridSize;
      }

      setNodes([
        ...nodes,
        {
          id: id,
          node,
          x,
          y,
          data: {},
          module: node.module,
          nodeId: node.id,
          sharedUserPairs: {},
          requiresSetup: node.requiresSetup,
        },
      ]);
    }
  }

  function nodeHeight() {
    return 75;
  }

  function nodeWidth(node: NodeOption) {
    if (!node) {
      return Math.round(380 / gridSize) * gridSize;
    }

    let width =
      pixelWidth(node.name, { font: "open sans", size: 20 }) +
      (node.input ? 200 : 150) +
      (node.outputs.length > 1 ? 40 : 0);

    width = Math.round(width / gridSize) * gridSize;

    return width;
  }

  function calculateTextWidth(text: string) {
    let width = pixelWidth(text, { font: "open sans", size: 16 });

    width = 25 + Math.ceil(width / gridSize) * gridSize;

    return width;
  }

  const [dragging, setDragging] = useState<DragData | null>(null);
  const [mousePos, setMousePos] = useState<Coords | null>(null);
  const [activeLink, setActiveLink] = useState<ActiveLinkData | null>(null);
  const [selectBox, setSelectBox] = useState<RectCoords | null>(null);

  const [selectedNodes, setSelectedNodes] = useState<string[]>([]);
  const [draggingGroup, setDraggingGroup] = useState<Coords[] | null>(null);

  const [contextMenu, setContextMenu] = useState<ContextMenuData | null>(null);

  const [deleteModal, setDeleteModal] = useState<string | null>(null);
  const [unlinkModal, setUnlinkModal] = useState<string | null>(null);

  const [editingNode, setEditingNode] = useState<string | null>(null);

  const [showUniqueWarning, setShowUniqueWarning] = useState(false);

  const [doubleClick, setDoubleClick] = useState<DoubleClickData>();

  const location = useLocation();
  const [error, setError] = useState<NodeError>();

  const gridSize = 25;

  useEffect(() => {
    var query = qs.parse(location.search, { ignoreQueryPrefix: true });

    if (query.errorNode && query.message) {
      setError({
        nodeId: query.errorNode.toString(),
        message: query.message.toString(),
      });
    }
  }, [location]);

  useEffect(() => {
    function onMouseUp(e: MouseEvent) {
      if (svgRef.current && !svgRef.current.contains(e.target as Node)) {
        setDragging(null);
        setActiveLink(null);
        setSelectBox(null);
      }

      setMousePos(null);
    }

    window.addEventListener("mouseup", onMouseUp);

    return () => window.removeEventListener("mouseup", onMouseUp);
  }, []);

  function onMouseDown(e: React.MouseEvent) {
    const pos = toSvgCoords(e.pageX, e.pageY);

    if (contextMenu) {
      setContextMenu(null);
    }

    if (e.button === 0) {
      const hits = nodes.filter((node) =>
        node.node && node.node.id === "core_label"
          ? pos.x >= node.x &&
            pos.x <=
              node.x + calculateTextWidth(node.data["label"] as string) &&
            pos.y >= node.y &&
            pos.y <= node.y + gridSize
          : pos.x >= node.x &&
            pos.x <= node.x + nodeWidth(node.node) &&
            pos.y >= node.y &&
            pos.y <= node.y + nodeHeight()
      );

      if (hits && hits.length >= 1) {
        const node = hits[hits.length - 1];

        if (selectedNodes.indexOf(node.id) === -1) {
          setSelectedNodes([]);
          setDraggingGroup(null);
        } else {
          setDraggingGroup(
            nodes.map((node) => ({ x: pos.x - node.x, y: pos.y - node.y }))
          );
          return;
        }

        if (e.target instanceof SVGCircleElement) {
          const circle = e.target as SVGCircleElement;
          if (
            circle.classList.contains("connector") &&
            circle.classList.contains("output")
          ) {
            setActiveLink({
              nodeId: node.id,
              outputId: circle.id.split("#")[1],
              mouseX: pos.x,
              mouseY: pos.y,
            });
            return;
          }
        }

        setDragging({
          nodeId: node.id,
          offsetX: pos.x - node.x,
          offsetY: pos.y - node.y,
        });

        if (node.node) {
          if (doubleClick) {
            if (doubleClick.nodeId === node.id) {
              setEditingNode(doubleClick.nodeId);
            }
            setDoubleClick(undefined);
          } else {
            setDoubleClick({
              nodeId: node.id,
            });
          }
        }

        return;
      }

      setSelectBox({ x1: pos.x, y1: pos.y, x2: pos.x, y2: pos.y });
      return;
    }

    setMousePos({ x: pos.x, y: pos.y });
    setDragging(null);
  }

  function onMouseUp(e: React.MouseEvent) {
    if (activeLink) {
      if (e.target instanceof SVGCircleElement) {
        const circle = e.target as SVGCircleElement;
        if (
          circle.classList.contains("connector") &&
          circle.classList.contains("input")
        ) {
          if (
            activeLink.nodeId !== circle.id &&
            !_.find(
              links,
              (link) =>
                link.nodeId === activeLink.nodeId &&
                link.outputId === activeLink.outputId &&
                link.inputId === circle.id
            )
          ) {
            setLinks([
              ...links,
              {
                nodeId: activeLink.nodeId,
                outputId: activeLink.outputId,
                inputId: circle.id,
              },
            ]);
          }
        }
      }
    }

    if (selectBox) {
      const x = selectBox.x1 > selectBox.x2 ? selectBox.x2 : selectBox.x1;
      const y = selectBox.y1 > selectBox.y2 ? selectBox.y2 : selectBox.y1;
      const width =
        selectBox.x1 > selectBox.x2
          ? selectBox.x1 - selectBox.x2
          : selectBox.x2 - selectBox.x1;
      const height =
        selectBox.y2 > selectBox.y1
          ? selectBox.y2 - selectBox.y1
          : selectBox.y1 - selectBox.y2;

      const selected = nodes
        .filter((node) => {
          return (
            x + width >= node.x &&
            x <= node.x + nodeWidth(node.node) &&
            y <= node.y + nodeHeight() &&
            y + height >= node.y
          );
        })
        .map((node) => node.id);

      setSelectedNodes(selected);

      if (selected.length === 0) {
        setDraggingGroup(null);
      }

      setSelectBox(null);
    }

    setDragging(null);
    setActiveLink(null);

    if (draggingGroup !== null) {
      setDraggingGroup(null);
      setSelectedNodes([]);
    }
  }

  function updatePosition(
    node: NodeInstance,
    pos: DOMPoint,
    offsetX: number,
    offsetY: number
  ) {
    return snap
      ? {
          ...node,
          x: Math.round((pos.x - offsetX) / gridSize) * gridSize,
          y: Math.round((pos.y - offsetY) / gridSize) * gridSize,
        }
      : {
          ...node,
          x: pos.x - offsetX,
          y: pos.y - offsetY,
        };
  }

  function onMouseMove(e: React.MouseEvent) {
    const pos = toSvgCoords(e.pageX, e.pageY);

    if (dragging !== null) {
      setNodes(
        nodes.map((node) => {
          if (node.id === dragging.nodeId) {
            return updatePosition(
              node,
              pos,
              dragging.offsetX,
              dragging.offsetY
            );
          }
          return node;
        })
      );
    } else if (draggingGroup !== null && selectedNodes.length > 0) {
      setNodes(
        nodes.map((node, index) => {
          if (selectedNodes.indexOf(node.id) > -1) {
            const dragging = draggingGroup[index];
            return updatePosition(node, pos, dragging.x, dragging.y);
          }
          return node;
        })
      );
    }

    if (mousePos !== null) {
      setCoords({
        x: coords.x - (pos.x - mousePos.x),
        y: coords.y - (pos.y - mousePos.y),
      });
    }

    if (selectBox !== null) {
      setSelectBox({
        x1: selectBox.x1,
        y1: selectBox.y1,
        x2: pos.x,
        y2: pos.y,
      });
    }

    if (activeLink !== null) {
      setActiveLink({ ...activeLink, mouseX: pos.x, mouseY: pos.y });
    }
  }

  function onZoom(e: React.WheelEvent) {
    setScale(Math.min(Math.max(0.125, scale + e.deltaY * 0.001), 4));
  }

  const [clipboard, setClipboard] = useState<string[]>([]);

  const copy = useCallback(() => {
    setClipboard(selectedNodes);
    setSelectedNodes([]);
  }, [selectedNodes]);

  const paste = useCallback(() => {
    const _nodes = nodes.filter((n) => clipboard.indexOf(n.id) > -1);

    const oldIds = _nodes.map((n) => n.id);

    const updatedNodes = _nodes.map((n) => {
      return {
        id: uuid(),
        node: n.node,
        x: n.x + gridSize,
        y: n.y + gridSize,
        data: { ...n.data },
        module: n.module,
        nodeId: n.node.id,
        sharedUserPairs: n.sharedUserPairs,
      };
    });

    const ids = updatedNodes.map((n) => n.id);

    const _links = links
      .filter(
        (l) =>
          clipboard.indexOf(l.nodeId) > -1 && clipboard.indexOf(l.inputId) > -1
      )
      .map((l) => {
        return {
          inputId: ids[oldIds.indexOf(l.inputId)],
          nodeId: ids[oldIds.indexOf(l.nodeId)],
          outputId: l.outputId,
        };
      });

    setNodesAndLinks([...nodes, ...updatedNodes], [...links, ..._links]);

    setSelectedNodes(ids);
    setClipboard(ids);
  }, [nodes, setNodesAndLinks, clipboard, links]);

  const del = useCallback(() => {
    setDeleteModal(selectedNodes[0]);
    setContextMenu(null);
  }, [setDeleteModal, setContextMenu, selectedNodes]);

  useEffect(() => {
    function onKeyDown(e: KeyboardEvent) {
      e = e || window.event;

      if (e.target instanceof HTMLBodyElement) {
        if (e.ctrlKey) {
          if (e.code === "KeyC") {
            copy();
          }

          if (e.code === "KeyV") {
            paste();
          }
        }

        if (e.code === "Delete") {
          del();
        }
      }
    }

    document.body.addEventListener("keydown", onKeyDown);

    return () => {
      document.body.removeEventListener("keydown", onKeyDown);
    };
  }, [copy, paste, del]);

  return (
    <div className="mt-2">
      <ButtonToolbar className="bg-base node-toolbar">
        <div
          style={{ flex: 1, display: "flex", gap: 30, alignItems: "center" }}
        >
          Node Editor
          <Form.Check
            type="switch"
            id="snap-to-grid"
            label="Snap to Grid"
            checked={snap}
            onChange={(e) => {
              if (e.target.checked) {
                setNodes(
                  nodes.map((n) => {
                    n.x = Math.ceil(n.x / gridSize) * gridSize;
                    n.y = Math.ceil(n.y / gridSize) * gridSize;
                    return n;
                  })
                );
              }

              setSnap(e.target.checked);
            }}
          />
          <Form.Check
            type="switch"
            id="show-grid-lines"
            label="Show Grid Lines"
            checked={showGrid}
            onChange={(e) => setShowGrid(e.target.checked)}
          />
          <div>
            <Button
              size="sm"
              disabled={selectedNodes.length === 0}
              onClick={copy}
            >
              <FontAwesomeIcon icon={faCopy} /> Copy
            </Button>
            <Button
              size="sm"
              className="ml-2"
              disabled={clipboard.length === 0}
              onClick={paste}
            >
              <FontAwesomeIcon icon={faPaste} /> Paste
            </Button>
          </div>
        </div>
        <span>{nodes.length} Nodes</span>
      </ButtonToolbar>
      <svg
        ref={svgRef}
        className="node-viewer"
        style={{
          width: svgWidth,
          height: svgHeight,
        }}
        onDrop={onDrop}
        onDragOver={(e) => e.preventDefault()}
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseMove={onMouseMove}
        onWheel={onZoom}
        viewBox={`${coords.x} ${coords.y} ${svgWidth * scale} ${
          svgHeight * scale
        }`}
        onContextMenu={(e) => e.preventDefault()}
      >
        {showGrid && (
          <g>
            <defs>
              <pattern
                id="grid"
                width={gridSize}
                height={gridSize}
                patternUnits="userSpaceOnUse"
              >
                <path
                  d={`M ${gridSize} 0 L 0 0 0 ${gridSize}`}
                  fill="none"
                  stroke="gray"
                  strokeOpacity={0.4}
                  strokeWidth="1"
                />
              </pattern>
            </defs>

            <rect
              x={coords.x}
              y={coords.y}
              width="100%"
              height="100%"
              fill="url(#grid)"
            />
          </g>
        )}

        {nodes.map((node, index) =>
          node.node ? (
            <Node
              key={index}
              node={node}
              selected={
                (dragging !== null && dragging.nodeId === node.id) ||
                (contextMenu !== null && contextMenu.nodeId === node.id) ||
                selectedNodes.indexOf(node.id) > -1
              }
              onContextMenu={(e) => {
                setContextMenu({
                  nodeId: node.id,
                  coords: { x: e.pageX, y: e.pageY - 40 },
                  connections:
                    selectedNodes.length > 1
                      ? links.filter(
                          (link) =>
                            selectedNodes.indexOf(link.nodeId) > -1 ||
                            selectedNodes.indexOf(link.inputId) > -1
                        ).length
                      : links.filter(
                          (link) =>
                            link.nodeId === node.id || link.inputId === node.id
                        ).length,
                });
              }}
              errorMessage={
                error && error.nodeId === node.id ? error.message : undefined
              }
              nodeWidth={nodeWidth}
              nodeHeight={nodeHeight}
              calculateTextWidth={calculateTextWidth}
              gridSize={gridSize}
            />
          ) : (
            <DeadNode
              key={index}
              node={node}
              selected={
                (dragging !== null && dragging.nodeId === node.id) ||
                (contextMenu !== null && contextMenu.nodeId === node.id) ||
                selectedNodes.indexOf(node.id) > -1
              }
              onContextMenu={(e) => {
                setContextMenu({
                  nodeId: node.id,
                  coords: { x: e.pageX - 330, y: e.pageY - 60 },
                  connections:
                    selectedNodes.length > 1
                      ? links.filter(
                          (link) =>
                            selectedNodes.indexOf(link.nodeId) > -1 ||
                            selectedNodes.indexOf(link.inputId) > -1
                        ).length
                      : links.filter(
                          (link) =>
                            link.nodeId === node.id || link.inputId === node.id
                        ).length,
                });
              }}
              errorMessage={
                error && error.nodeId === node.id ? error.message : undefined
              }
              nodeWidth={nodeWidth}
              nodeHeight={nodeHeight}
            />
          )
        )}

        {activeLink && (
          <ActiveLink
            nodes={nodes}
            link={activeLink}
            nodeWidth={nodeWidth}
            nodeHeight={nodeHeight}
          />
        )}

        {links.map((link, index) => (
          <Link
            key={index}
            nodes={nodes}
            link={link}
            nodeWidth={nodeWidth}
            nodeHeight={nodeHeight}
            onDelete={() => {
              setLinks([...links.filter((l, i) => i !== index)]);
            }}
          />
        ))}
        {nodes.map((node) => {
          return (
            <Connectors
              key={node.id}
              node={node}
              nodeWidth={nodeWidth}
              nodeHeight={nodeHeight}
            />
          );
        })}

        {selectBox && <SelectBox rect={selectBox} />}
      </svg>
      {contextMenu && (
        <ContextMenu
          coords={contextMenu.coords}
          connections={contextMenu.connections}
          canEdit={
            !!nodes.find((x) => x.id === contextMenu.nodeId) &&
            !!nodes.find((x) => x.id === contextMenu.nodeId)!.node
          }
          onEdit={() => {
            setEditingNode(contextMenu.nodeId);
            setContextMenu(null);
          }}
          onRemoveConnections={() => {
            setUnlinkModal(contextMenu.nodeId);
            setContextMenu(null);
          }}
          onDelete={() => {
            setDeleteModal(contextMenu.nodeId);
            setContextMenu(null);
          }}
          selectedNodes={selectedNodes}
        />
      )}
      {deleteModal && (
        <ConfirmationModal
          title={`Delete ${selectedNodes.length > 1 ? "Nodes" : "Node"}`}
          body={`Are you sure you want to delete ${
            selectedNodes.length > 1 ? "these nodes" : "this node"
          } and all connections? This cannot be undone!`}
          onClose={() => {
            setDeleteModal(null);
            setSelectedNodes([]);
          }}
          onConfirm={() => {
            if (selectedNodes.length > 0) {
              setNodesAndLinks(
                nodes.filter((node) => selectedNodes.indexOf(node.id) === -1),
                links.filter(
                  (link) =>
                    selectedNodes.indexOf(link.nodeId) === -1 &&
                    selectedNodes.indexOf(link.inputId) === -1
                )
              );
            } else {
              setNodesAndLinks(
                nodes.filter((node) => node.id !== deleteModal),
                links.filter(
                  (link) =>
                    link.nodeId !== deleteModal && link.inputId !== deleteModal
                )
              );
            }

            setDeleteModal(null);
            setSelectedNodes([]);
          }}
        />
      )}
      {unlinkModal && (
        <ConfirmationModal
          title="Remove all Connections"
          body={`Are you sure you want delete all connections to and from ${
            selectedNodes.length > 1 ? "these nodes" : "this node"
          }? This cannot be undone!`}
          onClose={() => {
            setUnlinkModal(null);
            setSelectedNodes([]);
          }}
          onConfirm={() => {
            setLinks(
              selectedNodes.length > 0
                ? links.filter(
                    (link) =>
                      selectedNodes.indexOf(link.nodeId) === -1 &&
                      selectedNodes.indexOf(link.inputId) === -1
                  )
                : links.filter(
                    (link) =>
                      link.nodeId !== unlinkModal &&
                      link.inputId !== unlinkModal
                  )
            );
            setUnlinkModal(null);
            setSelectedNodes([]);
          }}
        />
      )}
      {editingNode && (
        <NodeEditor
          nodes={nodes}
          links={links}
          setNodes={setNodes}
          nodeId={editingNode}
          onFinish={() => {
            setEditingNode(null);
          }}
        />
      )}
      {showUniqueWarning && (
        <InfoModal
          title="Node Limit"
          body="You can only use 1 of this type of node per applet, don't worry though, you can attach it to as many things as you want!"
          onClose={() => setShowUniqueWarning(false)}
        />
      )}
    </div>
  );
}

import { useRef } from "react";
import { Form } from "react-bootstrap";
import { EditorElement } from "../../../../../../types";
import {
  findNestedValue,
  getNestedError,
  setNestedErrors,
  setNestedValue,
} from "../../../../../shared/utils";

type HardTextInputProps = {
  data: { [key: string]: unknown };
  setData: (data: { [key: string]: unknown }) => void;
  errors: { [id: string]: string | null };
  setErrors: (data: { [id: string]: string | null }) => void;
  showErrors: boolean;
  element: EditorElement;
  parents: string[];
};

export function HardStringInput({
  data,
  setData,
  errors,
  setErrors,
  showErrors,
  element,
  parents,
}: HardTextInputProps) {
  const ref = useRef<HTMLInputElement>(null);

  function validate(value: string) {
    let error = "";

    if (element.required && (!value || value === "")) {
      error = "This field is required";
    }

    if (element.min !== -1 && value.length < element.min) {
      error = `This field must be between ${element.min} and ${element.max} characters`;
    }

    if (element.max !== -1 && value.length > element.max) {
      error = `This field must be between ${element.min} and ${element.max} characters`;
    }

    setNestedErrors(errors, setErrors, parents, element.id, error);
  }

  function setValue(value: string) {
    setNestedValue(data, setData, parents, element.id, value);
    validate(value);
  }

  return (
    <>
      <Form.Group style={{ flex: 2 }}>
        <Form.Label>{element.label}</Form.Label>
        <Form.Control
          type="text"
          placeholder={element.placeholder ? element.placeholder : ""}
          onChange={(e) => setValue(e.target.value)}
          value={findNestedValue(data, parents, element.id) as string}
          ref={ref}
          isInvalid={
            showErrors && !!getNestedError(errors, parents, element.id)
          }
          maxLength={element.max ? element.max : 10000}
        />
        <Form.Control.Feedback type="invalid">
          {getNestedError(errors, parents, element.id)}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
}

import { Route, Switch, useRouteMatch } from "react-router-dom";
import { CustomBot, Signin } from "./CustomBot";
import { SimpleCommands } from "./SimpleCommands";

export function Twitch() {
  const { path } = useRouteMatch();

  return (
    <div className="page-content">
      <Switch>
        <Route path={`${path}/commands`}>
          <SimpleCommands />
        </Route>
        <Route path={`${path}/custom-bot/signin`}>
          <Signin />
        </Route>
        <Route path={`${path}/custom-bot`}>
          <CustomBot />
        </Route>
      </Switch>
    </div>
  );
}

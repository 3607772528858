import {
  faBan,
  faCheck,
  faSearch,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { Button, Form, InputGroup, Table } from "react-bootstrap";
import { APIUrl } from "../../../Constants";
import { Paginator } from "../../shared/Paginator";
import { Spinner } from "../../shared/Spinner";
import * as _ from "underscore";
import { ConfirmationModal } from "../../shared/modals/ConfirmationModal";
import { formatNumber } from "../../shared/utils";

type Member = {
  displayName: string;
  id: string;
  providers: { [provider: string]: { username: string; userid: string } };
};

type MemberList = {
  page: number;
  pageSize: number;
  results: Member[];
  totalResults: number;
};

export function Members() {
  const [loading, setLoading] = useState(true);
  const [members, setMembers] = useState<MemberList>();
  const [page, setPage] = useState(1);
  const [searchField, setSearchField] = useState("");
  const [search, setSearch] = useState("");
  const [removeUser, setRemoveUser] = useState<string>();
  const [unbanUser, setUnbanUser] = useState<string>();
  const [banUser, setBanUser] = useState<string>();
  const [viewingBannedUsers, setViewingBannedUsers] = useState(false);

  const debounceSearch = useMemo(
    () => _.debounce((value: string) => setSearch(value), 1000),
    []
  );

  useEffect(() => {
    setLoading(true);

    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, search, viewingBannedUsers]);

  async function load() {
    const response = await axios.get(
      viewingBannedUsers
        ? `${APIUrl}/community/members/banned?page=${page}&search=${search}`
        : `${APIUrl}/community/members?page=${page}&search=${search}`
    );

    setLoading(false);
    var members = response.data as MemberList;
    setMembers(members);
  }

  async function BanUser(userId: string) {
    try {
      await axios.patch(`${APIUrl}/community/members?memberId=${userId}`);
      setBanUser(undefined);
      load();
    } catch (error) {
      console.log(error);
    }
  }

  async function RemoveUser(userId: string) {
    try {
      await axios.delete(`${APIUrl}/community/members?memberId=${userId}`);
      setRemoveUser(undefined);
      load();
    } catch (error) {
      console.log(error);
    }
  }

  async function UnbanUser(userId: string) {
    try {
      await axios.post(`${APIUrl}/community/members/unban?memberId=${userId}`);
      setUnbanUser(undefined);
      load();
    } catch (error) {
      console.log(error);
    }
  }

  function username(m: Member, provider: string) {
    return m.providers[provider] ? m.providers[provider].username : "Unknown";
  }

  if (!members) {
    return <Spinner />;
  }

  if (members.results.length === 0 && !search) {
    return (
      <>
        <p className="mb-1">
          {viewingBannedUsers
            ? "There are no users banned in your community!"
            : "This community has no members yet!"}
        </p>
        <Button
          variant="primary"
          onClick={() => setViewingBannedUsers(!viewingBannedUsers)}
          size="sm"
        >
          {viewingBannedUsers ? "View Active Members" : "View Banned Members"}
        </Button>
      </>
    );
  }

  return (
    <>
      <div className="d-flex">
        <h3 className="flex-grow-1">
          {viewingBannedUsers ? "Banned" : "Active"} Members:{" "}
          <span className="text-primary">
            {formatNumber(members.totalResults)}
          </span>
        </h3>
        <div className="d-flex">
          <div className="mr-2">
            <Button
              variant="primary"
              onClick={() => setViewingBannedUsers(!viewingBannedUsers)}
            >
              {viewingBannedUsers
                ? "View Active Members"
                : "View Banned Members"}
            </Button>
          </div>
          <Form style={{ width: 300 }}>
            <Form.Group>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faSearch} />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  placeholder="Search Member Usernames"
                  value={searchField}
                  onChange={(e) => {
                    setSearchField(e.target.value);
                    debounceSearch(e.target.value);
                  }}
                />
              </InputGroup>
            </Form.Group>
          </Form>
        </div>
      </div>
      <div className="bg-base">
        <Table striped>
          <thead>
            <tr>
              <th>Twitch</th>
              <th>Discord</th>
              <th>Minecraft</th>
              <th>Patreon</th>
              <th>Moderation</th>
            </tr>
          </thead>
          {loading ? (
            <Spinner size="sm" />
          ) : (
            <tbody>
              {members.results.map((m) => (
                <tr key={m.id}>
                  <td>{username(m, "Twitch")}</td>
                  <td>{username(m, "Discord")}</td>
                  <td>{username(m, "Minecraft")}</td>
                  <td>{username(m, "Patreon")}</td>
                  {viewingBannedUsers ? (
                    <td>
                      <Button
                        size="sm"
                        variant="success"
                        className="mr-2"
                        onClick={() => setUnbanUser(m.id)}
                      >
                        <FontAwesomeIcon icon={faCheck} /> Unban
                      </Button>
                    </td>
                  ) : (
                    <td>
                      <Button
                        size="sm"
                        variant="danger"
                        className="mr-2"
                        onClick={() => setRemoveUser(m.id)}
                      >
                        <FontAwesomeIcon icon={faTrash} /> Kick
                      </Button>
                      <Button
                        size="sm"
                        variant="danger"
                        onClick={() => setBanUser(m.id)}
                      >
                        <FontAwesomeIcon icon={faBan} /> Ban
                      </Button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          )}
        </Table>

        <div className="py-3 d-flex justify-content-center align-items-center position-relative">
          <Paginator
            totalResults={members.totalResults}
            pageSize={members.pageSize}
            page={page}
            setPage={setPage}
          />
        </div>
      </div>

      {removeUser && (
        <ConfirmationModal
          title="Kick User"
          body="Are you sure you want to kick this user? They will be able to join back if they click your community link."
          onConfirm={() => RemoveUser(removeUser)}
          onClose={() => setRemoveUser(undefined)}
        />
      )}

      {banUser && (
        <ConfirmationModal
          title="Ban User"
          body="Are you sure you want to ban this user? They will not be able to join until you unban them. "
          onConfirm={() => BanUser(banUser)}
          onClose={() => setBanUser(undefined)}
        />
      )}

      {unbanUser && (
        <ConfirmationModal
          title="Unban User"
          body="Are you sure you want to un-ban this user? They will have to manually join the community again via your link. "
          onConfirm={() => UnbanUser(unbanUser)}
          onClose={() => setUnbanUser(undefined)}
        />
      )}
    </>
  );
}

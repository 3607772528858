import axios from "axios";
import { useRef, useState } from "react";
import { Button, Form, InputGroup, Modal } from "react-bootstrap";
import { APIUrl, ClientUrl, CommunityUrl } from "../../../../Constants";
import { Community } from "../../../../types";
import { CustomAlert } from "../../../shared/CustomAlert";

type SettingsModalProps = {
  community: Community;
  setCommunity: (community: Community) => void;
  onClose: () => void;
};

export function SettingsModal({
  community,
  setCommunity,
  onClose,
}: SettingsModalProps) {
  const fileRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File>();
  const [name, setName] = useState(community.name);
  const [slug, setSlug] = useState(community.slug);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  async function save() {
    setLoading(true);
    var formData = new FormData();

    if (file) {
      formData.append("icon", file as Blob);
    }

    formData.append("name", name);
    formData.append("slug", slug);

    try {
      const response = await axios.post(`${APIUrl}/community`, formData);
      setCommunity(response.data);
      onClose();
    } catch (e: any) {
      setError(e.response.data);
      setLoading(false);
    }
  }

  return (
    <Modal show={true} onHide={onClose} backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>Community Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex align-items-center">
          <div
            style={{ width: 80, height: 80 }}
            className="d-flex align-items-center justify-content-center border border-primary"
          >
            <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : community.iconUrl
                  ? `${community.iconUrl}`
                  : `${ClientUrl}/logo.png`
              }
              alt="Community Icon"
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          </div>
          <div>
            <input
              ref={fileRef}
              type="file"
              style={{ display: "none" }}
              onChange={(e) =>
                setFile(
                  e.target.files && e.target.files.length > 0
                    ? e.target.files[0]
                    : undefined
                )
              }
              accept="image/png, image/jpeg, image/jpg, image/pjpeg, image/gif, image/x-png"
            />
            <Button
              className="ml-3"
              size="sm"
              onClick={() => fileRef.current?.click()}
            >
              Upload Icon
            </Button>
            <span className="ml-2">{file && file.name}</span>
          </div>
        </div>
        <Form.Group className="mt-3">
          <Form.Label>Community Name</Form.Label>
          <Form.Control
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter a community name"
          />
        </Form.Group>
        <Form.Group className="mt-3">
          <Form.Label>Community Url</Form.Label>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text className=".bg-base-bg">
                {CommunityUrl}/c/
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="text"
              value={slug}
              onChange={(e) => setSlug(e.target.value)}
              placeholder="Enter a community url"
            />
          </InputGroup>
        </Form.Group>
        <p className="mt-4">
          <strong>
            Community names, urls & icons must NOT contain explicit material!
          </strong>
        </p>
        {error && (
          <CustomAlert type="danger" title="Error">
            {error}
          </CustomAlert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex w-100">
          <div className="flex-grow-1 pr-2">
            <Button
              variant="outline-primary"
              onClick={onClose}
              block
              disabled={loading}
            >
              Cancel
            </Button>
          </div>
          <div className="flex-grow-1 pl-2">
            <Button variant="primary" onClick={save} block disabled={loading}>
              Save
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

import {
  faChevronDown,
  faChevronUp,
  faCog,
} from "@fortawesome/pro-light-svg-icons";
import { faStar } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Badge, OverlayTrigger, Popover } from "react-bootstrap";
import { useModules } from "../../../../../context/ModulesContext";
import { NodeOption, NodeType } from "../../../../../types";
import { getIconFor } from "../../../../shared/utils";
import { EditorSidenavContextMenu } from "./EditorSidenav";

type NodeViewProps = {
  nodes: NodeOption[];
  showModules?: boolean;
  setContextMenu: (contextMenu: EditorSidenavContextMenu) => void;
};

export function NodeView({
  nodes,
  showModules,
  setContextMenu,
}: NodeViewProps) {
  const [filter, setFilter] = useState("All");

  function filterButton(type: NodeType) {
    if (!nodes.find((x) => x.type === type)) {
      return null;
    }

    return (
      <Badge
        variant={filter === type ? "primary" : ""}
        onClick={() => setFilter(type)}
        pill
      >
        <FontAwesomeIcon icon={getIconFor(type)} />
      </Badge>
    );
  }

  return (
    <>
      <div className="sidenav-filter">
        <Badge
          variant={filter === "All" ? "primary" : ""}
          onClick={() => setFilter("All")}
          pill
        >
          All
        </Badge>
        {filterButton("Logic")}
        {filterButton("Trigger")}
        {filterButton("Action")}
      </div>

      <ul className="sidenav-items">
        {filter === "All" && (
          <NodeGroup
            type="Comment"
            nodes={nodes}
            showModules={showModules}
            setContextMenu={setContextMenu}
          />
        )}

        {(filter === "All" || filter === "Logic") && (
          <NodeGroup
            type="Logic"
            nodes={nodes}
            showModules={showModules}
            setContextMenu={setContextMenu}
          />
        )}

        {(filter === "All" || filter === "Trigger") && (
          <NodeGroup
            type="Trigger"
            nodes={nodes}
            showModules={showModules}
            setContextMenu={setContextMenu}
          />
        )}

        {(filter === "All" || filter === "Action") && (
          <NodeGroup
            type="Action"
            nodes={nodes}
            showModules={showModules}
            setContextMenu={setContextMenu}
          />
        )}
      </ul>
    </>
  );
}

export type NodeGroupProps = {
  type: NodeType;
  nodes: NodeOption[];
  showModules?: boolean;
  setContextMenu: (contextMenu: EditorSidenavContextMenu) => void;
};

function NodeGroup({
  type,
  nodes,
  showModules,
  setContextMenu,
}: NodeGroupProps) {
  const { modules } = useModules();
  const [expanded, setExpanded] = useState(true);

  function onDragStart(e: React.DragEvent<HTMLLIElement>, item: NodeOption) {
    e.dataTransfer.setData("drag_origin", "sidenav");
    e.dataTransfer.setData("node", item.id);
  }

  function onContextMenu(
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
    option: NodeOption
  ) {
    e.preventDefault();
    setContextMenu({ option, x: e.pageX, y: e.pageY });
  }

  if (!nodes.find((x) => x.type === type)) {
    return null;
  }

  return (
    <>
      <div
        className="bg-base-bg d-flex align-items-center px-2 hover"
        onClick={() => setExpanded(!expanded)}
      >
        <p className="mb-1 flex-grow-1">
          <small>{type}</small>
        </p>
        <FontAwesomeIcon icon={expanded ? faChevronDown : faChevronUp} />
      </div>
      {expanded &&
        nodes
          .filter((x) => x.type === type)
          .map((item, index) => (
            <OverlayTrigger
              key={index}
              placement="right"
              overlay={
                <Popover id={`tooltip-${index}`}>
                  <Popover.Title as="h3">
                    <div className="d-flex">
                      <div className="flex-grow-1">
                        <FontAwesomeIcon icon={getIconFor(item.type)} />{" "}
                        {item.name}
                      </div>
                      {item.beta && (
                        <div>
                          <Badge variant="primary">BETA</Badge>
                        </div>
                      )}
                    </div>
                  </Popover.Title>
                  <Popover.Content>{item.description}</Popover.Content>
                </Popover>
              }
            >
              <li
                onContextMenu={(e) => onContextMenu(e, item)}
                onDragStart={(e) => onDragStart(e, item)}
                draggable
              >
                <div className="sidenav-item-icon">
                  {showModules ? (
                    <div>
                      {modules.find((x) => x.id === item.module)?.imageUrl ? (
                        <img
                          src={
                            modules.find((x) => x.id === item.module)?.imageUrl
                          }
                          alt="Module Icon"
                        />
                      ) : (
                        <FontAwesomeIcon icon={faCog} size="lg" />
                      )}

                      <FontAwesomeIcon
                        icon={getIconFor(item.type)}
                        className="alt-icon"
                      />
                    </div>
                  ) : (
                    <FontAwesomeIcon icon={getIconFor(item.type)} />
                  )}
                </div>
                <span className="sidenav-item-divider" />
                <div className="sidenav-item-label">
                  <p className="sidenav-item-label-title">
                    {item.name}{" "}
                    {item.favourite && (
                      <FontAwesomeIcon icon={faStar} size="sm" />
                    )}
                  </p>
                </div>
                {item.beta && (
                  <div className="ml-3">
                    <Badge variant="primary">BETA</Badge>
                  </div>
                )}
              </li>
            </OverlayTrigger>
          ))}
    </>
  );
}

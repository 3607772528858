import {
  faExclamationTriangle,
  faMinus,
  faPeopleArrows,
  faPlus,
  faTimes,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, ListGroup, OverlayTrigger, Tooltip } from "react-bootstrap";
import { APIUrl, ClientUrl } from "../../../../../Constants";
import { NodeInstance, Paged, Team } from "../../../../../types";
import { Paginator } from "../../../../shared/Paginator";
import { SelectDropdown } from "../../../../shared/SelectDropdown";
import { Spinner } from "../../../../shared/Spinner";

type TeamsPanelProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  node: NodeInstance;
  setNode: (node: NodeInstance) => void;
};

type SharedTriggerUser = {
  triggerId: string;
  userId: string;
  username: string;
  sharedData: string[];
  sharedAllTriggers: boolean;
};

export function TeamsPanel({ open, setOpen, node, setNode }: TeamsPanelProps) {
  const [teams, setTeams] = useState<Team[]>([]);
  const [team, setTeam] = useState<string>();
  const [teamsLoading, setTeamsLoading] = useState(false);
  const [membersLoading, setMembersLoading] = useState(false);
  const [users, setUsers] = useState<Paged<SharedTriggerUser>>();
  const [adding, setAdding] = useState(false);
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState<string>();

  useEffect(() => {
    async function load() {
      setTeamsLoading(true);
      try {
        const response = await axios.get(`${APIUrl}/teams`);
        const data: Team[] = response.data;
        setTeams(data);
      } catch (e: any) {
        console.log(e);
      } finally {
        setTeamsLoading(false);
      }
    }
    load();
  }, []);

  useEffect(() => {
    async function updateTeam() {
      setMembersLoading(true);

      const response = await axios.get(
        `${APIUrl}/teams/${team}/members/triggers-shared?moduleId=${node.module}&triggerId=${node.node.id}&page=${page}`
      );
      console.log(response.data);
      setUsers(response.data);
      setMembersLoading(false);
    }

    updateTeam();
  }, [node, team, page]);

  function toggleSelectedUser(team: string, id: string, username?: string) {
    const _node = { ...node };

    if (_node.sharedUserPairs[team]) {
      if (_node.sharedUserPairs[team].find((x) => x.userId === id)) {
        if (node.sharedUserPairs[team].length === 1) {
          delete _node.sharedUserPairs[team];
        } else {
          _node.sharedUserPairs[team] = _node.sharedUserPairs[team].filter(
            (x) => x.userId !== id
          );
        }
      } else {
        _node.sharedUserPairs[team].push({
          userId: id,
          username: username,
        });
      }
    } else {
      _node.sharedUserPairs[team] = [{ userId: id, username: username }];
    }

    setNode(_node);
  }

  function isInvalidAddition(userId: string) {
    if (users) {
      return !!Object.keys(node.sharedUserPairs).find((team) =>
        node.sharedUserPairs[team].find((user) => user.userId === userId)
      );
    }

    return false;
  }

  if (teamsLoading) {
    return null;
  }

  return (
    <>
      <div className={`teams-panel-wrapper ${open ? "open" : ""}`}>
        <div className="teams-panel">
          <div className="d-flex align-items-center">
            <div
              className="teams-panel-icon"
              onClick={() => {
                setOpen(!open);
                setAdding(false);
              }}
            >
              <FontAwesomeIcon icon={faPeopleArrows} />
            </div>
            <h5 className="mb-0 ml-1 flex-grow-1">Team Node Sharing</h5>
            <div
              onClick={() => {
                setOpen(!open);
                setAdding(false);
              }}
            >
              <FontAwesomeIcon icon={faTimes} className="hover mr-3" />
            </div>
          </div>
          <div className="p-3">
            <Button
              className="mb-3"
              size="sm"
              onClick={() => setAdding(!adding)}
            >
              <FontAwesomeIcon icon={faPlus} /> Add User
            </Button>

            {node.sharedUserPairs &&
            Object.keys(node.sharedUserPairs).length > 0 ? (
              <ListGroup>
                {node.sharedUserPairs &&
                  Object.keys(node.sharedUserPairs).map((team) =>
                    node.sharedUserPairs[team].map((user) => (
                      <ListGroup.Item
                        key={`${user.userId}`}
                        className="d-flex align-items-center"
                      >
                        <p className="mb-0 flex-grow-1">{user.username}</p>
                        <FontAwesomeIcon
                          icon={faMinus}
                          className="hover text-danger"
                          onClick={() => toggleSelectedUser(team, user.userId)}
                        />
                      </ListGroup.Item>
                    ))
                  )}
              </ListGroup>
            ) : (
              <p>
                Here you can choose to listen to the same trigger from your
                teammates accounts, as long as they have shared it with you.
                Click "Add User" to get started.
              </p>
            )}

            {membersLoading ? <Spinner /> : <></>}
          </div>
        </div>
      </div>
      <div className={`add-teammate-modal-wrapper ${adding ? "open" : ""}`}>
        <div className="add-teammate-modal">
          <div className="mb-3">
            <FontAwesomeIcon
              icon={faTimes}
              className="hover float-right"
              onClick={() => setAdding(false)}
            />
            <p className="mb-1">Select a Team</p>
            <SelectDropdown
              placeholder="Select a Team"
              items={teams
                .filter(
                  (t) => t.activeUser && t.activeUser.state === "ACCEPTED"
                )
                .map((t) => ({
                  name: t.name,
                  value: t.id,
                  imageUrl: t.iconUrl || `${ClientUrl}/logo.png`,
                }))}
              value={team}
              onChange={(value) => setTeam(value)}
            />
          </div>
          {team && users && users.results.length > 0 && (
            <>
              <p className="my-2">
                <small>
                  Click the{" "}
                  <FontAwesomeIcon className="text-success" icon={faPlus} />{" "}
                  icon to listen to a shared trigger.
                  <br />
                  Click on the name of a user to see what data they are sharing
                  for this trigger.
                </small>
              </p>

              <ListGroup>
                {users.results.map((x) => (
                  <ListGroup.Item
                    key={x.userId}
                    onClick={() =>
                      selected === x.userId
                        ? setSelected(undefined)
                        : setSelected(x.userId)
                    }
                    className="hover"
                  >
                    <div className="d-flex align-items-center">
                      {isInvalidAddition(x.userId) ? (
                        <>
                          <p className="mb-0 flex-grow-1 text-muted">
                            {x.username}
                          </p>
                          <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            overlay={(props) => (
                              <Tooltip id="button-tooltip" {...props}>
                                You can only add a user once, even if they are
                                in multiple teams!
                              </Tooltip>
                            )}
                          >
                            <FontAwesomeIcon
                              icon={faExclamationTriangle}
                              className="text-danger hover"
                            />
                          </OverlayTrigger>
                        </>
                      ) : (
                        <>
                          <p className="mb-0 flex-grow-1">{x.username}</p>
                          <FontAwesomeIcon
                            icon={faPlus}
                            className="text-success hover"
                            onClick={() =>
                              toggleSelectedUser(team, x.userId, x.username)
                            }
                          />
                        </>
                      )}
                    </div>
                    {selected === x.userId && (
                      <>
                        <p className="mb-0">is sharing:</p>
                        {x.sharedData ? (
                          <ul className="list-unstyled">
                            {x.sharedData.map((x) => (
                              <li className="ml-2">
                                <small>
                                  {"{"}
                                  {x}
                                  {"}"}
                                </small>
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <small>All Triggers & Data</small>
                        )}
                      </>
                    )}
                  </ListGroup.Item>
                ))}
              </ListGroup>
              <div className="mt-3 d-flex justify-content-center">
                <Paginator
                  page={page}
                  totalResults={users.totalResults}
                  pageSize={users.pageSize}
                  setPage={setPage}
                />
              </div>
            </>
          )}

          {team && users && users.results.length === 0 && (
            <p>Nobody in this team is sharing this trigger.</p>
          )}
        </div>
      </div>
    </>
  );
}

import { faBracketsCurly } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useRef, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { InputProps } from "../../../types";
import { ContextModal } from "../../Applets/Editor/NodeEditor/Modals/ContextModal";

export function StringInput({
  id,
  name,
  value,
  defaultValue,
  setValue,
  disabled,
  error,
  placeholder,
  variables,
  required,
  min,
  max,
  onValidate,
  multiline,
}: InputProps) {
  const [dropdown, setDropdown] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [results, setResults] = useState(variables);
  const [selected, setSelected] = useState(0);
  const [searchStart, setSearchStart] = useState<number>();

  useEffect(() => {
    function onFocus(e: MouseEvent) {
      if (divRef.current && !divRef.current.contains(e.target as Node)) {
        setDropdown(false);
      }
    }

    window.addEventListener("click", onFocus);

    return () => {
      window.removeEventListener("click", onFocus);
    };
  }, []);

  function onChange(value: string) {
    if (
      variables &&
      searchStart !== undefined &&
      inputRef.current &&
      inputRef.current.selectionStart
    ) {
      const searchTerm = value.substring(
        searchStart + 1,
        inputRef.current.selectionStart
      );
      if (searchTerm) {
        setResults(variables.filter((x) => x.value.startsWith(searchTerm)));
      } else {
        setResults(variables);
      }
    }

    let error = "";

    if (required && (!value || value === "")) {
      error = "This field is required";
    }

    if (!!min && min !== -1 && value.length < min) {
      error = `This field must be between ${min} and ${max} characters`;
    }

    if (!!max && max !== -1 && value.length > max) {
      error = `This field must be between ${min} and ${max} characters`;
    }

    if (!!onValidate) {
      onValidate(error);
    }

    setValue(value);
  }

  function addVariable(variable: string) {
    var _value = (value as string) || "";

    if (inputRef.current && inputRef.current.selectionStart) {
      const pos = inputRef.current.selectionStart;
      if (_value[pos - 1] !== "{") {
        variable = `{${variable}}`;
      } else {
        variable = `${variable}}`;
      }
    } else {
      variable = `{${variable}}`;
    }

    if (!!_value) {
      if (inputRef.current && inputRef.current.selectionStart) {
        const pos = inputRef.current.selectionStart;

        if (searchStart !== undefined) {
          const searchTerm = _value.substring(
            searchStart + 1,
            inputRef.current.selectionStart
          );

          _value =
            _value.substring(0, searchTerm ? searchStart : searchStart + 1) +
            _value.substring(inputRef.current.selectionStart);
        }

        setValue([_value.slice(0, pos), variable, _value.slice(pos)].join(""));
        inputRef.current.focus();

        setTimeout(() => {
          if (inputRef.current) {
            inputRef.current.setSelectionRange(
              pos + variable.length,
              pos + variable.length
            );
            inputRef.current.blur();
            inputRef.current.focus();
          }
        }, 10);
      } else {
        setValue(_value + variable);
      }
    } else {
      setValue(variable);
    }

    setDropdown(false);
    setSearchStart(undefined);
    setResults(variables);
  }

  function onKeyDown(e: React.KeyboardEvent) {
    if (e.key === "{") {
      setSelected(0);
      setDropdown(true);

      if (inputRef.current && inputRef.current.selectionStart !== null) {
        setSearchStart(inputRef.current.selectionStart);
      }
    }

    if (dropdown && results && (e.key === "Tab" || e.key === "Enter")) {
      e.preventDefault();

      if (results.length > 0) {
        addVariable(results[selected].value);
      }
    }

    if (dropdown && results && e.key === "ArrowUp") {
      e.preventDefault();

      if (selected > 0) {
        setSelected(selected - 1);
      }
    }

    if (dropdown && results && e.key === "ArrowDown") {
      e.preventDefault();

      if (selected < results.length - 1) {
        setSelected(selected + 1);
      }
    }
  }

  return (
    <div>
      <Form.Group>
        <Form.Label>{name}</Form.Label>
        <InputGroup ref={divRef}>
          {multiline ? (
            <Form.Control
              id={id}
              ref={inputRef as any}
              type="text"
              as={"textarea"}
              rows={3}
              style={{ minHeight: 38, maxHeight: 300 }}
              placeholder={placeholder ? placeholder : "Enter value"}
              onChange={(e) => onChange(e.target.value)}
              value={
                value !== undefined
                  ? (value as string)
                  : (defaultValue as string) || ""
              }
              disabled={disabled}
              isInvalid={!!error}
              onKeyDown={onKeyDown}
              maxLength={max ? max : 10000}
            />
          ) : (
            <Form.Control
              id={id}
              ref={inputRef as any}
              type="text"
              placeholder={placeholder ? placeholder : "Enter value"}
              onChange={(e) => onChange(e.target.value)}
              value={
                value !== undefined
                  ? (value as string)
                  : (defaultValue as string) || ""
              }
              disabled={disabled}
              isInvalid={!!error}
              onKeyDown={onKeyDown}
              maxLength={max ? max : 10000}
            />
          )}

          {!!variables && variables.length > 0 && (
            <InputGroup.Text
              className="hover-primary bg-base-bg border-left-0"
              style={{ height: 38 }}
              onClick={() => {
                setDropdown(!dropdown);
                inputRef.current?.focus();
              }}
            >
              <FontAwesomeIcon icon={faBracketsCurly} />
            </InputGroup.Text>
          )}
          {!!variables && variables.length > 0 && dropdown && (
            <ContextModal
              variables={results}
              onUse={addVariable}
              selected={selected}
            />
          )}
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    </div>
  );
}

import { faEnvelopeOpenText } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { APIUrl } from "../../Constants";
import { ConfirmationModal } from "../shared/modals/ConfirmationModal";
import { Spinner } from "../shared/Spinner";

export function Emails() {
  const [loading, setLoading] = useState(true);
  const [subject, setSubject] = useState("");
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [global, setGlobal] = useState(false);
  const [showEmailConfirmationModal, setShowEmailConfirmationModal] =
    useState(false);
  const [
    showPreviewEmailConfirmationModal,
    setShowPreviewEmailConfirmationModal,
  ] = useState(false);

  useEffect(() => {
    async function load() {
      setLoading(false);
    }
    load();
  }, []);

  async function SendMarketingEmail() {
    try {
      await axios.post(`${APIUrl}/admin/marketing/send`, {
        Subject: subject,
        Title: title,
        Body: body,
        Global: global,
      });
      setSubject("");
      setTitle("");
      setBody("");
    } catch (error) {
      console.log(error);
    }
  }

  async function SendPreviewEmail() {
    try {
      await axios.post(`${APIUrl}/admin/marketing/send/preview`, {
        Subject: subject,
        Title: title,
        Body: body,
      });
    } catch (error) {
      console.log(error);
    }
  }

  async function ResetMarketingMailingList() {
    try {
      await axios.post(`${APIUrl}/admin/marketing/reset`);
    } catch (error) {
      console.log(error);
    }
  }

  async function ResetGlobalMailingList() {
    try {
      await axios.post(`${APIUrl}/admin/global-email/reset`);
    } catch (error) {
      console.log(error);
    }
  }

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className="page-content">
      <div className="d-flex mb-4">
        <div className="d-flex flex-grow-1">
          <h1>
            <FontAwesomeIcon icon={faEnvelopeOpenText} /> Emails
          </h1>
        </div>
      </div>
      {showEmailConfirmationModal ? (
        <ConfirmationModal
          title={"Confirm Bulk Email Send"}
          body={"Are you sure you wish to send the bulk email?"}
          onConfirm={() => {
            SendMarketingEmail();
            setShowEmailConfirmationModal(false);
          }}
          onClose={() => {
            setShowEmailConfirmationModal(false);
          }}
        />
      ) : null}
      {showPreviewEmailConfirmationModal ? (
        <ConfirmationModal
          title={"Confirm Preview Email Send"}
          body={
            "Are you sure you wish to send the preview email? It will be sent to admin@streamhydra.io"
          }
          onConfirm={() => {
            SendPreviewEmail();
            setShowPreviewEmailConfirmationModal(false);
          }}
          onClose={() => {
            setShowPreviewEmailConfirmationModal(false);
          }}
        />
      ) : null}
      <Row>
        <Col lg={4}>
          <Card>
            <Card.Header>
              <Card.Title>Send Bulk Email</Card.Title>
            </Card.Header>
            <Card.Body>
              <Form.Group>
                <Form.Check
                  type="switch"
                  checked={global}
                  custom
                  id="global"
                  label="Send to Global Mailing List"
                  onChange={(e) => setGlobal(e.target.checked)}
                />
                <Form.Control
                  className="mt-3"
                  type="text"
                  placeholder={"Subject"}
                  value={subject}
                  onChange={(e) => {
                    setSubject(e.target.value);
                  }}
                />
                <Form.Control
                  className="mt-3"
                  type="text"
                  placeholder={"Title"}
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
                <Form.Control
                  className="mt-3"
                  type="text"
                  as="textarea"
                  rows={7}
                  placeholder={"Body"}
                  value={body}
                  onChange={(e) => {
                    setBody(e.target.value);
                  }}
                />
              </Form.Group>
            </Card.Body>
            <Card.Footer>
              <Button
                className="mr-5"
                onClick={() => setShowPreviewEmailConfirmationModal(true)}
              >
                Send Preview Email
              </Button>
              <Button onClick={() => setShowEmailConfirmationModal(true)}>
                Send Bulk Email
              </Button>
            </Card.Footer>
          </Card>
        </Col>
        <Col lg={4}>
          <Card>
            <Card.Header>
              <Card.Title>Configure</Card.Title>
            </Card.Header>
            <Card.Body>
              <Button className="mb-4" onClick={() => ResetGlobalMailingList()}>
                Reset Global Mailing List
              </Button>
              <br />
              <Button onClick={() => ResetMarketingMailingList()}>
                Reset Marketing Mailing List
              </Button>
            </Card.Body>
            <Card.Footer></Card.Footer>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

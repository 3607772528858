import {
  faPlus,
  faSave,
  faTimes,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { Applet, ConfigGroup, ConfigPanel } from "../../../../types";
import { Panel } from "./Panel";
import { v4 as uuid } from "uuid";
import { ConfirmationModal } from "../../../shared/modals/ConfirmationModal";

type GroupProps = {
  group: ConfigGroup;
  setGroup: (group: ConfigGroup) => void;
  deleteGroup: () => void;
  data: Applet;
  setData: (data: Applet) => void;
  onDragStart: () => void;
  onDragEnd: () => void;
  onDragOver: () => void;
};

export function Group({
  group,
  setGroup,
  deleteGroup,
  data,
  setData,
  onDragStart,
  onDragEnd,
  onDragOver,
}: GroupProps) {
  const [renaming, setRenaming] = useState(false);
  const [nameInput, setNameInput] = useState("");
  const [dragging, setDragging] = useState(false);
  const [draggingPanel, setDraggingPanel] = useState<ConfigPanel>();
  const [deleting, setDeleting] = useState(false);

  function dragStart() {
    setDragging(true);
    onDragStart();
  }

  function dragEnd() {
    setDragging(false);
    onDragEnd();
  }

  function dragOver(e: React.DragEvent<HTMLDivElement>) {
    e.preventDefault();
    onDragOver();
  }

  function onPanelDragStart(panel: ConfigPanel) {
    setDraggingPanel(panel);
  }

  function onPanelDragEnd() {
    setDraggingPanel(undefined);
  }

  function onPanelDragOver(panel: ConfigPanel) {
    if (draggingPanel && panel !== draggingPanel) {
      let oldIndex = group.panels.indexOf(draggingPanel);
      let newIndex = group.panels.indexOf(panel);

      setGroup({
        ...group,
        panels: [
          ...group.panels.map((x, index) =>
            index === oldIndex ? panel : index === newIndex ? draggingPanel : x
          ),
        ],
      });
    }
  }

  return (
    <>
      <div
        className={`config-group ${dragging ? "dragging" : ""}`}
        draggable={!renaming}
        onDragStart={dragStart}
        onDragEnd={dragEnd}
        onDragOver={dragOver}
      >
        <div className="d-flex mb-2">
          <div className="flex-grow-1">
            {renaming ? (
              <>
                <Form
                  className="d-flex"
                  onSubmit={(e) => {
                    e.preventDefault();
                    setGroup({ ...group, name: nameInput });
                    setRenaming(false);
                  }}
                >
                  <Form.Control
                    value={nameInput}
                    onChange={(e) => setNameInput(e.target.value)}
                  />
                  <div>
                    <Button
                      className="ml-2"
                      size="sm"
                      style={{ width: 35, height: 35 }}
                      type="submit"
                    >
                      <FontAwesomeIcon icon={faSave} />
                    </Button>
                  </div>
                  <div>
                    <Button
                      className="ml-2"
                      size="sm"
                      style={{ width: 35, height: 35 }}
                      variant="outline-primary"
                      onClick={() => setRenaming(false)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>
                  </div>
                </Form>
              </>
            ) : (
              <div>
                <h5
                  className="hover mb-0"
                  onClick={() => {
                    setRenaming(true);
                    setNameInput(group.name);
                  }}
                >
                  {group.name}
                </h5>
              </div>
            )}
          </div>
          {!renaming && (
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => setDeleting(true)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          )}
        </div>

        <div className="config-panel-wrapper">
          {group.panels.map((panel) => (
            <Panel
              key={panel.id}
              group={group}
              panel={panel}
              setPanel={(panel) =>
                setGroup({
                  ...group,
                  panels: [
                    ...group.panels.map((x) => (x.id === panel.id ? panel : x)),
                  ],
                })
              }
              deletePanel={() =>
                setData({
                  ...data,
                  configGroups: [
                    ...data.configGroups.map((x) =>
                      x.id === group.id
                        ? {
                            ...group,
                            panels: [
                              ...group.panels.filter((x) => x.id !== panel.id),
                            ],
                          }
                        : x
                    ),
                  ],
                  globalVariables: [
                    ...data.globalVariables.filter(
                      (x) => x.subcategory !== panel.id
                    ),
                  ],
                })
              }
              onDragStart={() => onPanelDragStart(panel)}
              onDragEnd={() => onPanelDragEnd()}
              onDragOver={() => onPanelDragOver(panel)}
              data={data}
              setData={setData}
            />
          ))}
          <div
            className="config-panel new-panel"
            onClick={() =>
              setGroup({
                ...group,
                panels: [...group.panels, { id: uuid(), name: "New Panel" }],
              })
            }
          >
            <div className="d-flex justify-content-center align-items-center h-100">
              <FontAwesomeIcon icon={faPlus} size="2x" />
            </div>
          </div>
        </div>
      </div>
      {deleting && (
        <ConfirmationModal
          title="Delete Group?"
          body={`Are you sure you want to delete group ${group.name}? This will also delete all panels, elements and global variables associated with the group.`}
          onClose={() => setDeleting(false)}
          onConfirm={() => {
            deleteGroup();
            setDeleting(false);
          }}
        />
      )}
    </>
  );
}

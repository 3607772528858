import { WelcomeModal } from "./WelcomeModal";
import { useTutorial } from "../../context/TutorialContext";
import { TutorialPanel } from "./TutorialPanel";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons";

export function Tutorial() {
  const tutorial = useTutorial();
  const history = useHistory();

  return (
    <>
      {tutorial.inTutorial && tutorial.stage === "WELCOME" && (
        <WelcomeModal
          onClose={() => tutorial.skipTutorial()}
          startTutorial={() => tutorial.setStage("MODULES")}
        />
      )}

      {tutorial.inTutorial && tutorial.stage === "MODULES" && (
        <TutorialPanel
          title="Modules"
          top={275}
          left={310}
          arrowPosition="left"
          renderButton={true}
          onBtnClick={() => {
            history.push("/modules");
            tutorial.setStage("MODULES 2");
          }}
        >
          Modules are required to enable functionality in Stream Hydra. Each
          module connects to a specific service and enables that integration on
          your account.
        </TutorialPanel>
      )}

      {tutorial.inTutorial && tutorial.stage === "MODULES 2" && (
        <TutorialPanel
          title="Enable Modules"
          bottom={25}
          right={25}
          renderButton={true}
          onBtnClick={() => {
            tutorial.setStage("APPLETS");
          }}
        >
          Enable the module for each service you wish to use. If you would like
          a more in-depth explanation of each module, click on the{" "}
          <FontAwesomeIcon icon={faQuestionCircle} /> icon.
        </TutorialPanel>
      )}

      {tutorial.inTutorial && tutorial.stage === "APPLETS" && (
        <TutorialPanel
          title="Applets"
          top={150}
          left={310}
          arrowPosition="left"
          renderButton={true}
          onBtnClick={() => {
            history.push("/applets");
            tutorial.setStage("APPLETS 2");
          }}
        >
          Applets are the core of Stream Hydra. These are simple programs that
          will run any automation you require. Each module you have enabled,
          adds more functionality to the applet editor. Let's create one now.
        </TutorialPanel>
      )}
    </>
  );
}

import {
  faExclamationCircle,
  faExclamationTriangle,
  faMinusCircle,
  faPlus,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ClientUrl } from "../../Constants";
import { Team } from "../../types";
import { arrayMove } from "../shared/utils";

type TeamsListProps = {
  teams: Team[];
  updateTeamOrder: (teams: Team[]) => void;
  showAddTeamModal: () => void;
  selected?: string;
  onTeamSelect: (team: Team) => void;
};

export function TeamsList({
  teams,
  updateTeamOrder,
  showAddTeamModal,
  selected,
  onTeamSelect,
}: TeamsListProps) {
  return (
    <DragDropContext
      onDragEnd={(result) => {
        if (
          result.destination &&
          !(
            result.destination.droppableId === result.source.droppableId &&
            result.destination.index === result.source.index
          )
        ) {
          updateTeamOrder([
            ...arrayMove(teams, result.source.index, result.destination.index),
          ]);
        }
      }}
    >
      <Droppable droppableId="teams-list">
        {(provided) => (
          <ul
            className="teams-list"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {teams.map((team, index) => (
              <Draggable draggableId={team.id} index={index} key={team.id}>
                {(provided) => (
                  <li
                    className={`${
                      selected && selected === team.id ? "selected" : ""
                    }`}
                    onClick={() => onTeamSelect(team)}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                  >
                    <div className="icon">
                      <img
                        src={team.iconUrl || `${ClientUrl}/logo.png`}
                        alt="Team Icon"
                        width={50}
                        draggable={false}
                      />
                      {(!team.activeUser ||
                        team.activeUser.state === "PENDING") && (
                        <div className="team-type">
                          <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            size="lg"
                          />
                        </div>
                      )}
                      {(!team.activeUser ||
                        team.activeUser.state === "REJECTED") && (
                        <div className="team-type">
                          <FontAwesomeIcon
                            className="text-danger"
                            icon={faMinusCircle}
                            size="lg"
                          />
                        </div>
                      )}
                      {team.locked && (
                        <div className="team-type">
                          <FontAwesomeIcon
                            className="text-danger"
                            icon={faExclamationCircle}
                            size="lg"
                          />
                        </div>
                      )}
                    </div>
                  </li>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
            <li onClick={showAddTeamModal}>
              <div className="icon">
                <FontAwesomeIcon icon={faPlus} size="lg" />
              </div>
            </li>
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  );
}
